import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  doc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  deleteField,
} from "firebase/firestore"
import { CompanyContext } from "app/Company/context"
import {
  IContact,
  IContactGroupPayload,
} from "app/Conversations/conversations.types"

export const useFirestoreContact = () => {
  const { db } = useContext(FirebaseContext)
  const {
    state: { company },
  } = useContext(CompanyContext)

  const createCompanyContact = async (contact: Partial<IContact>) => {
    try {
      const c = doc(db, "companies", company.id.toString())
      const contacts = collection(db, c.path, "contacts")

      const existingContactQuery = query(
        contacts,
        where("phone", "==", contact.phone)
      )

      const queryContacts = await getDocs(existingContactQuery)

      const msisdn = `${+contact.area_code}${+contact.phone}`

      const modifiedData: Partial<IContact> = {
        ...contact,
        msisdn: +msisdn,
      }

      if (queryContacts.docs.length > 0) {
        await updateDoc(queryContacts.docs[0].ref, modifiedData)
        return queryContacts.docs[0].id
      } else {
        const createdContact = await addDoc(contacts, modifiedData)
        return createdContact.id
      }

      // await setDoc(doc(db, "companies", id), contact)
    } catch (error) {
      throw new Error(error)
    }
  }

  const saveCompanyContact = async (contact: IContact) => {
    try {
      const { id, ...data } = contact
      const c = doc(db, "companies", company.id.toString())
      const contacts = collection(db, c.path, "contacts").path

      const existingContact = doc(db, contacts, id)

      const msisdn = `${+data.area_code}${+data.phone}`

      const modifiedData: IContact = {
        ...data,
        msisdn: +msisdn,
      }

      await setDoc(existingContact, modifiedData, { merge: true })
      return id
    } catch (error) {
      throw new Error(error)
    }
  }

  const getCompanyContact = async (contact) => {
    try {
      const c = doc(db, "companies", company.id.toString())
      const contacts = collection(db, c.path, "contacts")

      const existingContactQuery = query(
        contacts,
        where("phone", "==", contact.phone)
      )

      const queryContacts = await getDocs(existingContactQuery)

      if (queryContacts.docs.length > 0) {
        return queryContacts.docs[0].data()
      } else {
        await Promise.reject("Kontakten findes ikke")
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const deleteCompanyContact = async (id: string) => {
    try {
      const c = doc(db, "companies", company.id.toString())
      const contacts = collection(db, c.path, "contacts")

      const contactToDelete = doc(contacts, id)

      await updateDoc(contactToDelete, {
        first_name: deleteField(),
        last_name: deleteField(),
        email: deleteField(),
      })
      await Promise.resolve("OK")
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCompanyContactHard = async (id: string) => {
    try {
      const c = doc(db, "companies", company.id.toString())
      const contacts = collection(db, c.path, "contacts")

      const contactToDelete = doc(contacts, id)

      await deleteDoc(contactToDelete)
      await Promise.resolve("OK")
    } catch (error) {
      console.log(error)
    }
  }

  const createCompanyContactGroup = async (group: IContactGroupPayload) => {
    try {
      const contactGroups = collection(
        db,
        `companies/${company.id.toString()}/contactgroups`
      )

      const createdContactGroup = await addDoc(contactGroups, group)

      await setDoc(
        createdContactGroup,
        { ...group, id: createdContactGroup.id },
        { merge: true }
      )

      return group

      // await setDoc(doc(db, "companies", id), contact)
    } catch (error) {
      throw new Error(error)
    }
  }

  const saveCompanyContactGroup = async (group: IContactGroupPayload) => {
    try {
      const contactGroup = doc(
        db,
        `companies/${company.id.toString()}/contactgroups/${group.id}`
      )

      await setDoc(contactGroup, group, { merge: true })
      return group
    } catch (error) {
      throw new Error(error)
    }
  }

  const deleteCompanyContactGroup = async (id: string) => {
    try {
      const contactGroups = collection(
        db,
        `companies/${company.id.toString()}/contactgroups`
      )

      const contactGroupToDelete = doc(contactGroups, id)

      await deleteDoc(contactGroupToDelete)
      await Promise.resolve("OK")
    } catch (error) {
      console.log(error)
    }
  }

  return {
    createCompanyContact,
    getCompanyContact,
    saveCompanyContact,
    deleteCompanyContact,
    deleteCompanyContactHard,
    createCompanyContactGroup,
    saveCompanyContactGroup,
    deleteCompanyContactGroup,
  }
}
