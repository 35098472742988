import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Form, FormField, Input, ValidationInput } from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"

const StyledUserForm = styled(Form)`
  padding-top: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 0;
  `}
`

function UserForm({ onFieldChange, user, title, header, children, ...props }) {
  const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
  const validationMessages = {
    ...defaultValidationMessages,
    customError: "Ups! Indtast en korrekt email.",
  }

  return (
    <StyledUserForm {...props}>
      {title && <h1>{title}</h1>}
      {header && <div>{header}</div>}
      <div>
        {/* {user.username &&
          <FormField label="Username">
            <Input value={user.username} readOnly />
          </FormField>
        } */}
        <FormField label="Fornavn">
          <Input
            placeholder="Indtast fornavn"
            value={user.firstName}
            tabIndex={0}
            onChange={(e) => onFieldChange(e, "firstName")}
          />
        </FormField>
        <FormField label="Efternavn">
          <Input
            placeholder="Indtast efternavn"
            value={user.lastName}
            tabIndex={0}
            onChange={(e) => onFieldChange(e, "lastName")}
          />
        </FormField>
        <FormField validationKey="email" label="Email*">
          <ValidationInput
            id="email"
            type="email"
            required
            placeholder="Indtast email"
            value={user.email}
            onChange={(e) => onFieldChange(e, "email")}
            customValidation={emailValidation}
            tabIndex={0}
            validationMessages={validationMessages}
          />
        </FormField>
        <FormField validationKey="phoneNumber" label="Telefonnummer">
          <Input
            id="phoneNumber"
            type="text"
            placeholder="Indtast telefon nr."
            value={user.phoneNumber}
            tabIndex={0}
            onChange={(e) => onFieldChange(e, "phoneNumber")}
          />
        </FormField>
      </div>
      {children}
    </StyledUserForm>
  )
}

export default UserForm

UserForm.defaultProps = {}

UserForm.propTypes = {
  children: PropTypes.node.isRequired,
}
