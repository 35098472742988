import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Accordion from "lib/Accordion"
import { Card } from "@clevertrack/shared"

const StyledDatepicker = styled(Accordion)`
  box-shadow: ${(props) => props.theme.mapButtonShadow};
`

function DatepickerDropdown({ toggled, children, ...props }) {
  return (
    <StyledDatepicker toggled={toggled} maxHeight="500px" {...props}>
      <Card size="sm">{children}</Card>
    </StyledDatepicker>
  )
}

export default DatepickerDropdown

DatepickerDropdown.defaultProps = {}
DatepickerDropdown.propTypes = {
  children: PropTypes.node.isRequired,
}
