import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import cogoToast from "@clevertrackdk/cogo-toast"
import Container from "app/Container"
import { getDashboards } from "services/dashboards"
import BIDashboard from "./BIDashboard"

const StyledDashboard = styled(Container)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 50;
  background: rgb(234 234 234);
`

function Dashboard({ children, ...props }) {
  const [dashboard, setDashboard] = useState(null)

  async function getDashboardsAsync() {
    try {
      const dashboardsResponse = await getDashboards()
      if (dashboardsResponse.data.result === "OK") {
        const {
          embedToken,
          embedUrl,
          embedID,
          displayName,
        } = dashboardsResponse.data.reports[0]
        const db = {
          token: embedToken,
          embed_url: embedUrl,
          report_id: embedID,
          title: displayName,
        }
        setDashboard(db)
        return true
      } else {
        cogoToast.error(`Kunne ikke indlæse dashboardet`)
        return false
      }
    } catch (error) {
      cogoToast.error("Kunne ikke indlæse dashboardet")
      return false
    }
  }

  useEffect(() => {
    if (!dashboard) getDashboardsAsync()
  }, [])

  return (
    <StyledDashboard {...props}>
      {dashboard && dashboard.hasOwnProperty("token") ? (
        <BIDashboard onRefresh={getDashboardsAsync} {...dashboard} />
      ) : null}
    </StyledDashboard>
  )
}

export default Dashboard

Dashboard.defaultProps = {}
Dashboard.propTypes = {
  children: PropTypes.node,
}
