import Datepicker from './Datepicker'
import Month from './Month'
import Day from './Day'
import Nav from './Nav'

Datepicker.Month = Month
Datepicker.Day = Day
Datepicker.Nav = Nav

export default Datepicker
