import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled, { css, keyframes } from "styled-components"
import tw from "twin.macro"
import Icon from "app/Icon"
import I18n from "app/I18n"
import isEqual from "lodash-es/isEqual"

const HeartBeat = keyframes`
  0% {
    background: #04BFAD;
    color: #fff;
  }
  100% {
    background: transparent;
    ${tw`text-brand-gray-base`}
  }
`

const HeartBeatRule = css`
  ${HeartBeat} linear .2s;
`

const StyledKPIBase = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  // overflow: hidden;
  // padding: 0.5rem 1rem;

  // color: ${(props) => props.theme.colors.blackOpaque};

  .title {
    font-weight: bold;
    display: grid;
    grid-template-columns: 3rem auto;
    align-items: baseline;
    ${tw`text-xl md:(text-lg) lg:(text-xl) opacity-80`}

    svg {
      width: 1.4rem;
      height: 1.4rem;
      // margin-right: 0.6rem;
      align-self: center;
      justify-self: center;

      ${(props) => props.theme.media.tablet_landscape_up`
        width: 1.4rem;
        height: 1.4rem;
      `}
    }
  }

  .value {
    display: block;
    // line-height: 1.25;
    background: transparent;
    padding-left: 0.8rem;
    ${tw`text-xl md:(text-lg) lg:(text-xl) opacity-60`}

    &.updated {
      animation: ${HeartBeatRule};
    }
  }


`

function KPIBase({ kpiTitle, translationKey, icon, unit, children, ...props }) {
  const [updated, setUpdated] = useState(false)
  const previousValue = useRef(null)

  const onAnimationEndHandler = () => {
    setUpdated(false)
  }

  useEffect(() => {
    if (children)
      if (
        (children.hasOwnProperty("props") &&
          !isEqual(children.props, previousValue.current)) ||
        (!children.hasOwnProperty("props") &&
          children !== previousValue.current)
      ) {
        previousValue.current = children.hasOwnProperty("props")
          ? children.props
          : children
        setUpdated(true)
      }
  }, [children])

  return (
    <StyledKPIBase {...props}>
      <span className="item">
        <span className="title">
          <Icon icon={icon} css={icon === "sigma" ? [tw`ml-2 -mt-1`] : ``} />
          <span>
            {translationKey ? (
              <I18n strKey={translationKey} />
            ) : kpiTitle ? (
              kpiTitle
            ) : (
              "<Missing Display Key>"
            )}
          </span>
        </span>
        <span
          className={`value${updated ? " updated" : ""}`}
          onAnimationEnd={onAnimationEndHandler}
        >
          {children}
          {unit && unit}
        </span>
      </span>
    </StyledKPIBase>
  )
}

export default KPIBase

KPIBase.defaultProps = {}
KPIBase.propTypes = {
  kpiTitle: PropTypes.string.isRequired,
}
