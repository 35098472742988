import { ActionMap, createActions } from "lib/reducer.types"
import {
  IFilters,
  ITrackerHistory,
  ITrackerDailyLog,
  IRoute,
  HistoryLogViewSettings,
} from "./history.types"

/* Action types */
export enum HistoryTypes {
  AddRoute = "History/AddRoute",
  SetFilters = "History/SetFilters",
  SetTrackerHistory = "History/SetTrackerHistory",
  SetTrackerLogs = "History/SetTrackerLogs",
  SetTooltip = "History/SetToolip",
  ToggleDay = "History/ToggleDay",
  PinRoute = "History/PinRoute",
  PinInterval = "History/PinInterval",
  ToggleRoute = "History/ToggleRoute",
  ToggleInterval = "History/ToggleInterval",
  ToggleMap = "History/ToggleMap",
  ToggleAnimating = "History/ToggleAnimating",
  ToggleRouteTimestamp = "History/ToggleRouteTimestamp",
  LoadingRenderCriticalData = "History/LoadingRenderCriticalData",
  SetLogViewSettings = "History/SetLogViewSettings",
  LoadingHistoryLogs = "History/LoadingHistoryLogs",
  IsolateRoutes = "History/IsolateRoute",
  Clear = "History/Clear",
  Reset = "History/Reset",
  ResetRoutes = "History/ResetRoutes",
}

/* Action creators */
type Actions = {
  [HistoryTypes.AddRoute]: { route: IRoute }
  [HistoryTypes.SetFilters]: { filters: IFilters }
  [HistoryTypes.SetTrackerHistory]: { history: ITrackerHistory[] }
  [HistoryTypes.SetTrackerLogs]: { logs: ITrackerDailyLog[] }
  [HistoryTypes.SetTooltip]: { toggledTooltip: any }
  [HistoryTypes.ToggleDay]: { id: string }
  [HistoryTypes.PinRoute]: { routeId: string }
  [HistoryTypes.ToggleRoute]: { routeId: string }
  [HistoryTypes.ToggleAnimating]: { animating: boolean }
  [HistoryTypes.ToggleRouteTimestamp]: { timestamp: string | null }
  [HistoryTypes.LoadingRenderCriticalData]: {
    loading: boolean
    total?: number
    completed?: number
  }
  [HistoryTypes.SetLogViewSettings]: { viewSettings: HistoryLogViewSettings }
  [HistoryTypes.LoadingHistoryLogs]: boolean
  [HistoryTypes.ToggleMap]: {}
  [HistoryTypes.Clear]: {}
  [HistoryTypes.Reset]: null
  [HistoryTypes.ResetRoutes]: {}
}

export type HistoryActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const HistoryActions = createActions<Actions>()
