import { privateApi } from "utils/api"

/**
 * Get trackers and their service setup
 */

const getServices = async () => {
  try {
    const response = privateApi().get("/service")
    return response
  } catch (err) {
    console.log(err)
  }
}

/**
 * Post / Create interval to tracker by id
 * @param {number} id tracker / vehicle ID
 * @param {object} body post body for interval
 */

const createInterval = async (id, body) => {
  try {
    const response = privateApi().post(`/service/${id}`, body)
    return response
  } catch (err) {
    console.log(err)
  }
}

/**
 * Patch / Update interval by interval id
 * @param {number} id interval id
 * @param {object} body patch body for interval
 */

const updateInterval = async (id, body) => {
  try {
    const response = privateApi().patch(`/service/${id}`, body)
    return response
  } catch (err) {
    console.log(err)
  }
}

/**
 * Delete interval by interval id
 * @param {number} id interval id to be deleted
 */

const deleteInterval = async (id) => {
  try {
    const response = privateApi().delete(`/service/${id}`)
    return response
  } catch (err) {
    console.log(err)
  }
}

export { getServices, createInterval, updateInterval, deleteInterval }
