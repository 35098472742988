import { privateApi } from "utils/api"

/**
 * Finds all vehicles by the passed id
 */
/* const getCarsByGroupId = async (id) => {
  try {
    const response = await api.post('/vehicles');
    console.log(response)
  } catch (e) {
    console.error(e);
  } */
// return cars.map(car => car.groups.find(id))
// }

/**
 * Returns all cars available
 */

const getAllVehicles = async () => {
  try {
    const response = await privateApi().get("/vehicles")
    return response
  } catch (e) {
    console.log(e)
  }
}

const getVehiclesInGroup = async (id) => {
  try {
    const response = await privateApi().get(`/VehicleGroup/${id}`)
    return response
  } catch (e) {
    console.log(e)
  }
}

const getVehicleDaysum = async (id) => {
  try {
    const response = await privateApi().get(`/currentdaysum/${id}`)
    return response
  } catch (e) {
    console.log(e)
  }
}

const updateVehiclesInGroup = async (id, body) => {
  try {
    const response = await privateApi().patch(`/VehicleGroup/${id}`, body)
    return response
  } catch (e) {
    console.log(e)
  }
}

const updateVehiclesData = async (ids) => {
  try {
    const response = await privateApi().post(`/vehicles/values`, ids)
    return response
  } catch (e) {
    console.log(e)
  }
}

const updateVehicleFeatures = async (id, body) => {
  try {
    const response = await privateApi().patch(`/VehiclesFeatures/${id}`, body)
    return response
  } catch (e) {
    console.log(e)
  }
}

const updateDriversID = async (body) => {
  try {
    const response = await privateApi().post(`/DriverWhitelist`, body)
    return response
  } catch (e) {
    console.log(e)
  }
}

const deleteDriversID = async (id) => {
  try {
    const response = await privateApi().delete(`/DriverWhitelist/${id}`)
    return response
  } catch (e) {
    console.log(e)
  }
}

const setVehicleGroups = async (id, body) => {
  try {
    const response = await privateApi().patch(`/VehicleToGroups/${id}`, body)
    return response
  } catch (e) {
    console.log(e)
  }
}

export {
  getAllVehicles,
  getVehiclesInGroup,
  getVehicleDaysum,
  updateVehiclesInGroup,
  updateVehiclesData,
  updateVehicleFeatures,
  updateDriversID,
  deleteDriversID,
  setVehicleGroups,
}
