import React, { useContext, useState, useEffect } from "react"
import tw from "twin.macro"
import { IHintProps, IHintIconProps, HintKey, IHintContent } from "./hint.types"
import Icon, { IconSizeEnum } from "lib/Icon"
import { getHintContent } from "./helper"
import { Button } from "@clevertrack/shared"
import Accordion from "lib/Accordion"
import { useUser } from "app/User/hooks"
import { UserContext } from "app/User/context"

export const HintIcon: React.FC<IHintIconProps> = ({ type, ...props }) => {
  const { toggleUserVisibilitySetting, getUserVisibilitySetting } = useUser()

  return !getUserVisibilitySetting(type) ? (
    <span
      tw="w-10 ml-2 cursor-pointer"
      onClick={() => toggleUserVisibilitySetting(type)}
      {...props}
    >
      <Icon icon="question-circle" size={IconSizeEnum.SM} />
    </span>
  ) : null
}

export const baseHintStyle = tw`p-4 text-lg flex items-start gap-x-8`
export const hintTheme = {
  default: tw`border border-solid border-brand-500 bg-blue-50`,
  warning: tw`border border-solid border-yellow-200 bg-yellow-50`,
  error: tw`border border-solid border-red-200 bg-red-50`,
}

export const Hint: React.FC<IHintProps> = ({
  type,
  inline,
  expireAfterMs,
  toggled,
  variant = "default",
  rememberOnDismiss = true,
  ...props
}) => {
  const {
    state: { userSettings },
  } = useContext(UserContext)
  const { toggleUserVisibilitySetting, getUserVisibilitySetting } = useUser()
  const [isVisible, setIsVisible] = useState(!!getUserVisibilitySetting(type))

  const onDismissHandler = (k: HintKey) => {
    setIsVisible(false)
    if (rememberOnDismiss) toggleUserVisibilitySetting(k)
  }

  const hintContent: IHintContent = getHintContent(type)

  useEffect(() => {
    setIsVisible(!!getUserVisibilitySetting(type))
  }, [userSettings?.visibilitySettings])

  useEffect(() => {
    if (toggled === true) {
      setIsVisible(true)
      if (rememberOnDismiss) toggleUserVisibilitySetting(type)
    } else {
      setIsVisible(false)
    }
  }, [toggled])

  return (
    <Accordion toggled={isVisible}>
      <div css={[baseHintStyle, hintTheme[variant]]} {...props}>
        <span tw="w-10">
          <Icon icon="info-circle" size={IconSizeEnum.MD} />
        </span>
        <p
          tw="m-0 text-xl leading-snug flex-grow max-w-5xl"
          dangerouslySetInnerHTML={{ __html: hintContent.content }}
        />
        <Button
          tw="justify-self-end m-0 w-10 h-8 flex items-center justify-end"
          variant="icon"
          type="button"
          onClick={() => onDismissHandler(type)}
        >
          <Icon icon="close" size={IconSizeEnum.SM} />
        </Button>
      </div>
    </Accordion>
  )
}
