import React, { useState } from "react"
import tw from "twin.macro"
import { DeviceStatusCriterium } from "routes/Alarms/alarm.types"
import Card from "lib/Card"
import TrackerStatus from "app/TrackerStatus"
import I18n from "app/I18n"
import { getStatusKey } from "app/TrackerStatus/helper"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"

interface IDeviceStatusCriteriaProps {
  criterium: DeviceStatusCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceStatusCriteriaElement extends IDeviceStatusCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

const possibleStates = [0, 1, 2, 3, 4, 5]

export const DeviceStatusCriteriaElement: React.FC<IDeviceStatusCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  return (
    <AlarmConfigurationComponent tw="border-b-0">
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">Statusskift</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">Annullér</span>
            </>
          ) : (
            <>
              <span tw="text-lg">Redigér</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">
        Opfyldt når: Trackerens status skifter fra{" "}
        {criterium.triggerFromStatus
          .map((state, i) => {
            const status = I18n({ strKey: getStatusKey(state) })
            return i === criterium.triggerFromStatus.length - 1 &&
              criterium.triggerFromStatus.length > 1
              ? `eller ${status.toLowerCase()}`
              : criterium.triggerFromStatus.length > 1
              ? `${status.toLowerCase()},`
              : `${status.toLowerCase()}`
          })
          .join(" ")}{" "}
        til{" "}
        {criterium.triggerOnStatus
          .map((state, i) => {
            const status = I18n({ strKey: getStatusKey(state) })
            return i === criterium.triggerOnStatus.length - 1 &&
              criterium.triggerOnStatus.length > 1
              ? `eller ${status.toLowerCase()}`
              : criterium.triggerOnStatus.length > 1
              ? `${status.toLowerCase()},`
              : `${status.toLowerCase()}`
          })
          .join(" ")}
        .
      </span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceStatusCriteria: React.FC<IDeviceStatusCriteriaProps> = ({
  criterium = {},
  onSave,
  onCancel,
  ...props
}) => {
  const [triggerFromStatus, setTriggerFromStatus] = useState(
    criterium?.triggerFromStatus ?? []
  )
  const [triggerOnStatus, setTriggerOnStatus] = useState(
    criterium?.triggerOnStatus ?? []
  )

  const onSelectFromHandler = (state) => {
    const newFromState = triggerFromStatus.includes(state)
      ? [...triggerFromStatus.filter((x) => x !== state)]
      : [...triggerFromStatus, state]

    const newToState = triggerOnStatus.filter((x) => x !== state)

    setTriggerFromStatus(newFromState)
    setTriggerOnStatus(newToState)
  }

  const onSelectToHandler = (state) => {
    const newToState = triggerOnStatus.includes(state)
      ? [...triggerOnStatus.filter((x) => x !== state)]
      : [...triggerOnStatus, state]

    const newFromState = triggerFromStatus.filter((x) => x !== state)

    setTriggerFromStatus(newFromState)
    setTriggerOnStatus(newToState)
  }

  const onSaveHandler = () => {
    if (onSave)
      onSave({
        ...criterium,
        triggerFromStatus,
        triggerOnStatus,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>Statusskift</CriteriumTypeTitle>
      <p tw="text-xl mt-0 text-brand-gray-base">
        Alarmering når en enhed skifter status
      </p>
      <p tw="mt-8">Når enheden skifter status fra...</p>
      <div tw="flex items-center justify-between space-y-0 space-x-1">
        {possibleStates.map((state) => {
          return (
            <Card
              key={state}
              size="sm"
              tw="cursor-pointer p-2 text-lg md:(w-32 h-24 text-xl p-4) flex flex-col items-center hover:opacity-100 transition"
              css={[
                !triggerFromStatus.includes(state) &&
                  tw`shadow-none opacity-60`,
              ]}
              onClick={() => onSelectFromHandler(state)}
            >
              <TrackerStatus status={state} showTitle />
            </Card>
          )
        })}
      </div>
      <p>til...</p>
      <div tw="flex items-center justify-between space-y-0 space-x-1">
        {possibleStates.map((state) => {
          return (
            <Card
              key={state}
              size="sm"
              tw="cursor-pointer p-2 text-lg md:(w-32 h-24 text-xl p-4) flex flex-col items-center hover:opacity-100 transition"
              css={[
                !triggerOnStatus.includes(state) && tw`shadow-none opacity-60`,
              ]}
              onClick={() => onSelectToHandler(state)}
            >
              <TrackerStatus status={state} showTitle />
            </Card>
          )
        })}
      </div>
      {onSave && onCancel && (
        <EditorButtonGroup
          onSave={onSaveHandler}
          onCancel={onCancelHandler}
          saveDisabled={
            triggerFromStatus.length === 0 || triggerOnStatus.length === 0
          }
        />
      )}
    </>
  )
}
