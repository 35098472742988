import React, { useLayoutEffect, useContext } from "react"
import PropTypes from "prop-types"
import Tracker from "../Tracker"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { IDevice } from "app/Device/types"
import { useDevices } from "app/Device/hooks"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { AppContext } from "context"
import { LazyListItem } from "app/LazyList"
import { getDisplayKey } from "app/Device/helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"

interface ITrackerItemProps {
  groupID: number
  deviceID: number
  simple: boolean
  device: IDevice
}

const TrackerItem: React.FC<ITrackerItemProps> = (props) => {
  const { deviceID, groupID, children, simple, ...restProps } = props
  const {
    state: { syncAddress },
    dispatch,
  } = useContext(DeviceContext)
  const { dispatch: appDispatch } = useContext(AppContext)
  const { subscribeToSomeDevices } = useDevices(syncAddress)
  const { showDeviceHealth } = useDevices()

  const onClickHandler = (e) => {
    e.stopPropagation()
    // Toggle specific tracker
    if (simple) {
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled: false })
      )
      dispatch(
        DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
          groupID,
          deviceID,
          untoggleRest: true,
        })
      )
    } else {
      dispatch(
        DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
          groupID,
          deviceID,
        })
      )
    }
  }

  useLayoutEffect(() => {
    if (props.device.toggled) {
      if (!simple) subscribeToSomeDevices([deviceID])
    }
  }, [props.device.toggled, props.simple])

  const toggleDeviceHealth = showDeviceHealth(restProps.device)

  const height = 46

  return (
    <LazyListItem height={height} selector="#lazyContainerTile">
      <Tracker
        onClick={onClickHandler}
        simple={simple}
        groupID={groupID}
        deviceID={deviceID}
        showDeviceHealth={toggleDeviceHealth}
        {...restProps}
      />
    </LazyListItem>
  )
}

export default TrackerItem

TrackerItem.defaultProps = {}
TrackerItem.propTypes = {
  children: PropTypes.node,
}
