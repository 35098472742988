import React from "react"
import { Spring } from "react-spring/renderprops"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledExpander = styled.div`
  overflow: hidden;
`

function Expander({ children, toggled, forwardRef, ...props }) {
  return (
    <Spring
      config={{
        tension: 170,
        friction: 22,
        clamp: true,
      }}
      from={{ height: 0, width: 0 }}
      to={{
        height: toggled ? "auto" : 0,
        width: toggled ? "auto" : 0,
      }}
    >
      {(styleProps) => (
        <StyledExpander style={styleProps} ref={forwardRef} {...props}>
          {children}
        </StyledExpander>
      )}
    </Spring>
  )
}

export default Expander

Expander.defaultProps = {
  toggled: false,
}

Expander.propTypes = {
  children: PropTypes.node.isRequired,
  toggled: PropTypes.bool,
}
