import React from "react"
import format from "date-fns/format"
import styled, { css, keyframes } from "styled-components"
import tw, { TwStyle } from "twin.macro"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Radar, Responsive } from "@clevertrack/shared"

const StyledEventFeedItem = styled.article<{
  slim: boolean
  expanded: boolean
  isRemainingEvent: boolean
  emphasize: boolean
  faded: boolean
}>`
  display: grid;
  grid-template-columns: 3rem 3rem auto;
  column-gap: 0.5rem;
  ${tw`items-start relative`}

  ${(props) =>
    props.faded
      ? `
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.6;
      background: white;
      content: "";
      display: block;
      z-index: 50;
      pointer-events: none;
    }
  `
      : ``}

  ${(props) => props.theme.media.tablet_landscape_up`
    column-gap: 1rem;
    grid-template-columns: 3rem 2rem 13rem auto;
  `}

  .timestamp {
  }

  .bullet {
    ${tw`relative z-50 transform scale-100 transition hover:(shadow-card scale-110)`}
  }

  &:first-child {
    @media print {
      margin-top: 1rem;
    }
  }

  &:first-child .bullet,
  &:last-child .bullet {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }
`

const CustomPulse = keyframes`
  to {
    transform: scale(1.75);
    background: transparent;
    border-color: transparent;
  }
`

const CustomPulseRule = css`
  ${CustomPulse} 1.2s;
`

const StyledRadar = styled(Radar)`
  &:before {
    border: none;
    animation: ${CustomPulseRule};
  }
  &:after {
    display: none;
  }
`

type EventFeedItemProps = {
  bulletComponent: React.ReactElement
  timestampComponent?: React.ReactElement
  event: any
  animateIfInProgress?: boolean
  hasRemainingEvents?: boolean
  eventsExpanded?: boolean
  isRemainingEvent: boolean
}

export type EventFeedItemOptions = {
  eventBulletPropertyKeys: string[]
  eventBulletIconKey?: string
  eventBulletIconSize?: IconSizeEnum
  eventBulletCss: TwStyle
  eventTimestampKey: string
  eventTimestampFormat: string
  eventIsInProgress: boolean
  eventEmphasizeCondition: [string, string | null]
  eventBulletClickEvent: (...args) => void
  renderEventDescription: (...args) => JSX.Element
  renderEventContent: (...args) => JSX.Element
}

export const EventFeedItem: React.FC<
  EventFeedItemProps & EventFeedItemOptions
> = ({
  bulletComponent,
  timestampComponent,
  event,
  animateIfInProgress,
  eventBulletPropertyKeys,
  eventBulletIconKey,
  eventBulletIconSize = IconSizeEnum.MD,
  eventBulletCss,
  eventTimestampKey,
  eventTimestampFormat,
  eventIsInProgress,
  eventEmphasizeCondition,
  hasRemainingEvents = false,
  eventBulletClickEvent,
  renderEventDescription,
  renderEventContent,
  isRemainingEvent = false,
  eventsExpanded = false,
  ...props
}) => {
  const eventTimestamp = format(
    new Date(event[eventTimestampKey]),
    eventTimestampFormat
  )

  const eventTimestampHover = format(
    new Date(event[eventTimestampKey]),
    "HH:mm:ss"
  )

  const [conditionKey, conditionValue, emphasizeCss] = eventEmphasizeCondition

  const eventBulletProps = eventBulletPropertyKeys.reduce(
    (returnProps, key) => {
      if (!returnProps[key]) returnProps[key] = event[key]
      return returnProps
    },
    {}
  )

  if (eventBulletCss && isRemainingEvent) {
    eventBulletProps.css = eventBulletCss
  }

  const eventBulletIcon = eventBulletIconKey ? event[eventBulletIconKey] : null

  const onBulletClickEventHandler = (e) => {
    e.stopPropagation()
    if (eventBulletClickEvent) {
      eventBulletClickEvent(event)
    }
  }

  return (
    <StyledEventFeedItem
      expanded={eventsExpanded}
      slim={hasRemainingEvents}
      isRemainingEvent={isRemainingEvent}
      faded={conditionValue && event[conditionKey] !== conditionValue}
      emphasize={event[conditionKey] === conditionValue}
      {...props}
    >
      {timestampComponent ? (
        <timestampComponent.type
          {...timestampComponent.props}
          title={eventTimestampHover}
        >
          {eventTimestamp}
        </timestampComponent.type>
      ) : (
        <span className="timestamp">{eventTimestamp}</span>
      )}

      <bulletComponent.type
        className="bullet"
        onClick={onBulletClickEventHandler}
        {...bulletComponent.props}
        {...eventBulletProps}
        event={event}
      >
        {eventBulletIcon && (
          <Icon size={eventBulletIconSize} icon={eventBulletIcon} />
        )}
        {eventIsInProgress && animateIfInProgress && <StyledRadar />}
      </bulletComponent.type>
      <Responsive
        phone={
          <span>
            {renderEventDescription && renderEventDescription(event)}
            <span tw="block opacity-80">
              {renderEventContent && renderEventContent(event)}
            </span>
          </span>
        }
        tabletLandscape={
          <>
            {renderEventDescription && renderEventDescription(event)}
            <span tw="opacity-80">
              {renderEventContent && renderEventContent(event)}
            </span>
          </>
        }
      />
    </StyledEventFeedItem>
  )
}
