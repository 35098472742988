import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ResultListGroup } from "../ResultListGroup"
import { ResultList } from "../ResultListGroup/ResultList"
import { Result } from "../ResultListGroup/Result"

function GroupTrackerSearchResults({
  query,
  results,
  suggestions,
  inSelection,
  availableForSelection,
  inSelectionTitle,
  availableForSelectionTitle,
  onAddToSelection,
  onRemoveFromSelection,
  onMatch,
  children,
  groupTrackerCountMap,
  ...props
}) {
  function isMatch(resultItem) {
    return !!results.find((item) => item.item.id === resultItem.id)
  }

  function isSuggestion(suggestionItem) {
    return !!suggestions
      .filter((x) => {
        return (
          !(query.length === 1 && x.score >= 0.04) ||
          !(query.includes(" ") && x.score >= 0.04)
        )
      })
      .find((item) => item.item.id === suggestionItem.id)
  }

  const classNames = []
  if ((results.length > 0 || suggestions.length > 0) && query.length > 0)
    classNames.push("results")

  useEffect(() => {
    if (onMatch) {
      const selectionMatches =
        inSelection.filter(isMatch).length +
        inSelection.filter(isSuggestion).length
      const availableMatches =
        availableForSelection.filter(isMatch).length +
        availableForSelection.filter(isSuggestion).length
      onMatch(selectionMatches + availableMatches)
    }
  })

  return (
    <ResultListGroup className={classNames.join(" ")}>
      <div>
        <ResultList title={availableForSelectionTitle}>
          {availableForSelection.map((item) => {
            const classNames = []
            const match = isMatch(item)
            const suggestion = isSuggestion(item)
            if (match) classNames.push("match")
            if (suggestion) classNames.push("suggestion")
            return (
              <Result
                key={item.id}
                className={classNames.join(" ")}
                onClick={() => onAddToSelection(item.id)}
                groupTrackerCountMap={groupTrackerCountMap}
                {...item}
              />
            )
          })}
        </ResultList>
      </div>
      {inSelection && inSelectionTitle && (
        <div>
          <ResultList title={inSelectionTitle}>
            {inSelection.map((item) => {
              const classNames = []
              const match = isMatch(item)
              const suggestion = isSuggestion(item)
              if (match) classNames.push("match")
              if (suggestion) classNames.push("suggestion")
              return (
                <Result
                  key={item.id}
                  className={classNames.join(" ")}
                  onClick={() => onRemoveFromSelection(item.id)}
                  groupTrackerCountMap={groupTrackerCountMap}
                  {...item}
                />
              )
            })}
          </ResultList>
        </div>
      )}
    </ResultListGroup>
  )
}

export default GroupTrackerSearchResults

GroupTrackerSearchResults.defaultProps = {}
GroupTrackerSearchResults.propTypes = {
  children: PropTypes.node,
}
