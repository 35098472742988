import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "app/Container"

const StyledPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 50;
`

const StyledInnerPage = styled(Container)`
  padding: 0;
  height: 100%;
  overflow-y: scroll;

  ${(props) => props.theme.media.tablet_landscape_up`
      width: 100%;
      margin: 0 auto;
      padding: 0;
  `}
`

function Page({ children, ...props }) {
  return (
    <StyledPage {...props}>
      <StyledInnerPage>{children}</StyledInnerPage>
    </StyledPage>
  )
}

export default Page

Page.defaultProps = {}
Page.propTypes = {
  children: PropTypes.node.isRequired,
}
