import { publicApi } from 'utils/api'

/**
 * Reset password by email from public api
 */

const resetPassword = async (body) => {
  try {
    const response = await publicApi().post(`/user/reset/public`, body)
    return response.data
  } catch (e) {
    console.error(e)
  }
}

const checkPasswordToken = async (token) => {
  try {
    const response = await publicApi(token).post(`/user/0/reset`)
    const { result, timeout } = response.data
    return (result === "OK" && timeout === 0) ? true : false
  } catch (e) {
    console.error(e)
  }
}

const setPasswordWithToken = async (token, body) => {
  try {
    const response = await publicApi(token).patch(`/user/0/reset`, body)
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export { resetPassword, checkPasswordToken, setPasswordWithToken }
