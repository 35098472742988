import React, {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { useSpring, animated } from "react-spring"
import { format } from "date-fns"
import { groupBy } from "lodash-es"
import { MapContext } from "./context"
import { ViewportContext } from "context/ViewportContext"
import useOnClickOutside from "hooks/useOnClickOutside"
import useOnDrag from "hooks/useOnDrag"
import { setActiveCluster, setAllowScroll, toggleCluster } from "./actions"
import { isViewport } from "helpers/viewport"
import { getStatusesInCluster } from "./helper"
import AnimatedPosition from "./AnimatedPosition"
import TrackerStatus from "app/TrackerStatus"
import { convertTrackerStatusCountToSeries } from "app/Chart/helper"
import Donut from "app/Chart/Donut"
import TrackerHeading from "app/TrackerHeading"
import {
  LostGSM,
  LostGPS,
  ExternalPowerLost,
  BatteryState,
} from "app/Device/Health"

const StyledCluster = styled.span`
  width: 1px;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: ${(props) => (props.isToggled ? 300 : 1)};

  &:hover {
    z-index: 200;
  }
`

const StyledPosition = styled.span<{ minimize: boolean }>`
  font-size: ${(props) => (props.minimize ? `1.3rem` : `1.5rem`)};
  font-weight: bold;
  line-height: 1;
  background: ${(props) => props.theme.colors.white};
  // box-shadow: 0px 6px 4px -1px rgba(0, 0, 0, 0.3);
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.clickable ? `pointer` : `default`)};
`

const StyledAnimatedPosition = styled(AnimatedPosition)`
  z-index: 50;
`

const StyledDot = styled(TrackerStatus)`
  width: 2rem;
  height: 2rem;
  display: flex;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`

const StyledLeaf = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  cursor: pointer;
`

const StyledInformation = styled(animated.div)<{ toggled: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: scale3d(0.8, 0.8, 0.8) translate3d(-50%, 0, 0);
  background: white;
  padding: 1rem 1rem 1rem 1rem;
  z-index: 30;
  transform-origin: top left;
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  opacity: 0;
  max-height: 17rem;
  overflow-y: scroll;
  font-size: 1.2rem;

  ${(props) =>
    props.toggled
      ? `transform: scale3d(1, 1, 1) translate3d(-50%, 0, 0);
         opacity: 1;`
      : `transform: scale3d(0.8, 0.8, 0.8) translate3d(-50%, 0, 0);
        opacity: 0;`}

  ${(props) => props.theme.media.tablet_landscape_up`
    max-height: 30rem;
    overflow-y: scroll;
    // border-radius: 2rem 0 0 0;
    // font-size: 1.4rem;
  `}
`

const StyledHeading = styled(TrackerHeading)`
  width: 1rem;
  height: 1rem;
  transform: ${(props) => `rotateZ(${props.heading - 45}deg)`};
  fill: ${(props) => props.theme.colors.white};
  position: absolute;
  z-index: 100;
`

const StyledDonut = styled(Donut)`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate3d(-0.5rem, -0.5rem, 0);
  z-index: -10;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.3);

  svg {
    fill: white;
    // filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
  }
`

const Cluster: React.FC = ({
  children,
  onClick,
  onMouseEnter,
  onLeafs,
  keepOpen = false,
  cluster,
  hideInformation,
  showProps,
  breadCrumb = false,
  onLeafClick,
  ...props
}) => {
  // Get the toggledCluster to ensure only one cluster is open at a time.
  const {
    state: { activeClusters, allowScroll },
    dispatch,
  } = useContext(MapContext)
  const vp = useContext(ViewportContext)
  const {
    id,
    properties: { point_count, point_count_abbreviated, cluster_id },
    lat,
    lng,
  } = props
  const toggled = activeClusters.some((x) => x === cluster_id)
  const infoWindowProps = useSpring({
    from: {
      transform: toggled
        ? `scale3d(1, 1, 1) translate3d(${
            isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? `-50%` : `0`
          }, 0, 0)`
        : `scale3d(0.8, 0.8, 0.8) translate3d(${
            isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? `-50%` : `0`
          }, 0, 0)`,
      opacity: toggled ? `1` : `0`,
    },
    to: {
      transform: toggled
        ? `scale3d(1, 1, 1) translate3d(${
            isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? `-50%` : `0`
          }, 0, 0)`
        : `scale3d(0.8, 0.8, 0.8) translate3d(${
            isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? `-50%` : `0`
          }, 0, 0)`,
      opacity: toggled ? `1` : `0`,
    },
  })

  const getLeaves = useCallback(() => {
    if (toggled) return cluster.getLeaves(cluster_id, Infinity)
    return []
  }, [cluster, activeClusters])

  const onLeafClickHandler = useCallback(
    (e, leafId, properties) => {
      e.stopPropagation()
      if (!keepOpen) dispatch(setActiveCluster(null))
      if (onLeafs) onLeafs({ lat, lng }, leafId)
      if (onLeafClick) onLeafClick({ lat, lng }, leafId, properties)
    },
    [onLeafClick]
  )

  function onClickHandler(e) {
    e.stopPropagation()
    if (toggled) {
      dispatch(toggleCluster(cluster_id))
    } else {
      dispatch(setActiveCluster(cluster_id))
      dispatch(setAllowScroll(false))
    }
    if (onClick) onClick()
  }

  const [allowClickOutside, setAllowClickOutside] = useState(false)
  useOnDrag({
    clickHandler: () => setAllowClickOutside(true),
    dragHandler: () => setAllowClickOutside(false),
  })

  function onClickOutside() {
    if (allowClickOutside) {
      dispatch(setAllowScroll(true))
      dispatch(setActiveCluster(null))
    }
  }

  const onMouseEnterHandler = useCallback(
    (e) => dispatch(setAllowScroll(false)),
    [dispatch, setAllowScroll]
  )

  const onMouseLeaveHandler = useCallback(
    (e) => dispatch(setAllowScroll(true)),
    [dispatch, setAllowScroll]
  )

  const infoWindowRef = useRef(null)

  const onTouchHandler = useCallback(
    (e) => {
      e.stopPropagation()
      if (infoWindowRef.current.contains(e.target)) e.stopPropagation()
    },
    [infoWindowRef]
  )

  /* function onTouchMoveHandler(e) {
    if (infoWindowRef.current.contains(e.target)) e.stopPropagation()
  }

  function onTouchEndHandler(e) {
    if (infoWindowRef.current.contains(e.target)) e.stopPropagation()
  } */

  useOnClickOutside(infoWindowRef, onClickOutside)

  /* useEffect(() => {
    if (!isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])) {
      dispatch(setAllowScroll(true))
    }
  }, [children]) */

  useLayoutEffect(() => {
    if (activeClusters.length === 0) dispatch(setAllowScroll(true))
  }, [activeClusters])

  /* const cluster_status = useRef()
  const cluster_status_series = useRef()
  const leaves = useRef() */

  if (!id) return null

  const cluster_status = getStatusesInCluster(cluster, id)
  const cluster_status_series = cluster_status
    ? convertTrackerStatusCountToSeries(cluster_status)
    : null
  const leaves = getLeaves()

  // if (!cluster_status && !cluster_status_series) return null
  const leavesAddressGroups =
    showProps && showProps.includes("address")
      ? groupBy(leaves, ({ properties: { address } }) => address.split(", ")[1])
      : null

  function renderLeaves(leavesToRender) {
    return leavesToRender
      .sort((a, b) =>
        a.properties.timestamp > b.properties.timestamp ? 1 : -1
      )
      .map((leaf) => {
        const {
          id,
          properties: {
            status,
            speed,
            heading,
            name,
            address,
            timestamp,
            duration,
          },
        } = leaf

        const time = timestamp
          ? format(
              new Date(
                `${timestamp.indexOf("Z") > 0 ? timestamp : `${timestamp}Z`}`
              ),
              "HH:mm"
            )
          : null

        return (
          <StyledLeaf
            key={id}
            onClick={(e) => onLeafClickHandler(e, id, leaf.properties)}
          >
            {!leavesAddressGroups && !breadCrumb && (
              <StyledDot status={status} {...leaf.properties}>
                {[1, 3, 4, 6].some((num) => num === status) && (
                  <TrackerHeading
                    heading={heading}
                    speed={speed}
                    icon="location-arrow"
                  />
                )}
              </StyledDot>
            )}
            <span>{name}</span>
            {showProps && (
              <span tw="flex flex-row items-center">
                {showProps && showProps.includes("timestamp") && (
                  <span tw="mr-4 font-bold">{time}</span>
                )}
                <StyledDot status={status}>
                  {[1, 3, 4, 6].some((num) => num === status) && (
                    <TrackerHeading
                      heading={heading}
                      speed={speed}
                      icon="location-arrow"
                    />
                  )}
                </StyledDot>
                {showProps && showProps.includes("timestamp") && duration && (
                  <span tw="mr-4 w-24">{` ${duration}`}</span>
                )}
                {showProps && showProps.includes("address") && (
                  <span tw="opacity-80">
                    {address === "?" ? (
                      <span tw="italic">Ukendt adresse</span>
                    ) : (
                      address.split(", ")[0]
                    )}
                  </span>
                )}
              </span>
            )}
            {leaf.properties && leaf.properties.values && (
              <span tw="border-solid flex border-0 border-l border-brand-gray-light ml-4">
                <LostGSM device={leaf.properties} />
                <LostGPS device={leaf.properties} />
                <ExternalPowerLost device={leaf.properties} />
                <BatteryState device={leaf.properties} />
              </span>
            )}
          </StyledLeaf>
        )
      })
  }

  return (
    <StyledCluster key={id} isToggled={toggled} ref={infoWindowRef}>
      <StyledAnimatedPosition>
        <StyledPosition
          onClick={onClickHandler}
          onMouseEnter={onMouseEnter ? onMouseEnter : () => ({})}
          clickable={!hideInformation}
          minimize={+point_count >= 100}
        >
          {point_count}
          <StyledDonut
            series={cluster_status_series}
            onMouseEnter={onMouseEnter ? onMouseEnter : () => ({})}
          />
        </StyledPosition>
      </StyledAnimatedPosition>
      <StyledInformation
        key={id}
        style={infoWindowProps}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onTouchStart={onTouchHandler}
        onTouchMove={onTouchHandler}
        onTouchEnd={onTouchHandler}
        toggled={toggled}
      >
        {leavesAddressGroups
          ? Object.entries(leavesAddressGroups).map(([key, groupLeaves]) => {
              return (
                <>
                  <span tw="mb-2 block mt-4 first:mt-0 text-2xl opacity-80 font-bold">
                    {key === "undefined" ? "Ukendt" : key}
                  </span>
                  {renderLeaves(groupLeaves)}
                </>
              )
            })
          : renderLeaves(leaves)}
      </StyledInformation>
    </StyledCluster>
  )
}

export default React.memo(Cluster)

Cluster.defaultProps = {}
Cluster.propTypes = {
  children: PropTypes.node,
}
