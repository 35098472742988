import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import "twin.macro"

const addRems = (a, b) => `${parseFloat(a) + parseFloat(b)}rem`
const bulletTopOffset = ".7rem"

const StyledEvent = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.size} 1fr;
  grid-column-gap: 1rem;

  .content {
    padding-bottom: 3rem;
  }
`

const StyledContentBefore = styled.div``

const StyledBulletContainer = styled.span`
  position: relative;
  width: 100%;
  height: 100%;

  ${StyledEvent}:first-child &:before {
    top: ${(props) => addRems(props.size, bulletTopOffset)};
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: ${(props) => props.thickness};
    top: 0;
    bottom: 0;
    background: ${(props) => props.theme.colors[props.lineColor]};
    opacity: 0.5;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
  }

  ${StyledEvent}:last-child &:before {
    bottom: calc(100% - ${(props) => addRems(props.size, bulletTopOffset)});
  }
`

const Bullet = styled.div`
  width: 100%;
  padding-bottom: 100%;
  margin-top: ${bulletTopOffset};
  border-radius: 50%;
  background: ${(props) => props.theme.colors[props.color]};
  position: relative;
  z-index: 50;
`

const iconPadding = 0.3

const StyledIcon = styled(Icon)`
  position: absolute;
  width: ${(props) => `${parseFloat(props.bulletSize) - iconPadding}rem`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

function Event({
  before,
  icon,
  color,
  lineColor,
  size,
  bullet,
  thickness,
  children,
  ...props
}) {
  return (
    <StyledEvent size={size} {...props}>
      {before && <StyledContentBefore>{before}</StyledContentBefore>}
      <StyledBulletContainer
        size={size}
        thickness={thickness}
        lineColor={lineColor}
        className="bullet-container"
      >
        {bullet ? (
          bullet
        ) : (
          <Bullet color={color}>
            {!!icon && (
              <StyledIcon
                icon={icon}
                bulletSize={size}
                size="sm"
                tw="fill-current"
              />
            )}
          </Bullet>
        )}
      </StyledBulletContainer>
      <div className="content">{children}</div>
    </StyledEvent>
  )
}

export default Event

Event.defaultProps = {
  icon: null,
  color: "primary",
  lineColor: "gray",
  size: "1.2rem",
  thickness: ".14rem",
  bullet: null,
}
Event.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  lineColor: PropTypes.string,
  size: PropTypes.string,
  thickness: PropTypes.string,
  bullet: PropTypes.node,
}
