import React, { useReducer, createContext } from "react"
import { uniqBy } from "lodash-es"
import { toggleItemInEnumArray, updateCollectionItemByIndex } from "utils/array"
import { DeviceActionMap, DeviceTypes } from "./actions"
import { IDevice, IDeviceAlarm, IDeviceGroup } from "./types"
import { getDisplayKey } from "./helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"

type InitialDeviceStateType = {
  syncAddress: boolean
  devices: IDevice[]
  deviceGroups: IDeviceGroup[]
  toggledDevices: IDevice[]
  toggledDeviceGroups: IDeviceGroup[]
  toggledDeviceAlarms: IDeviceAlarm[]
  deviceGroupVisibilitySetupToggled: boolean
  deviceSubscriptionIDs: number[]
  animating: boolean
  showCurrentRoute: boolean
}

const initialState: InitialDeviceStateType = {
  syncAddress: false,
  devices: [],
  deviceGroups: [],
  toggledDevices: [],
  toggledDeviceGroups: [],
  toggledDeviceAlarms: [],
  deviceGroupVisibilitySetupToggled: false,
  deviceSubscriptionIDs: [],
  animating: false,
  showCurrentRoute: true,
}

const DeviceContext = createContext<{
  state: InitialDeviceStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const reducer = (state: InitialDeviceStateType, action: DeviceActionMap) => {
  switch (action.type) {
    case DeviceTypes.SetSyncAddress:
      return { ...state, syncAddress: action.payload.sync }
    case DeviceTypes.SetDevices:
      return { ...state, devices: action.payload.devices }
    case DeviceTypes.SetDeviceGroups:
      return { ...state, deviceGroups: action.payload.groups }
    case DeviceTypes.SetAnimating:
      return { ...state, animating: action.payload }
    case DeviceTypes.SetToggledDevices:
      return { ...state, toggledDevices: action.payload.devices }
    case DeviceTypes.SetToggledDeviceGroups:
      return { ...state, toggledDeviceGroups: action.payload.groups }

    case DeviceTypes.ToggleShowCurrentRoute:
      return { ...state, showCurrentRoute: action.payload }

    case DeviceTypes.ToggleGroupVisibilitySetup:
      return {
        ...state,
        deviceGroupVisibilitySetupToggled: !state.deviceGroupVisibilitySetupToggled,
      }

    case DeviceTypes.ToggleDeviceGroup:
      const toggleDeviceGroup = state.deviceGroups.find(
        (grp) => grp.id === action.payload.groupID
      )

      if (toggleDeviceGroup) {
        const newGroups = updateCollectionItemByIndex<IDeviceGroup>(
          state.deviceGroups,
          action.payload.groupID,
          {
            ...toggleDeviceGroup,
            toggled: !toggleDeviceGroup.toggled,
          }
        )
        const relatedDeviceIDMap = toggleDeviceGroup.devices
        const newRelatedDevices = state.devices.map((device) => {
          if (relatedDeviceIDMap.includes(device.id)) {
            return {
              ...device,
              toggled:
                device.toggled && !toggleDeviceGroup.toggled === false
                  ? false
                  : device.toggled,
              /* toggledInGroups: device.toggledInGroups.includes(
                action.payload.groupID
              )
                ? device.toggledInGroups.filter(
                    (id) => id !== action.payload.groupID
                  )
                : [...device.toggledInGroups, action.payload.groupID], */
            }
          }
          return device
        })

        return {
          ...state,
          deviceGroups: newGroups,
          devices: newRelatedDevices,
        }
      }
      return state

    case DeviceTypes.ToggleDeviceGroupVisbility:
      const toggleDeviceGroupVisibility = state.deviceGroups.find(
        (grp) => grp.id === action.payload.groupID
      )

      if (toggleDeviceGroupVisibility) {
        const newGroups = updateCollectionItemByIndex<IDeviceGroup>(
          state.deviceGroups,
          action.payload.groupID,
          {
            ...toggleDeviceGroupVisibility,
            visible: !toggleDeviceGroupVisibility.visible,
          }
        )

        return {
          ...state,
          deviceGroups: newGroups,
        }
      }
      return state

    case DeviceTypes.ToggleDeviceInGroup:
      // Lookup the device
      const toggleDevice = state.devices.find(
        (device) => device.id === action.payload.deviceID
      )

      if (toggleDevice) {
        // New top-level devices list
        const newStateDevices = action.payload.untoggleRest
          ? state.devices.map((device) => ({
              ...device,
              toggled: false,
              toggledInGroups: [action.payload.groupID],
            }))
          : state.devices

        const newDevices = updateCollectionItemByIndex<IDevice>(
          newStateDevices,
          action.payload.deviceID,
          {
            ...toggleDevice,
            toggled: !toggleDevice.toggled,
            toggledInGroups: toggleDevice?.toggledInGroups.includes(
              action.payload.groupID
            )
              ? toggleDevice.toggledInGroups.filter(
                  (groupID) => groupID !== action.payload.groupID
                )
              : [...toggleDevice.toggledInGroups, action.payload.groupID],
          }
        )

        return {
          ...state,
          devices: newDevices,
        }
      }

      return state

    case DeviceTypes.ToggleDeviceSubscription:
      return {
        ...state,
        deviceSubscriptionIDs: toggleItemInEnumArray(
          state.deviceSubscriptionIDs,
          action.payload.deviceID
        ),
      }

    case DeviceTypes.UpdateDeviceByID:
      const updateDevice = state.devices.find(
        (device) => device.id === action.payload.id
      )

      if (updateDevice) {
        const newUpdatedDevices = updateCollectionItemByIndex<IDevice>(
          [...state.devices],
          action.payload.id,
          {
            ...updateDevice,
            ...action.payload.device,
          }
        )

        return {
          ...state,
          devices: newUpdatedDevices,
        }
      }

      return state

    case DeviceTypes.UpdateManyDevices:
      const updatedDevices = action.payload.devices
        .map((device) => {
          const findDeviceToUpdate = state.devices.find(
            (oldDevice) => oldDevice.id === device.id
          )

          if (findDeviceToUpdate) {
            const address = getDisplayKey(
              findDeviceToUpdate?.values,
              DisplayKeyEnum.Address
            )

            return {
              ...findDeviceToUpdate,
              ...device,
              values: findDeviceToUpdate
                ? [...device.values, address]
                : device.values,
            }
          }

          return null
        })
        .filter(Boolean)

      return {
        ...state,
        devices: uniqBy([...updatedDevices, ...state.devices], "id"),
      }
    case DeviceTypes.UntoggleAllDevices:
      const untoggledDevices = state.devices.map((device) => ({
        ...device,
        toggled: false,
        toggledInGroups: [],
      }))
      const untoggledGroups = state.deviceGroups.map((deviceGroup) => ({
        ...deviceGroup,
        toggled: false,
      }))

      return {
        ...state,
        devices: untoggledDevices,
        deviceGroups: untoggledGroups,
      }

    case DeviceTypes.AddDeviceAlarm:
      return {
        ...state,
        toggledDeviceAlarms: uniqBy(
          [
            ...state.toggledDeviceAlarms,
            {
              alertID: action.payload.alarmID,
              deviceID: action.payload.deviceID,
            },
          ],
          "alarmID"
        ),
      }
    case DeviceTypes.Reset:
      return {
        syncAddress: false,
        devices: [],
        deviceGroups: [],
        toggledDeviceGroups: [],
        toggledDevices: [],
        toggledDeviceAlarms: [],
        deviceGroupVisibilitySetupToggled: false,
        deviceSubscriptionIDs: [],
      }

    default:
      return state
  }
}

const DeviceProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <DeviceContext.Provider value={{ state, dispatch }}>
      {children}
    </DeviceContext.Provider>
  )
}

export { DeviceContext, DeviceProvider }
