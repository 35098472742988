import React from "react"
import { Dialog } from "@headlessui/react"
import styled from "styled-components"
import "twin.macro"

const StyledDialog = styled(Dialog)`
  position: relative;
  z-index: 1000;
`

const DialogModal: React.FC = ({ children, ...props }) => {
  return (
    <StyledDialog {...props}>
      <div tw="fixed inset-0 bg-white/30 z-40" aria-hidden="true" />
      <div tw="fixed inset-0 w-screen overflow-y-auto z-50">
        <div tw="flex items-center justify-center min-h-full p-4">
          {children}
        </div>
      </div>
    </StyledDialog>
  )
}

export { Dialog, DialogModal }
