import React from "react"
import styled from "styled-components"
import isNaN from "lodash-es/isNaN"
import KPIBase from "./Base"
import { IKpi } from "./Widget/widget.types"

const StyledNumber = styled(KPIBase)``

const Number: React.FC<IKpi> = ({ data: rawData, ...props }) => {
  const data =
    rawData && !isNaN(rawData) ? rawData.toString().replace(",", ".") : null
  if (props.kpiTitle === "speed" && +data < 0.1) return null
  const displayData = props.formatDataFnc ? props.formatDataFnc(data) : data
  return <StyledNumber {...props}>{displayData}</StyledNumber>
}

export default Number
