import { privateApi } from "utils/api"

/**
 * Returns current user
 */

const getUser = async () => {
  try {
    const response = await privateApi().get("/user")
    return response.data
  } catch (e) {
    console.error(e)
  }
}

/**
 * Returns list of users related to the current user
 */

const getUsers = async () => {
  try {
    const response = await privateApi().get("/users")
    return response
  } catch (e) {
    console.error(e)
  }
}

/**
 * Creates a new user
 */

const createUser = async (body) => {
  try {
    const transformedBody = Object.assign({}, body, {
      username: body.email,
    })
    const response = await privateApi().post(`/user`, transformedBody)
    return response
  } catch (e) {
    console.error(e)
  }
}

/**
 * Updates a user
 */

const updateUser = async (id, body) => {
  try {
    const response = await privateApi().patch(`/user/${id}`, body)
    return response
  } catch (e) {
    console.error(e)
  }
}

/**
 * Resets user password
 */

const resetPasswordByAdmin = async (id) => {
  try {
    const response = await privateApi().get(`/user/${id}/reset`)
    return response
  } catch (e) {
    console.error(e)
  }
}

const updatePassword = async (id, body) => {
  try {
    const response = await privateApi().patch(`/user/${id}/password`, body)
    return response
  } catch (e) {
    console.error(e)
  }
}

/**
 * Soft deletes a user
 */

const deleteUser = async (id) => {
  try {
    const response = await privateApi().delete(`/user/${id}`)
    return response
  } catch (e) {
    console.error(e)
  }
}

/**
 * Get assigned user groups and trackers
 */

const getAssignedTrackersAndGroupsByUserId = async (id) => {
  try {
    const response = await privateApi().get(`/user/${id}/assign`)
    return response
  } catch (e) {
    console.error(e)
  }
}

/**
 * Update assigned user groups and trackers
 */

const updateAssignedTrackersAndGroupsByUserId = async (id, body) => {
  try {
    const response = await privateApi().patch(`/user/${id}/assign`, body)
    return response
  } catch (e) {
    console.error(e)
  }
}

export {
  getUser,
  getUsers,
  createUser,
  updateUser,
  resetPasswordByAdmin,
  updatePassword,
  deleteUser,
  getAssignedTrackersAndGroupsByUserId,
  updateAssignedTrackersAndGroupsByUserId,
}
