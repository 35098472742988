import React from "react"
import PropTypes from "prop-types"
import { useMonth } from "@datepicker-react/hooks"
import styled from "styled-components"
import { da as locale } from "date-fns/locale"
import { format } from "date-fns"
import "twin.macro"
import Icon from "lib/Icon"
import getISOWeek from "date-fns/getISOWeek"
import Nav from "./Nav"
import Day from "./Day"
import Input from "../Input"

const StyledMonthWrapper = styled.div`
  display: grid;
  ${(props) =>
    props.includeWeekNumbers
      ? `grid-template-areas: "monthlabel monthlabel"
                              ". weekdaylabels"
                              "weeknumbers month";
          grid-template-rows: 4rem ${
            props.theme.datepicker.daylabel.height
          }rem 1fr;
          grid-template-columns: ${
            props.theme.datepicker.weeknumber.width
          }rem 1fr;
          width: ${props.theme.datepicker.days.width * 8}rem;
        `
      : `grid-template-areas: "monthlabel"
                              "weekdaylabels"
                              "month";
          grid-template-rows: 4rem ${
            props.theme.datepicker.daylabel.height
          }rem 1fr;
          grid-template-columns: 1fr;
          width: ${props.theme.datepicker.days.width * 7}rem;
        `}
`

const StyledMonth = styled.div`
  grid-area: month;
`

const StyledMonthHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledMonthLabel = styled.span`
  grid-area: monthlabel;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
`

const StyledWeekNumbers = styled.div`
  display: grid;
  grid-area: weeknumbers;
  grid-template-rows: auto;
`

const StyledWeekNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.datepicker.weeknumber.fontSize}rem;
  color: ${(props) => props.theme.datepicker.weeknumber.color};
`

const StyledDays = styled.span`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(
    7,
    ${(props) => `${props.theme.datepicker.days.width}rem`}
  );
`

const StyledDayLabels = styled.div`
  grid-area: weekdaylabels;
  display: grid;
  grid-template-areas: "mon tue wed thu fri sat sun";
`

const StyledDayLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: ${(props) => props.theme.datepicker.daylabel.width}rem;
  height: ${(props) => props.theme.datepicker.daylabel.height}rem;
  font-size: ${(props) => props.theme.datepicker.daylabel.fontSize}rem;
  color: ${(props) => props.theme.datepicker.daylabel.color};
`

function Month({
  includeWeekNumbers,
  year,
  month,
  firstDayOfWeek,
  dayComponent,
  onGoToNextMonths,
  onGoToPreviousMonths,
  onChangeYear,
  nextButtonComponent,
  prevButtonComponent,
  ...props
}) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    dayLabelFormat: (date: Date) => format(date, "dd", { locale }),
    weekdayLabelFormat: (date: Date) => format(date, "EEEEEE", { locale }),
    monthLabelFormat: (date: Date) => format(date, "MMMM yyyy", { locale }),
  })

  const onPreviousMonthHandler = (d) => {
    if (month === 0) {
      // From january to december
      onChangeYear(+year - 1)
    }
    onGoToPreviousMonths()
  }

  const onNextMonthHandler = (d) => {
    if (month === 11) {
      // From december to january
      onChangeYear(+year + 1)
    }
    onGoToNextMonths()
  }

  function renderWeekNumbers() {
    const weekNumbers = days
      .map((day) => getISOWeek(day.date))
      .filter((week) => !Number.isNaN(week))
      .filter((week, index, src) => src.indexOf(week) === index)

    return (
      <StyledWeekNumbers>
        {weekNumbers.map((week) => (
          <StyledWeekNumber key={week}>{week}</StyledWeekNumber>
        ))}
      </StyledWeekNumbers>
    )
  }

  const [monthString, yearString] = monthLabel.split(" ")

  return (
    <StyledMonthWrapper includeWeekNumbers={includeWeekNumbers} {...props}>
      <StyledMonthHeader>
        <prevButtonComponent.type
          onClick={onPreviousMonthHandler}
          {...prevButtonComponent.props}
        />
        <StyledMonthLabel>
          <span tw="inline-block capitalize">{monthString}</span>
          <span tw="ml-4 mt-1 inline-block">
            <Input
              size="sm"
              value={year}
              type="number"
              min="1900"
              max="9999"
              onChange={(e) => onChangeYear(e.target.value)}
            />
          </span>
        </StyledMonthLabel>
        <nextButtonComponent.type
          onClick={onNextMonthHandler}
          {...nextButtonComponent.props}
        />
      </StyledMonthHeader>
      {includeWeekNumbers && renderWeekNumbers()}
      <StyledDayLabels>
        {weekdayLabels.map((dayLabel) => (
          <StyledDayLabel key={dayLabel}>{dayLabel}</StyledDayLabel>
        ))}
      </StyledDayLabels>
      <StyledMonth>
        <StyledDays>
          {days.map((day, index) => {
            if (typeof day === "object") {
              return (
                <dayComponent.type
                  date={day.date}
                  key={day.date.toString()}
                  dayLabel={day.dayLabel}
                  {...dayComponent.props}
                />
              )
            }
            return <div key={index} />
          })}
        </StyledDays>
      </StyledMonth>
    </StyledMonthWrapper>
  )
}

export default Month

Month.defaultProps = {
  nextButtonComponent: (
    <Nav icon>
      <Icon icon="chevron-right" />
    </Nav>
  ),
  prevButtonComponent: (
    <Nav icon>
      <Icon icon="chevron-left" />
    </Nav>
  ),
  dayComponent: <Day />,
}

Month.propTypes = {
  nextButtonComponent: PropTypes.node,
  prevButtonComponent: PropTypes.node,
  dayComponent: PropTypes.node,
}
