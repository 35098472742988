import React, { useCallback, useContext, useEffect, useState } from "react"
// import styled from "styled-components"
import { Grid } from "app/Grid"
import "twin.macro"
import uniqBy from "lodash-es/uniqBy"
import { AlarmSelector } from "./components/AlarmSelector"
import { useAlarms } from "./hooks"
import { AlarmForm } from "./components/AlarmForm"
import { AlarmsContext } from "./context"
import { CriteriaEditor, NewCriteriaSelector } from "./components/criteria"
import {
  Alarm,
  AlarmSchedule,
  AlarmSetupPanels,
  AlertNotification,
} from "./alarm.types"
import { AlarmDevices } from "./components/Devices"
import { AlertSchedule } from "./components/Schedule"
import { AlertNotifications } from "./components/Notifications"
import { useFirestoreAlarms } from "services/firestore/alarms"
import cogoToast from "@clevertrackdk/cogo-toast"
import { AlarmActions, AlarmTypes } from "./actions"
import { usePromise } from "hooks/usePromise"
import { Loading, Responsive } from "@clevertrack/shared"
import { IScreen, Flow } from "app/Flow"
import PopOver from "app/PopOver"

enum AlarmScreens {
  LIST = "list",
  EDIT = "edit",
  PANEL = "panel",
}

/* const StyledAlarms = styled(Container)`
  ${tw`w-full h-full relative z-50 bg-white md:(pb-32)`}
  overflow-y: scroll;
` */

/* const StyledGrid = styled(Grid)`
  height: 100%;

  div > header {
    display: flex;
    position: sticky;
    align-items: center;
    top: 0;
    justify-content: space-between;
    z-index: 200;
    background: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 4rem;
    padding: 0 2rem 0 2rem;
    max-width: none;
    margin: 0 auto;

    div > header:first-of-type {
      display: flex;
      position: relative;
      align-items: center;
      top: 0;
      justify-content: space-between;
      padding: 2rem 0;
      background: none;
    }

    h1 {
      display: block;
      margin: 0;
      background: ${props.theme.colors.white};
      z-index: 20;
    }
  `}
` */

interface IAlarmsProps {}

export const Alarms: React.FC<IAlarmsProps> = () => {
  const [currentScreen, setCurrentScreen] = useState<AlarmScreens[]>([
    AlarmScreens.LIST,
  ])
  const {
    editAlarm,
    onCreateNewAlarm,
    onEditAlarm,
    onGetAlarms,
    onSaveAlarmDraft,
    onCancelEditAlarm,
    onDeleteAlarm,
  } = useAlarms()
  const {
    state: { panel, currentCriterium },
    dispatch,
  } = useContext(AlarmsContext)
  const {
    createNewFirebaseAlarmNotification,
    updateFirebaseAlarmNotification,
  } = useFirestoreAlarms()
  const { setPromise, loadingText, loading } = usePromise()
  const [updateStamp, setUpdateStamp] = useState(0)

  const onSaveNotificationHandler = async (
    notification: AlertNotification,
    omitToast: boolean = false
  ) => {
    try {
      if (notification.id) {
        await updateFirebaseAlarmNotification(notification)
        dispatch(
          AlarmActions(AlarmTypes.UpdateAlertNotificationByID, {
            alertNotification: notification,
          })
        )
      } else {
        const notificationID = await createNewFirebaseAlarmNotification(
          notification
        )
        dispatch(
          AlarmActions(AlarmTypes.AddAlertNotification, {
            alertNotification: { ...notification, id: notificationID },
          })
        )
        // Also update the current alarm with the new notification ID
        if (editAlarm && notificationID) {
          const alarmUpdate: Alarm = {
            ...editAlarm,
            alertNotificationIDs: [
              ...editAlarm.alertNotificationIDs,
              notificationID,
            ],
          }
          await onSaveAlarmDraft(alarmUpdate)
        }
      }
      dispatch(AlarmActions(AlarmTypes.SetCurrentEditorPanel, { panel: null }))
      if (!omitToast) cogoToast.success("Underretningen blev gemt")
    } catch (error) {
      if (!omitToast) cogoToast.error("Underretningen blev ikke gemt")
    }
  }

  const onSaveScheduleHandler = async (schedule: AlarmSchedule) => {
    try {
      if (editAlarm && schedule) {
        const newSchedule = editAlarm.schedule
          ? [schedule, ...editAlarm.schedule]
          : [schedule]
        const updatedAlarm = {
          ...editAlarm,
          schedule: uniqBy(newSchedule, "id"),
        }
        await onSaveAlarmDraft(updatedAlarm)
        cogoToast.success("Tidsplanen blev gemt")
        dispatch(
          AlarmActions(AlarmTypes.SetCurrentEditorPanel, { panel: null })
        )
      }
    } catch (error) {
      cogoToast.error("Tidsplanen blev ikke gemt")
    }
  }

  const onSaveDevicesHandler = async ({
    deviceIDs,
    deviceGroupIDs,
  }: {
    deviceIDs: string[]
    deviceGroupIDs: string[]
  }) => {
    try {
      if (editAlarm) {
        const updatedAlarm = {
          ...editAlarm,
          deviceIDs,
          deviceGroupIDs,
        }
        await onSaveAlarmDraft(updatedAlarm)
      }
    } catch (error) {
      cogoToast.error("Tidsplanen blev ikke gemt")
    }
  }

  const onCancelHandler = () => {
    dispatch(AlarmActions(AlarmTypes.SetCurrentEditorPanel, { panel: null }))
  }

  const onCancelEditHandler = () => {
    setCurrentScreen([AlarmScreens.LIST])
    if (!editAlarm?.updated && editAlarm?.id && editAlarm.draft) {
      onDeleteAlarm(editAlarm)
    }
  }

  const renderPanel = () => {
    switch (panel) {
      case AlarmSetupPanels.AlertDeviceEditor:
        return <AlarmDevices onSave={onSaveDevicesHandler} />
      case AlarmSetupPanels.AlertScheduleEditor:
        return (
          <AlertSchedule
            onSave={onSaveScheduleHandler}
            onCancel={onCancelHandler}
          />
        )
      case AlarmSetupPanels.CriteriaEditor:
        return <CriteriaEditor />
      case AlarmSetupPanels.CriteriaTypeSelector:
        return <NewCriteriaSelector />
      case AlarmSetupPanels.AlertNotificationEditor:
        return (
          <AlertNotifications
            onSave={onSaveNotificationHandler}
            onCancel={onCancelHandler}
          />
        )
    }
  }

  useEffect(() => {
    if (panel || currentCriterium) {
      setCurrentScreen([
        AlarmScreens.LIST,
        AlarmScreens.EDIT,
        AlarmScreens.PANEL,
      ])
    } else if (editAlarm) {
      setCurrentScreen([AlarmScreens.LIST, AlarmScreens.EDIT])
    } else {
      setCurrentScreen([AlarmScreens.LIST])
    }
  }, [panel, currentCriterium, editAlarm])

  useEffect(() => {
    onGetAlarms()
  }, [])

  const renderMobile = () => {
    const screenSet: IScreen[] = [
      {
        key: "list",
        children: (
          <AlarmSelector
            onNewAlarm={() => {
              setPromise({
                promise: onCreateNewAlarm(),
                loadingText: "Opretter alarm",
              })
              setCurrentScreen([AlarmScreens.EDIT])
            }}
            onEditAlarm={(alarm) => {
              onEditAlarm(alarm)
              setCurrentScreen([AlarmScreens.EDIT])
            }}
            onDelete={onDeleteAlarm}
            onCancel={onCancelEditAlarm}
          />
        ),
        wrapper: <div />,
      },
      {
        key: "edit",
        children: (
          <div tw="bg-white min-h-full p-8 pt-12 pb-64">
            <h4>Redigér alarm</h4>
            {loading && <Loading loadingText={loadingText} tw="bg-white" />}
            {editAlarm && (
              <AlarmForm
                onCancelEdit={onCancelEditHandler}
                onCreateNotification={onSaveNotificationHandler}
              />
            )}
          </div>
        ),
        wrapper: (
          <PopOver fromRight show={currentScreen.includes(AlarmScreens.EDIT)} />
        ),
      },
      {
        key: `panel`,
        children: (
          <div tw="bg-white p-8 pt-24 pb-64 min-h-full">{renderPanel()}</div>
        ),
        wrapper: (
          <PopOver
            fromRight
            onRest={() => setUpdateStamp(+new Date())}
            show={currentScreen.includes(AlarmScreens.PANEL)}
          />
        ),
      },
    ]
    return <Flow screenSet={screenSet} />
  }

  const renderDesktop = () => {
    return (
      <Grid gridConfig="60rem 1.5fr 1.5fr">
        <div>
          <AlarmSelector
            onNewAlarm={() =>
              setPromise({
                promise: onCreateNewAlarm(),
                loadingText: "Opretter alarm",
              })
            }
            onEditAlarm={onEditAlarm}
            onDelete={onDeleteAlarm}
            onCancel={onCancelEditAlarm}
          />
        </div>
        <div>
          {loading && <Loading loadingText={loadingText} tw="bg-white" />}
          {editAlarm && (
            <AlarmForm
              onCancelEdit={onCancelEditHandler}
              onCreateNotification={onSaveNotificationHandler}
            />
          )}
        </div>
        <div>{renderPanel()}</div>
      </Grid>
    )
  }

  return (
    <Responsive
      phone={<Grid>{renderMobile()}</Grid>}
      tabletLandscape={renderDesktop()}
    />
  )
}
