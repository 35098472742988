import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSpring, animated } from "@react-spring/web"

const StyledAnimatedPosition = styled(animated.span)`
  position: absolute;
  backface-visibility: hidden;
`

function AnimatedPosition({
  timestamp,
  preventAnimation = false,
  children,
  ...props
}) {
  const [pointProps, api] = useSpring(
    () => ({
      from: {
        transform: `scale3d(0, 0, 0)`,
      },
      to: { transform: `scale3d(1, 1, 1)` },
      config: {
        tension: 500,
        friction: 23,
      },
    }),
    []
  )

  return (
    <StyledAnimatedPosition key={timestamp} style={pointProps} {...props}>
      {children}
    </StyledAnimatedPosition>
  )
}

export default AnimatedPosition

AnimatedPosition.defaultProps = {}
AnimatedPosition.propTypes = {
  children: PropTypes.node.isRequired,
}
