import React from "react"
import styled from "styled-components"

const StyledGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & + & {
    margin-top: ${(props) => props.theme.list.group.spacing};
  }
`

const StyledTitle = styled.li`
  display: block;
  // text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1rem 0 1rem 0;
  // border-bottom: ${(props) => props.theme.list.item.border};

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 1rem;
  `}
`

interface IGroupProps extends React.HTMLAttributes<HTMLUListElement> {
  title?: string
}

export const Group: React.FC<IGroupProps> = ({ title, children, ...props }) => {
  return (
    <StyledGroup {...props}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {children}
    </StyledGroup>
  )
}
