import React, { useState } from "react"
import "twin.macro"
import {
  DeviceHealthCriterium,
  DeviceSpeedCriterium,
} from "routes/Alarms/alarm.types"

import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import Checkbox from "lib/Checkbox"
import Accordion from "lib/Accordion"
import Icon from "app/Icon"
import { IconSizeEnum } from "lib/Icon"
import { FormField, Input } from "@clevertrack/shared"

interface IDeviceHealthCriteriaProps {
  criterium: DeviceHealthCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceHealthCriteriaElement extends IDeviceHealthCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

export const DeviceHealthCriteriaElement: React.FC<IDeviceHealthCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  const renderDescription = () => {
    const parts: string[] = []
    if (criterium.connectionLost) parts.push("enheden mister GPS-forbindelse")
    if (criterium.powerLost)
      parts.push("enhedens eksterne strømforsyning afbrydes")
    if (criterium.powerTreshold) {
      parts.length === 2
        ? parts.push(
            `eller enhedens eksterne spænding er under ${criterium.powerTreshold} volt`
          )
        : parts.push(
            `enhedens eksterne spænding er under ${criterium.powerTreshold} volt`
          )
    }

    return `Opfyldt når: ${
      parts.length === 2 ? parts.join(" eller ") : parts.join(", ")
    }`
  }

  return (
    <AlarmConfigurationComponent tw="border-b-0">
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">Enhedens tilstand</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">Annullér</span>
            </>
          ) : (
            <>
              <span tw="text-lg">Redigér</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">{renderDescription()}</span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceHealthCriteria: React.FC<IDeviceHealthCriteriaProps> = ({
  criterium,
  onSave,
  onCancel,
  ...props
}) => {
  const [showTreshold, setShowTreshold] = useState<boolean>(
    !!criterium.powerTreshold
  )
  const [connectionLost, setConnectionLost] = useState<boolean>(
    criterium.connectionLost
  )
  const [powerLost, setPowerLost] = useState<boolean>(criterium.powerLost)
  const [powerTreshold, setPowerTreshold] = useState<number | null>(
    criterium.powerTreshold ?? 0
  )
  const [powerTresholdUnder, setPowerTresholdUnder] = useState<boolean>(
    criterium.powerTresholdUnder
  )
  const onSaveHandler = () => {
    if (onSave)
      onSave({
        ...criterium,
        connectionLost,
        powerLost,
        powerTreshold,
        powerTresholdUnder,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>Enhedens tilstand</CriteriumTypeTitle>
      <p tw="text-xl mt-0 text-brand-gray-base">
        Alarmering hvis en enhed mister strøm eller GPS-forbindelse
      </p>
      <div tw="mt-8">
        <CriteriumTypeTitle tw="mb-4 block">
          Udløs alarmen når:
        </CriteriumTypeTitle>
        <div tw="text-xl">
          <Checkbox
            checked={connectionLost}
            onChange={(checked) => setConnectionLost(checked)}
          >
            Trackeren mister GPS-forbindelse
          </Checkbox>
          <Checkbox
            checked={powerLost}
            onChange={(checked) => setPowerLost(checked)}
          >
            Trackerens strømforsyning bliver afbrudt
          </Checkbox>
        </div>
      </div>
      <div tw="mt-12">
        <CriteriumTypeTitle tw="block">
          Grænseværdi for ekstern spænding
        </CriteriumTypeTitle>
        <p tw="text-xl mt-0 max-w-3xl">
          Du kan opsætte en grænseværdi for enhedens eksterne spænding. Alarmen
          udløses hvis enhedens eksterne spænding kommer under den angivede
          værdi.
        </p>

        {!showTreshold && (
          <span
            tw="flex mt-2 items-center text-brand-500 cursor-pointer cursor-pointer hover:opacity-60 transition"
            onClick={() => {
              setPowerTreshold(11.0)
              setShowTreshold(true)
            }}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b ">
              Opsæt grænseværdi for ekstern spænding
            </span>
            <Icon size={IconSizeEnum.SM} icon="plus" />
          </span>
        )}
        <Accordion toggled={!!showTreshold}>
          <div tw="pt-4">
            {/* <div tw="py-4">
              <CriteriumTypeTitle tw="block">
                Udløs alarmen når
              </CriteriumTypeTitle>
              <div tw="flex">
                <Checkbox
                  inline
                  checkboxAppearance="radio"
                  checked={!powerTresholdUnder}
                  onChange={(checked) => setPowerTresholdUnder(false)}
                >
                  <span tw="text-xl">grænseværdien er under</span>
                </Checkbox>
                <Checkbox
                  inline
                  checkboxAppearance="radio"
                  checked={powerTresholdUnder}
                  onChange={(checked) => setPowerTresholdUnder(true)}
                >
                  <span tw="text-xl">grænseværdien er over</span>
                </Checkbox>
              </div>
            </div> */}
            <FormField label="Grænseværdi (volt)">
              <Input
                type="number"
                step=".01"
                placeholder="Indtast spænding"
                value={powerTreshold}
                onChange={(e) => setPowerTreshold(e.target.value)}
              />
            </FormField>
            <span
              tw="flex items-center text-brand-red-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
              onClick={() => {
                setPowerTreshold(null)
                setShowTreshold(false)
                setPowerTresholdUnder(true)
              }}
            >
              <span tw="mr-2 text-xl border-dotted border-0 border-b">
                Fjern grænseværdi
              </span>
              <Icon size={IconSizeEnum.SM} icon="close" />
            </span>
          </div>
        </Accordion>
      </div>
      {onSave && onCancel && (
        <EditorButtonGroup onSave={onSaveHandler} onCancel={onCancelHandler} />
      )}
    </>
  )
}
