import { useContext } from "react"
import { useFirestoreAlarms } from "services/firestore/alarms"
import { AlarmActions, AlarmTypes } from "./actions"
import { Alarm, AlarmSetupPanels, AlertCriterium } from "./alarm.types"
import { AlarmsContext } from "./context"

export const useAlarms = () => {
  const {
    state: { alarms, editAlarm, currentCriterium },
    dispatch,
  } = useContext(AlarmsContext)
  const {
    createNewFirebaseAlarm,
    getFirebaseAlarms,
    getFirebaseAlarmNotifications,
    deleteFirebaseAlarm,
    updateFirebaseAlarm,
    deleteFirebaseAlarmNotification,
  } = useFirestoreAlarms()

  const createNewAlarm = async () => {
    const newAlarm: Alarm = {
      title: "Ny alarm",
      description: "",
      disabled: false,
      triggerCount: 0,
      alertNotificationIDs: [],
      schedule: [],
      created: +new Date(),
      alarmTriggeredByDeviceID: [],
      draft: true,
    }
    const newCreatedAlarm = await createNewFirebaseAlarm(newAlarm)
    return newCreatedAlarm
  }

  const onNewHandler = async () => {
    const alarm = await createNewAlarm()
    if (alarm) {
      dispatch(AlarmActions(AlarmTypes.SetCurrentAlarm, { alarm }))
      dispatch(AlarmActions(AlarmTypes.AddAlarmToList, { alarm }))
    }
  }

  const onDeleteHandler = async (alarm: Alarm) => {
    const result = await deleteFirebaseAlarm(alarm.id)
    if (result === "OK") {
      if (alarm?.alertNotificationIDs) {
        for (const id of alarm.alertNotificationIDs) {
          await deleteFirebaseAlarmNotification(id)
        }
      }
      dispatch(
        AlarmActions(AlarmTypes.RemoveAlarmFromList, { alarmID: alarm.id })
      )
      if (editAlarm?.id === alarm.id) {
        dispatch(AlarmActions(AlarmTypes.ResetAlarmForm, null))
      }
    }
  }

  const onDeleteNotificationHandler = async (notificationID) => {
    const result = await deleteFirebaseAlarmNotification(notificationID)
    if (result === "OK") {
      // Also remove the notification ID from the alarm
      if (editAlarm) {
        const updatedAlarm: Alarm = {
          ...editAlarm,
          alertNotificationIDs: editAlarm?.alertNotificationIDs.filter(
            (alert) => alert !== notificationID
          ),
        }
        onSaveDraftHandler(updatedAlarm)
      }
      return "OK"
    }
  }

  const onSaveHandler = async (alarm: Alarm) => {
    const filteredAlarmsTriggeredByID = alarm.alarmTriggeredByDeviceID.filter(
      (id) => alarm.deviceIDs?.includes(id.toString())
    )
    const payload = {
      ...alarm,
      alarmTriggeredByDeviceID: filteredAlarmsTriggeredByID,
      draft: false,
      updated: +new Date(),
      criteriaTypes: alarm.criteria?.map((crit) => crit.type) ?? [],
    }
    const result = await updateFirebaseAlarm(payload)
    if (result) {
      dispatch(AlarmActions(AlarmTypes.SetCurrentAlarm, { alarm: payload }))
      dispatch(AlarmActions(AlarmTypes.UpdateAlarmByID, { alarm: payload }))
    }
    return "OK"
  }

  const onSaveDraftHandler = async (alarm: Alarm) => {
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentAlarm, {
        alarm: {
          ...alarm,
          draft: true,
        },
      })
    )
    return
  }

  const onLoadAlarms = async () => {
    const alarmsList = await getFirebaseAlarms()
    if (alarmsList) {
      dispatch(
        AlarmActions(AlarmTypes.SetAlarmList, {
          list: alarmsList,
        })
      )
    }
  }

  const onLoadNotifications = async () => {
    if (editAlarm && editAlarm.alertNotificationIDs) {
      const alertNotifications = await getFirebaseAlarmNotifications(
        editAlarm.alertNotificationIDs
      )
      if (alertNotifications) {
        dispatch(
          AlarmActions(AlarmTypes.SetAlertNotifications, { alertNotifications })
        )
      }
    }
  }

  const onEditHandler = (alarm: Alarm) => {
    if (!alarm.updated) {
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentAlarm, {
          alarm: {
            ...alarm,
            draft: true,
          },
        })
      )
    } else {
      dispatch(AlarmActions(AlarmTypes.SetCurrentAlarm, { alarm }))
    }
  }

  const onEditCriteriumHandler = (criterium: AlertCriterium) => {
    if (criterium.id === currentCriterium?.id) {
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
          panel: null,
        })
      )
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentCriterium, { criterium: null })
      )
    } else {
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
          panel: AlarmSetupPanels.CriteriaEditor,
        })
      )
      dispatch(AlarmActions(AlarmTypes.SetCurrentCriterium, { criterium }))
    }
  }

  const onResetHandler = () => {
    dispatch(AlarmActions(AlarmTypes.ResetAlarmForm, null))
  }

  return {
    onCreateNewAlarm: onNewHandler,
    onEditAlarm: onEditHandler,
    onDeleteAlarm: onDeleteHandler,
    onDeleteAlarmNotification: onDeleteNotificationHandler,
    onSaveAlarm: onSaveHandler,
    onSaveAlarmDraft: onSaveDraftHandler,
    onGetAlarms: onLoadAlarms,
    onGetAlarmNotifications: onLoadNotifications,
    onCancelEditAlarm: onResetHandler,
    onEditCriterium: onEditCriteriumHandler,
    alarms,
    editAlarm,
  }
}
