import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import BarChart from "./index"
import tw from "twin.macro"

const StyledBarChartAsPercentage = styled(BarChart)`
  > * {
    gap: 0;
  }

  ${(props) =>
    props.index > 0 &&
    `
    > *:nth-child(2) {
      > *:first-child {
        .hover {
          right: -${(100 / props.index - 1) * 100}%;
        }
      }
      > *:last-child {
        .hover {
          left: 100%;
        }
      }
    }
  `}
`

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.gray};
  margin: auto;
`

const StyledBefore = styled.span``

const StyledAfter = styled.span`
  ${tw`ml-4 text-lg`}
  width: auto;
`

function BarChartAsPercentage({ index, icon, color, hoverContent, ...props }) {
  const before = () => {
    return (
      <StyledBefore>
        {/* {!!icon && <StyledIcon icon={icon} size="sm" />} */}
        {` `}
      </StyledBefore>
    )
  }

  const bars = [
    {
      index,
      color,
      hoverContent,
      hoverPosition: 100,
    },
    {
      index: 100 - index,
      color: "grayLight",
      hoverContent,
      hoverPosition: -100,
    },
  ]

  return (
    <>
      <StyledBarChartAsPercentage
        index={index}
        before={before}
        after={() => <StyledAfter>{index}%</StyledAfter>}
        bars={bars}
        {...props}
      />
    </>
  )
}

export default BarChartAsPercentage

BarChartAsPercentage.defaultProps = {}
BarChartAsPercentage.propTypes = {}
