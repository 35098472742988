import React, { useContext } from "react"
import styled from "styled-components"
import { useDay } from "@datepicker-react/hooks"
import DatepickerContext from "./context"

const StyledDay = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background: none;
  border: none;
  cursor: pointer;

  ${(props) =>
    !props.isDisabled
      ? `
          &:hover {
            background: ${props.theme.datepicker.isInHoverRange.bg};
            color: ${props.theme.datepicker.isInHoverRange.color};
          }
        `
      : `
        cursor: default;
      `}

  ${(props) =>
    props.isSelected
      ? `
          background: ${props.theme.datepicker.isSelected.bg};
          color: ${props.theme.datepicker.isSelected.color};
        `
      : ``}

  ${(props) =>
    props.isInHoverRange
      ? `
          background: ${props.theme.datepicker.isInHoverRange.bg};
          color: ${props.theme.datepicker.isInHoverRange.color};
        `
      : ``}

  ${(props) =>
    props.isStartOrEnd
      ? `
          background: ${props.theme.datepicker.isStartOrEnd.bg};
          color: ${props.theme.datepicker.isStartOrEnd.color};
        `
      : ``}

  ${(props) =>
    props.isDisabled
      ? `
          cursor: normal;
          background: ${props.theme.datepicker.isDisabled.bg};
          color: ${props.theme.datepicker.isDisabled.color};
        `
      : ``}

  &:focus {
    outline: none;
  }
`

function Day({ dayLabel, date, ...props }) {
  // const dayRef = useRef(null)
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext)
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    // dayRef,
  })
  if (!dayLabel) {
    return <div />
  }

  return (
    <StyledDay
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      // ref={dayRef}
      // Comment out dayRef, because of issue on package @datepicker-react/hooks
      // Link: https://github.com/tresko/react-datepicker/issues/91
      // Date: 18.06.2020, Status: Open, waiting to be closed
      isSelected={isSelected}
      isStartOrEnd={isSelectedStartOrEnd}
      isInHoverRange={isWithinHoverRange}
      isDisabled={disabledDate}
      {...props}
    >
      {dayLabel}
    </StyledDay>
  )
}

export default Day
