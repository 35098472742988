import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledContainer = styled.div`
  padding: 0;

  ${(props) => props.theme.media.tablet_portrait_up`
    padding: 0;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `}

  ${(props) => props.theme.media.desktop_up`
    padding: 0;
  `}
`

const Container: React.FC = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

export default Container

Container.defaultProps = {
  children: null,
}
Container.propTypes = {
  children: PropTypes.node,
}
