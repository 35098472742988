import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import OffCanvas from "lib/OffCanvas"

const StyledPopUnder = styled(OffCanvas)`
  z-index: 80;
  overflow-x: hidden;
  max-height: 100%;
  top: 0;
  box-shadow: 0 -1px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 0 0;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity ${(props) => (props.show ? "0ms" : "0.7s")} steps(1);
`

function PopUnder({ children, ...props }) {
  return (
    <StyledPopUnder selector="#___offcanvas" {...props}>
      {children}
    </StyledPopUnder>
  )
}

export default PopUnder

PopUnder.defaultProps = {}
PopUnder.propTypes = {
  children: PropTypes.node.isRequired,
}
