import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react"
import styled from "styled-components"
import uniqBy from "lodash-es/uniqBy"
import isEqual from "lodash-es/isEqual"
import { Unsubscribe } from "firebase/database"
import HaversineGeolocation from "haversine-geolocation"
import { Link } from "gatsby"
import { Polyline } from "@react-google-maps/api"
import GoogleMap from "app/GoogleMap"
import TrackerLegend from "app/TrackerLegend"
import { SearchContext } from "app/Search/context"
import { ZonesContext } from "routes/Zones/context"
import { IconFlip } from "app/IconFlip"
import { ZonesActions, ZonesTypes } from "routes/Zones/actions"
import Icon from "app/Icon"
import Checkbox from "lib/Checkbox"
import { AppContext, ViewportContext } from "context"
import { UserContext } from "app/User/context"
import FeatureComponent from "app/FeatureComponent"
import { usePointsAndClusters } from "app/GoogleMap/usePointsAndClusters"
import { SizeEnum } from "theme"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { useDevices } from "app/Device/hooks"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { DeviceStatusEnum, IDevice } from "app/Device/types"
import { useUser } from "app/User/hooks"
import { UserVisibilitySettings } from "app/User/types"
import {
  pointsOfInterestRenderer,
  setPoints,
} from "routes/HistoryV2/Map/helper"
import {
  IMapPointOfInterest,
  IRoute,
  IRouteGeopoint,
} from "routes/HistoryV2/history.types"

import { Responsive } from "@clevertrack/shared"
import Cluster from "app/GoogleMap/Cluster"
import Point from "app/GoogleMap/Point"
import { useHistory } from "routes/HistoryV2/hooks"

import { HistoryContext } from "routes/HistoryV2/context"
import { HistoryActions, HistoryTypes } from "routes/HistoryV2/actions"
import { Tooltip } from "routes/HistoryV2/Map/Tooltip"
import last from "lodash-es/last"
import first from "lodash-es/first"
import { formatSecondsToDuration } from "utils/datetime"
import { getDisplayKey } from "app/Device/helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { isViewport } from "helpers"
import { StyledZonesToggle } from "routes/Zones/components/ZonesToggle"
import { StyledZonesPanel } from "routes/Zones/components/ZonesPanel"
import { IconSizeEnum } from "lib/Icon"
import FEATURE from "data/featureFlags"

const StyledStart = styled.div<{ sidebarToggled: boolean }>`
  height: ${(props) =>
    !props.sidebarToggled ? `calc(100% - 4.5rem)` : `100%`};
  width: 100%;
  position: relative;
  z-index: 50;
  // padding-bottom: ${SizeEnum.TapBarHeight}rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    height: 100%;
  `}
`

const StyledPoint = styled(Point)`
  cursor: pointer;

  > span {
    border-radius: 3rem;
    box-shadow: ${(props) => props.theme.mapButtonShadow};
    border: 2px solid ${(props) => props.theme.colors.white};
  }
`

const Start: React.FC = ({ children, ...props }) => {
  const {
    state: { toggledDeviceAlarms, animating, showCurrentRoute },
    dispatch: deviceDispatch,
  } = useContext(DeviceContext)
  const {
    state: { zoneSettingsToggled },
    dispatch: zonesDispatch,
  } = useContext(ZonesContext)
  const {
    state: { results, suggestions, query },
  } = useContext(SearchContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { sidebarToggled },
  } = useContext(AppContext)
  const { toggleUserVisibilitySetting, getUserVisibilitySetting } = useUser()
  const {
    devices,
    toggledDevices,
    toggledGroups,
    subscribeToValues,
    unsubscribeToValues,
  } = useDevices(true)
  const [mapOptions, setMapOptions] = useState({})
  const [forceRefresh, setForceRefresh] = useState(true)
  const [disableUpdates, setDisableUpdates] = useState(false)
  const vp = useContext(ViewportContext)
  const isPhoneOrTablet = isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
  const [
    preventSubsequentZoomAndCenter,
    setPreventSubsequentZoomAndCenter,
  ] = useState(false)
  const {
    dispatch,
    state: { toggledTooltip },
  } = useContext(HistoryContext)
  const [loaded, setLoaded] = useState(false)
  const { goOnline, goOffline } = useRealtimeDeviceValues()
  const { getCurrentTrip } = useHistory()
  const [breadcrumbs, setBreadcrumbs] = useState({
    mappedRoutes: null,
    geoPoints: null,
    pointsOfInterest: null,
    tooltipGeoPoints: null,
  })

  const mapRef = useRef(null)
  const breadcrumbRef = useRef<Unsubscribe | null>(null)

  const mappedRoutes = useMemo(() => {
    return breadcrumbs.mappedRoutes
  }, [breadcrumbs.mappedRoutes])

  const geoPoints = useMemo(() => {
    return breadcrumbs.geoPoints
  }, [breadcrumbs.geoPoints])

  const pointsOfInterest = useMemo(() => {
    return breadcrumbs.pointsOfInterest
  }, [breadcrumbs.pointsOfInterest])

  const tooltipGeoPoints = useMemo(() => {
    return breadcrumbs.tooltipGeoPoints
  }, [breadcrumbs.tooltipGeoPoints])

  const onValue = (data, id) => {
    const routes: IRouteGeopoint[] = data.reduce(
      (acc: IRouteGeopoint[], curr, index) => {
        const date = new Date(curr.timestamp)
        const log: IRouteGeopoint = {
          status:
            data[index - 1] &&
            curr.status.toString() === data[index - 1].status.toString()
              ? ""
              : (curr.status.toString() as DeviceStatusEnum),
          timestamp: date.toISOString(),
          speed: curr.speed.toString(),
          pos: {
            lat: curr.position.geometry.coordinates[1],
            lng: curr.position.geometry.coordinates[0],
            properties: null,
          },
          address: curr.address,
        }
        acc.push(log)
        return acc
      },
      []
    )

    if (breadcrumbRef.current) {
      const currentTrackerRoute: IRoute = {
        id: id.toString(),
        tracker_id: id.toString(),
        route: routes,
      }
      const newBreadcrumbs = setPoints([currentTrackerRoute], [], [], null)

      setBreadcrumbs(newBreadcrumbs)
      setPreventSubsequentZoomAndCenter(true)
    } else {
      setBreadcrumbs({
        mappedRoutes: null,
        geoPoints: null,
        pointsOfInterest: null,
        tooltipGeoPoints: null,
      })
      setPreventSubsequentZoomAndCenter(false)
    }
  }

  const unsub = () => {
    if (breadcrumbRef.current) {
      breadcrumbRef.current()
      breadcrumbRef.current = null
      setBreadcrumbs({
        mappedRoutes: null,
        geoPoints: null,
        pointsOfInterest: null,
        tooltipGeoPoints: null,
      })
      setPreventSubsequentZoomAndCenter(false)
      // setForceRefresh(true)
    }
  }

  useEffect(() => {
    subscribeToValues()
    return () => {
      unsubscribeToValues()
    }
  }, [])

  useMemo(() => {
    if (toggledDevices.length === 1) {
      // setForceRefresh(true)
      const { id } = toggledDevices[0]
      breadcrumbRef.current = getCurrentTrip(id.toString(), (data) =>
        onValue(data, id)
      )
    } else unsub()
  }, [toggledDevices])

  function onChange({ bounds, zoom }) {
    // Here, we get the initial bounds and zoom of the Google Map.
    // If any trackers are selected, we calculate the bounds based on those points instead.
    setMapOptions({ bounds, zoom })
  }

  function onMapsLoadHandler({ map, maps }) {
    mapRef.current = map
    setLoaded(true)
  }

  const createTrackers = () => {
    const trackersInSearchResults = [...results, ...suggestions]
      .filter((res) => {
        return !(
          (query.length === 1 && res.score > 0.36) ||
          (query.includes(" ") && res.score > 0.36)
        )
      })
      .map((result) => {
        return devices.map((x) => x.id).includes(result.item.id)
          ? result.item
          : null
      })
      .filter(Boolean)

    let trackerCollection: IDevice[] = []

    if (query.length > 0) {
      // Only use trackers from search results
      trackerCollection = trackersInSearchResults
        .map(({ id, position, ...rest }) => {
          const modifiedPosition = { id, ...position, properties: rest }
          return { id, position: modifiedPosition, ...rest } // ...to reflect this data model
        })
        .filter((tracker) => tracker.position.geometry.coordinates.length === 2)

      if (trackerCollection.filter((device) => device.toggled).length > 0) {
        trackerCollection = trackerCollection.filter((device) => device.toggled)
      }
    } else {
      trackerCollection =
        toggledDevices.length > 0
          ? toggledDevices
          : toggledGroups
              .flatMap((x) => x.devices)
              .map((id) => devices.find((device) => device.id === id))
    }

    return uniqBy(trackerCollection, "id")
      .map(({ id, position, ...rest }) => {
        const modifiedPosition = { id, ...position, properties: rest }
        return { id, position: modifiedPosition, ...rest }
      })
      .filter((device) => device.position.geometry.coordinates.length === 2)
      .map((tracker) => tracker.position)
  }

  const trackers = useMemo(() => createTrackers(), [
    mapOptions,
    devices,
    toggledDevices,
    toggledGroups,
    results,
    suggestions,
  ])

  const onSelectPoint = useCallback(
    (coordinates, pointId) => {
      if (pointId) {
        const clickedDevice = devices.find((device) => device.id === pointId)
        if (clickedDevice && clickedDevice.group.length > 0) {
          deviceDispatch(
            DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
              groupID: clickedDevice.group[0],
              deviceID: clickedDevice.id,
              toggleDevice: true,
            })
          )
        }
      }
    },
    [devices]
  )

  const onToggleZonesOverlay = (toggled) => {
    // if (toggled !== getUserVisibilitySetting(UserVisibilitySettings.MapZones)) {
    toggleUserVisibilitySetting(UserVisibilitySettings.MapZones)
  }

  const onToggleZoneSettings = () => {
    zonesDispatch(
      ZonesActions(ZonesTypes.ToggleZoneSettings, {
        toggled: !zoneSettingsToggled,
      })
    )
  }

  const currentTripDuration = useMemo(() => {
    if (toggledDevices.length === 1) {
      if (geoPoints && geoPoints.length > 0) {
        // Running
        return formatSecondsToDuration(
          (+new Date(last(geoPoints).properties.timestamp) -
            +new Date(first(geoPoints).properties.timestamp)) /
            1000,
          { shortUnits: true }
        )
      } else {
        // Stopped
        const currentTracker = toggledDevices[0]
        if (
          [DeviceStatusEnum.UNKNOWN, DeviceStatusEnum.STOPPED].includes(
            currentTracker.status
          ) &&
          currentTracker.values
        ) {
          const displayKey = getDisplayKey(
            currentTracker.values,
            DisplayKeyEnum.LastIgnitionStop
          )
          return displayKey && +new Date(displayKey.value) > 0
            ? formatSecondsToDuration(
                (+new Date() - +new Date(displayKey.value)) / 1000,
                { shortUnits: true }
              )
            : null
        }
      }
    }
    return null
  }, [geoPoints, devices, toggledDevices])

  const { renderPointsAndClusters } = usePointsAndClusters(
    trackers,
    mapOptions,
    onSelectPoint
  )

  const onOfflineOnlineToggle = () => {
    if (
      typeof document !== `undefined` &&
      document.visibilityState === "visible"
    ) {
      goOnline()
    } else {
      goOffline()
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (typeof document !== `undefined`) {
        document.addEventListener("visibilitychange", onOfflineOnlineToggle)
      }
    }
    return () => {
      isMounted = false
      if (typeof document !== `undefined`) {
        document.removeEventListener("visibilitychange", onOfflineOnlineToggle)
      }
      unsub()
      goOffline()
    }
  }, [])

  useEffect(() => {
    if (toggledDeviceAlarms.length > 0) {
      for (const alarm of toggledDeviceAlarms) {
        const device = devices.find((d) => d.id === alarm.deviceID)
        if (device) {
          deviceDispatch(
            DeviceActions(DeviceTypes.ToggleDeviceGroup, {
              groupID: device.group[0],
            })
          )
          deviceDispatch(
            DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
              groupID: device.group[0],
              deviceID: +alarm.deviceID,
            })
          )
        }
      }
    }
  }, [toggledDeviceAlarms])

  const onResetTooltipHandler = () => {
    dispatch(
      HistoryActions(HistoryTypes.SetTooltip, {
        toggledTooltip: null,
      })
    )
  }

  const timeoutRef = useRef(null)
  function onPolylineMouseUpHandler() {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => onResetTooltipHandler(), 4000)
  }

  function onPolylineHoverHandler(e) {
    if (
      e.domEvent.path &&
      e.domEvent.path.some(
        (elm) =>
          elm.classList && elm.classList.value.includes("AnimatedPosition")
      )
    ) {
      onResetTooltipHandler()
      return
    }

    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    const needle = { longitude: lng, latitude: lat, accuracy: 1 }
    const closestTooltipGeopoint = HaversineGeolocation.getClosestPosition(
      needle,
      tooltipGeoPoints,
      "m"
    )
    const closestPointOfInterest = HaversineGeolocation.getClosestPosition(
      needle,
      pointsOfInterest,
      "m"
    )
    const areTooltipAndPOIEqual = isEqual(
      closestTooltipGeopoint.properties.pos,
      closestPointOfInterest.properties.pos
    )

    if (!areTooltipAndPOIEqual) {
      dispatch(
        HistoryActions(HistoryTypes.SetTooltip, {
          toggledTooltip: closestTooltipGeopoint,
        })
      )
    }
  }

  function renderPoint(
    item: IMapPointOfInterest,
    isFirst: boolean = false,
    isLast: boolean = false
  ) {
    if (!item || !item.hasOwnProperty("properties")) return null

    const {
      properties: { status },
      geometry: { coordinates },
    } = item

    const [lng, lat] = coordinates
    const zIndex = [DeviceStatusEnum.RUNNING, DeviceStatusEnum.STOPPED].some(
      (x) => x === +status
    )
      ? +status === DeviceStatusEnum.RUNNING
        ? 100
        : 90
      : 10

    return (
      <StyledPoint
        key={item.key}
        hideInfo={true}
        lat={lat}
        lng={lng}
        style={{ zIndex }}
        emphasize={isFirst}
        disableEnlargeIfEmphasized
        toggleChildrenOnClick={true}
        onMouseOver={() => onResetTooltipHandler()}
        currentTripDuration={currentTripDuration}
        {...item}
      />
    )
  }

  function renderClusters(points, clusterInstance): React.ReactElement[] {
    const ptsAndClusters = points.map((item, i) => {
      const {
        properties: { cluster },
        geometry: { coordinates },
      } = item
      const [lng, lat] = coordinates
      return cluster ? (
        <Cluster
          key={item.key}
          cluster={clusterInstance}
          showProps={["address", "timestamp", "duration"]}
          lat={lat}
          lng={lng}
          keepOpen
          breadCrumb
          onClick={onResetTooltipHandler}
          onMouseOver={(e) => e.stopPropagation()}
          {...item}
        />
      ) : (
        <StyledPoint
          key={item.key}
          lat={lat}
          lng={lng}
          hideInfo={true}
          toggleChildrenOnClick={true}
          onMouseOver={(e) => e.stopPropagation()}
          onClick={onResetTooltipHandler}
          {...item}
        />
      )
    })

    return ptsAndClusters
  }

  const pointsOfInterestRendered = useMemo(() => {
    if (pointsOfInterest && pointsOfInterest.length > 0) {
      return pointsOfInterestRenderer(
        pointsOfInterest,
        renderPoint,
        renderClusters,
        mapOptions
      )
    }
    return []
  }, [pointsOfInterest, mapOptions])

  return (
    <StyledStart sidebarToggled={sidebarToggled} {...props}>
      <GoogleMap
        forwardRef={mapRef}
        onGoogleApiLoaded={onMapsLoadHandler}
        onChange={onChange}
        useRawFit
        // onDrag={() => setForceRefresh(true)}
        // onDragEnd={() => setForceRefresh(false)}
        resetBoundsOnResize={true}
        geoPoints={
          geoPoints && geoPoints.length > 0 && !isPhoneOrTablet
            ? geoPoints
            : trackers
        }
        forceAnimate={forceRefresh && !preventSubsequentZoomAndCenter}
        toggleUserInteractedOnEvents={["dragstart", "mouseover"]}
        untoggleUserInteractedOnEvents={[]}
        toggleZones={getUserVisibilitySetting(UserVisibilitySettings.MapZones)}
      >
        {pointsOfInterest &&
          pointsOfInterest.length > 0 &&
          pointsOfInterestRendered}
        {loaded &&
          mappedRoutes &&
          mappedRoutes.map((route, i) => {
            const {
              polyline: { path, ...options },
            } = route
            return [
              <Polyline
                key={`line_${i}`}
                path={path}
                options={{
                  ...options,
                  /* icons: [
                    {
                      icon: lineSymbolBlack,
                      offset: "0",
                      repeat: "15px",
                    },
                  ], */
                  zIndex: 15,
                  map: mapRef.current,
                }}
              />,
              <Polyline
                key={`line_${i}_bg`}
                path={path}
                options={{
                  ...options,
                  zIndex: 10,
                  strokeColor: "#fff",
                  strokeWeight: 6,
                  /* icons: [
                    {
                      icon: lineSymbolWhite,
                      offset: "0",
                      repeat: "15px",
                    },
                  ], */
                  map: mapRef.current,
                }}
              />,
              <Responsive
                key={`line_${i}_tooltip`}
                phone={
                  <Polyline
                    path={path}
                    onMouseDown={onPolylineHoverHandler}
                    onMouseUp={onPolylineMouseUpHandler}
                    options={{
                      ...options,
                      zIndex: 20,
                      strokeColor: "transparent",
                      strokeWeight: 72,
                      map: mapRef.current,
                    }}
                  />
                }
                tabletLandscape={
                  <Polyline
                    path={path}
                    onMouseMove={onPolylineHoverHandler}
                    onMouseOut={onResetTooltipHandler}
                    options={{
                      ...options,
                      zIndex: 20,
                      strokeColor: "transparent",
                      strokeWeight: 72,
                      map: mapRef.current,
                    }}
                  />
                }
              />,
            ]
          })}
        {trackers &&
          trackers.length > 0 &&
          renderPointsAndClusters({ currentTripDuration })}
        {loaded && toggledTooltip && (
          <span
            key="tooltip"
            lat={toggledTooltip.latitude}
            lng={toggledTooltip.longitude}
          >
            <Tooltip />
          </span>
        )}
      </GoogleMap>
      <TrackerLegend />
      <StyledZonesPanel toggled={zoneSettingsToggled}>
        <div className="inner">
          <Checkbox
            onChange={onToggleZonesOverlay}
            appearance="toggle"
            checked={getUserVisibilitySetting(UserVisibilitySettings.MapZones)}
            tw="p-4 border-b border-brand-400"
          >
            <span>Vis zoner</span>
          </Checkbox>
          <FeatureComponent user={user} feature={FEATURE.ZONES}>
            <Link to="/app/settings/zones" tw="p-4">
              <span tw="mr-4">Opsæt zoner</span>
              <Icon icon="chevron-right" size={IconSizeEnum.SM} />
            </Link>
          </FeatureComponent>
        </div>
      </StyledZonesPanel>
      <StyledZonesToggle onClick={onToggleZoneSettings}>
        <IconFlip
          toggled={zoneSettingsToggled}
          iconOn="layer-minus"
          iconOff="layer-plus"
          tw=""
        />
      </StyledZonesToggle>
    </StyledStart>
  )
}

export default Start
