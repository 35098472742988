import { HintKey } from "app/Hint/hint.types"
import { USERRIGHTS } from "data/featureFlags"
import { useCallback, useContext, useState } from "react"
import { useFirestoreUser } from "services/firestore/user"
import { toggleItemInEnumArray } from "utils/array"
import { UserContext } from "./context"
import { UserVisibilitySettings } from "./types"

export const useUser = () => {
  const {
    state: { user, userSettings },
  } = useContext(UserContext)
  const { saveFirebaseUser } = useFirestoreUser()
  const [groupTrackerCountMap, setGroupTrackerCountMap] = useState({})

  const getGroupTrackerCountMap = (userTrackers, userGroups) => {
    const userGroupIDs: number[] = userGroups.map((group) => group.id)
    const newGroupTrackerCountMap = {}
    for (const userGroupID of userGroupIDs) {
      newGroupTrackerCountMap[userGroupID] = userTrackers
        .filter((tracker) => tracker.group.includes(userGroupID))
        .length.toString()
    }
    setGroupTrackerCountMap(newGroupTrackerCountMap)
  }

  const resetGroupTrackerCountMap = () => {
    setGroupTrackerCountMap({})
  }

  const toggleUserVisibilitySetting = async (
    key: UserVisibilitySettings | HintKey
  ) => {
    const newVisibilitySettings = toggleItemInEnumArray<UserVisibilitySettings>(
      userSettings?.visibilitySettings || [],
      key
    )
    await saveFirebaseUser({ visibilitySettings: newVisibilitySettings })
  }

  const getUserVisibilitySetting = useCallback(
    (key: UserVisibilitySettings) =>
      userSettings?.visibilitySettings?.includes(key),
    [userSettings?.visibilitySettings]
  )

  return {
    groupTrackerCountMap,
    getGroupTrackerCountMap,
    resetGroupTrackerCountMap,
    toggleUserVisibilitySetting,
    getUserVisibilitySetting,
    canWrite: user?.feature.includes(USERRIGHTS.WRITE),
    user,
    userSettings,
  }
}
