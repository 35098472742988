import React, { useReducer, createContext } from "react"
import {
  Alarm,
  AlarmSetupPanels,
  AlertCriterium,
  AlertNotification,
  AlarmSchedule,
} from "./alarm.types"
import { AlarmActionMap, AlarmTypes } from "./actions"
import { updateCollectionItemByID } from "utils/array"

type InitialAlarmsStateType = {
  alarms: Alarm[]
  alertNotifications: AlertNotification[]
  editAlarm: Alarm | null
  currentCriterium: AlertCriterium | null
  currentNotification: AlertNotification | null
  currentSchedule: AlarmSchedule | null
  panel: AlarmSetupPanels | null
}

const initialState: InitialAlarmsStateType = {
  alarms: [],
  alertNotifications: [],
  editAlarm: null,
  currentCriterium: null,
  currentNotification: null,
  currentSchedule: null,
  panel: null,
}

const AlarmsContext = createContext<{
  state: InitialAlarmsStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const reducer = (state: InitialAlarmsStateType, action: AlarmActionMap) => {
  switch (action.type) {
    case AlarmTypes.SetAlarmList:
      return { ...state, alarms: action.payload.list }
    case AlarmTypes.AddAlarmToList:
      return { ...state, alarms: [...state.alarms, action.payload.alarm] }
    case AlarmTypes.SetCurrentAlarm:
      return { ...state, editAlarm: action.payload.alarm }
    case AlarmTypes.RemoveAlarmFromList:
      return {
        ...state,
        alarms: [
          ...state.alarms.filter(
            (alarm) => alarm.id !== action.payload.alarmID
          ),
        ],
      }
    case AlarmTypes.ResetAlarmForm:
      return { ...state, editAlarm: null, panel: null }
    // Criterium stuff
    case AlarmTypes.SetCurrentCriterium: // Sets current criterium being edited (or created)
      return { ...state, currentCriterium: action.payload.criterium }
    case AlarmTypes.SetCurrentEditorPanel: // Sets current criterium being edited (or created)
      return { ...state, panel: action.payload.panel }
    case AlarmTypes.UpdateAlarmByID:
      const newAlarms = updateCollectionItemByID<Alarm>(
        state.alarms,
        action.payload.alarm.id,
        action.payload.alarm
      )

      return {
        ...state,
        alarms: newAlarms,
      }
    case AlarmTypes.UpdateAlarmCriteriumByID: // Sets current criterium being edited (or created)
      if (state.editAlarm) {
        const newCriteria = updateCollectionItemByID<AlertCriterium>(
          state.editAlarm?.criteria ?? [],
          action.payload.criterium.id,
          action.payload.criterium
        )
        return {
          ...state,
          editAlarm: {
            ...state.editAlarm,
            draft: true,
            criteria: newCriteria,
          },
        }
      }

      return state
    case AlarmTypes.SetAlertNotifications:
      return {
        ...state,
        alertNotifications: action.payload.alertNotifications,
      }
    case AlarmTypes.AddAlertNotification:
      return {
        ...state,
        alertNotifications: [
          ...state.alertNotifications,
          action.payload.alertNotification,
        ],
      }
    case AlarmTypes.SetCurrentAlertNotification:
      return {
        ...state,
        currentNotification: action.payload.alertNotification,
      }
    case AlarmTypes.SetCurrentSchedule:
      return {
        ...state,
        currentSchedule: action.payload.schedule,
      }
    case AlarmTypes.UpdateAlertNotificationByID: // Sets current criterium being edited (or created)
      if (action.payload.alertNotification.id) {
        const newAlertNotifications = updateCollectionItemByID<
          AlertNotification
        >(
          state.alertNotifications,
          action.payload.alertNotification.id,
          action.payload.alertNotification
        )
        return {
          ...state,
          alertNotifications: newAlertNotifications,
        }
      }

      return state
    case AlarmTypes.Reset:
      return {
        ...state,
        eidtAlarm: null,
        currentCriterium: null,
        currentNotification: null,
        currentSchedule: null,
        panel: null,
      }
    default:
      return state
  }
}

const AlarmsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AlarmsContext.Provider value={{ state, dispatch }}>
      {children}
    </AlarmsContext.Provider>
  )
}

export { AlarmsContext, AlarmsProvider }
