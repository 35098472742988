import Icon from "lib/Icon"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledPlaybackPanel = styled.div`
  z-index: 200;
  ${tw`shadow-card bg-white p-4 print:hidden`}

  .controls {
    ${tw`flex items-center space-x-4`}
  }
`

const StyledButton = styled.span`
  ${tw`text-brand-black-light cursor-pointer`}
  z-index: 10;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  height: 3rem;
  border: none !important;
`

const StyledIcon = styled(Icon)`
  width: 1.4rem;
  height: 1.4rem;
  fill: current;
  transform: translate3d(0.2rem, 0, 0);
`

type PlaybackProps = {
  onPlay: (...args: any[]) => void
  onPause?: (...args: any[]) => void
  onStop?: (...args: any[]) => void
  playing?: boolean
  paused?: boolean
}

export const PlaybackPanel: React.FC<PlaybackProps> = ({
  onPlay,
  onPause,
  onStop,
  playing,
  paused,
  ...props
}) => {
  return (
    <StyledPlaybackPanel {...props}>
      <div className="controls">
        <StyledButton
          css={[playing && !paused && tw`opacity-60`]}
          onClick={onPlay}
        >
          <StyledIcon icon="play" />
          <span tw="text-lg">Afspil</span>
        </StyledButton>
        <StyledButton css={[paused && tw`opacity-60`]} onClick={onPause}>
          <StyledIcon icon="pause" />
          <span tw="text-lg">Pause</span>
        </StyledButton>
        <StyledButton css={[!playing && tw`opacity-60`]} onClick={onStop}>
          <StyledIcon icon="stop" />
          <span tw="text-lg">Stop</span>
        </StyledButton>
      </div>
    </StyledPlaybackPanel>
  )
}
