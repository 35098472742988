import React from "react"
import "twin.macro"
import { StyledBaseResult } from "app/ResultListGroup/Result"

export const AlarmConfigurationComponent = ({ children, ...props }) => (
  <StyledBaseResult
    tw="shadow-none p-4 px-6 border border-solid border-brand-gray-light hover:(shadow-card bg-white)"
    {...props}
  >
    {children}
  </StyledBaseResult>
)
