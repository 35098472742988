import React, { useState, useCallback, useEffect } from "react"
import { getClusters } from "./helper"
import Cluster from "./Cluster"
import Point from "./Point"
import { OverlayView } from "@react-google-maps/api"

export const usePointsAndClusters = (
  trackers: any,
  mapOptions?: any,
  onSelect?: any
) => {
  const [points, setPoints] = useState([])
  const [cluster, setCluster] = useState(null)

  const updatePoints = useCallback(() => {
    if (trackers && mapOptions.bounds && mapOptions.zoom) {
      // Creates renderable map markers based on the Trackers collection
      const { nw, se, sw, ne } = mapOptions.bounds
      const superclusterBounds = [nw.lng, sw.lat, se.lng, ne.lat]
      const { _instance, clusters } = getClusters(
        trackers,
        superclusterBounds,
        mapOptions.zoom
      )
      setPoints(clusters)
      setCluster(_instance)
    }
  }, [mapOptions, trackers])

  useEffect(() => {
    updatePoints()
  }, [trackers, mapOptions])

  const renderPointsAndClusters = ({
    wrapInOverlayView,
    currentTripDuration,
  }) => {
    return points.map((item) => {
      const {
        properties,
        geometry: { coordinates },
      } = item
      const [lng, lat] = coordinates

      const render = () => {
        return properties.cluster ? (
          <Cluster
            key={item.id}
            position={{ lng, lat }}
            lat={lat}
            lng={lng}
            cluster={cluster}
            onLeafs={onSelect ? onSelect : () => ({})}
            {...item}
          />
        ) : (
          <Point
            key={item.id}
            lat={lat}
            lng={lng}
            onClick={onSelect ? onSelect : () => ({})}
            currentTripDuration={currentTripDuration}
            // style={{ zIndex: 4000 }}
            {...item}
          />
        )
      }

      if (wrapInOverlayView) {
        return (
          <OverlayView
            key={item.id}
            mapPaneName="overlayMouseTarget"
            position={{ lng, lat }}
          >
            <>{render()}</>
          </OverlayView>
        )
      }
      return render()
    })
  }

  return { renderPointsAndClusters, points, cluster }
}
