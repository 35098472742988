import { useLocation } from "@reach/router"
import { NavigationPathEnum } from "app/Dashboard/navigation.types"

export const useTrackerSearch = () => {
  const location = useLocation()

  const calculateOffset = (offsetValue, elementHeight = 0.5) => {
    if (typeof window !== "undefined") {
      // Percentage of the viewport the offsetValue amounts to
      const offsetViewportPercentage = window.innerHeight - offsetValue

      // How much space of the viewport the element takes up
      const elementViewportHeight = window.innerHeight * elementHeight

      const offsetY =
        ((offsetViewportPercentage - elementViewportHeight) /
          elementViewportHeight) *
        100

      return offsetY
    }
  }

  const getOffsetByRoute = () => {
    switch (location.pathname) {
      case NavigationPathEnum.START:
        return calculateOffset(108, 0.5)
      default:
        return "100%"
    }
  }

  return { getOffsetByRoute }
}
