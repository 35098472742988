import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

type ExpandFeedButtonProps = {
  onClick: (...args) => void
}

const StyledExpandButton = styled.span`
  ${tw`block -my-4 relative w-max border border-brand-gray-light border-solid py-2 px-4 left-28 bg-transparent z-50 leading-none cursor-pointer md:(transition left-28 opacity-60 hover:(opacity-100 bg-white text-brand-500 border-current))`}
`

export const ExpandFeedButton: React.FC<ExpandFeedButtonProps> = ({
  children,
  onClick,
  ...props
}) => {
  const onClickHandler = (e) => {
    e.stopPropagation()
    if (onClick) onClick()
  }
  return (
    <StyledExpandButton onClick={onClickHandler}>{children}</StyledExpandButton>
  )
}
