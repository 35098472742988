import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDatepicker, START_DATE } from "@datepicker-react/hooks"
import { getYear } from "date-fns"
import Month from "./Month"
import DatepickerContext from "./context"

function Datepicker({
  numberOfMonths,
  minBookingDays,
  maxBookingDays,
  exactMinBookingDays,
  monthComponent,
  initialVisibleMonth,
  onChange,
  datepickerProps,
  startDate,
  endDate,
  ...props
}) {
  const [state, setState] = useState({
    endDate: endDate,
    startDate: startDate,
    focusedInput: START_DATE,
    initialMonthConsumed: false,
  })
  const [customYear, setCustomYear] = useState(getYear(startDate))

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    numberOfMonths,
    minBookingDays,
    maxBookingDays,
    exactMinBookingDays,
    initialVisibleMonth,
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    ...datepickerProps,
  })

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE })
    } else {
      setState(data)
    }
  }

  function onChangeYearHandler(y) {
    if (y.toString().length === 4) setCustomYear(y)
  }

  useEffect(() => {
    onChange(state)
  })

  useEffect(() => {
    if (
      state.initialMonthConsumed === false &&
      !isDateSelected(initialVisibleMonth)
    ) {
      onDateSelect(initialVisibleMonth)
      setState((prev) => ({
        ...prev,
        initialMonthConsumed: true,
      }))
    }
    return () => {
      setState((prev) => ({
        ...prev,
        initialMonthConsumed: false,
      }))
    }
  }, [])

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      {activeMonths.map((month) => {
        return (
          <monthComponent.type
            key={`${month.year}-${month.month}`}
            year={customYear}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
            onGoToNextMonths={goToNextMonths}
            onGoToPreviousMonths={goToPreviousMonths}
            onChangeYear={onChangeYearHandler}
            {...monthComponent.props}
            {...props}
          />
        )
      })}
    </DatepickerContext.Provider>
  )
}

export default Datepicker

Datepicker.defaultProps = {
  numberOfMonths: 1,
  minBookingDays: 1,
  exactMinBookingDays: true,
  initialVisibleMonth: new Date(),
  onChange: () => {},
  monthComponent: <Month />,
  startDate: null,
  endDate: null,
}

Datepicker.propTypes = {
  numberOfMonths: PropTypes.number,
  minBookingDays: PropTypes.number,
  exactMinBookingDays: PropTypes.bool,
  initialVisibleMonth: PropTypes.object,
  onChange: PropTypes.func,
  monthComponent: PropTypes.node,
  datepickerProps: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
}
