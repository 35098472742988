import React, { useState, useEffect, useContext, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { Button, ButtonGroup, Responsive, TagInput } from "@clevertrack/shared"
import Icon from "lib/Icon"
import Checkbox from "lib/Checkbox"
import cogoToast from "@clevertrackdk/cogo-toast"
import I18n from "app/I18n"
import { Grid } from "app/Grid"
import PopOver from "app/PopOver"
import Tile from "app/Tile"
import TileContainer from "app/TileContainer"
import UserForm from "app/UserForm"
import {
  getUsers,
  updateUser,
  createUser,
  deleteUser,
  resetPasswordByAdmin,
  getAssignedTrackersAndGroupsByUserId,
  updateAssignedTrackersAndGroupsByUserId,
} from "services/user"

import usePrevious from "hooks/usePrevious"

import UserSelector from "./UserSelector"
import AssignTrackers from "app/AssignTrackers"
import { SearchContext } from "app/Search/context"
import { resetSearch } from "app/Search/actions"
import { useDevices } from "app/Device/hooks"
import { IUser, UserTypeEnum } from "app/User/types"
import { UserTypeSelector } from "./UserTypeSelector"
import { CompanyContext } from "app/Company/context"
import { Hint } from "app/Hint"
import { UsersHintEnum } from "./users.types"

/* const StyledGrid = styled(Grid)`
  position: relative;
  div > header {
    display: flex;
    position: sticky;
    align-items: center;
    top: 0;
    justify-content: space-between;
    padding: 2rem 0;
    z-index: 200;
    background: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 2fr;
    grid-column-gap: 4rem;
    padding: 2rem 2rem 8rem;
    max-width: none;
    margin: 0 auto;

    div > header {
      display: flex;
      position: relative;
      align-items: center;
      top: 0;
      justify-content: space-between;
      padding: 0 0 2rem;
      background: none;
    }

    h1 {
      display: block;
      margin: 0;
      background: ${props.theme.colors.white};
      z-index: 20;
    }
  `}
` */

const FeatureFlagsWrapper = styled.div`
  padding-top: 1rem;
`

const UserRightsWrapper = styled.div`
  margin-bottom: 4rem;
`

export const StyledTile = styled(Tile)`
  box-shadow: none;
  border: none;
  padding: 2rem;
  padding-bottom: 0;
  background: white;
  height: auto;
`

export const StyledTileContainer = styled(TileContainer)`
  padding-bottom: 6.7rem;
  padding-top: 2rem;
  background: white;
`

function Users({ children, ...props }) {
  const { deviceGroups: groups, devices: vehicles } = useDevices(false)
  const { dispatch } = useContext(SearchContext)
  const {
    state: { company },
  } = useContext(CompanyContext)
  const [users, setUsers] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [showUserTypes, setShowUserTypes] = useState<boolean>(false)
  const [userType, setUserType] = useState<UserTypeEnum | null>(null)
  const [showUserForm, setShowUserForm] = useState(false)
  const [user, setUser] = useState({})
  const [userTrackers, setUserTrackers] = useState([])
  const [userGroups, setUserGroups] = useState([])
  const [showTrackerSelector, setShowTrackerSelector] = useState(false)
  const userFormRef = useRef(null)
  const wrapperRef = useRef(null)

  const updateAssignedTrackersAndGroups = async (id, grps, vhcls) => {
    try {
      const payload = {
        vehicles: vhcls
          ? vhcls.map((t) => t.id)
          : userTrackers.map((t) => t.id),
      }
      if (grps) {
        payload.groups = grps
          ? grps.map((t) => t.id)
          : userGroups.map((t) => t.id)
      }
      const response = await updateAssignedTrackersAndGroupsByUserId(
        id,
        payload
      )
      if (response.data.result === "OK") {
        if (grps) {
          setUserGroups(grps)
        }
        setUserTrackers(vhcls)
        // getAssignedTrackersAndGroups() |||||||  BUGFIX - WAS THIS SUPPOSED TO BE CALLED?
        cogoToast.success("Opdaterede enheder og grupper tilknyttet brugeren")
      }
    } catch (error) {
      cogoToast.error(
        `Noget gik galt under opdatering af enheder og grupper tilknyttet brugeren: ${error}`
      )
    }
  }

  const updateUserAsync = async (id, updatedUser) => {
    try {
      const response = await updateUser(id, updatedUser)
      if (response.data.result === "OK") {
        // await updateAssignedTrackersAndGroups(response.data.user.id)
        loadUsers()
        cogoToast.success("Bruger opdateret")
        onCancelHandler()
      }
    } catch (e) {
      cogoToast.error("Opdatering af bruger mislykkedes")
    }
  }

  const getAssignedTrackersAndGroups = async (id) => {
    try {
      const response = await getAssignedTrackersAndGroupsByUserId(id)
      if (response.data.result === "OK") {
        const { groups: group_ids, vehicles: tracker_ids } = response.data
        const user_groups = groups.filter((g) =>
          group_ids.find((id) => id === g.id)
        )
        const user_trackers = vehicles.filter((t) =>
          tracker_ids.find((id) => id === t.id)
        )
        setUserGroups(user_groups)
        setUserTrackers(user_trackers)
      }
    } catch (error) {
      cogoToast.error(
        `Kunne ikke hente enheder og grupper tilknyttet brugeren: ${error}`
      )
    }
  }

  const createUserAsync = async (newUser) => {
    try {
      const response = await createUser(newUser)
      if (response.data.result === "OK") {
        loadUsers()
        setSelectedUser(response.data.user.id)
        getAssignedTrackersAndGroups(response.data.user.id)
        setUser(response.data.user)
        cogoToast.success("Bruger oprettet")
      } else {
        const errorMsg = response.data.result.replace("Fejl: ", "")
        cogoToast.error(errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1))
      }
    } catch (e) {
      cogoToast.error("Noget gik galt under brugeroprettelse")
    }
  }

  const onDeleteHandler = async (id) => {
    try {
      const response = await deleteUser(id)
      if (response.data.result === "OK") {
        loadUsers()
        onCancelHandler()
        return cogoToast.success("Bruger slettet")
      } else {
        cogoToast.error(`Noget gik galt: ${response.data.result}`)
      }
    } catch {
      cogoToast.error("Kunne ikke slette brugeren på nuværende tidspunkt")
    }
  }

  const onResetPassword = async (id) => {
    try {
      const response = await resetPasswordByAdmin(id)
      if (response.data.result === "OK") {
        return cogoToast.success("Brugerens password nulstillet")
      } else {
        cogoToast.error(`Noget gik galt: ${response.data.result}`)
      }
    } catch {
      cogoToast.error("Kunne ikke nulstille brugerens password lige nu")
    }
  }

  const loadUsers = async () => {
    try {
      const response = await getUsers()
      const { users } = response.data
      setUsers(users)
    } catch (e) {}
  }

  const onNewUserHandler = () => {
    setShowUserTypes(true)
    setShowUserForm(false)
    setSelectedUser(null)
  }

  const onSelectUserTypeHandler = (user: IUser) => {
    const { id, ...template } = user
    setShowUserTypes(false)
    setShowUserForm(true)
    setSelectedUser(null)
    setUser(template)
  }

  const onSelectUser = (user) => {
    const newSelectedUser = users.find((x) => x.id === user.id)
    setShowUserTypes(false)
    setShowUserForm(true)
    setSelectedUser(newSelectedUser.id)
    setUser(newSelectedUser)

    getAssignedTrackersAndGroups(newSelectedUser.id)
  }

  const onChangeUser = (e, key) => {
    const { password, ...rest } = user
    const editedUser = {
      ...rest,
      [key]: e.target.value,
    }
    setUser(editedUser)
  }

  const onChangeUserBoolean = (value, key) => {
    const newValue = value ? 1 : 0
    const { password, ...rest } = user
    const editedUser = {
      ...rest,
      [key]: newValue,
    }

    return newValue !== user[key] ? setUser(editedUser) : setUser(rest)
  }

  const onUpdateHandler = (e, invalidFields) => {
    if (invalidFields.filter((field) => field.error).length.length > 0) {
      cogoToast.error("Nogle felter er ugyldige. Korrigér og prøv igen.")
    } else {
      updateUserAsync(user.id, user)
    }
  }

  const onCreateHandler = (e, invalidFields) => {
    if (invalidFields.filter((field) => field.error).length > 0) {
      cogoToast.error("Nogle felter er ugyldige. Korrigér og prøv igen. ")
    } else {
      createUserAsync(user)
    }
  }

  const onCancelHandler = () => {
    setSelectedUser(null)
    setShowUserTypes(false)
    setShowUserForm(false)
    setShowTrackerSelector(false)
    dispatch(resetSearch())
  }

  const onAssignedTrackersChange = (trackers) => {
    const [vhcls, grps] = trackers
    updateAssignedTrackersAndGroups(selectedUser, grps, vhcls)
  }

  const onOpenTrakcerSearch = (e) => {
    e.preventDefault()
    setShowTrackerSelector(true)
  }

  const onCloseTrackerSearch = (e) => {
    e.preventDefault()
    setShowTrackerSelector(false)
  }

  useEffect(() => {
    if (users === null) loadUsers()
  }, [])

  useEffect(() => {
    const fakeEvent = createFakeEvent(user.email)
    onChangeUser(fakeEvent, "username")
  }, [user.email])

  const deselectedFeature = usePrevious(user.feature)
  useEffect(() => {
    if (!!user.is_admin === false) {
      if (user.feature?.length < 1) {
        cogoToast.info("Mindst én funktion skal vælges")
        setTimeout(() => {
          addTagHandler(deselectedFeature[0])
        }, 100)
      }
    }
  }, [user.feature])

  const addTagHandler = (tag) => {
    const fakeEvent = createFakeEvent([...user.feature, tag])
    onChangeUser(fakeEvent, "feature")
  }

  const removeTagHandler = (tag) => {
    const fakeEvent = createFakeEvent(user.feature.filter((x) => x !== tag))
    onChangeUser(fakeEvent, "feature")
  }

  const createFakeEvent = (value) => {
    return { target: { value: value } }
  }

  const onUserFormSubmitHandler = (e, invalidFields) => {
    e.preventDefault()
    if (selectedUser === null) {
      onCreateHandler(e, invalidFields)
    } else {
      onUpdateHandler(e, invalidFields)
    }
  }

  const isNewAdminUser = useMemo(() => {
    return user.is_admin && !selectedUser
  }, [selectedUser, user])

  const renderFeatures = () => {
    const tagMap = Object.values(company?.features)
    return (
      <>
        <h4>Adgang</h4>
        <TagInput
          tagMap={tagMap}
          tags={user.feature}
          onAddTag={addTagHandler}
          onRemoveTag={removeTagHandler}
        />
      </>
    )
  }

  const renderFunctions = () => {
    const tagMap = Object.values(company?.functions)

    return (
      <>
        <h4>Funktioner</h4>
        <TagInput
          tagMap={tagMap}
          tags={user.feature}
          onAddTag={addTagHandler}
          onRemoveTag={removeTagHandler}
        />
      </>
    )
  }

  const renderUserForm = (append) => {
    if (!user) return null
    return (
      <>
        <header tw="flex items-center justify-between">
          <h3 tw="mr-4 whitespace-nowrap my-0 md:(my-8)">Bruger info</h3>
          <Button
            type="button"
            size="sm"
            variant="danger"
            onClick={() => onResetPassword(user.id)}
          >
            Nulstil Password
          </Button>
        </header>
        <UserForm
          onSubmit={onUserFormSubmitHandler}
          onFieldChange={onChangeUser}
          user={user}
          forwardRef={userFormRef}
        >
          <UserRightsWrapper>
            <h3>Rettigheder</h3>
            <Checkbox
              checked={user.is_admin === 1}
              appearance="toggle"
              onChange={(value) => onChangeUserBoolean(value, "is_admin")}
            >
              <span>Brugeren er administrator</span>
            </Checkbox>
            <Checkbox
              checked={user.active === 1}
              appearance="toggle"
              onChange={(value) => onChangeUserBoolean(value, "active")}
            >
              <span>{`${
                !!user.active ? `Brugeren er aktiv` : `Brugeren er inaktiv`
              }`}</span>
            </Checkbox>
            {!user.is_admin && (
              <FeatureFlagsWrapper>
                {renderFeatures()}
                {renderFunctions()}
              </FeatureFlagsWrapper>
            )}
          </UserRightsWrapper>
          <Hint
            tw="mb-8"
            toggled={
              selectedUser !== null &&
              userTrackers.length === 0 &&
              user.is_admin !== 1
            }
            variant="warning"
            type={UsersHintEnum.MissingDeviceAssignment}
          />
          {append}
        </UserForm>
      </>
    )
  }

  const renderUserCreationButton = () => {
    return isNewAdminUser ? (
      <Button
        type="submit"
        variant="primary"
        disabled={
          selectedUser !== null &&
          userTrackers.length === 0 &&
          user.is_admin !== 1
        }
      >
        {selectedUser === null ? `Opret bruger` : `Gem bruger`}
      </Button>
    ) : (
      <Button
        type="submit"
        variant={selectedUser ? "primary" : "default"}
        disabled={
          selectedUser !== null &&
          userTrackers.length === 0 &&
          user.is_admin !== 1
        }
      >
        <Responsive
          phone={
            <span>
              {selectedUser === null ? `Tildel enheder` : `Gem bruger`}
            </span>
          }
          tabletLandscape={
            <span>
              {selectedUser === null
                ? `Tildel enheder til brugeren`
                : `Gem bruger`}
            </span>
          }
        />

        {selectedUser === null && (
          <Icon tw="ml-4" icon="chevron-right" size="sm" />
        )}
      </Button>
    )
  }

  const renderUserCreation = () => {
    return (
      <Responsive
        phone={
          <PopOver show={showUserForm} selector={wrapperRef.current} fromBottom>
            <StyledTileContainer>
              <StyledTile>
                {renderUserForm(
                  <>
                    {user.id && !!user.is_admin === false && (
                      <>
                        <h3>
                          <I18n strKey="users_assigned_trackers" />
                        </h3>
                        <ButtonGroup>
                          <Button
                            variant="default"
                            onClick={onOpenTrakcerSearch}
                          >
                            <I18n strKey="trackers" />
                          </Button>
                        </ButtonGroup>
                      </>
                    )}
                    <>
                      <ButtonGroup
                        tw="-mx-8 px-8 bg-white"
                        position="right"
                        sticky
                        style={{ bottom: 0 }}
                      >
                        <Button
                          invert
                          variant="default"
                          type="button"
                          onClick={onCancelHandler}
                        >
                          <I18n strKey="cancel" />
                        </Button>
                        {renderUserCreationButton()}
                      </ButtonGroup>
                    </>
                  </>
                )}
              </StyledTile>
            </StyledTileContainer>
          </PopOver>
        }
        tabletLandscape={
          showUserForm
            ? renderUserForm(
                <ButtonGroup
                  position="right"
                  sticky
                  tw="px-0 bg-white md:(bottom-0)"
                >
                  <Button
                    invert
                    variant="default"
                    type="button"
                    onClick={onCancelHandler}
                  >
                    <I18n strKey="cancel" />
                  </Button>
                  {renderUserCreationButton()}
                </ButtonGroup>
              )
            : null
        }
      />
    )
  }

  const renderUserTypes = () => {
    return (
      <Responsive
        phone={
          <PopOver
            show={showUserTypes}
            selector={wrapperRef.current}
            fromBottom
            tw="bg-white p-4 py-20"
          >
            <UserTypeSelector onSelectUserType={onSelectUserTypeHandler} />
          </PopOver>
        }
        tabletLandscape={
          showUserTypes ? (
            <UserTypeSelector onSelectUserType={onSelectUserTypeHandler} />
          ) : (
            false
          )
        }
      />
    )
  }

  return (
    <Grid gridConfig="60rem 1.5fr 2fr" ref={wrapperRef}>
      <div>
        <header tw="hidden md:block">
          <h3>
            <I18n strKey="users_plural" />
          </h3>
          <Button
            icon="right"
            size="sm"
            variant="primary"
            onClick={onNewUserHandler}
          >
            <span>
              <I18n strKey="new" /> <I18n strKey="user" />
            </span>
            <Icon size="sm" icon="plus" />
          </Button>
        </header>
        <UserSelector
          myUser={props.myUser}
          users={users}
          onToggleUser={onSelectUser}
          onCancel={onCancelHandler}
          onDelete={onDeleteHandler}
          selectedUserID={selectedUser || null}
        />
        <ButtonGroup tw="bg-white md:hidden px-8" sticky="bottom">
          <Button icon="right" variant="primary" onClick={onNewUserHandler}>
            <span>
              <I18n strKey="new" /> <I18n strKey="user" />
            </span>
            <Icon size="sm" icon="plus" />
          </Button>
        </ButtonGroup>
      </div>
      <div>
        {renderUserTypes()}
        {renderUserCreation()}
      </div>
      <Responsive
        phone={
          <PopOver show={showTrackerSelector} fromBottom>
            <StyledTileContainer>
              <StyledTile>
                <AssignTrackers
                  availableGroups={groups}
                  availableTrackers={vehicles}
                  assignedGroups={userGroups}
                  assignedTrackers={userTrackers}
                  onToggleAssignment={onAssignedTrackersChange}
                />
              </StyledTile>
              <ButtonGroup tw="mt-16 px-8 bg-white" sticky="bottom">
                <Button variant="default" onClick={onCloseTrackerSearch}>
                  <I18n strKey="close" />
                </Button>
              </ButtonGroup>
            </StyledTileContainer>
          </PopOver>
        }
        tabletLandscape={
          <>
            {selectedUser !== null && user.is_admin === 0 && (
              <AssignTrackers
                availableGroups={groups}
                availableTrackers={vehicles}
                assignedGroups={userGroups}
                assignedTrackers={userTrackers}
                onToggleAssignment={onAssignedTrackersChange}
              />
            )}
          </>
        }
      />
    </Grid>
  )
}

export default Users

Users.defaultProps = {}
Users.propTypes = {
  children: PropTypes.node,
}
