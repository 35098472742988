import { AlarmHintEnum } from "routes/Alarms/alarm.types"
import { HistoryHintEnum } from "routes/HistoryV2/history.types"
import { DriverIDHintEnum } from "routes/Drivers/driver.types"
import { UsersHintEnum } from "routes/Users/users.types"
import { HintKey, IHintContent } from "./hint.types"
import { PlatformStatusHintEnum } from "app/NotificationCenter/PlatformStatus/platformstatus.types"

export const getHintContent = (key: HintKey): IHintContent => {
  switch (key) {
    case HistoryHintEnum.NoActivityDays:
      return {
        content: "Kun dage med aktivitet vises",
      }
    case DriverIDHintEnum.ImportTemplate:
      return {
        content:
          "<strong>Bemærk</strong>: Skabelonen indeholder to valgfri felter: 'cardID' og 'accesscode'. Disse kan tilføjes manuelt senere.<br /><strong>Bemærk</strong>: Filen skal gemmes som .csv fil, og være formateret som UTF-8.",
      }
    case DriverIDHintEnum.AccessCodeInUse:
      return {
        content:
          "Pinkoden er allerede i brug.<br />Vælg venligst en anden kode.",
      }
    case AlarmHintEnum.NoSelectedDevices:
      return {
        content: "Tildel enheder eller grupper, som er omfattet af alarmen.",
      }
    case AlarmHintEnum.NoSelectedCriteria:
      return {
        content: "Alarmen udløses når alle kriterier er opfyldt.",
      }
    case AlarmHintEnum.NoSelectedNotifications:
      return {
        content:
          "Når alarmen udløses bliver underretningen udsendt til de valgte modtagere.",
      }
    case AlarmHintEnum.NoSelectedSchedule:
      return {
        content:
          "Tilføj en tidsplan, hvis du kun ønsker at alarmen kun udløses i et bestemt tidsrum.",
      }
    case AlarmHintEnum.ScheduleWeek:
      return {
        content: `Hvis du vælger "udenfor perioden", og angiver tirsdag 08.00 - 16.00, vil alarmen kunne udløses mellem 16.00 og starttidspunktet den efterfølgende dag.`,
      }
    case AlarmHintEnum.SchedulePeriod:
      return {
        content:
          "Hvis en alarm udløses indenfor en angivet periode, ignoreres ugeskemaet.",
      }
    case AlarmHintEnum.UnsavedChanges:
      return {
        content: "Bemærk: Alarmen har ændringer der endnu ikke er gemte.",
      }
    case UsersHintEnum.MissingDeviceAssignment:
      return {
        content: "Bemærk: Du skal tildele mindst én enhed til brugeren",
      }
    case PlatformStatusHintEnum.DataSecurity:
      return {
        content:
          "Clevertrack opbevarer sikkert enhedens opdateringer på den enkelte enhed. Data bliver tilgængelig straks at forbindelsen er genetableret og køen fra indkomne data er afviklet.",
      }
    default:
      return {
        content: "",
      }
  }
}
