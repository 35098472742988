import { StorageReference } from "firebase/storage"
import React from "react"
import tw from "twin.macro"
import {
  DeviceDocumentDirectoryItem,
  UploadProgressMap,
} from "app/DocumentUpload/DeviceDocumentUpload"
import styled from "styled-components"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledFileList = styled.div`
  ${tw`space-y-2 mb-4`}
  .item {
    svg {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`

type FileListProps = {
  directory?: DeviceDocumentDirectoryItem[] | []
  fileMap?: UploadProgressMap
  onDelete?: (ref: StorageReference) => void
}

export const FileList: React.FC<FileListProps> = ({
  fileMap,
  directory,
  onDelete,
  ...props
}) => {
  /* const downloadHandler = (ref: StorageReference) => {
    console.log(ref)
    getBlob(ref)
      .then((value) => {
        console.log(value)
      })
      .catch((e) => console.log(e))
  } */

  const onDeleteHandler = (ref: StorageReference) => {
    if (onDelete) {
      onDelete(ref)
    }
  }

  return (
    <StyledFileList>
      {directory?.map((item: DeviceDocumentDirectoryItem, x) => {
        return (
          <div key={`item_${x}`} tw="flex items-center w-full" className="item">
            <a
              tw="transition-all cursor-pointer block hover:text-brand-500"
              target="_blank"
              href={item.downloadURL}
            >
              <span tw="text-xl leading-tight">{item.name}</span>
            </a>
            {onDelete && (
              <span
                tw="text-brand-red-500 ml-auto"
                onClick={(e) => onDeleteHandler(item.ref)}
              >
                <Icon icon="close" />
              </span>
            )}
          </div>
        )
      })}
    </StyledFileList>
  )
}
