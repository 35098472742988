import React from "react"
import styled from "styled-components"
import { FormField, ValidationInput } from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"

const StyledLogin = styled.div`
  position: relative;
`

const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
const validationMessages = {
  ...defaultValidationMessages,
  customError: "Ups! Indtast en korrekt email.",
}

function EmailForm({ ...props }) {
  return (
    <StyledLogin {...props}>
      <FormField validationKey="email" label="Email">
        <ValidationInput
          required
          id="email"
          type="email"
          placeholder="Indtast email"
          customValidation={emailValidation}
          validationMessages={validationMessages}
        />
      </FormField>
    </StyledLogin>
  )
}

export default EmailForm

EmailForm.defaultProps = {}
EmailForm.propTypes = {}
