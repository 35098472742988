import React, { useContext, useEffect, useMemo } from "react"
import styled from "styled-components"
import { SizeEnum } from "theme"
import tw from "twin.macro"
import orderBy from "lodash-es/orderBy"
import { Button, Icon } from "@clevertrack/shared"
import PopOver from "app/PopOver"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { MenuTypeEnum } from "context/App/app.types"
import { IconSizeEnum } from "lib/Icon"
import { NotificationCenterContext } from "./context"
import { PlatformStatus } from "./PlatformStatus"

type NotificationCenterProps = {}

const StyledPopOver = styled(PopOver)`
  width: 100vw;
  overflow-y: scroll;
  // max-width: 36rem;
  box-shadow: -1px 0px -4px 1px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 2000;
  bottom: ${SizeEnum.TapBarHeight}rem;

  ${tw`w-full bg-white px-8 pt-4 pb-16`}

  .wrapper {
    ${tw`bg-white h-full`}
    display: grid;
    grid-template-rows: ${SizeEnum.Tabs}rem auto;
    overflow-y: hidden;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    left: auto;
    top: ${SizeEnum.DesktopMenu}rem;
    right: 0;
    position: fixed;
    max-width: 40rem;
    bottom: 0;
    z-index: 2000;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 50rem;
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    max-width: 50rem;

  `}

  ${(props) => props.theme.media.xxxl_desktop_up`
    max-width: 60rem;
  `}
`

export const NotificationCenter: React.FC<NotificationCenterProps> = ({
  onClose,
  ...props
}) => {
  const {
    dispatch,
    state: { toggledMenus, navToggled },
  } = useContext(AppContext)
  const {
    state: { platformUpdates },
  } = useContext(NotificationCenterContext)

  const memoizedPlatformUpdates = useMemo(() => {
    return orderBy(platformUpdates, "timestamp", "desc")
  }, [platformUpdates])

  useEffect(() => {
    if (navToggled && toggledMenus.includes(MenuTypeEnum.Notifications)) {
      dispatch(GlobalAppActions(GlobalAppTypes.ToggleNav, { toggled: false }))
    }
  }, [toggledMenus, navToggled])

  return (
    <StyledPopOver
      fromRight
      show={toggledMenus.includes(MenuTypeEnum.Notifications)}
      selector="#___offcanvas"
    >
      <header tw="flex justify-between items-center">
        <h3 tw="m-0">Notifikationer</h3>
        <Button
          type="button"
          icon="left"
          onClick={onClose}
          variant="transparent"
        >
          <Icon icon="close" size={IconSizeEnum.SM} />
          <span tw="text-lg">Luk</span>
        </Button>
      </header>
      <section>
        {memoizedPlatformUpdates.map((status) => (
          <PlatformStatus key={status.id} {...status} />
        ))}
      </section>
    </StyledPopOver>
  )
}
