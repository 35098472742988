// --------------------------------------------
//
// ✨ Functional utilties to improve JS ✨
//
// --------------------------------------------

const shallowCopy = (object) => {
  return { ...object }
}

const lastInArr = (arr) => arr[arr.length - 1]

// Functional alternative to switches
const match = (x) => {
  const matched = (x) => ({
    on: () => matched(x),
    otherwise: () => x,
  })

  return {
    on: (check, λ) => (check(x) ? matched(λ(x)) : match(x)),
    otherwise: (λ) => λ(x),
  }

  //  match(object)
  //    .on(x => condition(x), () => thenDoThis() )
  //    .otherwise( () => doThis() )
}

// same idea, but to replace while loop pattern
const until = (check, x) => {
  const done = (x) => ({
    attempt: () => done(x),
    finally: (λ) => λ(x),
  })

  return {
    attempt: (λ) => {
      const cached_λ = λ(x)
      return check(cached_λ) ? done(cached_λ) : until(check, x)
    },
    finally: (_, λ) => λ(x),
  }
}

export { match, until, shallowCopy, lastInArr }
