import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StyledMapsButton = styled.button<ICustomMapsButton>`
  display: block;
  width: 4rem;
  height: 4rem;
  background: ${(props) => props.theme.colors.white};
  padding: 0;
  border: none;
  cursor: pointer;
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  ${tw`print:hidden`}

  &:focus {
    outline: none;
  }
`

interface ICustomMapsButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

const CustomMapsButton: React.FC<ICustomMapsButton> = ({
  children,
  ...props
}) => {
  return <StyledMapsButton {...props}>{children}</StyledMapsButton>
}

export { CustomMapsButton }
