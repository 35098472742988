import { useContext } from "react"
import { ZonesContext } from "./context"
import cogoToast from "@clevertrackdk/cogo-toast"
import { zoneService, GetAllZonesResponse } from "services/zones"
import { ZonesTypes, ZonesActions } from "./actions"
import { IZone } from "./zones.types"
import { CompanyContext } from "app/Company/context"
import { FirebaseContext } from "context/FirebaseProvider"
import { deleteDoc, doc, writeBatch } from "firebase/firestore"

export const useZones = () => {
  const {
    state: { company },
  } = useContext(CompanyContext)
  const { dispatch } = useContext(ZonesContext)
  const { db } = useContext(FirebaseContext)
  const service = zoneService()

  const getAllZones = async () => {
    try {
      const zonesResponse:
        | GetAllZonesResponse
        | undefined = await service.getAllZones()
      if (zonesResponse) {
        if (zonesResponse.data?.result === "OK") {
          if (company && company.id && db) {
            const firebaseCompany = doc(
              db,
              `companies/${company.id?.toString()}`
            )
            const batch = writeBatch(db)
            zonesResponse.data.zones.forEach((zoneDoc) => {
              const docRef = doc(firebaseCompany, `/zones/${zoneDoc.id}`)
              batch.set(docRef, zoneDoc)
            })
            await batch.commit()
          }
          dispatch(
            ZonesActions(ZonesTypes.SetZoneList, {
              list: zonesResponse.data.zones,
            })
          )
        } else {
          cogoToast.error(
            `Kunne ikke indlæse zoner: ${zonesResponse.data.result}`
          )
        }
      }
    } catch (error) {
      cogoToast.error(`Kunne ikke indlæse zoner: ${error}`)
    }
  }

  const createNewZone = async (zone: IZone) => {
    try {
      const createZoneResponse = await service.createZone(zone)
      if (createZoneResponse?.data.result === "OK") {
        cogoToast.success(`Zonen "${zone.name}" blev korrekt oprettet`)
        await getAllZones()
        return "success"
      } else {
        cogoToast.error(
          `Kunne ikke oprette zonen: ${createZoneResponse?.data.result}`
        )
        return "error"
      }
    } catch (error) {
      cogoToast.error(`Kunne ikke oprette zonen: ${error}`)
      return "error"
    }
  }

  const deleteZone = async (id: number) => {
    try {
      const deleteZoneResponse = await service.deleteZone(id)
      if (deleteZoneResponse?.data.result === "OK") {
        if (company && company.id && db) {
          const zoneDoc = doc(
            db,
            `companies/${company.id?.toString()}/zones/${id}`
          )
          await deleteDoc(zoneDoc)
        }
        cogoToast.success(`Zonen blev slettet`)
        await getAllZones()
      } else {
        cogoToast.error(
          `Kunne ikke slette zonen: ${deleteZoneResponse?.data.result}`
        )
      }
    } catch (error) {
      cogoToast.error(`Kunne ikke slette zonen: ${error}`)
    }
  }

  const updateZone = async (zone: IZone) => {
    try {
      const editZoneResponse = await service.updateZone(zone)
      if (editZoneResponse?.data.result === "OK") {
        cogoToast.success(`Zone gemt`)
        await getAllZones()
        return "success"
      } else {
        cogoToast.error(
          `Kunne ikke opdatere zonen: ${editZoneResponse?.data.result}`
        )
        return "error"
      }
    } catch (error) {
      cogoToast.error(`Kunne ikke opdatere zonen: ${error}`)
      return "error"
    }
  }

  return { getAllZones, createNewZone, deleteZone, updateZone }
}
