import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledPanel = styled.div`
  background: ${(props) => props.theme.colors.grayLighter};
  position: relative;
  z-index: 10;
  min-width: 100%;
  min-height: 100%;
  padding: 2rem 0 0;

  ${(props) => props.theme.media.tablet_landscape_up`
     padding-top: 1rem;
  `}
`

function Panel({ children, ...props }) {
  return <StyledPanel {...props}>{children}</StyledPanel>
}

export default Panel

Panel.defaultProps = {}
Panel.propTypes = {
  children: PropTypes.node.isRequired,
}
