import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

type GridType = {
  gridConfig?: string
}

const StyledGrid = styled.div<GridType>`
  display: grid;
  grid-template-columns: 1fr;
  overflow-y: scroll;
  height: calc(100vh - 9.8rem);
  padding: 0;

  h1 {
    display: none;
  }

  > * {
    &::-webkit-scrollbar {
      background: transparent;
      border-radius: 0;
      width: 8px;
      padding: 2px 2px 2px 0;
    }
    &::-webkit-scrollbar-button {
    }
    &::-webkit-scrollbar-track {
      /* 3 */
      background: transparent;
      border-radius: 0;
      padding: 2px 2px 2px 0;
    }
    &::-webkit-scrollbar-track-piece {
      /* 4 */
    }
    &::-webkit-scrollbar-thumb {
      /* 5 */
      background: transparent;
      border-radius: 0;
      margin: 2px;
    }
    &::-webkit-scrollbar-corner {
      /* 6 */
    }
    &::-webkit-resizer {
      /* 7 */
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    z-index: 60;
    height: calc(100vh - 8rem);

    h1 {
      display: block;

      top: 0;
      padding: 2rem 0;
      margin: 0;
      background: ${props.theme.colors.white};
      z-index: 20;
    }

    > div:not([class]) {
      padding: 0 1rem 2rem;
      height: 100%;
      overflow-y: scroll;

      &:first-child {
        padding: 0 1rem 2rem 3rem;
      }

      &:last-child:not(:first-child) {
        padding: 0 3rem 2rem 1rem;
      }
    }

    div > header {
      display: flex;
      position: sticky;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 1rem;
      top: 0;
      justify-content: space-between;
      z-index: 70;
      background: ${props.theme.colors.white};
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    grid-template-columns: ${props.gridConfig ?? `1fr`};
    grid-column-gap: 2rem;
  `}
`

const Grid: React.FC<GridType> = ({ children, ...props }) => {
  return <StyledGrid {...props}>{children}</StyledGrid>
}

export { Grid }
