import React, { useContext } from "react"
import { UserContext } from "app/User/context"

function FeatureComponent({ children, feature, ...props }) {
  const {
    state: { user },
  } = useContext(UserContext)
  const hasFeature = user?.is_admin
    ? true
    : user?.feature.some((f) => f === feature)
  return hasFeature ? <>{children}</> : null
}

export default FeatureComponent
