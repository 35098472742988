import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledNavLink = styled((props) => <Link {...props} />)`
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 1.6rem;
  height: 100%;
  color: ${(props) => props.theme.colors.gray};
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  transition: all ease-out 0.15s;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.primary};

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      background: ${(props) => props.theme.colors.primary};
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    color: ${props.theme.colors.gray};
    border-right: none;
    border-bottom: 5px solid transparent;

    &.active,
    &:hover {
      color: ${props.theme.colors.primary};
      border-right: none;

      &:not(.icon-only) {
        border-bottom: 5px solid currentColor;
      }

      &:after {
        display: none;
      }
    }
  `}
`

function NavLink({ snappy, children, ...props }) {
  function mouseDownHandler(e) {
    if (snappy && typeof e.target.click === "function") e.target.click()
  }

  if (!props.to) {
    return (
      <StyledNavLink
        as="a"
        onMouseDown={mouseDownHandler}
        draggable="false"
        {...props}
      >
        {children}
      </StyledNavLink>
    )
  }

  return (
    <StyledNavLink onMouseDown={mouseDownHandler} draggable="false" {...props}>
      {children}
    </StyledNavLink>
  )
}

export default NavLink

NavLink.defaultProps = {
  snappy: false,
}
NavLink.propTypes = {
  snappy: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
