import { formatDistanceToNowStrict, parseJSON } from "date-fns"
import { da } from "date-fns/locale"

import { formatFloat } from "utils"

const serviceTimeLeft = (date, ServiceIndex) => {
  const DanishDistance = formatDistanceToNowStrict(date, {
    locale: da,
  })
  return ServiceIndex < 100
    ? `${DanishDistance} tilbage`
    : `${DanishDistance} overskredet`
}

const hoverContentByUnit = (unit, data) => {
  const { currentValue, endValue, endDate, index } = data
  const formatLang = "da-DK"
  return unit !== "mdr"
    ? `${formatFloat(Math.floor(currentValue), formatLang)}
    /
    ${formatFloat(Math.floor(endValue), formatLang)} ${unit}`
    : serviceTimeLeft(parseJSON(endDate), index)
}

export { hoverContentByUnit }
