import { useContext, useState } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  ref,
  onValue,
  goOffline,
  goOnline,
  Unsubscribe,
  onChildChanged,
} from "firebase/database"
import { PlatformStatusNotificationProps } from "app/NotificationCenter/notificationcenter.types"
import { NotificationCenterContext } from "app/NotificationCenter/context"
import {
  NotificationActions,
  NotificationTypes,
} from "app/NotificationCenter/actions"
import { flatMap, orderBy, sortBy } from "lodash-es"

export const useRealtimePlatformHealth = () => {
  const { rtdbPlatform } = useContext(FirebaseContext)
  const [recentUpdates, setRecentUpdates] = useState<
    PlatformStatusNotificationProps[]
  >([])

  const { dispatch } = useContext(NotificationCenterContext)

  const dataRef = ref(rtdbPlatform, `updates`)

  const onUpdateHandler = (updates: PlatformStatusNotificationProps[]) => {
    const flattenedUpdates = flatMap(updates, (x) =>
      Object.entries(x).map(([key, val]) => val)
    )
    dispatch(
      NotificationActions(NotificationTypes.SetPlatformUpdates, {
        updates: flattenedUpdates,
      })
    )
  }

  const subscribeToPlatformUpdates = (): Unsubscribe => {
    const unsub = onValue(dataRef, (snapshots) => {
      const updates: PlatformStatusNotificationProps[] = []
      snapshots.forEach((snapshot) => {
        if (snapshot.exists())
          updates.push(snapshot.val() as PlatformStatusNotificationProps)
      })
      onUpdateHandler(updates)
    })
    return unsub
  }

  return {
    subscribeToPlatformUpdates,
    recentUpdates,
    goOffline: () => goOffline(rtdbPlatform),
    goOnline: () => goOnline(rtdbPlatform),
  }
}
