import { useContext } from "react"
import { useFirestoreUser } from "services/firestore/user"
import union from "lodash-es/union"
import { UserContext } from "app/User/context"
import { DeviceContext } from "app/Device/context"

export const useInvisibleTrackerGroups = () => {
  const { saveFirebaseUser } = useFirestoreUser()
  const {
    state: { userSettings },
  } = useContext(UserContext)
  const {
    state: { devices, deviceGroups },
  } = useContext(DeviceContext)

  const toggleInvisibleTrackerGroup = (id: number) => {
    if (!userSettings.invisibleGroups) {
      saveFirebaseUser({ invisibleGroups: [id] })
      return
    }

    // Is the group ID in the array?
    const newValue = userSettings.invisibleGroups?.includes(id)
      ? userSettings.invisibleGroups.filter((x) => id !== x)
      : [...userSettings.invisibleGroups, id]

    saveFirebaseUser({ invisibleGroups: newValue })
  }

  const filterAndSetInvisibleTrackerIds = () => {
    return devices
      ? devices
          .filter((device) => {
            // First, unify the invisible groups and vehicle groups
            const unionGroups = union(
              userSettings?.invisibleGroups,
              device.group
            )
            // If some item in the unionGroups array is not included in invisibleGroups, the tracker is visible.
            return !unionGroups.some(
              (group) => !userSettings?.invisibleGroups?.includes(group)
            )
          })
          .map((device) => device.id)
      : null
  }

  return {
    invisibleTrackerGroups: deviceGroups.filter((group) => !group.visible),
    getInvisibleTrackers: filterAndSetInvisibleTrackerIds,
    toggleInvisibleTrackerGroup,
  }
}
