import React from "react"
import "twin.macro"

export const ResultList: React.FC<{
  children: React.ReactNode
  title?: string
}> = ({ children, title, ...props }) => {
  return (
    <>
      <span tw="text-xl md:text-2xl">{title}</span>
      <div tw="flex flex-col" {...props}>
        {children}
      </div>
    </>
  )
}
