import React, { useReducer, createContext } from "react"
import { CompanyActionMap, CompanyTypes } from "./actions"
import {
  ICompany,
  ICompanySettings,
  initialCompanyState,
  defaultCompanySettings,
} from "./types"
import { IContact, IConversation } from "app/Conversations/conversations.types"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialCompanyStateType = {
  company: ICompany | null
  companySettings: ICompanySettings | null
}

const initialState: InitialCompanyStateType = {
  company: initialCompanyState,
  companySettings: defaultCompanySettings,
}
const CompanyContext = createContext<{
  state: InitialCompanyStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const companyReducer = (
  state: InitialCompanyStateType,
  action: CompanyActionMap
) => {
  switch (action.type) {
    case CompanyTypes.SetCompany:
      return {
        ...state,
        company: action.payload.company,
      }
    case CompanyTypes.SetCompanySettings:
      return {
        ...state,
        companySettings: action.payload.settings,
      }
    case CompanyTypes.ResetCompany:
      return initialState
    default:
      return state
  }
}

const CompanyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(companyReducer, initialState)

  return (
    <CompanyContext.Provider value={{ state, dispatch }}>
      {children}
    </CompanyContext.Provider>
  )
}

export { CompanyContext, CompanyProvider }
