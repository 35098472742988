import React, { useMemo, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Duration from "duration"
import I18n from "app/I18n"
import { getStatusCode, statusMap } from "./helper"
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  IRawDeviceUpdate,
} from "app/Device/types"
import Icon from "lib/Icon"
import { Tooltip } from "@clevertrack/shared"
import { getDisplayKey } from "app/Device/helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { format, isSameMinute } from "date-fns"

const StyledTrackerStatus = styled.span<StyledTrackerStatusProps>`
  background: ${(props) => props.theme.statusColors[props.status]};
  color: ${(props) => props.theme.statusColors[props.status]};
  width: ${(props) => (props.emphasize ? `3rem` : `2rem`)};
  height: ${(props) => (props.emphasize ? `3rem` : `2rem`)};
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* svg {
    fill: white;
  } */

  svg:not(.heading) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  /* display: flex;
  align-items: center;
  justify-content: center; */
`

const StyledSatcount = styled.span`
  z-index: 500;
  ${tw`absolute text-white w-full h-full flex items-center justify-center`}

  &:after {
    content: "";
    display: block;
    width: 2px;
    height: 85%;
    transform: rotateZ(-45deg);
    ${tw`bg-white absolute`};
  }
`

/* STATUS TYPES
 * 0: Stopped
 * 1: Idling
 * 2: Driving
 * 3: Working
 * 4: Moving
 * 5: Unknown
 */

interface StyledTrackerStatusProps {
  status: number | string
  title: string
  emphasize: boolean
  css: string
}

interface TrackerStatusProps extends StyledTrackerStatusProps {
  children: React.ReactElement
  showTitle: boolean
}

const TrackerStatus: React.FC = ({
  children,
  status,
  showTitle,
  css,
  ...props
}) => {
  const mappedStatus = getStatusCode(status)
  const { strKey } = statusMap.find((s) => s.code === mappedStatus)
  const mappedStatusText = I18n({ strKey })

  return (
    <>
      <StyledTrackerStatus status={mappedStatus} {...props} css={css}>
        {children}
      </StyledTrackerStatus>
      {showTitle && <span>{mappedStatusText}</span>}
    </>
  )
}

export default TrackerStatus
