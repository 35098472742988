import { useEffect, useState, useRef } from "react"

//------Hook to fire handler when user drags or click.------------
// Implements tolerance - pixels of error - for a better UX

export default function useOnDrag(handlers, tolerance_error = 50) {
  const [clicks, setClicks] = useState({})
  const { clickHandler, dragHandler } = handlers
  const { current: error } = useRef(tolerance_error)

  useEffect(
    () => {
      const setPrev = (e) =>
        setClicks({ ...clicks, prev: [e.screenX, e.screenY] })

      const setCurrent = (e) => {
        setClicks({ ...clicks, current: [e.screenX, e.screenY] })
        clicks.current = [e.screenX, e.screenY]
        Object.values(clicks).reduce((acc, click) => {
          const [x, y] = click
          const tolerance = (input) => Math.round(input / error)
          const newClick = [tolerance(x), tolerance(y)]
          acc.push(newClick)
          const [prev, current] = acc
          if (!!prev && !!current) {
            if (prev[0] === current[0] && prev[1] === current[1]) {
              clickHandler()
            } else {
              dragHandler()
            }
          }
          return acc
        }, [])
      }

      //TO DO - ADD MOBILE SUPPORT

      document.addEventListener("mousedown", setPrev)
      document.addEventListener("mouseup", setCurrent)

      return () => {
        document.removeEventListener("mousedown", setPrev)
        document.removeEventListener("mouseup", setCurrent)
      }
    },
    // Add clickHandler and dragHandler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [clickHandler, dragHandler]
  )
}
