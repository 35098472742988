import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GroupTrackerSearchResults from "app/GroupTrackerSearchResults"
import Search from "app/Search"
import { SearchContext } from "app/Search/context"
import I18n from "app/I18n"

const StyledTrackerSearchSelector = styled(Search)`
  border: ${(props) => `1px solid ${props.theme.colors.grayLight}`};
  overflow-y: scroll;

  ${(props) => props.theme.media.tablet_landscape_up`
    border: none;
  `}
`

function TrackerSearchSelector({
  children,
  inSelectionTitle,
  availableForSelectionTitle,
  inSelection,
  availableForSelection,
  onAddToSelection,
  onRemoveFromSelection,
  ...props
}) {
  const {
    state: { query, results, suggestions },
  } = useContext(SearchContext)

  return (
    <StyledTrackerSearchSelector
      withPhoneBorder
      type="text"
      placeholder={I18n({ strKey: "start_search_input_placeholder" })}
      {...props}
    >
      <GroupTrackerSearchResults
        query={query}
        results={results}
        suggestions={suggestions}
        inSelection={inSelection}
        availableForSelection={availableForSelection}
        inSelectionTitle={inSelectionTitle}
        availableForSelectionTitle={availableForSelectionTitle}
        onAddToSelection={onAddToSelection}
        onRemoveFromSelection={onRemoveFromSelection}
      />
    </StyledTrackerSearchSelector>
  )
}

export default TrackerSearchSelector

TrackerSearchSelector.defaultProps = {}
TrackerSearchSelector.propTypes = {
  children: PropTypes.node.isRequired,
}
