import {
  Alarm,
  AlertCriteriumTypeEnum,
  DeviceHealthCriterium,
  DeviceSpeedCriterium,
  DeviceStatusCriterium,
  DeviceZoneCriterium,
} from "./alarm.types"

// Pre-defined criteria
const ignitionCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_ignition_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [0, 4, 5],
  triggerOnStatus: [1],
  created: 0,
}

const stoppedCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_stopped_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [1, 2, 3],
  triggerOnStatus: [0],
  created: 0,
}

const idleCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_idle_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [0, 1, 3, 4, 5],
  triggerOnStatus: [2],
  created: 0,
}

const movingCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_moving_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [0, 1, 2, 3, 5],
  triggerOnStatus: [4],
  created: 0,
}

const speedAboveZeroCriteria: DeviceSpeedCriterium = {
  id: "DEFAULT_speed_above_zero_criteria",
  type: AlertCriteriumTypeEnum.DeviceSpeed,
  treshold: 0,
  created: 0,
}

const speedAboveEconomicHighwaySpeedCriteria: DeviceSpeedCriterium = {
  id: "DEFAULT_speed_above_economic_highway_speed_criteria",
  type: AlertCriteriumTypeEnum.DeviceSpeed,
  treshold: 110,
  created: 0,
}

const offlineCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_offline_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [0, 1, 2, 3, 4],
  triggerOnStatus: [5],
  created: 0,
}

const onlineCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_online_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [5],
  triggerOnStatus: [0, 1, 2, 3, 4],
  created: 0,
}

const powerLostCriteria: DeviceHealthCriterium = {
  id: "DEFAULT_power_lost_criteria",
  type: AlertCriteriumTypeEnum.DeviceHealth,
  powerLost: true,
  powerTreshold: null,
  connectionLost: false,
  powerTresholdUnder: true,
  created: 0,
}

const connectionLostCriteria: DeviceHealthCriterium = {
  id: "DEFAULT_connection_lost_criteria",
  type: AlertCriteriumTypeEnum.DeviceHealth,
  powerLost: false,
  powerTreshold: null,
  connectionLost: true,
  powerTresholdUnder: true,
  created: 0,
}

const connectionLostStatusCriteria: DeviceStatusCriterium = {
  id: "DEFAULT_connection_list_status_criteria",
  type: AlertCriteriumTypeEnum.DeviceStatus,
  triggerFromStatus: [0, 5],
  triggerOnStatus: [1, 2, 3, 4],
  created: 0,
}

const zoneCriteria: DeviceZoneCriterium = {
  id: "DEFAULT_zone_criteria",
  type: AlertCriteriumTypeEnum.DeviceZone,
  zoneID: "",
  triggerOnEnter: false,
  triggerOnLeave: false,
  triggerOnAtLocation: false,
  triggerOnOutsideLocation: false,
  created: 0,
}

// Default templates

const templateDefaults: Alarm = {
  alarmTriggeredByDeviceID: [],
  disabled: false,
  triggerCount: 0,
  schedule: [],
}

const ignitionAlarm: Alarm = {
  ...templateDefaults,
  id: "template_ignition_alarm",
  title: "Tændingsalarm",
  description: "Alarm ved tænding",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceStatus],
  criteria: [ignitionCriteria],
}

const movementAlarm: Alarm = {
  ...templateDefaults,
  id: "template_movement_alarm",
  title: "Flyttealarm",
  description: "Alarmering ved flytning",
  criteriaTypes: [
    AlertCriteriumTypeEnum.DeviceStatus,
    AlertCriteriumTypeEnum.DeviceSpeed,
  ],
  criteria: [movingCriteria, speedAboveZeroCriteria],
}

const idleAlarm: Alarm = {
  ...templateDefaults,
  id: "template_idle_alarm",
  title: "Tomgangsalarm",
  description: "Alarmering ved unødvendig tomgang",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceStatus],
  criteria: [idleCriteria],
}

const stoppedAlarm: Alarm = {
  ...templateDefaults,
  id: "template_stopped_alarm",
  title: "Stopalarm",
  description: "Alarmering når en enhed med aktivitet stopper",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceStatus],
  criteria: [stoppedCriteria],
}

const offlineAlarm: Alarm = {
  ...templateDefaults,
  id: "template_offline_alarm",
  title: "Offlinealarm",
  description: "Alarmering ved enhed der går offline",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceStatus],
  criteria: [offlineCriteria],
}

const speedingAlarm: Alarm = {
  ...templateDefaults,
  id: "template_speeding_alarm",
  title: "Hastighedsalarm",
  description: "Alarmering ved overskredet hastighed",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceSpeed],
  criteria: [speedAboveEconomicHighwaySpeedCriteria],
}

const onlineAlarm: Alarm = {
  ...templateDefaults,
  id: "template_online_alarm",
  title: "Onlinealarm",
  description: "Alarmering når en offline-enhed bliver registreret igen",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceStatus],
  criteria: [onlineCriteria],
}

const deviceEjectedAlarm: Alarm = {
  ...templateDefaults,
  id: "template_device_ejected_alarm",
  title: "Alarm ved udtaget enhed",
  description: "Alarm når en enhed bliver fjernet eller udtaget",
  criteriaTypes: [
    AlertCriteriumTypeEnum.DeviceStatus,
    AlertCriteriumTypeEnum.DeviceHealth,
  ],
  criteria: [movingCriteria, powerLostCriteria],
}

const powerLostAlarm: Alarm = {
  ...templateDefaults,
  id: "template_power_lost_alarm",
  title: "Alarm ved strømafbrydelse",
  description: "Alarmering når en enheds strømforsyning bliver afbrudt",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceHealth],
  criteria: [powerLostCriteria],
}

const connectionLostAlarm: Alarm = {
  ...templateDefaults,
  id: "template_connection_lost_alarm",
  title: "Alarm ved manglende GPS-forbindelse",
  description: "Alarmering når en enhed mister GPS-forbindelse",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceHealth],
  criteria: [connectionLostCriteria, connectionLostStatusCriteria],
}

const zoneAlarm: Alarm = {
  ...templateDefaults,
  id: "template_zone_alarm",
  title: "Zonealarm",
  description: "Alarmering når en enhed forlader eller ankommer til en zone",
  criteriaTypes: [AlertCriteriumTypeEnum.DeviceZone],
  criteria: [zoneCriteria],
}

export const alarmTemplates = [
  ignitionAlarm,
  movementAlarm,
  idleAlarm,
  stoppedAlarm,
  offlineAlarm,
  speedingAlarm,
  onlineAlarm,
  zoneAlarm,
  deviceEjectedAlarm,
  powerLostAlarm,
  connectionLostAlarm,
]
