import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  doc,
  getDoc,
  setDoc,
  collection,
  updateDoc,
  serverTimestamp,
  getDocs,
  writeBatch,
  query,
  orderBy,
  addDoc,
  deleteDoc,
} from "firebase/firestore"
import { ref, get, update } from "firebase/database"
import { CompanyContext } from "app/Company/context"
import { CompanyTypes, CompanyActions } from "app/Company/actions"
import {
  ICompanySettings,
  defaultCompanySettings,
  initialCompanyState,
  ICompany,
} from "app/Company/types"
import { FirestoreDevice, IRawDeviceUpdate } from "app/Device/types"
import { v4 } from "uuid"
import { StringDictionary } from "lib/global.types"
import { deleteDriversID, updateDriversID } from "services/vehicles"
import cogoToast from "@clevertrackdk/cogo-toast"

type FirestoreCompanyDevice = {
  id: number
  name: string
  currentTrip?: IRawDeviceUpdate[]
}

export const useFirestoreCompany = () => {
  const { db, rtdb } = useContext(FirebaseContext)
  const {
    dispatch,
    state: { company },
  } = useContext(CompanyContext)

  const createFirebaseCompany = async (id: string, name: string) => {
    try {
      await setDoc(doc(db, "companies", id), { id, name })
    } catch (error) {
      throw new Error(error)
    }
  }

  const getFirebaseCompany = async (
    companyId: string,
    companyName: string,
    authenticatedUser
  ) => {
    try {
      const { dashboard_count, report_count } = authenticatedUser

      if (dashboard_count > 0 || report_count > 0) {
        const companyFeatures: StringDictionary<string> = {}
        if (dashboard_count > 0) companyFeatures.DASHBOARD = "Dashboard"
        if (report_count > 0) companyFeatures.REPORTS = "Rapporter"
        await saveFirebaseCompany(
          {
            features: companyFeatures,
          },
          authenticatedUser.company_id
        )
      }

      const firebaseCompany = doc(db, "companies", companyId)
      let firebaseCompanyDoc = await getDoc(firebaseCompany)

      if (!firebaseCompanyDoc.exists()) {
        await createFirebaseCompany(companyId, companyName)
        firebaseCompanyDoc = await getDoc(firebaseCompany)
      }

      if (firebaseCompanyDoc.exists()) {
        const {
          id,
          name,
          unread,
          msisdn,
          functions,
          features,
          ...settings
        } = firebaseCompanyDoc.data() as ICompany
        setFirebaseCompany({ id, name, unread, msisdn, functions, features })
        setFirebaseCompanySettings(settings)
        return { functions, features }
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const saveFirebaseCompany = async (args, companyID) => {
    try {
      const firebaseCompany = doc(db, "companies", companyID)

      await updateDoc(firebaseCompany, {
        ...args,
        updated: serverTimestamp(),
      })

      return "OK"
    } catch (error) {
      throw new Error(error)
    }
  }

  const setFirebaseCompany = (c: ICompany) => {
    const { features, functions, ...rest } = c
    const {
      features: defaultFeatures,
      functions: defaultFunctions,
      ...defaults
    } = initialCompanyState
    const mergedCompany = {
      features: { ...defaultFeatures, ...features },
      functions: { ...defaultFunctions, ...functions },
      ...defaults,
      ...rest,
    }
    dispatch(
      CompanyActions(CompanyTypes.SetCompany, {
        company: mergedCompany as ICompany,
      })
    )
  }

  const setFirebaseCompanySettings = (settings) => {
    const defaults = defaultCompanySettings
    const mergedCompanySettings = { ...defaults, ...settings }
    dispatch(
      CompanyActions(CompanyTypes.SetCompanySettings, {
        settings: mergedCompanySettings as ICompanySettings,
      })
    )
  }

  const getFirebaseCompanyDevices = async () => {
    try {
      if (db) {
        const firebaseCompanyDevices = collection(
          db,
          `companies/${company?.id}/devices`
        )
        const devices = await getDocs(firebaseCompanyDevices)

        return devices.docs.map((x) => x.id)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const getFirebaseCompanyDrivers = async () => {
    try {
      if (db) {
        const firebaseCompanyDrivers = collection(
          db,
          `companies/${company?.id}/drivers`
        )
        const drivers = await getDocs(firebaseCompanyDrivers)

        return drivers.docs.map((x) => {
          return {
            ...x.data(),
            id: x.id,
            count: x.data().devices.length,
          }
        })
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const getFirebaseCurrentDeviceTrip = async (
    id
  ): Promise<IRawDeviceUpdate[] | null> => {
    try {
      if (db) {
        const firebaseCompanyDeviceCurrentTrip = query(
          collection(db, `companies/${company?.id}/devices/${id}/currentTrip`),
          orderBy("timestamp", "asc")
        )
        const currentDeviceTrip = await getDocs(
          firebaseCompanyDeviceCurrentTrip
        )

        if (currentDeviceTrip.size > 0) {
          return currentDeviceTrip.docs.map((x) => x.data() as IRawDeviceUpdate)
        }
        return null
      }
    } catch (error) {
      throw new Error(error)
    }
    return null
  }

  const saveFirebaseCompanyDevices = async (devices: FirestoreDevice[]) => {
    try {
      if (db && rtdb && company) {
        const updates = {}
        const batch = writeBatch(db)
        for (const device of devices) {
          batch.set(
            doc(db, `companies/${company.id}/devices/${device.id.toString()}`),
            device
          )
          const data = await get(ref(rtdb, `vehicles/${device.id.toString()}`))
          updates[`vehicles/${device.id.toString()}`] = {
            ...data.val(),
            name: null,
            companyID: company.id,
            description: null,
            feature: null,
            group: null,
            unit_id: null,
            note: null,
          }
        }
        await batch.commit()
        await update(ref(rtdb), updates)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const saveFirebaseCompanyDrivers = async (drivers) => {
    try {
      if (db && company) {
        const batch = writeBatch(db)
        for (const driver of drivers) {
          const id = driver.id ?? v4()
          batch.set(doc(db, `companies/${company.id}/drivers/${id}`), {
            ...driver,
            created: serverTimestamp(),
          })

          const backendPayload = {
            driverID: id,
            deviceIDs: driver.devices,
          }

          if (driver.accesscode && driver.accesscode !== "") {
            backendPayload.pincode = driver.accesscode
          }

          if (driver.cardID && driver.cardID !== "") {
            backendPayload.cardID = driver.cardID
          }

          if (backendPayload.cardID || backendPayload.pincode) {
            // Send payload to backend to update the keypad with whitelisted drivers
            const result = await updateDriversID(backendPayload)

            if (result?.data.result === "OK") {
              cogoToast.success(
                `Fører '${driver.firstName} ${driver.lastName}' oprettet`
              )
            }
          }
        }
        await batch.commit()
      }
      return "OK"
    } catch (error) {
      throw new Error(error)
    }
  }

  const saveFirebaseCompanyDriver = async (driver) => {
    try {
      let driverID
      if (driver.id) {
        driverID = driver.id
        const firebaseDriver = doc(
          db,
          `companies/${company.id.toString()}/drivers/${driver.id}`
        )

        await updateDoc(firebaseDriver, {
          ...driver,
          updated: serverTimestamp(),
        })
      } else {
        driverID = v4()
        await saveFirebaseCompanyDrivers([{ ...driver, id: driverID }])
      }

      if (driverID) {
        const backendPayload = {
          driverID,
          deviceIDs: driver.devices,
        }

        if (driver.accesscode && driver.accesscode !== "") {
          backendPayload.pincode = driver.accesscode
        }

        if (driver.cardID && driver.cardID !== "") {
          backendPayload.cardID = driver.cardID
        }

        if (backendPayload.cardID || backendPayload.pincode) {
          // Send payload to backend to update the keypad with whitelisted drivers
          const result = await updateDriversID(backendPayload)

          if (result?.data.result === "OK") {
            cogoToast.success("Fører ID opdateret")
          }
        }
      }

      return "OK"
    } catch (error) {
      throw new Error(error)
    }
  }

  const deleteFirebaseCompanyDriver = async (id) => {
    try {
      if (db && company) {
        const driver = doc(
          db,
          `companies/${company.id.toString()}/drivers/${id}`
        )

        // Send payload to backend to update the keypad with whitelisted drivers
        const result = await deleteDriversID(id)
        await deleteDoc(driver)
        if (result?.data.result === "OK") {
          cogoToast.success("Fører ID slettet")
        }
      }
      return "OK"
    } catch (error) {
      throw new Error(error)
    }
  }

  return {
    getFirebaseCompany,
    getFirebaseCompanyDevices,
    getFirebaseCompanyDrivers,
    getFirebaseCurrentDeviceTrip,
    saveFirebaseCompanyDevices,
    saveFirebaseCompany,
    saveFirebaseCompanyDrivers,
    saveFirebaseCompanyDriver,
    deleteFirebaseCompanyDriver,
  }
}
