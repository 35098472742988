import type { Timezone } from "countries-and-timezones"
import { IContact } from "app/Conversations/conversations.types"
import { DeviceStatusEnum } from "app/Device/types"
import { DisplayKeyValueType } from "app/TrackerKPI/kpi.types"
import { WeekdayEnum } from "lib/global.types"

export enum AlarmSetupPanels {
  AlertNotificationEditor = "AlertNotificationEditor",
  AlertScheduleEditor = "AlertScheduleEditor",
  AlertDeviceEditor = "AlertDeviceEditor",
  CriteriaTypeSelector = "CriteriaTypeSelector",
  CriteriaEditor = "CriteriaEditor",
}

export enum AlarmHintEnum {
  NoSelectedDevices = "Alarms/NoSelectedDevices",
  NoSelectedSchedule = "Alarms/NoSelectedSchedule",
  NoSelectedCriteria = "Alarms/NoSelectedCriteria",
  NoSelectedNotifications = "Alarms/NoSelectedNotifications",
  UnsavedChanges = "Alarms/UnsavedChanges",
  ScheduleStart = "Alarms/ScheduleStart",
  ScheduleWeek = "Alarms/ScheduleWeek",
  SchedulePeriod = "Alarms/SchedulePeriod",
}

export type Alarm = {
  id: string // Firebase ID
  title: string
  description?: string
  alertNotificationIDs: string[] // A map of notification ID's that should trigger, when this alarm is triggered
  alarmTriggeredByDeviceID: number[]
  criteriaTypes?: AlertCriteriumTypeEnum[] // A map of criteria type enums
  criteria?: AlertCriterium[]
  criteriaFulfilledAt?: number // Timestamp - should reset when the alarm has been dismissed
  deviceIDs?: string[] // Array of deviceIDs this alarm should trigger on, when criteria are fulfilled
  deviceGroupIDs?: string[] // Only used as a convenience property for the frontend
  sendAlertAfterDuration?: number // IDEA: Send the alert when the criteria are fulfilled longer than the set duration
  schedule?: AlarmSchedule[] // Alarm can only trigger in this schedule. If no schedule is set, alarm can trigger at any time
  muted?: boolean // IDEA: Requires user to actively mute alarm
  muteUntil?: number // IDEA: Timestamp for when this alarm is no longer muted
  disabled: boolean // Disables alarm until enabled again
  level?: AlertNotificationLevelEnum
  triggerCount: number // Number of times the alarm triggered
  upgradeAlarmLevelAfterTriggers?: number // Escalate alarm level after n triggers. Enabled if > 0.
  template?: boolean
  created?: number
  updated?: number
  draft?: boolean
}

export enum AlarmScheduleIntervalTypeEnum {
  DateIntervalSchedule = "DateIntervalSchedule",
  WeekdaysSchedule = "WeekdaysSchedule",
}

export type DateIntervalSchedule = {
  id: string
  interval: [number, number] // [from Timestamp, to Timestamp]
  timeInterval?: [string, string] // from HH:mm to HH:mm
}

export type WeekdaySchedule = {
  [index in WeekdayEnum]?: [string, string, boolean]
}

export type AlarmSchedule = {
  id: string
  intervals?: DateIntervalSchedule[]
  weekdays?: WeekdaySchedule
  timezone: Timezone
  triggerInsideSchedule: boolean
}

export enum AlertNotificationLevelEnum {
  None = 0, // Alert not active
  Warning = 1,
  Minor = 2,
  Major = 3,
  Critical = 4,
}

export enum AlertNotificationMethodEnum {
  App = "App", // Clevertrack Platform Notification Center
  Email = "Email", // Send an email with the alert
  SMS = "SMS", // Send an SMS to the recipient
}

export type AlertBaseNotification = {
  id?: string // Firebase ID
  subject: string
  message: string
}

type DeviceMap = {
  [key: string]: number
}

export type AlertNotification = AlertBaseNotification & {
  recipients: IAlarmNotificationRecipient[]
  alarmLastTriggeredAtDeviceMap: DeviceMap
  notificationLastTriggeredAt: number // Timestamp for the last time this notification was sent out
  notificationLastTriggeredAtDeviceMap: DeviceMap
  notificationTriggerFrequency: number // Time in seconds for how often this notification is allowed to trigger, if NOT muted. Enabled if > 0, otherwise only send once.
  notificationDisableTriggerOnInitialCriteriaFullfilment: boolean
  notificationReadByContactIDs: string[] // The contacts that read this alarm (e.g. followed a link to the App and viewed the alarm)
}

export interface IAlarmNotificationRecipient extends Partial<IContact> {
  notificationMethods: AlertNotificationMethodEnum[]
}

export type AlertCriterium =
  | DeviceSpeedCriterium
  | DeviceDailyAverageSpeedCriterium
  | DeviceStatusCriterium
  | DeviceZoneCriterium
  | DeviceUpdateCriterium
  | DeviceDisplayKeyValueCriterium
  | DeviceHealthCriterium

export enum AlertCriteriumTypeEnum {
  DeviceSpeed = "DeviceSpeed", // V2
  DeviceDailyAverageSpeed = "DeviceDailyAverageSpeed", // V2
  DeviceStatus = "DeviceStatus",
  DeviceZone = "DeviceZone",
  DeviceUpdate = "DeviceUpdate", // V3, might not be implemented, awaiting user feedback
  DeviceDisplayKeyValue = "DeviceDisplayKeyValue", // V2
  DeviceHealth = "DeviceHealth",
}

// Criterium is fulfilled when the device...
// ...speed is over the treshold
export type DeviceSpeedCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceSpeed
  treshold: number
}

// ...average daily speed is
export type DeviceDailyAverageSpeedCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceDailyAverageSpeed
  lowerTreshold?: number
  upperTreshold?: number
}

// ...changes to a certain status
export type DeviceStatusCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceStatus
  triggerFromStatus: DeviceStatusEnum[]
  triggerOnStatus: DeviceStatusEnum[]
}

// ...leaves or enters a zone
export type DeviceZoneCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceZone
  triggerOnEnter: boolean
  triggerOnLeave: boolean
  triggerOnAtLocation: boolean // V2
  triggerOnOutsideLocation: boolean
  zoneID: string
}

// ...did not update in duration (in seconds)
export type DeviceUpdateCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceUpdate
  duration: number
}

// ...fulfills any of the health metrics
export type DeviceHealthCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceHealth
  connectionLost: boolean
  powerLost: boolean
  powerTreshold: number | null
  powerTresholdUnder: boolean // Is power treshold over or under
}

// ...display key value exceeds the set value
export type DeviceDisplayKeyValueCriterium = {
  id: string
  created: number
  updated?: number
  type: AlertCriteriumTypeEnum.DeviceDisplayKeyValue
  valueType: DisplayKeyValueType
  treshold: number // If DisplayKeyValueType is TimeSpan, treshold is in seconds. If DisplayKeyValueType is float, treshold is a number.
}
