import React, { useReducer, createContext } from "react"
import {
  SET_SEARCH_RESULTS,
  SET_SEARCH_QUERY,
  SET_SEARCH_SUGGESTIONS,
  RESET_SEARCH,
} from "./actions"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */
type InitialSearchState = {
  results: any[]
  suggestions: any[]
  query: string
}

const initialState = {
  results: [],
  suggestions: [],
  query: "",
}

const SearchContext = createContext<{
  state: InitialSearchState
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })
const { Provider } = SearchContext

const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_SEARCH_RESULTS:
        return {
          ...state,
          results: action.results,
        }
      case SET_SEARCH_QUERY:
        return {
          ...state,
          query: action.query,
        }
      case SET_SEARCH_SUGGESTIONS:
        return {
          ...state,
          suggestions: action.suggestions,
        }
      case RESET_SEARCH:
        return {
          results: [],
          suggestions: [],
          query: "",
        }
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { SearchContext, SearchProvider }
