import styled from "styled-components"
import tw from "twin.macro"
import Expander from "lib/Expander"

export const StyledZonesPanel = styled(Expander)`
  position: absolute;
  z-index: 120;
  top: ${(props) => props.theme.mobileContentOffset};
  margin-top: 5rem;
  right: 6rem;
  height: 4rem;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.mapButtonShadow};

  .inner {
    white-space: nowrap;
    ${tw`text-xl space-y-2`}

    > :first-child {
      padding: 1.25rem 1rem 0.5rem;
    }

    > :only-child {
      padding: 1.25rem 1rem;
    }

    a {
      padding: 0.5rem 1rem 1rem;
      ${tw`flex items-center text-brand-black-base transition-all pt-4 hover:(text-brand-500) border-solid border-0 border-t border-gray-200 mt-4`}

      svg {
        ${tw`fill-current ml-auto`}
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 11rem;
    right: 6rem;
    margin-top: 0;
  `}
`
