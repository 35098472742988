/* Action types */
export const SET_TRACKER_GROUPS = "SET_TRACKER_GROUPS"
export const SET_VEHICLES = "SET_VEHICLES"
export const SET_TIME_LEFT = "SET_TIME_LEFT"
export const SET_IS_POLLING = "SET_IS_POLLING"
export const SET_TOGGLED_TRACKERS = "SET_TOGGLED_TRACKERS"
export const UPDATE_VEHICLES = "UPDATE_VEHICLES"
export const UPDATE_VEHICLE_BY_ID = "UPDATE_VEHILE_BY_ID"
export const SET_POLLING_FREQUENCY = "SET_POLLING_FREQUENCY"
export const ADD_TRACKER_GROUP = "ADD_TRACKER_GROUP"
export const TOGGLE_TRACKER_GROUP = "TOGGLE_TRACKER_GROUP"
export const TOGGLE_TRACKER = "TOGGLE_TRACKER"
export const TOGGLE_ALL_TRACKERS = "TOGGLE_ALL_TRACKERS"
export const TOGGLE_VISIBILITY_SETTINGS = "TOGGLE_VISIBILITY_SETTINGS"
export const RESET_GROUPS = "RESET_GROUPS"
export const SET_ANIMATING = "SET_ANIMATING"

/* Action creators */

export const setTrackerGroups = (data) => {
  return {
    type: SET_TRACKER_GROUPS,
    data,
  }
}

export const setVehicles = (data) => {
  return {
    type: SET_VEHICLES,
    data,
  }
}

export const setTimeLeft = (timer) => {
  return {
    type: SET_TIME_LEFT,
    timer,
  }
}

export const setIsPolling = (polling) => {
  return {
    type: SET_IS_POLLING,
    polling,
  }
}

export const setToggledTrackers = (trackers) => {
  return {
    type: SET_TOGGLED_TRACKERS,
    trackers,
  }
}

export const updateVehicles = (data) => {
  return {
    type: UPDATE_VEHICLES,
    data,
  }
}

export const updateVehicleByID = (data) => {
  return {
    type: UPDATE_VEHICLE_BY_ID,
    data,
  }
}

export const setPollingFrequency = (frequency) => {
  return {
    type: SET_POLLING_FREQUENCY,
    frequency,
  }
}

export const addTrackerGroup = (item) => {
  return {
    type: ADD_TRACKER_GROUP,
    item,
  }
}

export const toggleTrackerGroup = (id) => {
  return {
    type: TOGGLE_TRACKER_GROUP,
    id,
  }
}

export const toggleTracker = (tracker) => {
  return {
    type: TOGGLE_TRACKER,
    tracker,
  }
}

export const toggleAllTrackers = () => {
  return {
    type: TOGGLE_ALL_TRACKERS,
  }
}

export const toggleVisibilitySettings = () => {
  return {
    type: TOGGLE_VISIBILITY_SETTINGS,
  }
}

export const resetGroups = () => {
  return {
    type: RESET_GROUPS,
  }
}

export const setAnimating = (animating) => {
  return {
    type: SET_ANIMATING,
    animating,
  }
}
