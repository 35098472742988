import styled from "styled-components"

export const StyledGrid = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;

  > :nth-child(2) {
    align-self: stretch;
    height: 100%;
    // padding-bottom: 12rem;
    overflow-y: scroll;
  }
`
