import React, { useContext, useEffect, useRef, useState } from "react"
import uniqBy from "lodash-es/uniqBy"
import styled from "styled-components"
import tw from "twin.macro"
import Panel from "app/Panel"
import { PanelHeader } from "app/Panel/Header"
import cogoToast from "@clevertrackdk/cogo-toast"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Loading,
  ValidationInput,
} from "@clevertrack/shared"
import { FormContext } from "@clevertrack/shared/src/components/Form/context"
import { useFormData } from "hooks/useFormData"
import { usePromise } from "hooks/usePromise"
import Accordion from "lib/Accordion"
import Checkbox from "lib/Checkbox"
import Icon from "lib/Icon"
import { useFirestoreContact } from "services/firestore/contact"
import { ContactPrefix } from "."
import { ConversationActions, ConversationTypes } from "../actions"
import { ConversationContext } from "../context"
import {
  IContact,
  IContactGroup,
  IContactGroupPayload,
  PopOverRoutesEnum,
} from "../conversations.types"
import { useConversation } from "../hooks"
import { ContactItem } from "./Item"
import { ContactList } from "./List"
import { ContactSearch } from "./Search"

const StyledContactList = styled(ContactList)`
  li {
    ${tw`px-2 pr-16`}
  }
`

type GroupFormProps = {
  onClose: (...args) => void
}

const defaultGroupDataModel: IContactGroup = {
  title: "",
  contact_ids: [],
}

export const GroupForm: React.FC<GroupFormProps> = ({ onClose, ...props }) => {
  const {
    state: { fields },
  } = useContext(FormContext)
  const {
    state: { toggledRoutes, editGroup, selectedContacts },
    dispatch,
  } = useContext(ConversationContext)
  const [
    groupData,
    updateGroupData,
    resetGroupModel,
    setDataModel,
  ] = useFormData<IContactGroup>(editGroup ?? defaultGroupDataModel)
  const {
    createCompanyContactGroup,
    saveCompanyContactGroup,
    deleteCompanyContactGroup,
  } = useFirestoreContact()
  const { contactsSorted, getData } = useConversation()
  const { setPromise, promiseStatus, loading } = usePromise()
  const formRef = useRef<HTMLFormElement>(null)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const onSuccess = (group: IContactGroup) => {
    dispatch(
      ConversationActions(ConversationTypes.AddContact, { contact: group })
    )
    cogoToast.success(`Gruppen "${group.title}" blev gemt`)
    resetGroupModel()
    formRef.current.reset()
    onClose()
  }

  const onError = (error, msg?: string) => {
    const err = [msg ?? `Kunne ikke gemme gruppen:`, error].filter(Boolean)
    cogoToast.error(err.join(" "))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const newGroupData: IContactGroupPayload = {
      ...groupData,
      contact_ids: selectedContacts.map((x) => x.id).filter(Boolean),
      is_group: true,
    }
    try {
      if (editGroup && editGroup.id) {
        await setPromise({
          promise: saveCompanyContactGroup(newGroupData),
          onSuccess,
          onError,
        })
      } else {
        await setPromise({
          promise: createCompanyContactGroup(newGroupData),
          onSuccess,
          onError,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectContact = (c) => {
    dispatch(
      ConversationActions(ConversationTypes.SetSelectedContacts, {
        contacts: uniqBy([...selectedContacts, c], "id"),
      })
    )
  }

  const onDeSelectContact = (c) => {
    dispatch(
      ConversationActions(ConversationTypes.SetSelectedContacts, {
        contacts: uniqBy([...selectedContacts, c], "id").filter(
          (contact) => contact.id !== c.id
        ),
      })
    )
  }

  const onDelete = async (e) => {
    e.preventDefault()
    if (groupData.id) {
      await setPromise({
        promise: deleteCompanyContactGroup(groupData.id),
        onSuccess: () => cogoToast.success("Gruppe slettet"),
        onError: (err) => cogoToast.error("Kunne ikke slette gruppen", err),
      })
      setConfirmDelete(false)
      resetGroupModel()
      formRef.current.reset()
      onClose()
    }
  }

  const onCancel = () => {
    setConfirmDelete(false)
    resetGroupModel()
    onClose()
  }

  const renderConfirm = () => {
    return (
      <Button
        variant="transparent"
        tw="p-2 px-4 m-0 font-normal text-brand-500"
        type="submit"
        disabled={fields.length > 0 || selectedContacts.length === 0}
      >
        {groupData.id ? "Gem" : "Opret"}
      </Button>
    )
  }

  const renderCancel = () => {
    return (
      <Button
        variant="transparent"
        tw="p-2 px-4 m-0 font-normal text-brand-red-500"
        type="button"
        onClick={onCancel}
      >
        Annuller
      </Button>
    )
  }

  useEffect(() => {
    if (editGroup) setDataModel(editGroup)
  }, [editGroup])

  useEffect(() => {
    if (!toggledRoutes.includes(PopOverRoutesEnum.EditGroup)) {
      setDataModel(defaultGroupDataModel)
      if (
        toggledRoutes.includes(PopOverRoutesEnum.CreateGroup) &&
        selectedContacts.length === 0
      ) {
        dispatch(ConversationActions(ConversationTypes.ResetGroup, null))
        dispatch(
          ConversationActions(ConversationTypes.SetSelectedContacts, {
            contacts: [],
          })
        )
      }
    }
  }, [toggledRoutes])

  return (
    <Panel tw="p-6 pt-8 bg-white">
      <Form onSubmit={onSubmit} forwardRef={formRef}>
        <PanelHeader
          title={editGroup ? "Redigér gruppe" : "Ny gruppe"}
          confirmComponent={renderConfirm()}
          cancelComponent={renderCancel()}
        />
        <div tw="mt-4">
          <FormField label="Gruppenavn*" validationKey="title">
            <ValidationInput
              id="first_name"
              placeholder="Indtast gruppenavn"
              onChange={(e) => updateGroupData("title", e.target.value)}
              defaultValue={groupData.title}
              // tw="text-2xl"
              required
            />
          </FormField>
          <Accordion
            toggled={selectedContacts.length > 0}
            tw="flex w-full overflow-x-scroll items-start py-4 mt-2 space-x-4 md:(-mb-4)"
          >
            {(selectedContacts as IContact[]).map((contact) => {
              const contactPrefix = [
                contact.first_name?.substring(0, 1),
                contact.last_name?.substring(0, 1),
              ].join("")
              return (
                <div
                  key={contact.id}
                  tw="flex flex-col flex-shrink-0 items-center w-20 cursor-pointer"
                  onClick={() => onDeSelectContact(contact)}
                >
                  <ContactPrefix tw="w-20 h-20 mx-auto relative">
                    <span tw="text-2xl">
                      {contactPrefix !== "" && isNaN(+contactPrefix) ? (
                        contactPrefix
                      ) : (
                        <Icon tw="w-8 h-8" icon="person" />
                      )}
                    </span>
                    <span tw="shadow-card bg-white w-6 h-6 absolute top-0 right-0 rounded-full flex items-center justify-center transition-all hover:(bg-brand-red-500 text-white)">
                      <Icon size="xs" icon="close" />
                    </span>
                  </ContactPrefix>
                  <span tw="text-lg truncate w-20 text-center mt-2">
                    {contact.first_name ??
                      `+${contact.area_code} ${contact.phone}`}
                  </span>
                </div>
              )
            })}
          </Accordion>
          <ContactSearch
            placeholder="Find kontakt eller indtast tlf. nr."
            withPhoneBorder={true}
            data={contactsSorted.filter((contact) => !!contact.first_name)}
            wrapperProps={{
              style: { padding: 0 },
            }}
            tw="m-0 mt-4 bg-brand-gray-lighter text-brand-gray-dark"
          />
          {contactsSorted.filter((contact) => !!contact.first_name).length >
            0 && (
            <StyledContactList
              hideCreateButtons
              onSearchResultSelect={onSelectContact}
              onSearchResultDeselect={onDeSelectContact}
              allowSelectWhileDisabled
            >
              {contactsSorted
                .filter((contact) => !!contact.first_name)
                .map((contact) => {
                  const isSelected = selectedContacts
                    .map((sc) => sc.id)
                    .includes(contact.id)
                  return (
                    <ContactItem
                      key={contact.id}
                      contact={contact}
                      onSelect={onSelectContact}
                      onDeselect={onDeSelectContact}
                      allowSelectWhileDisabled
                      tw="items-center relative"
                    >
                      <Checkbox
                        tw="absolute right-0 top-6"
                        checked={isSelected}
                        onChange={(selected) =>
                          selected
                            ? onDeSelectContact(contact)
                            : onSelectContact(contact)
                        }
                      />
                    </ContactItem>
                  )
                })}
            </StyledContactList>
          )}
          {editGroup && (
            <ButtonGroup position="center">
              {confirmDelete ? (
                <>
                  <Button
                    size="sm"
                    type="button"
                    variant="transparent"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Annuller
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    variant="danger"
                    onClick={onDelete}
                  >
                    Bekræft sletning?
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  type="button"
                  variant="danger"
                  onClick={() => setConfirmDelete(true)}
                >
                  Slet gruppe
                </Button>
              )}
            </ButtonGroup>
          )}
        </div>
      </Form>
      {loading && <Loading loadingText="Gemmer" />}
    </Panel>
  )
}
