import React, { useState, useEffect } from "react"
import Icon from "lib/Icon"
import styled from "styled-components"
import Accordion from "../../Accordion"

const StyledItem = styled.li`
  border-bottom: ${(props) => props.theme.list.item.border};
`

const StyledTitle = styled.span`
  display: grid;
  grid-template-columns: 1fr 4.4rem;
  position: relative;
  cursor: pointer;
  // padding: ${(props) => props.theme.list.item.padding};
  background: ${(props) => props.theme.list.item.bg};
  transition: ${(props) => props.theme.list.item.transition};

  &:hover {
    background: ${(props) => props.theme.list.item.bgHover};
  }

  a {
    color: currentColor;
  }
`

const StyledCollapsible = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`

const StyledIcon = styled(Icon)`
  border: none;
  margin: 0;
  position: absolute;
  height: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "all" : "none")};
  transition: ease-out 0.2s all;
  transform: ${(props) => (props.show ? "rotateZ(0)" : "rotateZ(-90deg)")};

  &:focus {
    outline: 0;
  }
`

interface ListItemProps extends React.ReactHTMLElement<HTMLLIElement> {
  title: string
  toggled: boolean
  openIcon: string
  closeIcon: string
  children: React.ReactNode
  showIcons: boolean
  onClick: any
  onAnimationStart: any
  onAnimationEnd: any
  maxHeight: string
  transitionDuration: string
}

export const Item: React.FC<ListItemProps> = (props) => {
  const {
    title,
    toggled,
    openIcon,
    closeIcon,
    children,
    showIcons,
    onAnimationStart,
    onAnimationEnd,
    maxHeight,
    transitionDuration,
    onClick,
    ...rest
  } = props

  return (
    <>
      <StyledItem {...rest}>
        <StyledTitle hasChildren={!!children} onClick={onClick}>
          {title}
          {(children || showIcons) && (
            <StyledCollapsible>
              <StyledIcon size="sm" show={!toggled} icon={openIcon} />
              <StyledIcon size="sm" show={toggled} icon={closeIcon} />
            </StyledCollapsible>
          )}
        </StyledTitle>
      </StyledItem>
      {children && (
        <Accordion
          onStart={onAnimationStart}
          onRest={onAnimationEnd}
          maxHeight={maxHeight}
          transitionDuration={transitionDuration}
          toggled={toggled}
          as="li"
        >
          {children}
        </Accordion>
      )}
    </>
  )
}
