import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Tooltip } from "@clevertrack/shared"

const StyledBarChart = styled.div`
  display: flex;
  align-items: center;
`

const BarContainer = styled.div`
  position: relative;
  min-height: 7px;
  flex: 1;
  display: flex;
  gap: 2px;
  // max-width: 65%;

  ${(props) => props.theme.media.big_desktop_up`
    // max-width: 24rem;
  `}

  ${(props) => props.theme.media.xxxl_desktop_up`
    // max-width: 70%;
  `}
`

const Bar = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors[props.color]};
  min-height: 7px;
  flex: ${(props) => props.index};
`

const HoverArea = styled.div`
  position: absolute;
  cursor: pointer;
  top: -75%;
  bottom: -50%;
  left: 0;
  right: 0;
  z-index: 1;
`

function BarChart({ before, after, bars, ...props }) {
  const [tooltipsToggled, setTooltipsToggled] = useState(false)

  const hoverHandler = (e, id) => {
    const { type } = e
    switch (type) {
      case "mouseenter":
        setTooltipsToggled(bars.map((item, i) => Number(i) === Number(id)))
        break
      case "mouseleave":
        setTooltipsToggled(bars.map((item) => false))
        break
    }
  }

  return (
    <StyledBarChart {...props}>
      {!!before && before()}
      <BarContainer>
        {bars.map((bar, id) => {
          return (
            <Bar key={`bar_${id}`} index={bar.index} color={bar.color}>
              <HoverArea
                className="hover"
                onMouseEnter={(e) => hoverHandler(e, id)}
                onMouseLeave={(e) => hoverHandler(e, id)}
              />
              {!!bar?.hoverContent && (
                <>
                  {/* <Tooltip
                    toggled={true}
                    tw="hidden font-bold py-1 left-1/3 print:(block)"
                  >
                    {bar.hoverContent}
                  </Tooltip> */}
                  <Tooltip
                    toggled={tooltipsToggled[id]}
                    position={bar?.hoverPosition || 50}
                    tw="font-bold py-2 left-1/2"
                  >
                    {bar.hoverContent}
                  </Tooltip>
                </>
              )}
            </Bar>
          )
        })}
      </BarContainer>
      {!!after && after()}
    </StyledBarChart>
  )
}

export default BarChart

BarChart.defaultProps = {}
BarChart.propTypes = {}
