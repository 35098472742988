import { differenceInMonths, parseJSON } from "date-fns"
import { worthy } from "utils/worthy"

const getIntervalValue = (data) => {
  return data.unit !== "mdr"
    ? data.intervalValue
    : differenceInMonths(parseJSON(data.endDate), parseJSON(data.startDate))
}

const serviceLogInjectOriginal = (initial, injection) => {
  if (!!initial && !!injection) {
    const newEntry = { ...injection }
    const copyOriginal = [
      "id",
      "edit",
      "author",
      "date",
      "interval",
      "intervalStamp",
    ]
    const worthyChecks = ["realHours", "realMileage", "note"]
    for (const [key, _] of Object.entries(newEntry)) {
      const value = newEntry[key]

      const key_inside = (list) => list.some((check) => check === key)
      if (key_inside(worthyChecks) && !worthy(value)) {
        newEntry[key] = initial[key]
      }
      if (key_inside(copyOriginal)) {
        newEntry[key] = initial[key]
      }
    }
    return newEntry
  } else {
    return null
  }
}

const serviceLogIsDifferent = (originalEntry, newEntry) => {
  const virtual = {
    ...originalEntry,
    ...newEntry,
  }
  delete virtual.edit
  delete originalEntry.edit
  return JSON.stringify(virtual) !== JSON.stringify(originalEntry)
}

const formatDateStylish = (d: string) => {
  const newDate = d ? new Date(d) : new Date()
  const date = parseJSON(newDate)
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export {
  getIntervalValue,
  serviceLogInjectOriginal,
  serviceLogIsDifferent,
  formatDateStylish,
}
