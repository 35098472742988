import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledHeader = styled.div`
  position: absolute;
  top: 2rem;
  left: ${(props) => (props.hasSidebar ? `1rem` : `2rem`)};
  right: ${(props) => (props.hasSidebar ? `1rem` : `auto`)};
  z-index: 200;

  ${(props) =>
    props.hasTitle &&
    !props.hasSidebar &&
    `
      padding: 0 1rem;
      position: sticky;
      top: 0;
      z-index: 200;
      background: ${props.theme.colors.blackLight};
      padding: 0.5rem 0;

      h2 {
        margin: 0;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.5;
        color: ${props.theme.colors.white};
      }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  `}
`

function Header({ children, ...props }) {
  return <StyledHeader {...props}>{children}</StyledHeader>
}

export default Header

Header.defaultProps = {}
Header.propTypes = {
  children: PropTypes.node.isRequired,
}
