import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { IBaseResultProps, StyledBaseResult } from "./Result"

const StyledCreateListItem = styled(StyledBaseResult)`
  ${tw`border-dashed border border-gray-300 flex items-center bg-brand-gray-lighter text-xl py-4 mt-4 relative shadow-none
  hover:(border-transparent bg-white shadow-card)`}
`

export const CreateListItem: React.FC<IBaseResultProps> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <StyledCreateListItem onClick={onClick} {...props}>
      {children}
    </StyledCreateListItem>
  )
}
