import { useContext } from "react"
import { getString } from "utils/i18n"
import { UserContext } from "app/User/context"

// Convenience component for retrieving a translation using only the key.
// When translating a component attribute, e.g. an input placeholder, invoke the component as a function, like:
// I18n({ strKey: "start_search_input_placeholder" })

interface II18n {
  strKey: string
}

function I18n({ strKey: key }: II18n) {
  const {
    state: {
      user: { language },
    },
  } = useContext(UserContext)
  const lang = language ? language.split("-")[0] : "da"
  const i18nString = getString(key, lang)
  return i18nString
}

export default I18n
