import { useContext } from "react"
import {
  ref,
  child,
  onValue,
  query,
  get,
  limitToFirst,
} from "firebase/database"
import { FirebaseContext } from "context/FirebaseProvider"
import { IRawDeviceUpdate } from "app/Device/types"
import { useUser } from "app/User/hooks"
import { UserVisibilitySettings } from "app/User/types"
import { HistoryContext } from "./context"
import { HistoryActions, HistoryTypes } from "./actions"
import { HistoryLogViewSettings } from "./history.types"

export const useHistory = () => {
  const { rtdb } = useContext(FirebaseContext)
  const { toggleUserVisibilitySetting } = useUser()
  const { dispatch } = useContext(HistoryContext)

  const dataRef = ref(rtdb, `currentTrips`)

  const getCurrentTrip = (deviceID, onValueHandler) => {
    const q = query(child(dataRef, deviceID))
    const unsub = onValue(
      q,
      (snapshot) => {
        const list: IRawDeviceUpdate[] = []
        snapshot.forEach((childSnapshot) => {
          list.push(childSnapshot.val() as IRawDeviceUpdate)
        })
        onValueHandler(list)
      },
      { onlyOnce: true }
    )
    return unsub
  }

  const getFirstLogOfCurrentTrip = async (deviceID) => {
    const q = query(child(dataRef, deviceID.toString()), limitToFirst(1))

    const snapshot = await get(q)
    if (snapshot.exists()) return snapshot.val()
    return null
  }

  const onShowZeroDistanceTripsChangeHandler = async (checked) => {
    await toggleUserVisibilitySetting(
      UserVisibilitySettings.HistoryShowZeroDistanceTrips
    )
    const userLogViewSettings: HistoryLogViewSettings = {
      showZeroDistanceTrips: checked,
    }
    dispatch(
      HistoryActions(HistoryTypes.SetLogViewSettings, {
        viewSettings: userLogViewSettings,
      })
    )
  }

  return {
    getCurrentTrip,
    getFirstLogOfCurrentTrip,
    onShowZeroDistanceTripsChangeHandler,
  }
}
