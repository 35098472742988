import React, { useContext } from "react"
import PropTypes from "prop-types"
import "twin.macro"
import CrudList from "app/CrudList"
import { Button, Card } from "@clevertrack/shared"
import { useInvisibleTrackerGroups } from "app/TrackerGroups/hooks"
import styled from "styled-components"
import { IconFlip } from "app/IconFlip"
import { CrudListItem } from "app/CrudList/Item"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import tw from "twin.macro"

const StyledCard = styled(Card)<{ hidden?: boolean }>`
  opacity: 1;
  transition: opacity 0.2s ease-out;
  position: relative;

  ${(props) => (props.hidden ? `.title { opacity: 0.5; }` : ``)}
`

function GroupSelector({
  selectedGroup,
  groups,
  onToggleGroup,
  onDelete,
  onCancel,
  ...props
}) {
  const { dispatch } = useContext(DeviceContext)
  const {
    invisibleTrackerGroups,
    toggleInvisibleTrackerGroup,
  } = useInvisibleTrackerGroups()

  const onToggleVisibility = (id) => {
    toggleInvisibleTrackerGroup(id)
    dispatch(
      DeviceActions(DeviceTypes.ToggleDeviceGroupVisbility, { groupID: id })
    )
  }

  return (
    <CrudList {...props}>
      {groups &&
        groups.map((group) => {
          const { id, name, description, vehicleCount } = group
          const isHidden = invisibleTrackerGroups?.find((x) => x.id === id)
          return (
            <CrudListItem
              key={id}
              item={group}
              onEdit={onToggleGroup}
              onDelete={onDelete}
              onCancel={onCancel}
              editID={selectedGroup}
              css={isHidden ? tw`opacity-60` : ``}
            >
              <div className="title">
                <div
                  tw="flex items-center cursor-pointer"
                  onClick={() => onToggleVisibility(id)}
                >
                  <Button variant="icon" size="sm" tw="mr-4" invert>
                    <IconFlip
                      toggled={isHidden}
                      iconOff="eye"
                      iconOn="eye-slash"
                      size="sm"
                    />
                  </Button>
                  <h4>
                    {name} ({vehicleCount})
                  </h4>
                </div>
                {description !== "" && (
                  <small
                    tw="max-w-sm md:max-w-lg overflow-hidden overflow-ellipsis"
                    title={description}
                  >
                    {description}
                  </small>
                )}
              </div>
            </CrudListItem>
          )
        })}
    </CrudList>
  )
}

export default GroupSelector

GroupSelector.defaultProps = {
  onToggleGroup: () => {},
  onDelete: () => {},
  groups: null,
}

GroupSelector.propTypes = {
  onToggleGroup: PropTypes.func,
  onDelete: PropTypes.func,
  groups: PropTypes.array,
}
