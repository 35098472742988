import { namedStatusColors } from "theme/colors"

/**
 * Converts an object of key value pairs to a react-vis data series
 * @param {Object} obj Object with key value pairs of status and tracker count
 */

export function convertTrackerStatusCountToSeries(obj) {
  const series: any[] = []
  Object.entries(obj).forEach(([key, value]) => {
    series.push({
      id: key,
      angle: value,
      className: key,
      style: {
        stroke: namedStatusColors[key],
        fill: namedStatusColors[key],
      },
    })
  })
  return series
}
