class LogModel {
  constructor() {
    this.interval = {}
    this.intervalStamp = 0
    this.note = ""
    this.author = {}
    this.date = null
    this.realMileage = null
    this.realHours = null
    this.realDate = null
  }
}

export { LogModel }

// this.edit = {
//   date: "",
//   user: {}
// }

// this.realMileage = 0
// this.realHours = 0
// this.date = new Date()
