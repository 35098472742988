import { ICoordinates } from "app/GoogleMap/map.types"

export interface IZoneMeta {
  id?: string
  name: string
  description: string
  active: boolean
}

export interface IZone extends IZoneMeta {
  Coordinates: ICoordinates[]
}

export type Zones = IZone[]

export enum ZoneFormActionsEnum {
  SAVE = "SAVE",
  CREATE = "CREATE",
}
