import { AlertNotification } from "routes/Alarms/alarm.types"

const defaultSuggestedSubject = `{TITLE} på {DEVICE} blev udløst`

const defaultSuggestedMessage = `Hej {FNAME}

'{TITLE}' blev udløst på enheden '{DEVICE}'.

Klik på linket herunder for at se enheden:

{LINK}
`

const defaults: Partial<AlertNotification> = {
  recipients: [],
  notificationReadByContactIDs: [],
  notificationLastTriggeredAt: 0,
  notificationLastTriggeredAtDeviceMap: {},
  notificationDisableTriggerOnInitialCriteriaFullfilment: false,
  subject: defaultSuggestedSubject,
  message: defaultSuggestedMessage,
}

const ignitionAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_ignition_notification",
  refID: "template_ignition_alarm",
  recipients: [],
  notificationTriggerFrequency: 3600,
}

const idleAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_idle_notification",
  refID: "template_idle_alarm",
  recipients: [],
  notificationTriggerFrequency: 900,
  notificationDisableTriggerOnInitialCriteriaFullfilment: true,
}

const movementAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_movement_notification",
  refID: "template_movement_alarm",
  recipients: [],
  notificationTriggerFrequency: 3600,
}

const stopAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_stop_notification",
  refID: "template_stopped_alarm",
  recipients: [],
  notificationTriggerFrequency: 3600,
}

const offlineAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_offline_notification",
  refID: "template_offline_alarm",
  recipients: [],
  notificationTriggerFrequency: 0,
}

const speedingAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_speeding_notification",
  refID: "template_speeding_alarm",
  recipients: [],
  notificationTriggerFrequency: 0,
}

const onlineAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_online_notification",
  refID: "template_online_alarm",
  recipients: [],
  notificationTriggerFrequency: 0,
}

const zoneAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_zone_notification",
  refID: "template_zone_alarm",
  recipients: [],
  notificationTriggerFrequency: 0,
}

const deviceEjectedAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_device_ejected_notification",
  refID: "template_device_ejected_alarm",
  recipients: [],
  notificationTriggerFrequency: 0,
}

const powerLostAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_power_lost_notification",
  refID: "template_power_lost_alarm",
  recipients: [],
  notificationTriggerFrequency: 3600,
}

const connectionLostAlarmNotification: Partial<AlertNotification> & {
  refID: string
} = {
  ...defaults,
  id: "template_connection_lost_notification",
  refID: "template_connection_lost_alarm",
  recipients: [],
  notificationTriggerFrequency: 3600,
  notificationDisableTriggerOnInitialCriteriaFullfilment: true,
}

export const alarmNotificationTemplates = [
  ignitionAlarmNotification,
  movementAlarmNotification,
  idleAlarmNotification,
  stopAlarmNotification,
  deviceEjectedAlarmNotification,
  speedingAlarmNotification,
  offlineAlarmNotification,
  onlineAlarmNotification,
  powerLostAlarmNotification,
  connectionLostAlarmNotification,
  zoneAlarmNotification,
]
