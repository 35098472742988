import {
  ITrackerDailyLog,
  ILog,
  ITrip,
  IParked,
  TripTypes,
  ITripState,
  IDateFilter,
  HistoryLogViewSettings,
} from "./history.types"
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  endOfDay,
} from "date-fns"
import {
  groupBy,
  flatMap,
  last,
  uniqBy,
  dropRight,
  findLastIndex,
  max,
  orderBy,
  first,
  remove,
  sum,
} from "lodash-es"
import { v4 as uuidv4, v4 } from "uuid"
import Duration from "duration"
import {
  IDisplayKey,
  DisplayKeyValueType,
  DisplayKeyEnum,
} from "app/TrackerKPI/kpi.types"
import { IPosition } from "app/GoogleMap/map.types"
import isToday from "date-fns/isToday"
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  IRawDeviceUpdate,
} from "app/Device/types"
import { secondsFromDuration } from "./Log/TrackerLog/dayHelper"

/**
 * Retrieves the trips of a given day
 */

const getTrips = (dayLogs: ILog[], currentTrip) => {
  const trips: TripTypes[] = []
  const chronologicDayLogs: ILog[] = dayLogs

  /**
   * Recursively run through chronologicDayLogs, and take items until we encounter a status = 0 (Stopped, indicating a trip ended)
   */

  const trippingThroughTime = (logs: ILog[]) => {
    const lastStopIndex = findLastIndex(
      logs,
      (x: ILog) => +x.status === DeviceStatusEnum.STOPPED
    )

    const trip_states: ITripState[] =
      lastStopIndex > 0 ? logs.slice(lastStopIndex) : logs.slice(0)

    const remainingLogs: ILog[] = dropRight(logs, trip_states.length)

    const trip: ITrip = {
      id: `trip_${format(
        new Date(trip_states[0].timestamp),
        "yyyy:MM:dd HH:mm"
      )}_${lastStopIndex}`,
      trip_states: trip_states.map((x) => {
        if (x.in_progress) {
          return {
            ...x,
            status: x.originalStatus,
          }
        } else return x
      }),
      summary: getSummary(trip_states),
      type: "trip",
      in_progress: lastStopIndex < 0 || trip_states[0].in_progress,
    }

    if (currentTrip && trip.in_progress) {
      trip.current_trip = currentTrip.sort((a, b) =>
        a.timestamp < b.timestamp ? -1 : 1
      )
    }

    trips.unshift(trip)

    if (trip_states.length > 0 && remainingLogs[remainingLogs.length - 1]) {
      const parked: IParked = {
        id: uuidv4(),
        type: "parked",
        start_timestamp: trip_states[0].timestamp,
        stop_timestamp: remainingLogs[remainingLogs.length - 1].timestamp,
      }
      trips.unshift(parked)
      trippingThroughTime(remainingLogs)
    } else return
  }

  trippingThroughTime(chronologicDayLogs)

  return trips
}

/**
 * Reduces the display keys for the daily logs, to create a summary
 */

const getSummary = (dayLogsRaw: ILog[] | ITripState[]) => {
  const dayLogs = dayLogsRaw.map((x) => {
    if (
      [DeviceStatusEnum.IN_TRANSPORT, DeviceStatusEnum.STOPPED].includes(
        x.status
      )
    ) {
      return {
        ...x,
        display_keys: x.display_keys,
      }
    }
    return x
  })
  const data = flatMap(dayLogs, (x) => x.display_keys).filter(Boolean)
  const summaryDisplayKeys: IDisplayKey[] = uniqBy(data, "name").filter(Boolean)

  return summaryDisplayKeys
    .map((x) => {
      let ret
      if (x.type === "TimeSpan") {
        const calculatedTimespan = sum(
          data
            .filter((d) => d.name === x.name)
            .map((d) => secondsFromDuration(d.value))
        )
        const now = new Date()
        const secondsFromEpoch = new Date(+now + calculatedTimespan * 1000)
        const durationFromEpoch = Duration(now, secondsFromEpoch)
        ret = {
          ...x,
          value: `${String(durationFromEpoch.hours).padStart(2, "0")}:${String(
            durationFromEpoch.minute
          ).padStart(2, "0")}:${String(durationFromEpoch.second).padStart(
            2,
            "0"
          )}`,
        }
        return ret
      }
      ret = {
        ...x,
        value: data
          .filter((d) => d.name === x.name)
          .map((d) => Number(d.value))
          .reduce((acc, curr) => Number(acc) + Number(curr), 0),
      }

      return ret
    })
    .filter(Boolean)
}

interface IDay {
  day: string
  status: DeviceStatusEnum
  address: string
  display_keys: IDisplayKey[]
  duration: string
  pos: IPosition
  timestamp: string
}

type Days = {
  [key: string]: IDay[]
}

const createPseudoDayStartTripState = (tripState) => {
  const pseudoTripState = {
    ...tripState,
    id: `${tripState.id}_pseudoStartTripState`,
    isPseudoStart: true,
  }
  const dayStart = startOfDay(new Date(tripState.timestamp))

  pseudoTripState.timestamp = dayStart

  return pseudoTripState
}

const createPseudoDayEndTripState = (tripState) => {
  const pseudoTripState = {
    ...tripState,
    id: `${tripState.id}_pseudoEndTripState`,
    isPseudoEnd: true,
  }
  const dayEnd = endOfDay(new Date(tripState.timestamp))

  pseudoTripState.timestamp = dayEnd

  return pseudoTripState
}

const generateMetaTimeline = (
  start: ILog,
  stop: ILog,
  daysInStartState,
  modifiedStopIsPlaceholder
) => {
  const statusUnchangedSinceTimestamp = new Date(start.timestamp)
  const statusUnchangedUntillTimestamp = new Date(stop.timestamp)
  const startLogEndTimestamp = endOfDay(statusUnchangedSinceTimestamp)
  const stopLogStartTimestamp = startOfDay(statusUnchangedUntillTimestamp)
  const elapsedDurationStart = Duration(
    statusUnchangedSinceTimestamp,
    startLogEndTimestamp
  )
  const elapsedDurationStop = Duration(
    stopLogStartTimestamp,
    statusUnchangedUntillTimestamp
  )
  // Create a log that signifies the end of the day for the first log.
  const startMetaLog = {
    ...start,
    id: `${start.id}_metaStartLogEndOfDay`,
    status: DeviceStatusEnum.STOPPED,
    timestamp: startLogEndTimestamp.toISOString(),
    duration: `${String(elapsedDurationStart.hours).padStart(2, "0")}:${String(
      elapsedDurationStart.minute
    ).padStart(2, "0")}:${String(elapsedDurationStart.second).padStart(
      2,
      "0"
    )}`,
    isPseudoEnd: true,
  }
  const stopMetaLog = {
    ...stop,
    id: `${stop.id}_metaStopLogEndOfDay`,
    duration: "00:00:00",
    status: start.status,
    timestamp: stopLogStartTimestamp.toISOString(),
    isPseudoStart: true,
  }
  const modifiedStop = {
    ...stop,
    id: `${stop.id}_modifiedStop`,
    duration: `${String(elapsedDurationStop.hours).padStart(2, "0")}:${String(
      elapsedDurationStop.minute
    ).padStart(2, "0")}:${String(elapsedDurationStop.second).padStart(2, "0")}`,
  }

  if (modifiedStopIsPlaceholder) {
    modifiedStop.placeholder = modifiedStopIsPlaceholder
    modifiedStop.status = DeviceStatusEnum.RUNNING
  }

  const metaTimeline: ILog[] = [start, startMetaLog, stopMetaLog, modifiedStop]

  for (let currentDay = 0; currentDay <= daysInStartState; currentDay++) {
    const newMetaLogDate = addDays(statusUnchangedSinceTimestamp, currentDay)
    if (
      !isSameDay(newMetaLogDate, new Date(start.timestamp)) &&
      !isSameDay(newMetaLogDate, new Date(stop.timestamp))
    ) {
      const newMetaLogStop = endOfDay(newMetaLogDate)
      const newMetaLogStart = startOfDay(newMetaLogDate)
      const durr = Duration(newMetaLogStart, newMetaLogStop)
      const newStopMetaLog = {
        ...stop,
        id: `${newMetaLogStop.toISOString()}_metaStopEndOfDay`,
        status: DeviceStatusEnum.STOPPED,
        timestamp: newMetaLogStop.toISOString(),
        duration: `${String(durr.hours).padStart(2, "0")}:${String(
          durr.minute
        ).padStart(2, "0")}:${String(durr.second).padStart(2, "0")}`,
        isPseudoEnd: true,
      }
      const newStartMetaLog = {
        ...start,
        id: `${newMetaLogStart.toISOString()}_metaStartOfDay`,
        duration: "00:00:00",
        status: start.status,
        timestamp: newMetaLogStart.toISOString(),
        isPseudoStart: true,
      }
      metaTimeline.push(newStopMetaLog)
      metaTimeline.push(newStartMetaLog)
    }
  }

  return orderBy(metaTimeline, "timestamp", "desc")
}

export const analyseData = (
  sourceLogs: ILog[],
  dateFilter: IDateFilter,
  device,
  currentTrip: IRawDeviceUpdate[] | null,
  logViewSettings: HistoryLogViewSettings
) => {
  const results: ITrackerDailyLog[] = []

  let currentTripLogs: ILog[] = []

  const firstSourceLog = first(sourceLogs)

  const dummyDefaultDisplayKeys = [
    {
      name: DisplayKeyEnum.TotalFuel,
      value: 0,
      type: "float" as DisplayKeyValueType,
    },
    {
      name: DisplayKeyEnum.TotalDistance,
      value: 0,
      type: "float" as DisplayKeyValueType,
    },
  ]

  if (currentTrip) {
    currentTripLogs = currentTrip.reduce((acc, curr, i) => {
      const prevItem = last(acc) as ILog
      if (i === 0 || (prevItem && +curr.status !== +prevItem.status)) {
        // If this is the first entry in currentTrip, use the highest value in logsTimestamp (latest entry)
        const fromTimestamp =
          i === 0
            ? new Date(
                curr.values.find(
                  (x) => x.name === DisplayKeyEnum.LastConfirmationTime
                )?.value as string
              )
            : // Otherwise use the timestamp of the previously generated trip of currentTrips.
              new Date(prevItem.timestamp)

        // The "to" timestamp is either Date.now() or the next status change entry in current trip, OR the last entry of currentTrip.
        const toTimestamp = new Date(
          curr.values.find(
            (x) => x.name === DisplayKeyEnum.LastConfirmationTime
          )?.value as string
        )

        const tripDuration = Duration(fromTimestamp, toTimestamp)

        const thisTimestamp = new Date(
          curr.values.find(
            (x) => x.name === DisplayKeyEnum.LastConfirmationTime
          )?.value as string
        )

        acc.push({
          id: v4(),
          address: curr.address ?? "",
          status: curr.status,
          timestamp: thisTimestamp.toISOString(),
          duration: `${String(tripDuration.hour).padStart(2, "0")}:${String(
            tripDuration.minute
          ).padStart(2, "0")}:${String(tripDuration.second).padStart(2, "0")}`,
          pos: {
            properties: {},
            lat: curr.position.geometry.coordinates[1],
            lng: curr.position.geometry.coordinates[0],
          },
          display_keys: dummyDefaultDisplayKeys,
        })
      }

      return acc
    }, [] as ILog[])

    // When done looping, finally insert a fake trip_state, that reflects the ongoing trip.
    const latestCurrentTripEntry = last(currentTrip)
    const lastTripState = last(currentTripLogs)
    const lastSourceLog = first(sourceLogs)

    if (latestCurrentTripEntry && lastTripState) {
      const latestCurrentTripEntryTimestamp = latestCurrentTripEntry.values.find(
        (x) => x.name === DisplayKeyEnum.LastConfirmationTime
      )?.value as string

      const now = new Date()
      const duration = Duration(
        new Date(lastTripState.timestamp),
        new Date(latestCurrentTripEntryTimestamp)
      )

      if (lastTripState) {
        if (!logViewSettings.splitTripsOnDateChange) {
          currentTripLogs.push({
            ...lastTripState,
            id: `${lastTripState.id}_placeholder`,
            placeholder: true,
            duration: `${
              duration.days > 0 ? `${String(duration.days)}.}` : ""
            }${String(duration.hour).padStart(2, "0")}:${String(
              duration.minute
            ).padStart(2, "0")}:${String(duration.second).padStart(2, "0")}`,
            timestamp: now.toISOString(),
            status:
              lastSourceLog && isToday(new Date(lastSourceLog.timestamp))
                ? lastTripState.status
                : DeviceStatusEnum.STOPPED,
          })
        } else {
          currentTripLogs.push({
            ...lastTripState,
            id: `${lastTripState.id}_placeholder`,
            placeholder: true,
            duration: `${
              duration.days > 0 ? `${String(duration.days)}.}` : ""
            }${String(duration.hour).padStart(2, "0")}:${String(
              duration.minute
            ).padStart(2, "0")}:${String(duration.second).padStart(2, "0")}`,
            timestamp: now.toISOString(),
            status: DeviceStatusEnum.STOPPED,
            originalStatus: lastTripState.status,
            in_progress: ![
              DeviceStatusEnum.STOPPED,
              DeviceStatusEnum.UNKNOWN,
            ].includes(latestCurrentTripEntry.status),
          })
        }
      }
    }
  }

  const unsortedSourceLogs: ILog[] = []

  const allSourceLogs: ILog[] = [
    ...currentTripLogs
      .filter((log) =>
        isBefore(+new Date(firstSourceLog.timestamp), +new Date(log.timestamp))
      )
      .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
      .sort((a, b) => (a.placeholder ? -1 : 1)),
    ...sourceLogs,
  ]

  if (!logViewSettings.splitTripsOnDateChange) {
    allSourceLogs.forEach((log, index) => {
      if (log.duration.indexOf(".") > -1) {
        // Encountered a status that has been active for multiple days
        const daysInCurrentState = log.duration.split(".")[0]
        const statusUnchangedSinceLog = allSourceLogs[index + 1]

        // If the current log is a stop, we assume the previous state was either work, ignition or idle.
        if (
          daysInCurrentState &&
          +daysInCurrentState > 0 &&
          log.status === DeviceStatusEnum.STOPPED
        ) {
          const { placeholder, ...restLog } = log
          const metaLogs = generateMetaTimeline(
            statusUnchangedSinceLog,
            restLog,
            daysInCurrentState,
            placeholder
          )
          unsortedSourceLogs.push(...metaLogs)
        } else {
          unsortedSourceLogs.push(log)
        }
      } else {
        unsortedSourceLogs.push(log)
      }
    })
  }

  const logs: ILog[] = !logViewSettings.splitTripsOnDateChange
    ? orderBy(uniqBy(unsortedSourceLogs, "id"), "timestamp", "desc")
    : allSourceLogs

  const modifiedLogs = logs

  const days = groupBy(
    uniqBy(modifiedLogs, "id").map((x) => {
      return {
        ...x,
        day: format(new Date(x.timestamp), "yyyy-MM-dd"),
        status: +x.status,
      }
    }),
    "day"
  ) as Days

  const dayEntries = Object.entries(days)

  const fromDateFilter = new Date(dateFilter.start)
  const toDateFilter = new Date(dateFilter.stop)

  // Before going through the algorithm below, we need to ensure
  // that trips that pass midnight, has its trip states belong to the day it started.

  // To do this, we loop through each day, to see if the last tripState we have on record
  // for that day, is a stop. If it is not, we can deduct that the trip passes midnight.
  dayEntries.forEach(([key, dayTripStates], index) => {
    if (
      dayTripStates[0].status !== DeviceStatusEnum.STOPPED &&
      dayEntries.length > 1 &&
      logViewSettings.splitTripsOnDateChange
    ) {
      // Trip passes midnight. Find the stop index from the next day
      // This logic is a bit weird, since the days are ordered in descending order,
      // so the "next" day is actually the "previous" day in the dayEntries array.
      if (dayEntries[index - 1] && dayEntries[index - 1][1].length > 0) {
        // The tripstates within the day are ordered in descending order, so the
        // latest trip state is first. Thus, we need to find the index of the last stopped state.
        const nextDayFirstStopIndex = findLastIndex(
          dayEntries[index - 1][1],
          (x) => x.status === DeviceStatusEnum.STOPPED
        )
        // Next, we need to take a slice of the array, starting at the nextDayStopIndex (included),
        // until the end of the array. Slice or splice will make things tricky, so instead, let's use
        // lodash "remove" function, that mutates the array we remove from, and returns the removed elements.
        const remainderOfTrip = remove(
          days[dayEntries[index - 1][0]],
          (val, idx) =>
            nextDayFirstStopIndex >= 0 && idx >= nextDayFirstStopIndex
        )
        // Finally, insert the remainder of the trip at the start of the current day.
        days[key].unshift(...remainderOfTrip)

        // If this empties the trips states from the previous day, delete it.
        if (days[dayEntries[index - 1][0]].length === 0) {
          delete days[dayEntries[index - 1][0]]
        }
      }
    }
  })

  dayEntries.forEach(([key, day], index) => {
    const currentDayDate = new Date(key)

    if (
      (isBefore(currentDayDate, toDateFilter) ||
        isSameDay(currentDayDate, toDateFilter)) &&
      (isAfter(currentDayDate, fromDateFilter) ||
        isSameDay(currentDayDate, fromDateFilter))
    ) {
      const nextDayLogs = dayEntries[index - 1]
        ? [...dayEntries[index - 1][1]].reverse()
        : null // Do this, so we don't mutate the original array
      const nextDayStopIndex = nextDayLogs
        ? nextDayLogs.findIndex((x) => x.status === DeviceStatusEnum.STOPPED)
        : -1
      // Something is still running past midnight!
      const prevDayLogs = dayEntries[index + 1]
        ? [...dayEntries[index + 1][1]].reverse()
        : null
      const prevDayStartIndex = prevDayLogs
        ? prevDayLogs.findIndex((x) =>
            [
              DeviceStatusEnum.RUNNING,
              DeviceStatusEnum.IDLE,
              DeviceStatusEnum.IN_TRANSPORT,
            ].includes(x.status)
          )
        : -1
      const lastCurrentDayStartIndex = day.findIndex(
        (x) => x.status === DeviceStatusEnum.RUNNING
      )
      const firstCurrentDayStopIndex = findLastIndex(
        day,
        (x) => x.status === DeviceStatusEnum.STOPPED
      )
      const lastCurrentDayStopIndex = day.findIndex(
        (x) => x.status === DeviceStatusEnum.STOPPED
      )
      if (!logViewSettings.splitTripsOnDateChange) {
        // In this scenario, the users prefers seeing summaries of trips,
        // but split up when the date changes. That means we need to insert a "pseudo"-tripstate,
        // to simulate the right summary data.
        let firstCurrentDayPseudoState
        let lastCurrentDayPseudoState

        if (
          nextDayLogs &&
          nextDayStopIndex !== null &&
          nextDayStopIndex > -1 &&
          lastCurrentDayStartIndex > -1 &&
          day[0].status !== DeviceStatusEnum.STOPPED
        ) {
          const lastCurrentDayState = {
            ...day[0],
            id: `${day[0].id}_lastCurrentDayState`,
            status: DeviceStatusEnum.STOPPED,
          }

          const startTimestamp = day[lastCurrentDayStartIndex].timestamp

          lastCurrentDayPseudoState = createPseudoDayEndTripState(
            lastCurrentDayState,
            startTimestamp
          )
        }

        if (
          prevDayLogs &&
          prevDayStartIndex !== null &&
          prevDayStartIndex > -1 &&
          firstCurrentDayStopIndex > -1 &&
          last(prevDayLogs) &&
          last(day) &&
          ![DeviceStatusEnum.RUNNING, DeviceStatusEnum.IN_TRANSPORT].includes(
            last(day).status
          )
        ) {
          const firstCurrentDayState = {
            ...last(day),
            status: last(prevDayLogs).status,
          }

          const endTimestamp = day[firstCurrentDayStopIndex].timestamp

          firstCurrentDayPseudoState = createPseudoDayStartTripState(
            firstCurrentDayState,
            endTimestamp
          )
        }

        if (lastCurrentDayPseudoState) day.unshift(lastCurrentDayPseudoState)
        if (firstCurrentDayPseudoState) day.push(firstCurrentDayPseudoState)

        // Finally, adjust the new durations of the day, based on the inserted timestamps.
        days[key] = day.map((x, i) => {
          if (day[i + 1]) {
            const duration = new Duration(
              new Date(day[i + 1].timestamp),
              new Date(x.timestamp)
            )
            const newDuration = `${String(duration.hours).padStart(
              2,
              "0"
            )}:${String(duration.minute).padStart(2, "0")}:${String(
              duration.second
            ).padStart(2, "0")}`
            return {
              ...x,
              duration: newDuration,
            }
          }
          return {
            ...x,
            duration: "00:00:00",
          }
        })
      }
    }
  })

  Object.keys(days).forEach((value) => {
    const currentDay = new Date(value)
    if (
      !(
        isBefore(currentDay, toDateFilter) ||
        isSameDay(currentDay, toDateFilter)
      ) ||
      !(
        isAfter(currentDay, fromDateFilter) ||
        isSameDay(currentDay, fromDateFilter)
      )
    ) {
      delete days[value]
      return
    }
    const isDayToday = isToday(new Date(value))
    let summary = getSummary(days[value])

    if (isDayToday && device) {
      summary = summary.map((x) => {
        if (x) {
          if (
            x.name === DisplayKeyEnum.TotalFuel &&
            device.values.find(
              (kpi) => kpi.name === DisplayKeyEnum.FuelConsumptionToday
            )
          ) {
            return {
              ...x,
              value: device.values
                .find((kpi) => kpi.name === DisplayKeyEnum.FuelConsumptionToday)
                .value.replace(",", "."),
            }
          }
          if (
            x.name === DisplayKeyEnum.TotalDistance &&
            device.values.find(
              (kpi) => kpi.name === DisplayKeyEnum.DailyDistanceToday
            )
          ) {
            return {
              ...x,
              value: device.values
                .find((kpi) => kpi.name === DisplayKeyEnum.DailyDistanceToday)
                .value.replace(",", "."),
            }
          }
        }
        return x
      })
    }

    const log: ITrackerDailyLog = {
      trips: getTrips(days[value], currentTrip),
      summary,
      timestamp: value,
    }
    results.push(log)
  })

  return results
}
