import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { IconFlip } from "app/IconFlip"

const StyledTile = styled.div<StyledTileProps>`
  margin: 0;
  // border: 1px solid ${(props) => props.theme.colors.grayLight};
  position: relative;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-top: 0;
    border-top: none;
    ${
      !props.wrap &&
      `
      border-left: none;
      border-right: none;
      box-shadow: none;
    `
    }
  `}
`

const StyledTileTitle = styled.div`
  font-weight: bold;
  padding: 1rem 2rem 1rem;
  display: block;
  color: ${(props) => props.theme.colors.blackOpaque};
  font-size: 1.4rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${(props) => props.theme.colors.white};

  ${(props) => props.theme.media.tablet_landscape_up`
    letter-spacing: 0.1rem;
  `}
`

const StyledTileWrapper = styled.div`
  padding: 1rem;
`

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  pointer-events: all;

  svg {
    margin-left: 1.5rem;
    margin-top: 0.25rem;
  }
`

interface StyledTileProps {
  wrap?: boolean
}

interface TileProps extends StyledTileProps {
  title?: React.ReactNode
  onToggle?: (e: any) => void
  toggled?: boolean
}

const Tile: React.FC<TileProps> = ({
  children,
  title,
  wrap,
  onToggle,
  toggled,
  ...props
}) => {
  return (
    <StyledTile wrap={wrap} {...props}>
      {title && (
        <StyledTileTitle>
          {onToggle ? (
            <StyledToggle onClick={onToggle}>
              <span>{title}</span>
              <IconFlip
                toggled={toggled}
                iconOn="chevron-down"
                iconOff="chevron-up"
                size="sm"
              />
            </StyledToggle>
          ) : (
            <span>{title}</span>
          )}
        </StyledTileTitle>
      )}
      {wrap ? <StyledTileWrapper>{children}</StyledTileWrapper> : children}
    </StyledTile>
  )
}

export default Tile
