import { usePromise } from "hooks/usePromise"
import React, { useMemo, useState } from "react"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import styled from "styled-components"
import tw from "twin.macro"

const StyledLink = styled.div`
  ${tw`bg-white flex flex-col items-center text-brand-black-base -mb-2 -mx-4 border border-solid border-brand-gray-light border-t-0`}
  position: relative;
  // max-height: 20rem;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    align-self: flex-start;
    ${tw`mr-4`}
  }

  .info {
    ${tw`p-4`}
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`

export const MessageLink: React.FC<{ url: string }> = ({ url, ...props }) => {
  const { parseURLMeta } = useFirebaseFunctions()
  const { setPromise, promiseStatus, loading } = usePromise()
  const [meta, setMeta] = useState()

  const onSuccess = ({ data }) => {
    setMeta(data)
  }

  useMemo(() => {
    try {
      const promise = parseURLMeta({ url })
      setPromise({ promise, onSuccess })
    } catch (error) {
      console.log(error)
      return null
    }
  }, [url])

  return meta?.title ? (
    <StyledLink>
      <img src={meta.image} alt="" />
      <div className="info">
        <span tw="font-bold text-2xl block">{meta.title}</span>
        <span tw="text-xl leading-4">{meta.description}</span>
      </div>
      <a href={meta.url} target="_blank" rel="noopener noreferrer" />
    </StyledLink>
  ) : null
}
