import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"

const StyledTileContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  // padding: 0 1rem 0;
  // position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  height: 100%;
  max-height: 100%;
  padding-bottom: 8rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
    right: auto;
    // min-width: 30rem;
    width: 100%;
    z-index: 300;
    border-right: 1px solid ${props.theme.colors.grayLight};
    overflow-x: auto;
    max-height: none;
    padding-bottom: 0;
    ${tw`bg-gray-100`}
  `}
`

function TileContainer({ children, ...props }) {
  return (
    <StyledTileContainer id="lazyContainerTile" {...props}>
      {children}
    </StyledTileContainer>
  )
}

export default TileContainer

TileContainer.defaultProps = {}
TileContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
