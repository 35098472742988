import React from "react"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    // console.log(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h2>Der skete en uventet fejl</h2>
          <ol>
            <li>Prøv at genindlæse hjemmesiden.</li>
            <li>
              Skriv eventuelt en email til os på{" "}
              <a href="mailto:mail@clevertrack.dk">mail@clevertrack.dk</a>, og
              lad os vide hvad der gik galt.
            </li>
          </ol>
        </>
      )
    }

    return this.props.children
  }
}

export { ErrorBoundary }
