/* Action types */
export const SET_LANG = 'SET_LANG'
export const TOGGLE_DISMISS = 'TOGGLE_DISMISS'

/* Action creators */

export const setLang = (lang) => {
  return {
    type: SET_LANG,
    lang,
  }
}

export const toggleDismissKey = (key) => {
  return {
    type: TOGGLE_DISMISS,
    key,
  }
}
