import { PlatformStatusEnum } from "./PlatformStatus/platformstatus.types"

export enum NotificationType {
  SYSTEM_MESSAGE = "SystemMessage",
  DEVICE_ALERT = "DeviceAlert",
  PLATFORM_STATUS = "PlatformStatus",
  ADDRESS_LOOKUP = "DAWAStatus",
}

export type BaseNotificationProps = {
  id: string
  title: string
  body: string
  category: NotificationType
  timestamp: number
}

export type ProviderDescriptionMap = {
  body: string
  timestamp: number
}[]

export type PlatformStatusNotificationProps = BaseNotificationProps & {
  category: NotificationType
  platformStatus: PlatformStatusEnum
  isCurrent: boolean
  providerTitle?: string
  providerDescription: string | ProviderDescriptionMap
}
