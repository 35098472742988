import styled from "styled-components"
import { CustomMapsButton } from "lib/CustomMapsButton"
import tw from "twin.macro"

export const StyledZonesToggle = styled(CustomMapsButton)`
  position: absolute;
  z-index: 120;
  top: ${(props) => props.theme.mobileContentOffset};
  margin-top: 5rem;
  right: 1rem;
  ${tw`print:hidden`}

  svg {
    ${tw`text-brand-black-base fill-current`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 11rem;
    right: 1rem;
    margin-top: 0;
  `}
`
