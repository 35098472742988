import { useState, useEffect } from "react"

export const useWindowActive = () => {
  const [windowActive, setWindowActive] = useState(() =>
    typeof document !== "undefined" ? document.hasFocus() : false
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("focus", () => setWindowActive(true))
      window.addEventListener("blur", () => setWindowActive(false))
    }

    return () => {
      window.removeEventListener("focus", () => setWindowActive(true))
      window.removeEventListener("blur", () => setWindowActive(false))
    }
  }, [])

  return {
    windowActive,
    isWindowActive: () =>
      typeof document !== "undefined" ? document.hasFocus() : false,
  }
}
