export enum PlatformStatusEnum {
  OPERATIONAL = "operational", // Green
  UNDER_MAINTENANCE = "under_maintenance", // Yellow
  DEGRADED_PERFORMANCE = "degraded_performance", // Yellow
  PARTIAL_OUTAGE = "partial_outage", // Red
  MAJOR_OUTAGE = "major_outage", // Red
  NONE = "", // Green
}

export enum PlatformStatusHintEnum {
  DataSecurity = "PlatformStatus/DataSecurity",
}

export const PlatformStatusGreen = [
  PlatformStatusEnum.OPERATIONAL,
  PlatformStatusEnum.NONE,
]

export const PlatformStatusYellow = [
  PlatformStatusEnum.UNDER_MAINTENANCE,
  PlatformStatusEnum.DEGRADED_PERFORMANCE,
]
export const PlatformStatusRed = [
  PlatformStatusEnum.PARTIAL_OUTAGE,
  PlatformStatusEnum.MAJOR_OUTAGE,
]

export type OnomondoPlatformStatusResponse = {
  id: string
  name: string
  status: PlatformStatusEnum
  created_at: string
  updated_at: string
  position: number
  description: string | null
  showcase: boolean
  start_date: string | null
  group_id: number | null
  page_id: string
  group: boolean
  only_show_if_degraded: boolean
}
