import React, { useContext } from "react"
import styled from "styled-components"
import "twin.macro"
import { Responsive } from "@clevertrack/shared"

import { ResultListGroup } from "app/ResultListGroup"
import { ResultList } from "app/ResultListGroup/ResultList"
import EmptyItem from "../EmptyItem"
import { SearchContext } from "app/Search/context"

const StyledEmptyService = styled.div`
  padding: 1rem 2rem;
`

const Spacer = styled.div`
  padding-top: 5rem;
  padding-bottom: 4rem;
`

const Title = styled.div`
  h3 {
    margin-bottom: 0;
  }
`

function EmptyService({ missing, onClick, toggled, children, ...props }) {
  const {
    state: { results, suggestions, query },
  } = useContext(SearchContext)

  const allResults = [...results, ...suggestions].map(({ item }) => item.id)

  const renderResultList = () => {
    return (
      <ResultList>
        {missing.map((tracker, i) => {
          if (query.length > 0 && !allResults.includes(tracker.id)) return null
          return (
            <EmptyItem
              key={`missing_${i}`}
              tracker={tracker}
              toggle={toggled[tracker.id]}
              onClick={() => onClick(tracker)}
            />
          )
        })}
      </ResultList>
    )
  }

  return (
    <Responsive
      tabletLandscape={
        <StyledEmptyService {...props}>
          {children}
          <ResultListGroup columns={1}>{renderResultList()}</ResultListGroup>
        </StyledEmptyService>
      }
      phone={<Spacer>{renderResultList()}</Spacer>}
    />
  )
}

export default EmptyService
