import Accordion from "lib/Accordion"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { ExpandFeedButton } from "./ExpandFeedButton"
import { EventFeedItem, EventFeedItemOptions } from "./Item"

type EventFeedComponents = {
  EventBulletComponent: React.ReactElement // Should render a children property for icons
  FirstEventTimestampComponent?: React.ReactElement
  EventTimestampComponent?: React.ReactElement
  LastEventTimestampComponent?: React.ReactElement
}

type EventFeedProps = {
  firstEvent: any
  lastEvent: any
  remainingEvents: any[]
  expandRemainingEventsButtonText: string
  inverse?: boolean
  renderComponents: EventFeedComponents
  itemOptions: EventFeedItemOptions
  defaultExpanded?: boolean
  onFeedExpanded?: (...args) => void
  resetStateWhenTrue?: boolean
}

const StyledEventFeed = styled.section<{ inverse: boolean }>`
  ${tw`flex relative`}
  ${(props) => (props.inverse ? tw`flex-col-reverse` : tw`flex-col`)}
`

export const EventFeed: React.FC<EventFeedProps> = ({
  firstEvent,
  lastEvent,
  remainingEvents,
  renderComponents,
  itemOptions,
  expandRemainingEventsButtonText,
  defaultExpanded = false,
  resetStateWhenTrue = false,
  inverse = false,
  onFeedExpanded,
  ...props
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  useEffect(() => {
    if (expanded && onFeedExpanded) onFeedExpanded()
  }, [expanded])

  useEffect(() => {
    if (resetStateWhenTrue) setExpanded(false)
  }, [resetStateWhenTrue])

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded])

  return (
    <>
      <StyledEventFeed inverse={inverse}>
        <EventFeedItem
          bulletComponent={renderComponents.EventBulletComponent}
          timestampComponent={renderComponents.FirstEventTimestampComponent}
          event={firstEvent}
          hasRemainingEvents={remainingEvents.length > 0}
          css={[tw`pb-4`, expanded && remainingEvents.length > 0 && tw`pb-8`]}
          eventsExpanded={expanded}
          {...itemOptions}
        />

        {!expanded && remainingEvents.length > 0 && (
          <ExpandFeedButton onClick={() => setExpanded(true)}>
            {expandRemainingEventsButtonText}
          </ExpandFeedButton>
        )}

        <Accordion toggled={expanded} tw="space-y-4">
          {remainingEvents.map((event) => (
            <EventFeedItem
              key={event[itemOptions.eventTimestampKey]}
              event={event}
              isRemainingEvent={true}
              bulletComponent={renderComponents.EventBulletComponent}
              timestampComponent={renderComponents.EventTimestampComponent}
              {...itemOptions}
            />
          ))}
        </Accordion>

        <EventFeedItem
          timestampComponent={renderComponents.LastEventTimestampComponent}
          bulletComponent={renderComponents.EventBulletComponent}
          event={
            itemOptions.eventIsInProgress
              ? { ...lastEvent, status: lastEvent.status }
              : lastEvent
          }
          animateIfInProgress
          hasRemainingEvents={remainingEvents.length > 0}
          css={[tw`pt-4`, expanded && remainingEvents.length > 0 && tw`pt-8`]}
          eventsExpanded={expanded}
          {...itemOptions}
        />
      </StyledEventFeed>
    </>
  )
}
