import { DisplayKeyEnum, IDisplayKey } from "app/TrackerKPI/kpi.types"
import { allCategories } from "app/TrackerKPI/Widget/helper"
import { Category } from "app/TrackerKPI/Widget/widget.types"
import { DeviceTypeEnum } from "./types"

export const assertDeviceTypeByAvailableDisplayKeys = (
  displayKeys: IDisplayKey[]
) => {
  const displayKeyNameMap = displayKeys.map((x) => x.name)
  if (displayKeyNameMap.includes(DisplayKeyEnum.EngineHoursToday)) {
    return DeviceTypeEnum.Machine
  } else if (displayKeyNameMap.includes(DisplayKeyEnum.DrivingHoursToday)) {
    return DeviceTypeEnum.Car
  } else if (displayKeyNameMap.includes(DisplayKeyEnum.BatteryLongevity)) {
    return DeviceTypeEnum.Beacon
  } else if (
    displayKeyNameMap.includes(DisplayKeyEnum.BatteryLongevityVolt72)
  ) {
    return DeviceTypeEnum.Asset
  } else return DeviceTypeEnum.MaterialScanner
}

export const assertDeviceCANAvailability = (displayKeys: IDisplayKey[]) => {
  const displayKeyNameMap: DisplayKeyEnum[] = displayKeys.map((x) => x.name)
  const CANIdentifiers = [
    DisplayKeyEnum.FuelConsumptionToday,
    DisplayKeyEnum.MachineWithCAN,
  ]
  return displayKeyNameMap.some((x) => CANIdentifiers.includes(x))
}

export const getDisplayKey = (
  displayKeys: IDisplayKey[],
  target: DisplayKeyEnum,
  category?: Category
) => {
  const targetFromDisplayKeys = displayKeys.find((key) => key.name === target)
  const targetFromAllCategories = category
    ? category.find((key) => key.key === target)
    : allCategories.find((key) => key.key === target)

  return { ...targetFromDisplayKeys, ...targetFromAllCategories }
}
