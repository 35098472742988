import { privateApi } from "utils/api"

/**
 * Create new vehicle group
 * @param {object} body
 */

const createGroup = async (body) => {
  try {
    const response = await privateApi().post("/Groups", body)
    return response
  } catch (e) {}
}

/**
 * Update vehicle group id with new information
 * @param {integer} id
 * @param {object} body
 */
const updateGroup = async (id, body) => {
  try {
    const response = await privateApi().patch(`/groups/${id}`, body)
    return response
  } catch (e) {}
}

/**
 * Delete vehicle group by id
 * @param {integer} id
 */
const deleteGroup = async (id) => {
  try {
    const response = await privateApi().delete(`/groups/${id}`)
    return response
  } catch (e) {}
}

export { createGroup, updateGroup, deleteGroup }
