import React, { useState, useEffect, useContext, useMemo } from "react"
import tw from "twin.macro"
import Icon, { IconSizeEnum } from "lib/Icon"
import styled from "styled-components"
import getDay from "date-fns/getDay"
import format from "date-fns/format"
import isToday from "date-fns/isToday"
import Accordion from "lib/Accordion"
import { Card, Responsive } from "@clevertrack/shared"
import { ITrackerDailyLog, ITrip, IRoute } from "routes/HistoryV2/history.types"
import { WeekDays } from "lib/global.types"
import { Trip } from "./Trip"
import { HistoryContext } from "routes/HistoryV2/context"
import { HistoryActions, HistoryTypes } from "routes/HistoryV2/actions"
import BarChart from "app/BarChart"
import { secondsFromDuration, summaryDataFromDay } from "./dayHelper"
import { findDisplayKey } from "utils/displayKeys"
import { formatSecondsToDuration } from "utils/datetime"
import useViewport from "hooks/useViewport"
import { fetchIntervalRoute } from "services/history"
import { DeviceContext } from "app/Device/context"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { DeviceStatusEnum, DeviceTypeEnum } from "app/Device/types"
import { useHistory } from "routes/HistoryV2/hooks"
import sum from "lodash-es/sum"

const StyledDay = styled.article`
  margin-bottom: 1.5rem;
  position: relative;

  .trips_wrapper {
    ${tw`relative`}
    @media print {
      break-inside: avoid-column;
    }
    &:before {
      left: 5.5rem;
      display: block;
      top: 2rem;
      bottom: 2rem;
      content: "";
      ${tw`border border-solid border-0 border-brand-gray-light border-l absolute`}
    }
  }

  .summary {
    padding: 0 0 0.9rem;
    /* padding-bottom: 1.5rem; */

    .header {
      padding: 0.5rem 1.4rem;
      display: grid;
      align-items: baseline;
      grid-template-columns: 1fr 1.4fr;
      column-gap: 1rem;
    }

    .sticky {
      position: sticky;
      top: 0;
      z-index: 1000;
      ${tw`bg-white`}
    }

    .overview {
      padding: 1.8rem 1.4rem 0.9rem;
      display: grid;
      grid-template-columns: auto 2rem;
      column-gap: 1rem;
      align-items: flex-end;
      position: relative;

      > *:first-child {
        flex: 0 1 12rem;
      }

      .barchart {
        column-gap: 1rem;
        // flex: 1 1 27rem;
        ${(props) => props.theme.media.tablet_portrait_up`
          align-self: center;
        `}
        ${(props) => props.theme.media.big_desktop_up`
          column-gap: 1rem;
          position: static;
          align-self: center;
        `}
      }

      .rightcontent {
        flex: 2;
        min-width: 13rem;
      }
    }

    .total {
      ${tw`flex mt-4`}
      padding: 1rem 1.4rem;
      gap: 1rem;
      align-items: center;
      justify-content: stretch;
      flex: 1;
    }
  }
`

const KPIdata = styled.div`
  flex: 1;
  font-size: 1.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem 2rem;

  .data {
    /* margin: 0 1rem; */

    line-height: 1.8rem;
    letter-spacing: -0.5px;
    display: flex;
    flex-direction: column;
    flex: 1 0 46%;

    .info {
      width: 0;
      white-space: nowrap;
      line-height: 1.2rem;
    }

    ${(props) => props.theme.media.tablet_landscape_up`
      flex-direction: row;
      column-gap: 1rem;
      flex: 1 1 33%;
    `}

    ${(props) => props.theme.media.big_desktop_up`
      flex: 1 0 33%;
    `}

    ${(props) => props.theme.media.xxxl_desktop_up`
      flex: 1 0 25%;
    `}
  }

  ${(props) => props.theme.media.big_desktop_up`
    .info:before {
      content: "- ";
    }

    .info {
      width: auto;
      white-space: normal;
    }
  `}
`

const RotatingIcon = styled(Icon)`
  transform: rotate(${(props) => (props.rotate ? "90deg" : "0deg")});
  transition: ${(props) => (props.rotate ? "0.15s" : "0.3s")};
`

export const Day: React.FC<{
  day: ITrackerDailyLog
  deviceType: DeviceTypeEnum
  deviceHasCAN: boolean
  displayKeys: any[]
  dayToggled: boolean
  onClick: () => void
  showSeconds: boolean
  showDefaultExpanded: boolean
}> = ({
  day,
  deviceType,
  deviceHasCAN,
  displayKeys,
  dayToggled,
  onClick,
  showSeconds,
  showDefaultExpanded,
  ...props
}) => {
  const {
    dispatch,
    state: { routes, logViewSettings },
  } = useContext(HistoryContext)
  const {
    state: { devices },
  } = useContext(DeviceContext)
  const [showZeroDurationTripID, setShowZeroDurationTripID] = useState([])

  const toggledDevices = useMemo(() => {
    return devices.filter((device) => device.toggled)
  }, [devices])

  const vp = useViewport()
  const [toggled, setToggled] = useState<boolean>(false)
  const { onShowZeroDistanceTripsChangeHandler } = useHistory()
  const weekDay = getDay(new Date(day.timestamp))
  const isDateToday = isToday(new Date(day.timestamp))
  const date = format(new Date(day.timestamp), "dd-MM-yyyy")

  const isMachineWithCan = deviceType === DeviceTypeEnum.Machine && deviceHasCAN

  const summaryData = summaryDataFromDay(
    day,
    isMachineWithCan,
    showSeconds,
    showZeroDurationTripID,
    logViewSettings.showZeroDistanceTrips,
    isDateToday && toggledDevices[0] ? toggledDevices[0].values : day.summary
  )

  const dailyCompletedTripDistance = sum(
    day.trips
      .filter((x) => x.type === "trip")
      .flatMap(
        (trip) =>
          trip.summary.find(
            (item) => item.name === DisplayKeyEnum.TotalDistance
          )?.value
      )
  )?.toFixed(1)

  const fuelConsumptionToday = sum(
    day.trips
      .filter((x) => x.type === "trip" && !x.in_progress)
      .flatMap(
        (trip) =>
          +trip.summary.find((item) => item.name === DisplayKeyEnum.TotalFuel)
            ?.value
      )
  )

  const displayKeyDataSource =
    isDateToday && toggledDevices[0] ? toggledDevices[0].values : day.summary

  const drivingHoursToday = displayKeyDataSource.find(
    (x) => x.name === DisplayKeyEnum.DrivingHoursToday
  )?.value
  const engineHoursToday = displayKeyDataSource.find(
    (x) => x.name === DisplayKeyEnum.EngineHoursToday
  )?.value
  const idleHoursToday = displayKeyDataSource.find(
    (x) => x.name === DisplayKeyEnum.IdleHoursToday
  )?.value

  const deviceTypeMap = {
    machine: {
      text: "Motortimer",
      empty: "Intet arbejde",
      icon: "timer",
      data: engineHoursToday
        ? secondsFromDuration(engineHoursToday)
        : isMachineWithCan
        ? summaryData.groupedTrips.statusTotalSeconds[DeviceStatusEnum.IDLE] +
          summaryData.groupedTrips.statusTotalSeconds[DeviceStatusEnum.WORKING]
        : summaryData.groupedTrips.statusTotalSeconds[
            DeviceStatusEnum.RUNNING
          ] +
          summaryData.groupedTrips.statusTotalSeconds[DeviceStatusEnum.WORKING],
    },
    car: {
      text: "Køretimer",
      empty: "Ingen kørsel",
      icon: "steering-wheel",
      data: drivingHoursToday
        ? secondsFromDuration(drivingHoursToday)
        : summaryData.groupedTrips.statusTotalSeconds[1],
    },
    beacon: {
      text: "Flyttet",
      empty: "Ingen flytning",
      icon: "route",
      data: summaryData.groupedTrips.statusTotalSeconds[4],
    },
  }

  const deviceInfo = deviceTypeMap[deviceType]

  const viewOnMapHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    dispatch(HistoryActions(HistoryTypes.ToggleMap, {}))
  }

  const getKPIIconByDeviceTypeAndCAN = (kpi, icon) =>
    kpi === "Køretimer" && isMachineWithCan
      ? "play"
      : kpi === "Køretimer" && deviceType === "machine"
      ? "timer"
      : icon

  const getKPINameByDeviceTypeAndCAN = (kpi) => {
    if (deviceType === "beacon") {
      switch (kpi) {
        case "Køretimer":
          return "Tændingstid"
        case "Flyttet":
          return kpi
        default:
          return "Stoppet"
      }
    }
    return kpi === "Køretimer" && isMachineWithCan
      ? "Tænding"
      : kpi === "Køretimer" && deviceType === "machine"
      ? "Motortimer"
      : kpi
  }

  const modifiedBars = summaryData.bars.map((bar) => {
    const kpiGhost = bar.hoverContent.split(":")[0]
    return kpiGhost === "Køretimer"
      ? {
          ...bar,

          hoverContent: bar.hoverContent.replace(
            "Køretimer",
            getKPINameByDeviceTypeAndCAN(kpiGhost)
          ),
        }
      : bar
  })

  const onRestHandler = () => {
    dispatch(
      HistoryActions(HistoryTypes.ToggleAnimating, {
        animating: false,
      })
    )
  }

  const onStartHandler = () => {
    dispatch(
      HistoryActions(HistoryTypes.ToggleAnimating, {
        animating: true,
      })
    )
  }

  const onShowZeroDistanceTripInlineHandler = (e, id) => {
    e.stopPropagation()
    setShowZeroDurationTripID((prev) => [id, ...prev])
  }

  const onFetchTrip = async (trackerID, start, stop, trip) => {
    try {
      const route = await fetchIntervalRoute(trackerID, start, stop)
      if (route !== null) {
        const rt: IRoute = {
          id: `${trip.id}`,
          tracker_id: trackerID,
          route: route.map((pt) => {
            return {
              ...pt,
              status: pt.status !== "" ? +pt.status : pt.status,
            }
          }),
        }
        dispatch(HistoryActions(HistoryTypes.AddRoute, { route: rt }))
        dispatch(
          HistoryActions(HistoryTypes.LoadingRenderCriticalData, {
            loading: true,
            completed: 1,
          })
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onFetchDayTrips = async () => {
    const trips = day.trips.filter((x) => x.type === "trip") as ITrip[]
    const promises: Promise<void>[] = []

    for (const trip of trips) {
      if (!trip.in_progress) {
        const rt = routes.find((r) => r.id === trip.id)
        dispatch(
          HistoryActions(HistoryTypes.LoadingRenderCriticalData, {
            loading: true,
            total: trips.length,
            completed: 0,
          })
        )
        if (!rt) {
          promises.push(
            onFetchTrip(
              toggledDevices[0].id,
              trip.trip_states[trip.trip_states.length - 1].timestamp,
              trip.trip_states[0].timestamp,
              trip
            )
          )
        }
      } else if (trip.current_trip && trip.in_progress) {
        const currentTripRoute: IRoute = {
          id: `${trip.id}`,
          tracker_id: toggledDevices[0].id,
          route: trip.current_trip.map((pt, i) => {
            const thisTimestamp = new Date(
              pt.values.find(
                (x) => x.name === DisplayKeyEnum.LastConfirmationTime
              )?.value as string
            )
            const previousLog = trip.current_trip[i - 1]
            return {
              address: pt.address,
              speed: pt.speed.toString(),
              status:
                previousLog && previousLog.status !== pt.status
                  ? +pt.status
                  : trip.current_trip.length - 1 === i || i === 0
                  ? +pt.status
                  : "",
              timestamp: thisTimestamp.toISOString(),
              pos: {
                properties: {},
                lat: pt.position.geometry.coordinates[1],
                lng: pt.position.geometry.coordinates[0],
              },
              in_progress: trip.current_trip.length - 1 === i,
              is_part_of_ongoing_trip: true,
            }
          }),
        }

        dispatch(
          HistoryActions(HistoryTypes.AddRoute, { route: currentTripRoute })
        )

        /**
         * address: "Lervejdal 14D, 8740 Brædstrup"
         * pos: {lat: 56.0361977, lng: 9.639813}
         * speed:0
         * status: "1"
         * timestamp: "2022-09-12T06:41:39"
         */
      }
    }

    await Promise.all(promises)

    dispatch(
      HistoryActions(HistoryTypes.LoadingRenderCriticalData, {
        loading: false,
        total: null,
        completed: null,
      })
    )
  }

  useEffect(() => {
    setToggled(dayToggled)
  }, [dayToggled])

  useEffect(() => {
    return () => setToggled(false)
  }, [])

  useEffect(() => {
    if (dayToggled) onFetchDayTrips()
  }, [dayToggled])

  const renderDefaultBarChart = () => {
    return (
      <BarChart
        tw="self-center md:(self-end)"
        before={() => (
          <div tw="flex flex-col justify-end">
            <span tw="flex items-center opacity-80">
              <Icon tw="mr-2" size={IconSizeEnum.SM} icon={deviceInfo.icon} />
              <span tw="pr-2 font-bold text-xl">{deviceInfo.text}</span>
            </span>
          </div>
        )}
        after={() => (
          <span tw="text-xl opacity-80 font-bold">
            {deviceInfo.data !== 0
              ? formatSecondsToDuration(deviceInfo.data, {
                  viewport: vp,
                  showSeconds,
                })
              : deviceInfo.empty}
          </span>
        )}
        className="barchart"
        bars={modifiedBars}
      />
    )
  }

  return (
    <StyledDay toggled={toggled} {...props}>
      <Card className="summary" tw="cursor-pointer" onClick={onClick}>
        <div
          tw="relative border-solid border-0 border-b border-brand-gray-light flex gap-x-8 items-center whitespace-nowrap"
          className={
            toggled ? ["header", "sticky"].join(" ") : ["header"].join(" ")
          }
        >
          <span>
            <span tw="text-xl font-bold">
              {isDateToday ? "I dag" : WeekDays[weekDay].long}
            </span>
            <span tw="text-xl opacity-60 ml-4">{date}</span>
          </span>
          <span tw="flex space-x-4 items-baseline text-lg opacity-60 lg:(text-lg) xl:(text-xl)">
            <span tw="ml-auto">
              {`kl. ${summaryData.dayTime.formatted?.firstTimestamp} - ${
                day.trips.some((trip) => trip.in_progress)
                  ? summaryData.dayTime.formatted?.lastTimestamp
                  : summaryData.dayTime.formatted?.lastTimestamp
              }`}
            </span>
            <Icon icon="clock" size={IconSizeEnum.SM} />
            {summaryData.dayTime.formatted && (
              <span>{summaryData.dayTime.formatted?.seconds}</span>
            )}
          </span>
        </div>
        <div className="overview">
          {renderDefaultBarChart()}

          <RotatingIcon
            tw="self-center justify-self-end mr-1 opacity-80"
            icon="chevron-right"
            size="sm"
            rotate={toggled}
          />
        </div>
        {/* toggled={toggled} */}
        <Accordion
          toggled={toggled}
          transitionDuration=".5s"
          onStart={onStartHandler}
          onRest={onRestHandler}
        >
          <div tw="flex items-start">
            <div className="total">
              <KPIdata>
                {summaryData.total.map((item, i) => {
                  return (
                    <div className="data" key={`kpi_data_${i}`}>
                      <span>
                        <Icon
                          tw="opacity-80 mr-2"
                          icon={getKPIIconByDeviceTypeAndCAN(
                            item.kpi,
                            item.icon
                          )}
                          size="sm"
                        />
                        <span tw="font-bold opacity-80">
                          {getKPINameByDeviceTypeAndCAN(item.kpi)}
                        </span>
                      </span>
                      <span tw="opacity-60">
                        {item.value} <span className="info">{item.info}</span>
                      </span>
                    </div>
                  )
                })}
                <Responsive
                  phone={
                    <>
                      {deviceType === "car" && (
                        <div className="data">
                          <span>
                            <Icon icon="route" tw="opacity-80 mr-2" size="sm" />
                            <span tw="font-bold opacity-80">Distance</span>
                          </span>
                          <span tw="opacity-60">
                            {`${(+findDisplayKey(
                              day.summary,
                              DisplayKeyEnum.TotalDistance
                            )?.value).toFixed(1)} km`}
                          </span>
                        </div>
                      )}
                      {+findDisplayKey(day.summary, DisplayKeyEnum.TotalFuel)
                        ?.value > 0 ? (
                        <div className="data">
                          <span>
                            <Icon
                              icon="droplet-solid"
                              tw="opacity-80 mr-2"
                              size="sm"
                            />
                            <span tw="font-bold opacity-80">
                              Brændstofforbrug
                            </span>
                          </span>
                          <span tw="opacity-60">
                            {`${(+findDisplayKey(
                              day.summary,
                              DisplayKeyEnum.TotalFuel
                            )?.value).toFixed(1)} l`}
                          </span>
                        </div>
                      ) : null}
                    </>
                  }
                />
              </KPIdata>
            </div>
          </div>
          <Responsive
            phone={
              <header
                tw="flex items-center w-full -mb-4 mt-4 border-solid border-0 border-b border-brand-gray-light"
                className="header"
              >
                <h3 tw="text-xl my-0 opacity-80">Dagens ture</h3>
                <span
                  tw="text-lg flex items-center text-brand-500 ml-auto cursor-pointer cursor-pointer hover:opacity-60 transition"
                  onClick={(e) => viewOnMapHandler(e)}
                >
                  <span tw="mr-2">Se alle ture på kortet</span>
                  <Icon
                    tw="mt-px"
                    size={IconSizeEnum.XS}
                    icon="chevron-right"
                  />
                </span>
              </header>
            }
            desktop={
              <header
                tw="flex items-center w-full -mb-4 mt-4 border-solid border-0 border-b border-brand-gray-light"
                className="header"
              >
                <h3 tw="text-xl my-0 opacity-80">Dagens ture</h3>
              </header>
            }
          />
          <div
            tw="mx-4 my-6 p-0 flex flex-col-reverse"
            className="trips_wrapper"
          >
            {day.trips.map((trip, i) => {
              if (trip.type !== "parked") {
                const tripDuration = secondsFromDuration(
                  trip.trip_states[0].duration
                )
                if (
                  !showZeroDurationTripID.includes(trip.id) &&
                  tripDuration <= 60 &&
                  !logViewSettings.showZeroDistanceTrips &&
                  trip.trip_states.length === 2 &&
                  !trip.in_progress
                ) {
                  return (
                    <span tw="relative h-1 w-full">
                      <span
                        tw="absolute right-0 text-lg z-50 border-solid border border-brand-gray-light bg-white py-1 px-2 transition-all hover:(text-brand-500 border-current)"
                        css={{ transform: "translateY(-50%)" }}
                        onClick={(e) =>
                          onShowZeroDistanceTripInlineHandler(e, trip.id)
                        }
                      >
                        <span tw="opacity-60">Skjult 1 min. tur. </span>
                        <span tw="text-brand-500">Vis?</span>
                      </span>
                    </span>
                  )
                }
              }
              return trip.type === "parked" ? null : (
                <Trip
                  key={trip.id}
                  trip={trip}
                  tripIndex={i}
                  completedDailyDistanceToday={dailyCompletedTripDistance}
                  fuelConsumptionToday={fuelConsumptionToday}
                  isFirstTripOfDay={i === day.trips.length - 1}
                  prevTrip={day.trips[i - 1]}
                  getTripTime={summaryData.getTripTime}
                  displayKeys={displayKeys}
                  deviceHasCAN={deviceHasCAN}
                  showSeconds={showSeconds}
                  showDefaultExpanded={showDefaultExpanded}
                  deviceType={deviceType}
                  dayToggled={toggled}
                />
              )
            })}
          </div>
        </Accordion>
      </Card>
    </StyledDay>
  )
}
