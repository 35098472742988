import { useContext, useLayoutEffect, useRef, useState } from "react"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { UserContext } from "app/User/context"
import isToday from "date-fns/isToday"
import { Unsubscribe } from "firebase/database"
import { isEqual } from "lodash-es"
import { useFirestoreCompany } from "services/firestore/company"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { updateAssignedTrackersAndGroupsByUserId } from "services/user"
import { getAllVehicles, getVehiclesInGroup } from "services/vehicles"
import { DeviceActions, DeviceTypes } from "./actions"
import { DeviceContext } from "./context"
import {
  assertDeviceCANAvailability,
  assertDeviceTypeByAvailableDisplayKeys,
  getDisplayKey,
} from "./helper"
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  FirestoreDevice,
  IDevice,
  IDeviceGroup,
  IRawDevice,
  IRawDeviceGroup,
} from "./types"
import { CompanyContext } from "app/Company/context"
import { AppContext } from "context/App"

let deviceSubscriptions: {
  id: string
  unsub: Unsubscribe
  sync: boolean
}[] = []

const useDevices = (subscriptionActive = true) => {
  const {
    state: { devices, deviceGroups, toggledDevices, toggledDeviceGroups },
    dispatch,
  } = useContext(DeviceContext)
  const {
    state: {
      company: { id: companyID },
    },
  } = useContext(CompanyContext)
  const {
    state: { user, userSettings },
  } = useContext(UserContext)
  const {
    state: { currentTime },
  } = useContext(AppContext)
  const {
    getDeviceValuesByID,
    getDeviceValuesByCompanyID,
    goOnline,
  } = useRealtimeDeviceValues()
  const { saveFirebaseCompanyDevices } = useFirestoreCompany()
  const [
    vehiclesAndGroupsInProgress,
    setVehiclesAndGroupsInProgress,
  ] = useState(false)
  const subscriptionRef = useRef<Unsubscribe | null>(null)

  useLayoutEffect(() => {
    const newToggledDevices = devices.filter((device) => device.toggled)
    const newToggledGroups = deviceGroups.filter((group) => group.toggled)
    if (!isEqual(toggledDevices, newToggledDevices)) {
      dispatch(
        DeviceActions(DeviceTypes.SetToggledDevices, {
          devices: newToggledDevices,
        })
      )
    }

    if (!isEqual(toggledDeviceGroups, newToggledGroups)) {
      dispatch(
        DeviceActions(DeviceTypes.SetToggledDeviceGroups, {
          groups: newToggledGroups,
        })
      )
    }
  }, [devices, deviceGroups])

  const setDevicesAndGroups = async (
    {
      groups,
      vehicles,
    }: {
      groups: IRawDeviceGroup[]
      vehicles: IRawDevice[]
    },
    fresh: boolean
  ) => {
    if (fresh) {
      const newDevices: IDevice[] = vehicles.map((vehicle) => {
        const { data, ...restVehicle } = vehicle
        return {
          ...restVehicle,
          deviceHasCAN: assertDeviceCANAvailability(data),
          deviceType: assertDeviceTypeByAvailableDisplayKeys(data),
          values: data,
          toggled: false, // Untoggled by default
          toggledInGroups: [],
        }
      })
      const newGroups: IDeviceGroup[] = groups.map((group) => ({
        ...group,
        devices: newDevices
          .filter((device) => device.group.includes(group.id))
          .map((device) => device.id),
        toggled: false,
        visible: !userSettings.invisibleGroups?.includes(group.id),
      }))

      dispatch(
        DeviceActions(DeviceTypes.SetDeviceGroups, { groups: newGroups })
      )
      dispatch(DeviceActions(DeviceTypes.SetDevices, { devices: newDevices }))

      newDevices
        .filter((device) => +device.status === DeviceStatusEnum.UNKNOWN)
        .map((device) => updateDeviceValues(device, true))

      const updateCompanyDevices: FirestoreDevice[] = []
      for (const device of newDevices) {
        updateCompanyDevices.push({
          id: device.id,
          name: device.name,
        })
      }

      await saveFirebaseCompanyDevices(updateCompanyDevices)
    }
  }

  const resetDevicesAndGroups = async () => {
    const res = await unsubscribeToValues()
    dispatch(DeviceActions(DeviceTypes.Reset, null))
    return res
  }

  const populateVehiclesAndGroups = async (fresh: boolean = false) => {
    try {
      if (!vehiclesAndGroupsInProgress) {
        if (deviceGroups.length > 0 && devices.length > 0) {
          await setDevicesAndGroups(
            { groups: deviceGroups, vehicles: devices },
            fresh
          )
        } else if (deviceGroups.length > 0 && devices.length === 0) {
          // In this scenario, the user lost their assigned vehicles.
          // Reassign the vehicles to the user
          const userDeviceGroups = deviceGroups.map((group) => group.id)
          const devicesInGroupsPromises = await Promise.all(
            deviceGroups.map((group) => getVehiclesInGroup(group.id))
          )

          const devicesInGroups = devicesInGroupsPromises.flatMap(
            (res) => res?.data.vehicles
          )

          const payload = {
            vehicles: devicesInGroups,
            groups: userDeviceGroups,
          }

          if (devicesInGroups.length > 0) {
            const updateRes = await updateAssignedTrackersAndGroupsByUserId(
              user?.id,
              payload
            )

            if (updateRes && updateRes.data.result === "OK") {
              await refreshVehiclesAndGroups()
            }
          }
        } else {
          await refreshVehiclesAndGroups()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const refreshVehiclesAndGroups = async () => {
    try {
      if (deviceGroups.length === 0 && devices.length === 0) {
        setVehiclesAndGroupsInProgress(true)
        const vehiclesResponse = await getAllVehicles()
        if (vehiclesResponse && vehiclesResponse.data.result === "OK") {
          setDevicesAndGroups({ ...vehiclesResponse.data }, true)
          setVehiclesAndGroupsInProgress(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateDeviceValues = async (
    data: Partial<IDevice>,
    updateOffline?: boolean,
    sync: boolean = false
  ) => {
    if (data && !!data?.values && data.id) {
      const deviceUpdate = {
        ...data,
        values: [
          ...data.values.filter((x) => x.name !== DisplayKeyEnum.Address),
        ].filter(Boolean),
      }

      return deviceUpdate
      /* const lastConfirmationTime = data.values.find(
        (dKey) => dKey.name === DisplayKeyEnum.LastConfirmationTime
      )?.value

      if (
        (lastConfirmationTime && isToday(new Date(lastConfirmationTime))) ||
        updateOffline ||
        sync
      ) {
        const deviceUpdate = {
          ...data,
          values: [
            ...data.values.filter((x) => x.name !== DisplayKeyEnum.Address),
          ].filter(Boolean),
        }

        return deviceUpdate
      } */
    } else {
      // console.log("device updated voided")
      return null
    }
  }

  const updateDeviceValuesBySnapshot = (snapshot, sync) => {
    const data = snapshot.val()
    const deviceUpdates = Object.entries(data).map(
      ([key, deviceData]: [string, IDevice]) => {
        const isToggled = !!toggledDevices.find(
          (x) => x.id.toString() === key.toString()
        )
        return updateDeviceValues(deviceData, false, isToggled)
      }
    )

    Promise.all(deviceUpdates).then((updatedData) => {
      const updatedDevices = updatedData.filter(Boolean)
      dispatch(
        DeviceActions(DeviceTypes.UpdateManyDevices, {
          devices: updatedDevices,
        })
      )
    })
  }

  const subscribeToValues = () => {
    const subscription = getDeviceValuesByCompanyID(
      companyID,
      updateDeviceValuesBySnapshot
    )
    subscriptionRef.current = subscription
  }

  const subscribeToSomeDevices = (subIDs, sync = false) => {
    goOnline()
    const subscriptionMap = subIDs
      .map((id) => {
        // debugger
        const existingSubscription = deviceSubscriptions.find(
          (s) => s.id.toString() === id.toString()
        )
        if (existingSubscription) {
          return null
        }
        return {
          id: id.toString(),
          unsub: getDeviceValuesByID(id.toString(), (args) =>
            updateDeviceValuesBySnapshot(args, sync)
          ),
          sync,
        }
      })
      .filter(Boolean)

    deviceSubscriptions = [...deviceSubscriptions, ...subscriptionMap]
    // getDeviceValues(updateDeviceValues, subscriptionDeviceIDMap)
    return deviceSubscriptions
  }

  const unsubscribeToValues = () => {
    if (subscriptionRef.current) {
      subscriptionRef.current()
    }
  }

  const unsubscribeSomeDevices = async (unsubIDs) => {
    await deviceSubscriptions
      .filter((x) => unsubIDs.includes(x.id))
      .map((subscription) => subscription.unsub())
    deviceSubscriptions = await [
      ...deviceSubscriptions.filter((x) => unsubIDs.includes(x.id)),
    ]
    return deviceSubscriptions
  }

  const showDeviceHealth = (device: IDevice) => {
    const {
      externalVoltageLost,
      GPSFailure,
      values,
      status,
      batteryLongevity,
      batteryLongevityVolt72,
    } = device
    let lostGSMSignal

    if (values && [1, 2, 3, 4].includes(+status)) {
      const treshold = 60 * 5 * 1000 // 5 Minutes
      const lastConfirmationTimestamp = getDisplayKey(
        values,
        DisplayKeyEnum.LastConfirmationTime
      )
      const lastSignal = lastConfirmationTimestamp?.value
        ? +new Date(lastConfirmationTimestamp.value)
        : null

      if (lastSignal) {
        lostGSMSignal = currentTime - lastSignal > treshold
      }
    }

    if (!!GPSFailure || lostGSMSignal) {
      return true
    }

    if (
      [
        DeviceTypeEnum.Machine,
        DeviceTypeEnum.Car,
        DeviceTypeEnum.MaterialScanner,
      ].includes(device.deviceType)
    ) {
      return !!externalVoltageLost
    }

    if ([DeviceTypeEnum.Beacon].includes(device.deviceType)) {
      return batteryLongevity < 15
    }

    if ([DeviceTypeEnum.Asset].includes(device.deviceType)) {
      return batteryLongevityVolt72 < 15
    }

    return false
  }

  return {
    updateDeviceValues,
    setDevicesAndGroups,
    resetDevicesAndGroups,
    populateVehiclesAndGroups,
    refreshVehiclesAndGroups,
    subscribeToValues,
    subscribeToSomeDevices,
    unsubscribeToValues,
    unsubscribeSomeDevices,
    showDeviceHealth,
    subscriptionActive: deviceSubscriptions.length > 0,
    subscriptionIDs: () => deviceSubscriptions.map((sub) => sub.id),
    devices,
    deviceGroups,
    toggledGroups: toggledDeviceGroups,
    toggledDevices,
  }
}

export { useDevices }
