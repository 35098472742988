import React, { useContext } from "react"
import { UserContext } from "app/User/context"
import { USERRIGHTS } from "data/featureFlags"

function AdminComponent({ hideFromNoneAdmins, children, user, ...props }) {
  if (
    !!user?.is_admin ||
    (user?.feature.includes(USERRIGHTS.WRITE) && !hideFromNoneAdmins)
  ) {
    return <>{children}</>
  } else {
    return null
  }
}

export default AdminComponent
