import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledTimeline = styled.div``

function Timeline({ children, ...props }) {
  return <StyledTimeline {...props}>{children}</StyledTimeline>
}

export default Timeline

Timeline.defaultProps = {}
Timeline.propTypes = {
  children: PropTypes.node.isRequired,
}
