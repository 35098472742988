import React, { useContext } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Button } from "@clevertrack/shared"
import { ModalPage } from "app/Dialog/dialog.types"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { Dialog } from "app/Dialog"

const StyledContent = styled.div`
  ${tw`text-xl leading-snug`}

  p {
    ${tw`text-xl leading-snug`}
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    width: 60rem;
  `}
`

export const AutoOffDislcaimer: React.FC = ({
  onChangeDOutOnIgnition,
  dOutOnIgnitionChecked,
  defaultPage,
  children,
  ...props
}) => {
  const {
    dispatch,
    state: { modalPage },
  } = useContext(AppContext)

  const setToggledPage = (modal) => {
    dispatch(GlobalAppActions(GlobalAppTypes.SetCurrentModal, { modal }))
  }

  const renderContent = () => {
    switch (modalPage) {
      case ModalPage.ABOUT:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              Sådan fungerer Auto-sluk
            </Dialog.Title>
            <p>
              Auto-sluk er en eftermonteret teknologi udviklet af Clevertrack®
              med det formål automatisk at nedbringe unødvendig tomgang på
              maskiner. Funktionen kan kun styres af brugere med adgang til
              ”Enhedsindstillinger” og kan derfor ikke deaktiveres af
              maskinføreren. Her er en detaljeret beskrivelse af, hvordan
              Clevertrack Auto-sluk virker:
            </p>
            <ul>
              <li>
                Auto-sluk fungerer på samme måde som at slukke motoren ved at
                afbryde tændingen med nøglen, når maskinen står stille og kører
                i tomgang.
              </li>
              <li>
                Når maskinen har stået i tomgang uafbrudt i den definerede
                periode, afbrydes tændingen automatisk i 5 sekunder.
              </li>
              <li>
                I dette tidsrum bringes maskinens motor til standsning. Efter 5
                sekunder vender tændingen automatisk tilbage, hvilket bevarer
                arbejdslys, displays og andet udstyr, som er aktivt ved tænding.
              </li>
            </ul>
            <p>
              Hvis du ønsker, at alt forbliver slukket efter Auto-sluk, kan du
              aktivere funktionen <strong>”Fasthold tænding fra”</strong>.
            </p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              Luk
            </Button>
          </StyledContent>
        )
      case ModalPage.DOUTONIGNITION:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">Fasthold tænding fra</Dialog.Title>
            <p>
              Funktionen kan anvendes til at bevare strømmen på maskinens
              batteri. Efter Auto-sluk vil tændingen være fastholdt fra, hvilket
              sparer strøm på maskinens batteri. Dette er praktisk, hvis
              maskinen står ubenyttet imellem drift og i længere perioder uden
              behov for lys, display eller andet udstyr, der normalt er aktivt
              ved tænding.
            </p>
            <p>
              Når nøglen fysisk i maskinen drejes om til tænding fra, vil
              fastholdelsen ophører og maskinen kan igen startes.
            </p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              Luk
            </Button>
          </StyledContent>
        )
      case ModalPage.ENGINELOWTEMPERATURE:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              Udskyd under koldstart
            </Dialog.Title>
            <p>
              Auto-sluk udskydes, indtil motoren har opnået den indtastede
              temperatur. Hvis maskinens motor stadig kører i tomgang, når den
              ønskede temperatur og varighed for Auto-sluk er opnået, vil
              motoren automatisk slukke.
            </p>
            <p>
              <strong>Anbefaling: </strong>Denne funktion kan med fordel
              aktiveres, hvis maskinen ikke er udstyret med et oliefyr. Det er
              særligt nyttigt, hvis der er behov for dugfrie ruder, opvarmning
              af motoren eller hvis der er behov for lade spænding og opladning
              af motorbatteriet.
            </p>
            <p>
              Vi anbefaler normalt at indstille opvarmningen til{" "}
              <strong>30 grader</strong> for intelligent styring af Auto-sluk
              funktionen i koldt og varmt vejr. Husk at du altid kan se
              motortemperatur på dine maskiner i{" "}
              <strong>”Helbreds rapporten”</strong> under{" "}
              <strong>”Rapporter”</strong> og tjekke op om temperaturen er sat
              rigtigt efter formålet.
            </p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              Luk
            </Button>
          </StyledContent>
        )
      case ModalPage.FIRSTACTIVATION:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              Udskyd 1. gang dagligt
            </Dialog.Title>
            <p>
              Auto-sluk udskydes ved første start på dagen i den indtastede
              varighed. Hvis maskinen stadig kører i tomgang, når
              udskydelsestiden og varigheden for Auto-sluk er opnået, vil
              motoren automatisk slukke. Bemærk, at hvis tændingen afbrydes
              inden for den indtastede varighed, vil funktionen først være aktiv
              igen næste dag (ved døgnskifte kl. 00:00).
            </p>
            <p>
              <strong>Anbefaling: </strong>Denne funktion kan med fordel
              aktiveres, hvis maskinen ikke er udstyret med et oliefyr. Det er
              særligt nyttigt, hvis der er behov for dugfrie ruder, opvarmning
              af motoren eller hvis der er behov for lade spænding og opladning
              af motorbatteriet, ved den første daglige start af maskinen.
            </p>
            <p>
              Funktionen er primært udviklet til maskiner uden data på
              motortemperatur (ingen CAN-data). For maskiner med CAN-data, hvor
              motortemperatur er tilgængelig, anbefales det også at benytte{" "}
              <strong>”Udskyd under koldstart”</strong> for en fleksibel styring
              af Auto-sluk funktionen i koldt og varmt vejr.
            </p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              Luk
            </Button>
          </StyledContent>
        )
      default:
        return null
    }
  }

  return modalPage === ModalPage.DISCLAIMER ? (
    <StyledContent>
      <Dialog.Title tw="my-0">Vigtig info for Auto-sluk</Dialog.Title>
      <h3>Efterkontrol af maskinens parkeringsbremse</h3>
      <p>
        <span>
          Vi anbefaler, at der udføres en efterkontrol af maskinens
          parkeringsbremse for <strong>hjuldrevne maskiner</strong>. Formålet
          med denne kontrol er at sikre, at maskinen forbliver bremset ved
          Auto-sluk, såfremt der ikke er en maskinfører til stede i førerhuset.
        </span>
      </p>
      <h3>Instruktioner for efterkontrol:</h3>
      <ol>
        <li>Tænd motoren i maskinen</li>
        <li>
          Tryk ”Aktivér Auto-sluk” og sæt ønsket varighed før Auto-sluk, afslut
          ved at trykke ”Gem”
        </li>
        <li>Bliv i maskinen og afvent Auto-sluk</li>
        <li>
          Tjek at maskinens parkeringsbremse <strong>automatisk</strong> slår
          til, eller forbliver aktiv ved motorstandsning efter 5 sekunder
        </li>
        <li>
          Hvis parkeringsbremsen er defekt eller udebliver{" "}
          <strong tw="text-brand-red-500">skal</strong> Auto-sluk slås fra igen,
          og kan aktiveres når fejlen er udbedret.
        </li>
      </ol>
      <h3>Vigtigt for maskiner med læssebremse:</h3>
      <p>
        I maskiner, hvor læssebremse anvendes, kan denne deaktivere automatisk
        efter Auto-sluk. Vi anbefaler at kontakte maskinforhandleren for
        automatisk aktivering af parkeringsbremse når tænding afbrydes.
      </p>
      <p tw="text-center font-bold">
        Ved aktivering af Auto-sluk bekræfter jeg at have læst ovenstående, og
        ved defekt eller manglende parkeringsbremse kan Clevertrack ikke holdes
        ansvarlig.
      </p>
      {children}
    </StyledContent>
  ) : (
    renderContent()
  )
}
