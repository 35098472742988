import React from "react"
import LazyLoad from "react-lazyload"

export const LazyListItem: React.FC = ({
  height,
  selector,
  continous,
  children,
  ...props
}) => {
  return (
    <LazyLoad height={height} offset={100} scrollContainer={selector}>
      {children}
    </LazyLoad>
  )
}
