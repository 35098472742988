import React, { useState } from "react"
import "twin.macro"
import { DeviceUpdateCriterium } from "routes/Alarms/alarm.types"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { Select } from "lib/Select"
import { FormField, Input } from "@clevertrack/shared"

interface IDeviceUpdateCriteriaProps {
  criterium: DeviceUpdateCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceUpdateCriteriaElement extends IDeviceUpdateCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

const durationOptions = [
  {
    label: "Et døgn",
    value: 1,
  },
  {
    label: "Tre døgn",
    value: 3,
  },
  {
    label: "Syv døgn",
    value: 7,
  },
  {
    label: "14 dage",
    value: 14,
  },
  {
    label: "30 dage",
    value: 30,
  },
]

const DurationOption = ({ innerProps, innerRef, data }) => {
  return (
    <div
      tw="px-4 py-4 hover:bg-brand-gray-lighter cursor-pointer"
      ref={innerRef}
      {...innerProps}
    >
      <span tw="block text-2xl">{data.label}</span>
    </div>
  )
}

export const DeviceUpdateCriteriaElement: React.FC<IDeviceUpdateCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  const days = criterium.duration / (3600 * 24)
  return (
    <AlarmConfigurationComponent>
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">Sidst opdateret</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">Annullér</span>
            </>
          ) : (
            <>
              <span tw="text-lg">Redigér</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">
        For mere end {days} {days === 1 ? `dag` : `dage`} siden
      </span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceUpdateCriteria: React.FC<IDeviceUpdateCriteriaProps> = ({
  criterium,
  onSave,
  onCancel,
  ...props
}) => {
  const [selectedDuration, setSelectedDuration] = useState<number | null>(
    durationOptions.find(
      (duration) => duration.value === criterium.duration / (3600 * 24)
    )?.value ?? null
  )
  const onSaveHandler = () => {
    if (onSave && selectedDuration)
      onSave({
        ...criterium,
        duration: selectedDuration * 3600 * 24,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>Sidst opdateret</CriteriumTypeTitle>
      <p>
        Angiv hvor længe der skal gå, fra enheden sidst har sendt opdateringer
        til alarmen udløses
      </p>
      <FormField label="Vælg varighed" tw="mt-4">
        <Select
          components={{ Option: DurationOption }}
          options={durationOptions}
          value={durationOptions.find((opt) => opt.value === selectedDuration)}
          placeholder="Angiv varighed"
          onChange={(opt) => setSelectedDuration(opt.value)}
          style={{ zIndex: 600 }}
        />
      </FormField>
      <p>Eller angiv selv hvor mange dage der skal gå</p>
      <FormField label="Indtast varighed (dage)" tw="mt-4">
        <Input
          type="number"
          min="1"
          value={selectedDuration}
          onChange={(e) => setSelectedDuration(e.target.value)}
        />
      </FormField>
      {onSave && onCancel && (
        <EditorButtonGroup onSave={onSaveHandler} onCancel={onCancelHandler} />
      )}
    </>
  )
}
