export const DKPolygon = {
  coordinates: [
    [
      [10.4723096, 57.9391398],
      [10.2950263, 57.8887771],
      [10.0918221, 57.7992474],
      [9.8467112, 57.7833042],
      [9.6768522, 57.7270807],
      [9.5204902, 57.6197381],
      [9.3579054, 57.4639848],
      [9.2608738, 57.3571711],
      [8.9796495, 57.3636984],
      [8.7421131, 57.326139],
      [8.4755659, 57.3251311],
      [8.3152771, 57.2580892],
      [8.1079745, 57.0987854],
      [7.9365706, 56.9291354],
      [7.7919674, 56.6656255],
      [7.7594161, 56.3507354],
      [7.7318645, 56.1073263],
      [7.764523, 55.9397551],
      [7.8194547, 55.8504331],
      [7.8230381, 55.7497844],
      [7.7351475, 55.6352275],
      [7.718668, 55.5389888],
      [7.784586, 55.4425139],
      [7.8999424, 55.389508],
      [7.9713535, 55.3582949],
      [8.053751, 55.298922],
      [8.053751, 55.2081286],
      [8.053751, 55.0919874],
      [8.2844639, 55.0605398],
      [8.3174229, 55.0856998],
      [8.3833408, 55.0605398],
      [8.4822178, 55.0448068],
      [8.5371494, 54.9912681],
      [8.5756016, 54.9124049],
      [8.657999, 54.8997724],
      [8.7623692, 54.8871359],
      [8.8337803, 54.9029309],
      [8.9161777, 54.8997724],
      [9.0205479, 54.8808162],
      [9.157877, 54.8618512],
      [9.2457676, 54.8492028],
      [9.2677402, 54.8270587],
      [9.3116856, 54.8080683],
      [9.3776035, 54.8049024],
      [9.4050694, 54.8302229],
      [9.460001, 54.8333868],
      [9.5643711, 54.8650126],
      [9.6357822, 54.8492028],
      [9.7676182, 54.8207295],
      [9.9049473, 54.8397139],
      [10.1643276, 54.7455696],
      [10.2082729, 54.8500676],
      [10.2577114, 54.843742],
      [10.4334927, 54.6376222],
      [10.7026577, 54.5708053],
      [10.8235073, 54.6280837],
      [10.7575893, 54.6821059],
      [10.8344936, 54.7392277],
      [10.9113979, 54.7582504],
      [10.9113979, 54.7106768],
      [10.9773159, 54.6694013],
      [11.2190151, 54.6376222],
      [11.3947964, 54.5708053],
      [11.8012905, 54.4496197],
      [12.1418667, 54.462393],
      [12.2242641, 54.6249037],
      [12.4714565, 54.7106768],
      [12.7351284, 54.8089339],
      [12.8724575, 54.859554],
      [13.4767056, 54.9383975],
      [13.8008022, 54.9352418],
      [14.3006802, 54.9636343],
      [14.4874477, 54.9131449],
      [14.7181606, 54.8467814],
      [14.9763393, 54.7929784],
      [15.2180386, 54.7898113],
      [15.3553677, 54.8531064],
      [15.4652309, 54.9352418],
      [15.4981899, 55.0518382],
      [15.4872036, 55.1837807],
      [15.5366421, 55.2902723],
      [15.5366421, 55.3808781],
      [15.4652309, 55.4650491],
      [15.3498745, 55.4930663],
      [15.2564907, 55.5179537],
      [15.1356411, 55.5241731],
      [14.9873257, 55.5086228],
      [14.9378872, 55.486842],
      [14.8335171, 55.4930663],
      [14.7456264, 55.4214273],
      [14.6247768, 55.3340389],
      [14.5313931, 55.2652411],
      [14.4160366, 55.2057287],
      [14.3633151, 55.1577262],
      [14.1435885, 55.129471],
      [13.9073825, 55.1263303],
      [13.6931491, 55.1169068],
      [13.4789157, 55.1200482],
      [13.1328464, 55.1043385],
      [12.9625583, 55.1169068],
      [12.8472018, 55.107481],
      [12.7349782, 55.1382784],
      [12.5982821, 55.2264532],
      [12.5900424, 55.3203304],
      [12.6367342, 55.3390792],
      [12.6147616, 55.431132],
      [12.7191317, 55.4918628],
      [12.7273715, 55.5400694],
      [12.8839266, 55.6145953],
      [12.9004061, 55.640956],
      [12.7081454, 55.8326982],
      [12.6545334, 55.9315161],
      [12.6517868, 56.0452045],
      [12.5501633, 56.1126447],
      [12.3633957, 56.2135837],
      [12.3407578, 56.1847438],
      [12.0276475, 56.2809197],
      [11.9946885, 56.3646856],
      [11.8051744, 56.3935802],
      [11.7172837, 56.4027002],
      [11.6019273, 56.4011804],
      [11.5140367, 56.3874989],
      [11.2531114, 56.1342695],
      [11.1707139, 56.1097732],
      [10.7971787, 55.7667301],
      [10.7834458, 55.7373616],
      [10.7944322, 55.7126132],
      [10.7074857, 55.6635411],
      [10.7047391, 55.7594746],
      [10.7239652, 55.7903706],
      [10.7376981, 55.8289562],
      [10.8777738, 55.9075524],
      [10.8942533, 55.9352542],
      [11.0617948, 56.1025817],
      [11.272155, 56.319363],
      [11.305114, 56.3650303],
      [11.3353264, 56.4303914],
      [11.3133538, 56.4895754],
      [11.272155, 56.5471532],
      [11.3490593, 56.5107985],
      [11.403991, 56.5062517],
      [11.5303338, 56.4819929],
      [11.6786492, 56.4910917],
      [11.7775261, 56.5183753],
      [11.8736565, 56.5653175],
      [11.9093621, 56.5985961],
      [11.8341959, 56.7662728],
      [11.7143548, 56.9342694],
      [11.6260779, 56.9355162],
      [11.5495598, 56.9252771],
      [11.4287102, 56.9222791],
      [11.3353264, 56.8937869],
      [11.2584221, 56.8652729],
      [11.1925042, 56.8096833],
      [11.1595452, 56.7705709],
      [11.154052, 56.7284042],
      [11.1622918, 56.6665747],
      [11.2172234, 56.5940598],
      [11.0414422, 56.6907154],
      [10.9150994, 56.729911],
      [10.7255852, 56.7359374],
      [10.6404412, 56.856264],
      [10.7640374, 57.0420081],
      [10.9208179, 56.9980548],
      [11.0880482, 57.0021919],
      [11.2253773, 57.0261171],
      [11.280309, 57.0530146],
      [11.5137684, 57.1916749],
      [11.2225342, 57.5158229],
      [11.0247803, 57.5541552],
      [11.0302734, 57.7305521],
      [10.9533691, 57.7964802],
      [10.7281494, 57.9425567],
      [10.6237793, 57.9513024],
      [10.4837036, 57.9410989],
      [10.4723096, 57.9391398],
    ],
  ],
  type: "Polygon",
}
