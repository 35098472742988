import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledUserName = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
`

const StyledName = styled.span`
  font-size: 1.4rem;
`

const StyledCompany = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`

function UserName({ company, firstName, lastName, children, ...props }) {
  return (
    <StyledUserName {...props}>
      <StyledCompany title={company}>{company}</StyledCompany>
      <StyledName title={`${firstName} ${lastName}`}>
        {`${firstName} ${lastName}`}
      </StyledName>
    </StyledUserName>
  )
}

export default UserName

UserName.defaultProps = {}
UserName.propTypes = {
  children: PropTypes.node,
}
