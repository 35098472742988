import cogoToast from "@clevertrackdk/cogo-toast"
import { privateApi } from "utils/api"

/**
 * Create new vehicle group
 * @param {object} body
 */

const getReports = async () => {
  try {
    const response = privateApi().get("/powerbi/reports")
    return response
  } catch (e) {
    cogoToast.error(`Kunne ikke indlæse rapporter: ${e}`)
  }
}

export { getReports }
