import React, { useState, useContext, useEffect } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import Hotjar from "@hotjar/browser"
import cogoToast from "@clevertrackdk/cogo-toast"
import * as Sentry from "@sentry/browser"
import { isLoggedIn, logout } from "services/auth"
import { UserActions, UserTypes } from "app/User/actions"
import { UserContext } from "app/User/context"
import { IUser } from "app/User/types"
import { useFirestoreUser } from "services/firestore/user"
import { useFirestoreCompany } from "services/firestore/company"
import Dashboard from "app/Dashboard"
import { useDevices } from "app/Device/hooks"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import { ConversationContext } from "app/Conversations/context"
import { CompanyContext } from "app/Company/context"
import { CompanyActions, CompanyTypes } from "app/Company/actions"
import { queryResolver } from "utils/queryResolver"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { StringDictionary } from "lib/global.types"
import { useZones } from "routes/Zones/hooks"
import FEATURE, { PATH_MAP, USERRIGHTS } from "data/featureFlags"
import uniq from "lodash-es/uniq"

interface PrivateRouteProps {
  component: React.ReactNode
  feature?: string
  allowAllAuth?: boolean
  path: string
  dashboardTitle?: React.ReactNode | string
  dashboardSidebar?: React.ReactNode
  allowOverflowScroll?: boolean
  showPlatformStatus: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  feature: featureFlag,
  allowAllAuth,
  dashboardTitle,
  dashboardSidebar,
  allowOverflowScroll = false,
  showPlatformStatus = false,
  ...rest
}) => {
  const {
    dispatch,
    state: { user, userSettings },
  } = useContext(UserContext)

  const {
    state: { syncAddress },
    dispatch: deviceDispatch,
  } = useContext(DeviceContext)

  const { dispatch: conversationDispatch } = useContext(ConversationContext)

  const {
    state: { company },
    dispatch: companyDispatch,
  } = useContext(CompanyContext)

  const { getAllZones } = useZones()
  const { getFirebaseUser } = useFirestoreUser()
  const { getFirebaseCompany } = useFirestoreCompany()
  const {
    devices,
    resetDevicesAndGroups,
    populateVehiclesAndGroups,
  } = useDevices(syncAddress)
  const location = useLocation()

  // Initially, assume no user or authentication is set.
  const [loading, setLoading] = useState(false)

  async function onAttemptRenderPrivateRoute() {
    try {
      const authCheck = await isLoggedIn()
      const { user: authenticatedUser }: { user: IUser } = authCheck
      if (authenticatedUser) {
        setLoading(false)
        await getFirebaseCompany(
          authenticatedUser.company_id,
          authenticatedUser.company,
          authenticatedUser
        )
        await getFirebaseUser(authenticatedUser.id.toString())
        const u: IUser = {
          ...authenticatedUser,
          feature: authenticatedUser?.is_admin
            ? uniq([...authenticatedUser?.feature, USERRIGHTS.WRITE])
            : authenticatedUser?.feature,
        }
        dispatch(UserActions(UserTypes.SetUser, { user: u }))
        Sentry.setUser({
          id: u.id.toString(),
          email: u.email,
          username: `${u.firstName} ${u.lastName}`,
        })
        Sentry.setContext("userMeta", {
          features: u.feature,
          company: u.company,
          companyID: u.company_id,
          admin: u.is_admin,
        })
        if (Hotjar.isReady()) {
          Hotjar.identify(authenticatedUser.id.toString(), {
            Name: `${authenticatedUser.firstName} ${authenticatedUser.lastName}`,
            Company: `${authenticatedUser.company}`,
          })
          Hotjar.stateChange(location.pathname)
        }
        return true
      } else {
        await logout()
        Sentry.setUser(null)
        conversationDispatch(ConversationActions(ConversationTypes.Reset, null))
        companyDispatch(CompanyActions(CompanyTypes.ResetCompany, null))
        dispatch(UserActions(UserTypes.ResetUser, null))
        resetDevicesAndGroups()
        if (typeof window !== `undefined`) navigate("/")
        setLoading(false)
        return false
      }
    } catch (e) {
      cogoToast.error(
        "Kunne ikke forbinde til serveren. Tjek evt. din internetforbindelse."
      )
    }
  }

  const handleAlarmURLParams = () => {
    const params: StringDictionary<string> = queryResolver(location.href)
    if (params.hasOwnProperty("deviceID") && params.hasOwnProperty("alarmID")) {
      deviceDispatch(
        DeviceActions(DeviceTypes.AddDeviceAlarm, {
          alarmID: params?.alarmID,
          deviceID: +params?.deviceID,
        })
      )
    }
  }

  useEffect(() => {
    let isMounted = true
    // On the initial pass, check if authenticated is false.
    handleAlarmURLParams()
    if (isMounted) onAttemptRenderPrivateRoute()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    if (isMounted && user.id) {
      getAllZones()
      populateVehiclesAndGroups().then(() => handleAlarmURLParams())
    }
    return () => {
      isMounted = false
    }
  }, [user])

  const hasAccess = ({ is_admin, feature: userFeatures }) => {
    if (is_admin) {
      return true
    }

    // Fake population of user features, if none found.
    // if (feature.length < 1) user.feature = ["Map"]

    if (allowAllAuth) {
      return true
    } else if (!!featureFlag) {
      const hasFeature = userFeatures.some((f) => f === featureFlag)
        ? true
        : false

      if (!hasFeature && userFeatures.length > 0) {
        // Find a feature the user has access to.
        if (!!is_admin || userFeatures.includes(FEATURE.START)) {
          navigate("/app")
        } else {
          const featurePath = userFeatures.find((item) => {
            if (PATH_MAP[item]) return true
          })

          if (featurePath) navigate(`/app${PATH_MAP[featurePath]}`)
        }
      }

      if (rest.path === "/" && !hasFeature) {
        return false
      } else {
        return hasFeature
      }
    } else {
      return false
    }
  }

  // If authenticated is "false", render null initially. Otherwise render private route.
  if (user) {
    return hasAccess(user) ? (
      <Dashboard
        key="dashboard"
        user={user}
        title={dashboardTitle ?? null}
        sidebar={dashboardSidebar ?? null}
        allowOverflowScroll={allowOverflowScroll ?? null}
        showPlatformStatus={showPlatformStatus}
      >
        <Component user={user} {...rest} />
      </Dashboard>
    ) : null
  } else {
    return null
  }
}

export default PrivateRoute
