import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledNav = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 3.4rem;
  width: 3.4rem;

  &:focus {
    outline: none;
  }
`

function Nav({ children, ...props }) {
  return <StyledNav {...props}>{children}</StyledNav>
}

export default Nav

Nav.defaultProps = {
  children: null,
}
Nav.propTypes = {
  children: PropTypes.node,
}
