export type KpiDataType = "TimeSpan" | "float" | "DateTime" | "string" | "int"

export interface IKpi {
  key: string
  icon: string
  kpiTitle?: string
  unsetValue: null | string
  translationKey?: string
  unit?: string
  type?: KpiDataType
  formatDataFnc?: (...args) => any
  data?: any
  value?: any
  sortOrder?: number
}

export enum BatteryChargingStatusEnum {
  CHARGING = "battery_charging",
  NOT_CHARGING = "battery_not_charging",
  CONNECTED = "battery_charger_connected",
  DISCONNECTED = "battery_charger_disconnected",
}

export type Category = IKpi[]
