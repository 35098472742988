import translation from "data/translation.json"

const getString = (key, lang) => {
  // Search through the translation collection for a matching key.
  if (!translation.data[lang].main.entries[key]) {
    console.warn("No translation for key", key, "in lang", lang)
  }
  return translation.data[lang].main.entries[key]
    ? translation.data[lang].main.entries[key].v
    : key
}

export { getString }
