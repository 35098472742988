import React, { useReducer, createContext } from "react"
import {
  SET_VEHICLES,
  SET_TIME_LEFT,
  SET_IS_POLLING,
  SET_TOGGLED_TRACKERS,
  UPDATE_VEHICLES,
  SET_TRACKER_GROUPS,
  SET_POLLING_FREQUENCY,
  TOGGLE_TRACKER_GROUP,
  TOGGLE_TRACKER,
  TOGGLE_ALL_TRACKERS,
  TOGGLE_VISIBILITY_SETTINGS,
  RESET_GROUPS,
  SET_ANIMATING,
} from "./actions"
import intersectionBy from "lodash-es/intersectionBy"
import unionBy from "lodash-es/unionBy"
import orderBy from "lodash-es/orderBy"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

interface TrackerGroupState {
  toggledGroups: number[]
  groups: number[]
  toggledTrackers: number[]
  vehicles: any[]
  toggleAll: boolean
  isPolling: boolean
  pollingFrequency: number
  timer: number
  visibilitySetupToggled: boolean
  animating: boolean
}

const initialState: TrackerGroupState = {
  toggledGroups: [],
  groups: [],
  toggledTrackers: [],
  vehicles: [],
  toggleAll: false,
  isPolling: false,
  pollingFrequency: 20000,
  timer: 0,
  visibilitySetupToggled: false,
  animating: false,
}
const TrackerGroupContext = createContext(initialState)
const { Provider } = TrackerGroupContext

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const toggleTrackerGroup = (state, action) => {
  const newIds = state.toggledGroups.slice()
  const untoggle = state.toggledGroups.find((id) => id === action.id)
    ? true
    : false
  return untoggle
    ? newIds.filter((id) => id !== action.id)
    : [...newIds, action.id]
}

const toggleTracker = (state, action) => {
  const newTrackers = state.toggledTrackers.slice()
  const untoggle = state.toggledTrackers.find(
    (tracker) => tracker.id === action.tracker.id
  )
    ? true
    : false

  return untoggle
    ? newTrackers.filter((tracker) => tracker.id !== action.tracker.id)
    : [...newTrackers, action.tracker]
}

const updateTrackerValues = (oldValues, newValues) => {
  const updateableVehicles = intersectionBy(oldValues, newValues, "id").map(
    (vehicle) => {
      const newVehicle = newValues.find((x) => x.id === vehicle.id)
      const updatedVehicle = {
        ...vehicle,
        data: unionBy(newVehicle.values, vehicle.data, "name").sort((a, b) =>
          a.sortIndex < b.sortIndex ? -1 : 1
        ),
        heading: newVehicle.heading ?? vehicle.heading,
        speed: newVehicle.speed ?? vehicle.speed,
        position: newVehicle.position ?? vehicle.position,
        status: newVehicle.status ?? vehicle.status,
      }
      return updatedVehicle
    }
  )
  return orderBy(unionBy(updateableVehicles, oldValues, "id"), "name")
}

const TrackerGroupProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case TOGGLE_TRACKER_GROUP:
        return {
          ...state,
          toggledGroups: toggleTrackerGroup(state, action),
        }
      case TOGGLE_TRACKER:
        return {
          ...state,
          toggledTrackers: toggleTracker(state, action),
        }
      case SET_TOGGLED_TRACKERS:
        return {
          ...state,
          toggledTrackers: action.trackers,
        }
      case SET_IS_POLLING:
        return {
          ...state,
          isPolling: action.polling,
        }
      case TOGGLE_ALL_TRACKERS:
        return {
          ...state,
          toggleAll: !state.toggleAll,
        }
      case SET_TRACKER_GROUPS:
        return {
          ...state,
          groups: action.data,
        }
      case SET_VEHICLES:
        return {
          ...state,
          vehicles: orderBy(action.data, "name"),
        }
      case SET_TIME_LEFT:
        return {
          ...state,
          timer: action.timer,
        }
      case SET_ANIMATING:
        return {
          ...state,
          animating: action.animating,
        }
      case UPDATE_VEHICLES:
        const newVehiclesData = updateTrackerValues(state.vehicles, action.data)
        return {
          ...state,
          vehicles: newVehiclesData,
        }
      case SET_POLLING_FREQUENCY:
        return {
          ...state,
          pollingFrequency: action.frequency,
        }
      case TOGGLE_VISIBILITY_SETTINGS:
        return {
          ...state,
          visibilitySetupToggled: !state.visibilitySetupToggled,
        }
      case RESET_GROUPS:
        return initialState
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { TrackerGroupContext, TrackerGroupProvider }
