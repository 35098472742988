export type Dictionary<T> = {
  [key: number]: T
}

export type StringDictionary<T> = {
  [key: string]: T
}

export type UrlDictionary = {
  [index: string]: string
}

export interface IDocumentRef {
  _type: "page" | "flow" | "stories" | "product" | "article"
  slug: {
    current: string
  }
  parentSlug?: string
}

export enum WeekdayEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface IWeekday {
  short: string
  long: string
}

export type Result = { status: "OK" } | { status: "ERROR"; msg: string }

export const WeekDays: Dictionary<IWeekday> = {
  0: { short: "Søn", long: "Søndag" },
  1: { short: "Man", long: "Mandag" },
  2: { short: "Tir", long: "Tirsdag" },
  3: { short: "Ons", long: "Onsdag" },
  4: { short: "Tor", long: "Torsdag" },
  5: { short: "Fre", long: "Fredag" },
  6: { short: "Lør", long: "Lørdag" },
}

export type SocialMediaSite = "facebook" | "linkedin" | "instagram" | "twitter"

export const SocialMediaSites: StringDictionary<string> = {
  instagram: "instagram",
  facebook: "facebook",
  linkedin: "linkedin",
  twitter: "twitter",
}

export interface IAddress {
  address1: string
  address2?: string
  city: string
  country: string
  firstName: string
  lastName: string
  zip: string
}
