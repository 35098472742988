import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react"
import tw from "twin.macro"
import styled from "styled-components"
import isMobilePhone from "validator/lib/isMobilePhone"
import { uniqBy } from "lodash-es"
import { Button, TagInput } from "@clevertrack/shared"
import { ContactSearch } from "../Contact/Search"
import { ContactList } from "../Contact/List"
import {
  INewMessagePayload,
  IContact,
  PopOverRoutesEnum,
  RouteTypeEnum,
  IMessage,
  MessageStatusEnum,
  IResolvedConversation,
} from "../conversations.types"
import Panel from "app/Panel"
import { PanelHeader } from "app/Panel/Header"
import { SearchContext } from "app/Search/context"
import { setSearchQuery } from "app/Search/actions"
import PopOver from "app/PopOver"
import { ConversationContext } from "../context"
import { ConversationActions, ConversationTypes } from "../actions"
import { MessageForm } from "./Form"
import { ConversationMessages } from "./Messages"
import { CompanyContext } from "app/Company/context"
import { useConversation } from "../hooks"
import Icon, { IconSizeEnum } from "lib/Icon"
import { BaseContact, ContactPrefix, ContactHeader } from "../Contact"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { useFirestoreContact } from "services/firestore/contact"
import { v4 } from "uuid"
import { UserContext } from "app/User/context"
import { ContactItem } from "../Contact/Item"
import useOnClickOutside from "hooks/useOnClickOutside"

const StyledPanel = styled(Panel)`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  overflow-y: hidden;
`

const StyledMain = styled.main`
  ${tw`bg-white`}
  display: grid;
  grid-template-rows: auto 1fr;
`

const StyledConversation = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 20rem;
`

const InputWithPrefix = styled.div`
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: auto;
  ${tw`py-4`}

  .input_component {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > span + div {
      flex: 1 1 auto;
      max-height: 3rem;
      > div {
        display: block;
      }
    }
  }
`

interface IConversationProps {
  onClose: (...args) => void
  rootRef: React.MutableRefObject<any>
}

export const ViewConversation: React.FC<IConversationProps> = (allProps) => {
  const { onClose, rootRef, ...props } = allProps
  const {
    state: { company },
  } = useContext(CompanyContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const [showContactList, setShowContactList] = useState<boolean>(false)
  const [isGroupMessage, setIsGroupMessage] = useState(false)
  const [groupTitle, setGroupTitle] = useState(null)
  const [oldConversationMessages, setOldConversationMessages] = useState([])
  const {
    state: { toggledRoutes, selectedContacts, currentConversationID },
    dispatch,
  } = useContext(ConversationContext)
  const { createCompanyContact } = useFirestoreContact()

  const {
    state: { query },
    dispatch: searchDispatch,
  } = useContext(SearchContext)

  const conversationRootRef = useRef(null)
  const {
    onSetNavigationState,
    contactsSorted,
    conversationsSorted,
    contactsAndGroupsSorted,
    subscribeToUserConversations,
    subscribeToContacts,
  } = useConversation()
  const {
    sendMessage,
    setMessagesRead,
    getConversationMessagesByID,
  } = useFirebaseFunctions()

  const onSelectContact = (c) => {
    searchDispatch(setSearchQuery(""))
    if (c.hasOwnProperty("contact_ids")) {
      setGroupTitle(c.title)
      dispatch(
        ConversationActions(ConversationTypes.SetSelectedContacts, {
          contacts: uniqBy([...selectedContacts, ...c.contact_ids], "id"),
        })
      )
    } else {
      setGroupTitle(null)
      dispatch(
        ConversationActions(ConversationTypes.SetSelectedContacts, {
          contacts: uniqBy([...selectedContacts, c], "id"),
        })
      )
    }

    setShowContactList(false)
  }

  const onDeselectContact = (id) => {
    dispatch(
      ConversationActions(ConversationTypes.SetSelectedContacts, {
        contacts: uniqBy(
          selectedContacts.filter((x) => x.id !== id),
          "id"
        ),
      })
    )
  }

  const onCancelHandler = () => {
    searchDispatch(setSearchQuery(""))
    dispatch(
      ConversationActions(ConversationTypes.SetConversation, {
        conversation: null,
      })
    )
    setOldConversationMessages([])
    onClose()
  }

  const onFocusHandler = () => {
    setShowContactList(true)
  }

  const searchFormRef = useRef(null)
  // const [someInputWithFocus] = useChildrenInFocus(searchFormRef)

  const onClickOutside = () => {
    setShowContactList(false)
  }

  useOnClickOutside(searchFormRef, onClickOutside)

  const onBlurHandler = () => {
    if (query.length === 0) {
      // setShowContactList(false)
    }
  }

  const onKeyDownHandler = (e) => {
    /* if (e.which === 8 && selectedContacts.length > 0) {
      const lastSelectedContact = last(selectedContacts)
      onDeselectContact(lastSelectedContact.id)
    } */
  }

  const onKeyUpHandler = async (e) => {
    if (isMobilePhone(query, ["da-DK"])) {
      createAnonymousContactHandler()
    }
  }

  const onCreateGroupHandler = () => {
    dispatch(
      ConversationActions(ConversationTypes.ToggleRoute, {
        key: PopOverRoutesEnum.CreateGroup,
      })
    )
  }

  const createAnonymousContactHandler = async () => {
    // First, see if this contact already exists.
    const existingContact = contactsSorted.find(
      (contact) => contact.phone.toString() === query.toString()
    )
    if (!existingContact) {
      const anonymousContact: Partial<IContact> = {
        area_code: 45,
        phone: query,
        msisdn: +`45${query}`,
      }
      const newContactID = await createCompanyContact(anonymousContact)
      anonymousContact.id = newContactID
      const newContacts = await subscribeToContacts()
      dispatch(
        ConversationActions(ConversationTypes.SetContacts, {
          contacts: newContacts,
        })
      )
      const newConversation: IResolvedConversation = {
        id: newContactID,
        contact: anonymousContact,
        messages: [],
        last_message_timestamp: 0,
      }
      dispatch(
        ConversationActions(ConversationTypes.AddConversation, {
          newConversation,
        })
      )
      dispatch(
        ConversationActions(ConversationTypes.SetSelectedContacts, {
          contacts: [...selectedContacts, anonymousContact],
        })
      )
    } else {
      dispatch(
        ConversationActions(ConversationTypes.SetSelectedContacts, {
          contacts: [...selectedContacts, existingContact],
        })
      )
    }

    searchDispatch(setSearchQuery(""))

    // setShowContactList(false)
  }

  const onSubmitHandler = async (message) => {
    const payload: INewMessagePayload = {
      internalID: v4(),
      companyID: company.id as string,
      message,
      recipients: selectedContacts as IContact[],
    }
    if (isGroupMessage) {
      payload.isGroupMessage = isGroupMessage
      payload.sent_to_group = groupTitle
    }
    selectedContacts.map((contact) => {
      const newMessage: IMessage = {
        internal_id: payload.internalID,
        time: +new Date(),
        message,
        message_read: true,
        status: MessageStatusEnum.ENROUTE,
        sent: true,
      }

      if (isGroupMessage) {
        newMessage.sent_to_group = groupTitle
      }

      if (currentConversation) {
        dispatch(
          ConversationActions(ConversationTypes.UpdateConversationByID, {
            conversation: {
              id: contact.id,
              contact: contact as IContact,
              last_message_timestamp: newMessage.time,
              messages: currentConversation
                ? [...currentConversation?.messages, newMessage]
                : [newMessage],
            },
          })
        )
      } else {
        dispatch(
          ConversationActions(ConversationTypes.AddConversation, {
            newConversation: {
              id: contact.id,
              contact: contact as IContact,
              last_message_timestamp: newMessage.time,
              messages: [newMessage],
            },
          })
        )
      }
    })
    if (selectedContacts.length === 1) {
      dispatch(
        ConversationActions(ConversationTypes.SetConversation, {
          conversation: selectedContacts[0].id,
        })
      )
      onSetNavigationState([
        RouteTypeEnum.Conversations,
        PopOverRoutesEnum.ViewConversation,
      ])
      await subscribeToUserConversations(selectedContacts[0].id)
    } else {
      dispatch(
        ConversationActions(ConversationTypes.SetConversation, {
          conversation: null,
        })
      )
      dispatch(
        ConversationActions(ConversationTypes.SetSelectedContacts, {
          contacts: [],
        })
      )
      onSetNavigationState([RouteTypeEnum.Conversations])
      await subscribeToUserConversations()
    }
    try {
      const res = await sendMessage(payload)
      if (res.data) {
        if (company && user && currentConversationID) {
          dispatch(
            ConversationActions(ConversationTypes.SetMessage, {
              message: "",
            })
          )
          setMessagesRead({
            companyID: company.id as string,
            contactID: currentConversationID,
            userID: user.id,
          })
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const { currentConversation, currentConversationMessages } = useMemo(() => {
    const currConvo = conversationsSorted.find(
      (x) => x.id === currentConversationID
    )
    if (currConvo) {
      onSelectContact(currConvo.contact)
      return {
        currentConversation: currConvo,
        currentConversationMessages: uniqBy(
          [...oldConversationMessages, ...currConvo.messages],
          "id"
        ),
      }
    }
    return { currentConversation: null, currentConversationMessages: [] }
  }, [
    oldConversationMessages,
    conversationsSorted,
    currentConversationID,
    toggledRoutes,
  ])

  /*   useEffect(() => {
    const conversation = conversationsSorted.find(
      (x) => x.id === currentConversationID
    )
    if (conversation) {
      setCurrentConversation(conversation)
      onSelectContact(conversation.contact)
    }
  }, [conversationsSorted.length, currentConversationID]) */

  useEffect(() => {
    if (selectedContacts.length === 1) {
      setIsGroupMessage(false)
      dispatch(
        ConversationActions(ConversationTypes.SetConversation, {
          conversation: selectedContacts[0].id,
        })
      )
    } else if (selectedContacts.length > 1) {
      setIsGroupMessage(true)
      dispatch(
        ConversationActions(ConversationTypes.SetConversation, {
          conversation: null,
        })
      )
    }
  }, [selectedContacts])

  useEffect(() => {
    if (toggledRoutes.includes(PopOverRoutesEnum.NewMessage)) {
      setIsGroupMessage(false)
      setGroupTitle(null)
    }
  }, [toggledRoutes])

  useEffect(() => {
    const latestMessage =
      currentConversation?.messages[currentConversation?.messages.length - 1]
    if (
      user &&
      currentConversation &&
      currentConversation.messages.length > 0 &&
      latestMessage &&
      currentConversation?.contact?.last_read_by &&
      currentConversation?.contact?.last_read_by[user.id] < latestMessage.time
    ) {
      setMessagesRead({
        companyID: company.id as string,
        contactID: currentConversationID,
        userID: user.id,
      })
    }
  }, [currentConversation?.messages])

  /* useEffect(() => {
    if (!subscriptionSet) {
      subscribeToUserConversations(currentConversation.id)
      setSubscriptionSet(true)
    }
  }, [currentConversation]) */

  useEffect(() => {
    if (currentConversationID) {
      getConversationMessagesByID({
        companyID: company.id,
        contactID: currentConversationID,
      }).then((res) => {
        setOldConversationMessages(res.data)
        dispatch(
          ConversationActions(
            ConversationTypes.SetConversationUnreadCountByID,
            {
              unreadMap: [
                {
                  id: currentConversationID,
                  unread_count: 0,
                },
              ],
            }
          )
        )
      })
    }
    return () => {
      dispatch(
        ConversationActions(ConversationTypes.SetMessage, {
          message: "",
        })
      )
    }
  }, [currentConversationID])

  const renderCancel = () => {
    return (
      <Button
        variant="transparent"
        tw="p-2 px-4 m-0 font-normal text-brand-red-300 cursor-pointer"
        type="button"
        onClick={onCancelHandler}
        tabIndex="-1"
      >
        Annuller
      </Button>
    )
  }

  let title = "Ny besked"
  let contactPrefix = " "
  let phone = ""

  if (currentConversation && currentConversation.contact) {
    contactPrefix = [
      currentConversation?.contact?.first_name,
      currentConversation?.contact?.last_name
        ? `${currentConversation?.contact?.last_name}`
        : "",
    ].join(" ")

    phone = [
      `+${currentConversation?.contact?.area_code}`,
      currentConversation?.contact?.phone,
    ].join(" ")

    title = contactPrefix === " " ? phone : contactPrefix
  }

  return useMemo(
    () => (
      <StyledPanel tw="pt-6">
        <PanelHeader
          tw="px-6 pb-6 border-0 border-b border-solid border-gray-200 items-center relative"
          title={
            contactPrefix !== " " ? (
              <>
                <span tw="-mt-4 block truncate">{title}</span>
                <span tw="block text-lg font-normal text-brand-gray-dark opacity-60 absolute left-0 right-0 bottom-2">
                  {phone}
                </span>
              </>
            ) : (
              title
            )
          }
          confirmComponent={renderCancel()}
          cancelComponent={null}
        />
        <StyledMain ref={searchFormRef}>
          <header>
            {toggledRoutes.includes(PopOverRoutesEnum.NewMessage) ? (
              <>
                <InputWithPrefix tw="border-0 border-b border-solid border-gray-200 px-6 items-center flex-wrap">
                  <span tw="text-gray-400 self-start">Til:</span>
                  <div className="input_component">
                    <span className="inputs" tw="flex items-center">
                      <TagInput
                        tw="top-auto"
                        tagMap={selectedContacts.map((x) => ({
                          value: x.id,
                          label: x.first_name ?? `+${x.area_code} ${x.phone}`,
                        }))}
                        show
                        onRemoveTag={onDeselectContact}
                      />
                    </span>
                    <ContactSearch
                      placeholder=""
                      withPhoneBorder={false}
                      onFocus={onFocusHandler}
                      onBlur={onBlurHandler}
                      onKeyUp={onKeyUpHandler}
                      onKeyDown={onKeyDownHandler}
                      hideSearchIcon={true}
                      noPad
                      tw="p-0 m-0 mt-0"
                    />
                  </div>
                </InputWithPrefix>
                {selectedContacts.length > 1 && (
                  <BaseContact
                    tw="border-0 border-b border-solid border-gray-200"
                    onClick={onCreateGroupHandler}
                    prefix={
                      <ContactPrefix>
                        <Icon size={IconSizeEnum.MD} icon="groups" />
                      </ContactPrefix>
                    }
                  >
                    <ContactHeader
                      name={<span tw="font-normal">+ Opret ny gruppe</span>}
                    />
                  </BaseContact>
                )}
              </>
            ) : null}
          </header>
          <section tw="relative" ref={conversationRootRef}>
            {conversationRootRef && conversationRootRef.current && (
              <PopOver
                selector={conversationRootRef.current}
                fromBottom
                show={showContactList}
                zIndex={200}
                tw="absolute bg-white"
              >
                <ContactList
                  // onlySearchResults
                  hideCreateButtons
                  onSearchResultSelect={onSelectContact}
                  tw="bg-white"
                >
                  {contactsAndGroupsSorted.map((contact) => {
                    return (
                      <ContactItem
                        key={contact.id}
                        contact={contact}
                        onSelect={onSelectContact}
                      />
                    )
                  })}
                </ContactList>
                <Button
                  variant="default"
                  size="sm"
                  tw="mx-auto block mt-8"
                  onClick={() => setShowContactList(false)}
                >
                  Luk
                </Button>
              </PopOver>
            )}
            <StyledConversation>
              {currentConversation &&
              currentConversation?.messages.length > 0 ? (
                <ConversationMessages
                  messages={currentConversationMessages}
                  contact={currentConversation.contact}
                />
              ) : (
                <Panel tw="flex flex-col items-center justify-center bg-white text-brand-gray-light">
                  <h4>Ingen beskeder</h4>
                </Panel>
              )}
              <MessageForm
                key="messageForm"
                onSubmit={onSubmitHandler}
                isGroupMessage={selectedContacts.length > 1}
              />
            </StyledConversation>
          </section>
        </StyledMain>
      </StyledPanel>
    ),
    [
      currentConversation,
      currentConversationMessages,
      currentConversationID,
      toggledRoutes,
      selectedContacts.length,
      contactsAndGroupsSorted.length,
      query,
      showContactList,
      conversationRootRef.current,
    ]
  )
}
