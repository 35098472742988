import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react"
import { useParams } from "@reach/router"
import Container from "app/Container"
import tw from "twin.macro"
import styled, { createGlobalStyle } from "styled-components"
import Icon from "lib/Icon"
import { Button, ButtonGroup, Logo, Responsive } from "@clevertrack/shared"
import PopOver from "app/PopOver"

import { HistoryMap } from "./Map"
import { HistoryLog } from "./Log"
import { HistoryFilters } from "./Filters"
import { HistoryContext } from "./context"
import { getVehicleHistoryById } from "services/history"
import { HistoryActions, HistoryTypes } from "./actions"
import { HistoryLogViewSettings, ITrackerHistory } from "./history.types"
import { DeviceContext } from "app/Device/context"
import { CompanyContext } from "app/Company/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { useUser } from "app/User/hooks"
import { UserVisibilitySettings } from "app/User/types"
import Checkbox from "lib/Checkbox"
import { useHistory } from "./hooks"

const LocalPrintStyle = createGlobalStyle`
  @media print {
    @page {
      counter-increment: page;
      @bottom-right {
        content: counter(page) " af " counter(pages);
      }
    }
  }
`

const StyledHistory = styled(Container)`
  ${tw`w-full h-full relative z-50 bg-white`}

  .ui {
    position: relative;
    height: 100%;

    &.empty {
      display: grid;
      grid-template-rows: 1fr 2fr;
    }
  }

  .log {
    padding: 1rem 1.2rem;
    position: relative;
  }

  ${(props) => props.theme.media.desktop_up`
    background: ${props.theme.colors.grayLighter}8C;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    max-height: calc(100vh - 8rem);
    overflow: hidden;

    .ui {
      z-index: 1000;
      overflow-y: scroll;
    }

    .map {
      position: relative;
      z-index: 500;
    }

    .log {
      &.empty {
        display: block;
      }
    }
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    grid-template-columns: 1fr 1.25fr;
  `}

  ${(props) => props.theme.media.xxxl_desktop_up`
    grid-template-columns: 1fr 1.75fr;
  `}

  .header {
    padding: 8rem 2rem 3rem;
    background: ${(props) => props.theme.colors.white};
    border-bottom: 2px solid ${(props) => props.theme.colors.grayLighter};
    position: relative;

    ${tw`print:(py-0)`}

    h2 {
      display: none;
    }

    p {
      opacity: 0.6;
      line-height: 2.2rem;
      font-size: 2rem;
      margin-top: 1.5rem;

      ${(props) => props.theme.media.tablet_landscape_up`
        max-width: 30rem;
        font-size: 1.6rem;
        margin-top: 1rem;
        padding-bottom: 0;
        padding-right: 3.2rem;
      `}
    }

    ${(props) => props.theme.media.tablet_landscape_up`
      display: grid;
      grid-template-columns: 2fr 3fr;
      padding: 3rem 2rem 3rem;

      h2 {
        display: block;
        margin: 0;
        font-size: 230%;
      }
    `}
    ${(props) => props.theme.media.big_desktop_up`
      grid-template-columns: 1fr 1fr;
    `}
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 10000;
  left: 1rem;
  bottom: 8.2rem;
  background: white;
  color: ${(props) => props.theme.colors.secondary};
  box-shadow: ${(props) => props.theme.mapButtonShadow};
`

const StyledPopOver = styled(PopOver)`
  ${tw`print:hidden`}
  top: 0;
  bottom: 6.55rem;
  z-index: 800;
  ${(props) => props.theme.media.tablet_landscape_up`
    bottom: 0;
 `}
`

export const BackButton = ({ onClick, ...props }) => {
  return (
    <StyledButton onClick={onClick} icon="left" size="sm" invert>
      <Icon size="sm" icon="long-arrow-left" />
      <span>Tilbage</span>
    </StyledButton>
  )
}

const getVehicleHistory = async (
  toggledTrackers,
  filters,
  splitTripsOnDateChange = false
) => {
  try {
    const promises = toggledTrackers.map((tracker) =>
      getVehicleHistoryById(
        tracker.id,
        filters.date.start,
        filters.date.stop,
        splitTripsOnDateChange
      )
    )
    const data: ITrackerHistory[] = await Promise.all(promises)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const History: React.FC = ({ ...props }) => {
  const {
    state: { toggledDevices },
    dispatch: deviceDispatch,
  } = useContext(DeviceContext)
  const {
    state: { company },
  } = useContext(CompanyContext)
  const params = useParams()
  const {
    userSettings,
    toggleUserVisibilitySetting,
    getUserVisibilitySetting,
  } = useUser()

  const {
    state: { filters, routes, toggleMap, history, logViewSettings },
    dispatch,
  } = useContext(HistoryContext)

  const { onShowZeroDistanceTripsChangeHandler } = useHistory()

  const [toggleViewSettings, setToggleViewSettings] = useState(false)

  const [filtersConfirmed, setFiltersConfirmed] = useState(false)
  const settingsContainerRef = useRef(null)

  const currentTracker = useMemo(() => {
    let parameterizedTracker
    if (params.trackerId) {
      parameterizedTracker = toggledDevices.find(
        (x) => x.id.toString() === params.trackerId
      )
      if (parameterizedTracker && toggledDevices.length > 1) {
        deviceDispatch(
          DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
            groupID: parameterizedTracker.group[0],
            deviceID: parameterizedTracker.id,
            untoggleRest: true,
          })
        )
      }
    }
    return parameterizedTracker ?? toggledDevices[0] ?? null
  }, [toggledDevices, params])

  const getHistory = useCallback(async () => {
    if (toggledDevices.length > 0 || filtersConfirmed) {
      dispatch(HistoryActions(HistoryTypes.LoadingHistoryLogs, true))
      const data = !(logViewSettings as HistoryLogViewSettings)
        .splitTripsOnDateChange
        ? await getVehicleHistory(
            toggledDevices.filter((x) => {
              return x.id === currentTracker.id
            }),
            filters,
            true
          )
        : await getVehicleHistory(
            toggledDevices.filter((x) => {
              return x.id === currentTracker.id
            }),
            filters,
            false
          )
      if (data) {
        dispatch(
          HistoryActions(HistoryTypes.SetTrackerHistory, { history: data })
        )
        setFiltersConfirmed(false)
        dispatch(HistoryActions(HistoryTypes.LoadingHistoryLogs, false))
      }
    }
  }, [
    toggledDevices,
    currentTracker,
    logViewSettings as HistoryLogViewSettings,
    filters,
  ])

  useEffect(() => {
    return () => {
      dispatch(HistoryActions(HistoryTypes.Reset, null))
    }
  }, [])

  useEffect(() => {
    const userLogViewSettings: HistoryLogViewSettings = {
      minDurationTreshold: 0,
      groupTripsPerDay: true,
      splitTripsOnDateChange:
        getUserVisibilitySetting(
          UserVisibilitySettings.HistorySplitTripsOnDateChange
        ) ?? false,
    }
    dispatch(
      HistoryActions(HistoryTypes.SetLogViewSettings, {
        viewSettings: userLogViewSettings,
      })
    )
  }, [])

  useEffect(() => {
    dispatch(HistoryActions(HistoryTypes.Reset, null))
    getHistory()
  }, [
    currentTracker,
    (logViewSettings as HistoryLogViewSettings).splitTripsOnDateChange,
    filters,
  ])

  useEffect(() => {
    if (toggledDevices.length === 0) {
      dispatch(HistoryActions(HistoryTypes.Reset, null))
    }
  }, [toggledDevices])

  const onConfirmFiltersHandler = () => {
    dispatch(HistoryActions(HistoryTypes.Reset, null))
  }

  const titlePlaceholder = "Vælg enhed"

  const showSeconds = getUserVisibilitySetting(
    UserVisibilitySettings.HistorySeconds
  )
  const showDefaultExpanded = getUserVisibilitySetting(
    UserVisibilitySettings.HistoryDefaultExpandedStatusChanges
  )

  const showSplitTripsOnDateChange = getUserVisibilitySetting(
    UserVisibilitySettings.HistorySplitTripsOnDateChange
  )

  const showZeroDistanceTrips = getUserVisibilitySetting(
    UserVisibilitySettings.HistoryShowZeroDistanceTrips
  )

  const onShowSplitTripsOnDateChangeHandler = async (checked) => {
    await toggleUserVisibilitySetting(
      UserVisibilitySettings.HistorySplitTripsOnDateChange
    )
    const userLogViewSettings: HistoryLogViewSettings = {
      splitTripsOnDateChange: checked,
    }
    dispatch(HistoryActions(HistoryTypes.SetTrackerHistory, { history: [] }))
    dispatch(
      HistoryActions(HistoryTypes.SetLogViewSettings, {
        viewSettings: userLogViewSettings,
      })
    )
  }

  const onToggleViewSettingsHandler = () => {
    setToggleViewSettings((prev) => !prev)
  }

  const renderOffCanvasLogViewSettings = () => {
    // Mobile view vs. desktop view
    return (
      <div>
        <div tw="px-8">
          <h3 tw="mt-6">
            Indstillinger
            <small tw="block text-xl text-gray-400 font-normal">
              For datavisning
            </small>
          </h3>
          <Checkbox
            onChange={() =>
              toggleUserVisibilitySetting(UserVisibilitySettings.HistorySeconds)
            }
            checked={showSeconds}
            size="sm"
            tw="flex items-center"
          >
            <span tw="text-xl">Vis sekunder</span>
          </Checkbox>
          <Checkbox
            onChange={() =>
              toggleUserVisibilitySetting(
                UserVisibilitySettings.HistoryDefaultExpandedStatusChanges
              )
            }
            checked={showDefaultExpanded ?? false}
            size="sm"
            tw="flex items-center"
          >
            <span tw="text-xl">Skjul statusskift</span>
          </Checkbox>
          <Checkbox
            onChange={onShowSplitTripsOnDateChangeHandler}
            checked={showSplitTripsOnDateChange ?? false}
            size="sm"
            tw="flex items-center"
          >
            <span tw="text-xl">Opdel ikke ture ved datoskift</span>
          </Checkbox>
          <Checkbox
            onChange={onShowZeroDistanceTripsChangeHandler}
            checked={showZeroDistanceTrips ?? false}
            size="sm"
            tw="flex items-baseline"
          >
            <span tw="text-xl">Vis ture under 1 minuts varighed</span>
          </Checkbox>
          <ButtonGroup position="center" tw="mt-8">
            <Button
              tw="mt-4 px-8"
              onClick={onToggleViewSettingsHandler}
              variant="primary"
            >
              Gem og luk
            </Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }

  const renderOffCanvasLogViewSettingsContainer = (mountInSelector) => {
    return (
      <Responsive
        phone={
          <PopOver
            show={toggleViewSettings}
            fromRight
            zindex={500}
            tw="h-full w-full pt-8 md:(w-1/2 pt-0) bg-white shadow-card"
          >
            {renderOffCanvasLogViewSettings()}
          </PopOver>
        }
        tabletLandscape={
          <PopOver
            show={toggleViewSettings}
            fromLeft
            selector={mountInSelector}
            zindex={10000}
            tw="absolute h-full w-full pt-8 md:(w-1/2 pt-0) bg-white shadow-card"
          >
            {renderOffCanvasLogViewSettings()}
          </PopOver>
        }
      />
    )
  }

  return (
    <>
      <LocalPrintStyle />
      <StyledHistory {...props}>
        <div tw="hidden print:(block px-4 mt-8 mb-4 text-right border-solid border-0 border-b border-brand-gray-lighter pb-4)">
          <span>
            <Logo colored />
            <span tw="block font-bold text-2xl text-left">{company?.name}</span>
          </span>
          <span tw="block text-left">Historik for: {currentTracker?.name}</span>
        </div>
        <div id="___ui" className="ui">
          <div className="header">
            <div tw="mb-8 md:mb-0 print:(hidden)">
              <h2>Historik</h2>
              <p tw="hidden md:block">
                {!!currentTracker ? currentTracker?.name : titlePlaceholder}
              </p>
              {toggledDevices[0] && (
                <p tw="block md:hidden">{currentTracker?.name}</p>
              )}
            </div>
            <HistoryFilters onConfirmFilters={onConfirmFiltersHandler} />
          </div>
          <div className="log">
            <div tw="mr-2 mb-6 flex items-center justify-end cursor-pointer text-xl hover:(text-brand-500 transition) print:(hidden)">
              <span tw="block ml-auto" onClick={onToggleViewSettingsHandler}>
                <Icon icon="cog" size="sm" /> Visningsindstillinger
              </span>
            </div>
            <HistoryLog />
          </div>
        </div>
        <Responsive
          phone={
            <>
              {renderOffCanvasLogViewSettingsContainer()}
              <StyledPopOver show={toggleMap} fromRight>
                <HistoryMap />
              </StyledPopOver>
            </>
          }
          desktop={
            <div id="___map" className="map" tw="relative overflow-hidden">
              <HistoryMap />
              {renderOffCanvasLogViewSettingsContainer("#___map")}
            </div>
          }
        />
      </StyledHistory>
    </>
  )
}
