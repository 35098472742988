import React, { useState, useRef } from "react"
import styled from "styled-components"
import "twin.macro"
import TextareaAutosize from "react-textarea-autosize"
import Icon from "lib/Icon"
import Timeline from "app/Timeline"
import Event from "app/Timeline/Event"
import { formatDateStylish, getIntervalValue } from "../helper"
import { formatFloat } from "utils"
import { worthy } from "utils/worthy"
import DateFilter from "app/History/DateFilter"
import {
  EditableCalibration,
  EditableButtonGroup,
  DeleteButtonGroup,
} from "../EditService"
import { useUser } from "app/User/hooks"
import {
  DeviceDocumentUpload,
  DeviceFilePathEnum,
} from "app/DocumentUpload/DeviceDocumentUpload"

const LogItem = styled.div`
  position: relative;
  .p-style {
    margin: 0;
    color: ${(props) => props.theme.colors.gray};
    font-size: 85%;
    line-height: 1.4;
  }

  &.reparation {
    margin-top: 1.6rem;
  }
`

const LogHeader = styled.div`
  padding-bottom: 1.25rem;
`

const LogFooter = styled.div`
  padding-top: 0.8rem;
`

const LogDetail = styled.div`
  color: ${(props) => props.theme.colors.blackOpaque};
  font-size: 80%;
  font-weight: bold;
  position: relative;
`

const Calibration = styled(LogDetail)`
  ${(props) =>
    !!props.edit === true
      ? `cursor: pointer;
      text-decoration: underline;
      text-decoration-style: dotted;
      &:focus > .link {
        color: ${props.theme.colors.primary};
      }
      &:hover > .link {
        color: ${props.theme.colors.primary};
      }`
      : ``}
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    opacity: 1;
  }

  transform: scale(1.075) translateX(0.5rem);
`

const NoteFieldLineHeight = 1.6

const NoteField = styled.div`
  span {
    font-size: 80%;
  }

  textarea {
    font-size: 1.5rem;
    resize: vertical;
    overflow: hidden;
    // border: none !important;
    border: 1px solid ${(props) => props.theme.colors.grayLight};
    outline: none !important;
    // background: transparent;
    padding: 10px;
    margin: 0;
    // min-height: ${NoteFieldLineHeight}rem;
    line-height: 1.2;
    color: ${(props) => props.theme.colors.grayDark};
    display: block;
    width: 100%;
    outline: none;
    cursor: ${(props) => (props.contentEditable ? "pointer" : "auto")};

    &:read-only {
      border: none;
      background: transparent;
      padding: 0;
      resize: none;
    }

    &:focus {
      cursor: auto;
    }

    &::placeholder {
      opacity: 0.7;
    }

    &::selection {
      background: ${(props) =>
        props.contentEditable && props.theme.colors.primary};
      color: ${(props) => props.contentEditable && "white"};
    }
  }
`

function EditTimeline({ ...props }) {
  const {
    serviceEntry,
    registerService,
    currentInterval,
    data,
    user,
    cancelRegisterService,
    onSubmitHandler,
    editMode,
    editHandler,
    onUpdateHandler,
    setDate,
    onEntryChange,
    onIntervalChange,
    formatLang,
    userFullname,
    setDisplayNoUI,
    fetch,
  } = props
  const { canWrite } = useUser()

  const TimelineRef = useRef(null)

  const modifyNoteFields = (TimelineObject, callback) => {
    if (!!TimelineObject?.current) {
      const { current: timeline } = TimelineObject
      const noteFields = timeline.getElementsByTagName("TEXTAREA")
      let i = 0
      for (const field of noteFields) {
        callback(field, i)
        i++
      }
    }
  }

  /* const adjustNoteFieldHeight = (element) =>
    (element.style.height = element.scrollHeight + "px") */

  const onEntryChangeHandler = (e, k) => {
    const key = k
      ? k
      : currentInterval.unit === "km"
      ? "realMileage"
      : "realHours"
    onEntryChange({
      [key]: worthy(e.target.value) ? Number(e.target.value) : null,
    })
  }

  /* useEffect(() => {
    // modifyNoteFields(TimelineRef, adjustNoteFieldHeight)
    if (allowStatedHeight) {
      modifyNoteFields(TimelineRef, (element, i) => {
        return (element.style.height = textareaHeights[i] + "px")
      })
    }
  }) */

  const [textareaHeights, setTextareaHeights] = useState([])
  const [allowStatedHeight, setAllowStatedHeight] = useState(false)

  const RenderNoteField = (editable, entry) => {
    if (editable) {
      return (
        <NoteField>
          <TextareaAutosize
            placeholder="+ Tilføj note f.eks. udført arbejde"
            spellCheck={false}
            tabIndex={0}
            onChange={(e) => {
              onEntryChange({
                note: e.target.value,
              })
            }}
            defaultValue={entry.note}
          />
        </NoteField>
      )
    } else {
      return (
        <NoteField>
          <TextareaAutosize
            readOnly
            placeholder="Ingen note skrevet"
            spellCheck={false}
            tabIndex={0}
            value={entry.note}
          />
        </NoteField>
      )
    }
  }

  const RenderEmailNotificationField = (editable) => {
    if (editable) {
      return (
        <NoteField>
          <span tw="block mt-4 font-bold opacity-80">
            Besked til næste servicepåmindelse
          </span>
          <TextareaAutosize
            placeholder="+ Tilføj besked til næste servicepåmindelse"
            spellCheck={false}
            tabIndex={0}
            onChange={(e) => {
              onIntervalChange("emailMsg", e.target.value)
            }}
            defaultValue={currentInterval.emailMsg}
          />
        </NoteField>
      )
    } else {
      return null
    }
  }

  const renderEntryMileageOrHours = (entry, allowEdit) => {
    return entry.realMileage || entry.realHours ? (
      <EditableCalibration
        edit={allowEdit}
        unit={entry.interval.unit}
        initialValue={
          entry.interval.unit === "km"
            ? Math.floor(entry?.realMileage ?? data.mileage)
            : Math.floor(entry?.realHours ?? data.hours)
        }
        onChange={(e) =>
          onEntryChangeHandler(
            e,
            entry.interval.unit === "km" ? "realMileage" : "realHours"
          )
        }
        tabIndex={0}
      >
        {allowEdit && <Icon icon="edit" size="sm" />} Aflæste{" "}
        {entry.interval.unit}:{" "}
        {entry.interval.unit === "km" ? (
          <>
            {formatFloat(
              (allowEdit && entry?.realMileage) || entry.realMileage,
              formatLang
            )}
          </>
        ) : (
          <>
            {formatFloat(
              (allowEdit && entry?.realHours) || entry.realHours,
              formatLang
            )}
          </>
        )}
      </EditableCalibration>
    ) : entry.interval.unit === "km" ? (
      allowEdit && (
        <EditableCalibration
          edit={true}
          unit="km"
          initialValue={Math.floor(entry?.realMileage ?? data.mileage)}
          onChange={(e) => onEntryChangeHandler(e, "realMileage")}
          onBlur={(e) => onEntryChangeHandler(e, "realMileage")}
          tabIndex={0}
        >
          {!!entry.realMileage ? (
            <>
              <Icon icon="edit" size="sm" />
              {` ${entry.realMileage}`}
            </>
          ) : (
            <> + Tilføj aflæste </>
          )}
          {`km`}
        </EditableCalibration>
      )
    ) : (
      allowEdit && (
        <EditableCalibration
          edit={true}
          unit="timer"
          initialValue={Math.floor(entry?.realHours ?? data.hours)}
          onChange={(e) => onEntryChangeHandler(e, "realHours")}
          onBlur={(e) => onEntryChangeHandler(e, "realHours")}
          tabIndex={0}
        >
          {!!entry.realHours ? (
            <>
              <Icon icon="edit" size="sm" />
              {` ${entry.realHours} `}
            </>
          ) : (
            <> + Tilføj aflæste </>
          )}
          {` timer`}
        </EditableCalibration>
      )
    )
  }
  return (
    <div ref={TimelineRef}>
      <Timeline>
        {registerService[0] && currentInterval && (
          <Event color="gray">
            <LogItem>
              <div>{currentInterval.type} (ny registrering)</div>
              <LogHeader>
                <DateFilter
                  untoggleOnBlur
                  date={
                    serviceEntry.realDate
                      ? new Date(serviceEntry.realDate)
                      : new Date()
                  }
                  onSelectDate={setDate}
                  closeOnSelect={true}
                  inputComponent={
                    <Calibration edit={true}>
                      {!!serviceEntry.realDate ? (
                        <span className="link" tabIndex={0}>
                          <Icon icon="edit" size="sm" />
                          {` Udført d. ${formatDateStylish(
                            serviceEntry.realDate
                          )}`}
                        </span>
                      ) : (
                        <span className="link" tabIndex={0}>
                          + Tilføj udførselsdato
                        </span>
                      )}
                    </Calibration>
                  }
                />

                {currentInterval.unit !== "mdr" ? (
                  <EditableCalibration
                    edit={true}
                    unit={currentInterval.unit}
                    initialValue={
                      currentInterval.unit === "km"
                        ? Math.floor(serviceEntry?.realMileage ?? data.mileage)
                        : Math.floor(serviceEntry?.realHours ?? data.hours)
                    }
                    onChange={(e) =>
                      onEntryChangeHandler(
                        e,
                        currentInterval.unit === "km"
                          ? "realMileage"
                          : "realHours"
                      )
                    }
                    onBlur={(e) =>
                      onEntryChangeHandler(
                        e,
                        currentInterval.unit === "km"
                          ? "realMileage"
                          : "realHours"
                      )
                    }
                  >
                    {!!serviceEntry.realMileage || !!serviceEntry.realHours ? (
                      <>
                        <Icon icon="edit" size="sm" />
                        {` ${
                          currentInterval.unit === "km"
                            ? serviceEntry.realMileage
                            : serviceEntry.realHours
                        } `}
                      </>
                    ) : (
                      <> + Tilføj aflæste </>
                    )}
                    {currentInterval.unit}
                  </EditableCalibration>
                ) : (
                  <>
                    <EditableCalibration
                      edit={true}
                      unit="timer"
                      type={serviceEntry.type}
                      initialValue={Math.floor(
                        serviceEntry?.realHours ?? data.hours
                      )}
                      onChange={(e) => onEntryChangeHandler(e, "realHours")}
                      onBlur={(e) => onEntryChangeHandler(e, "realHours")}
                    >
                      {!!serviceEntry.realHours ? (
                        <>
                          <Icon icon="edit" size="sm" />
                          {` ${serviceEntry.realHours} `}
                        </>
                      ) : (
                        <> + Tilføj aflæste </>
                      )}
                      {`timer`}
                    </EditableCalibration>
                    <EditableCalibration
                      edit={true}
                      unit="km"
                      type={serviceEntry.type}
                      initialValue={Math.floor(
                        serviceEntry?.realMileage ?? data.mileage
                      )}
                      onChange={(e) => onEntryChangeHandler(e, "realMileage")}
                      onBlur={(e) => onEntryChangeHandler(e, "realMileage")}
                    >
                      {!!serviceEntry.realMileage ? (
                        <>
                          <Icon icon="edit" size="sm" />
                          {` ${serviceEntry.realMileage} `}
                        </>
                      ) : (
                        <> + Tilføj aflæste </>
                      )}
                      {`km`}
                    </EditableCalibration>
                  </>
                )}
                {currentInterval.type !== "Reparation" && (
                  <LogDetail>
                    Service v. hver: {` `}
                    {formatFloat(
                      getIntervalValue(currentInterval),
                      formatLang
                    )}{" "}
                    {` `}
                    {currentInterval.unit}
                  </LogDetail>
                )}
              </LogHeader>

              {RenderNoteField(true, serviceEntry)}
              {currentInterval &&
                currentInterval.notify &&
                currentInterval.email &&
                RenderEmailNotificationField(true)}
              <LogFooter>
                <LogDetail>
                  Registreret d. {formatDateStylish()} af{" "}
                  <span>{userFullname(user)} (dig)</span>
                </LogDetail>
              </LogFooter>
              <EditableButtonGroup
                user={null}
                onCancel={cancelRegisterService}
                onSubmit={() => {
                  const heights = []
                  modifyNoteFields(TimelineRef, (element) => {
                    heights.push(element.scrollHeight)
                  })
                  setTextareaHeights(heights)
                  setAllowStatedHeight(true)
                  return onSubmitHandler()
                }}
                setAllowStatedHeight={setAllowStatedHeight}
              />
            </LogItem>
          </Event>
        )}
        {data.log &&
          data.log
            .sort((a, b) => (a.realDate > b.realDate ? -1 : 1))
            .map((ent, i) => {
              const allowEdit = editMode[i]
              const entry = allowEdit ? serviceEntry : ent
              return (
                <Event key={`log_event_${i}`}>
                  <LogItem>
                    <div tw="flex items-start justify-between">
                      <div>
                        {entry.interval.type} {` `}
                        {canWrite && (
                          <StyledIcon
                            size="sm"
                            icon={allowEdit ? "close" : "edit"}
                            onClick={(e) =>
                              editHandler(e, i, entry.id, allowEdit)
                            }
                          />
                        )}
                      </div>
                      {allowEdit && (
                        <DeleteButtonGroup
                          user={user}
                          deleteId={entry.id}
                          fetch={fetch}
                          setDisplayNoUI={setDisplayNoUI}
                          onCancel={cancelRegisterService}
                        />
                      )}
                    </div>
                    <LogHeader>
                      <DateFilter
                        key={`log_event_${i}_date`}
                        untoggleOnBlur
                        date={new Date(entry.realDate + "Z") ?? new Date()}
                        onSelectDate={setDate}
                        closeOnSelect={true}
                        readOnly={!allowEdit}
                        inputComponent={
                          allowEdit ? (
                            <Calibration edit={true}>
                              {entry.realDate !== "0001-01-01T00:00:00" ||
                              entry.realDate !== "0001-01-01T00:00:00" ? (
                                <span className="link" tabIndex={0}>
                                  <Icon icon="edit" size="sm" />
                                  {` Udført d. ${formatDateStylish(
                                    entry.realDate + "Z"
                                  )}`}
                                </span>
                              ) : (
                                <span className="link" tabIndex={0}>
                                  + Tilføj udførselsdato
                                </span>
                              )}
                            </Calibration>
                          ) : (
                            entry.realDate !== "0001-01-01T00:00:00" && (
                              <Calibration edit={allowEdit}>
                                <span tabIndex={0}>
                                  {allowEdit && <Icon icon="edit" size="sm" />}{" "}
                                  Udført d.{" "}
                                  {formatDateStylish(entry.realDate + "Z")}
                                </span>
                              </Calibration>
                            )
                          )
                        }
                      />

                      {entry.interval.unit !== "mdr" ? (
                        renderEntryMileageOrHours(entry, allowEdit)
                      ) : (
                        <>
                          {entry.realHours ? (
                            <EditableCalibration
                              edit={allowEdit}
                              unit="timer"
                              initialValue={Math.floor(
                                entry?.realHours ?? data.hours
                              )}
                              onChange={(e) =>
                                onEntryChangeHandler(e, "realHours")
                              }
                              onBlur={(e) =>
                                onEntryChangeHandler(e, "realHours")
                              }
                              tabIndex={0}
                            >
                              {allowEdit && <Icon icon="edit" size="sm" />}{" "}
                              Aflæste timer:{" "}
                              {formatFloat(
                                (allowEdit && entry?.realHours) ||
                                  entry.realHours,
                                formatLang
                              )}
                            </EditableCalibration>
                          ) : (
                            allowEdit && (
                              <EditableCalibration
                                edit={true}
                                unit="timer"
                                initialValue={Math.floor(
                                  entry?.realHours ?? data.hours
                                )}
                                onChange={(e) =>
                                  onEntryChangeHandler(e, "realHours")
                                }
                                onBlur={(e) =>
                                  onEntryChangeHandler(e, "realHours")
                                }
                                tabIndex={0}
                              >
                                {!!entry.realHours ? (
                                  <>
                                    <Icon icon="edit" size="sm" />
                                    {` ${entry.realHours} `}
                                  </>
                                ) : (
                                  <> + Tilføj aflæste </>
                                )}
                                {`timer`}
                              </EditableCalibration>
                            )
                          )}
                          {entry.realMileage ? (
                            <EditableCalibration
                              edit={allowEdit}
                              unit="km"
                              initialValue={Math.floor(
                                entry?.realMileage ?? data.mileage
                              )}
                              onChange={(e) =>
                                onEntryChangeHandler(e, "realMileage")
                              }
                              onBlur={(e) =>
                                onEntryChangeHandler(e, "realMileage")
                              }
                              tabIndex={0}
                            >
                              {allowEdit && <Icon icon="edit" size="sm" />}{" "}
                              Aflæste km:{" "}
                              {formatFloat(
                                (allowEdit && entry?.realMileage) ||
                                  entry.realMileage,
                                formatLang
                              )}
                            </EditableCalibration>
                          ) : (
                            allowEdit && (
                              <EditableCalibration
                                edit={true}
                                unit="km"
                                initialValue={Math.floor(
                                  entry?.realMileage ?? data.mileage
                                )}
                                onChange={(e) =>
                                  onEntryChangeHandler(e, "realMileage")
                                }
                                onBlur={(e) =>
                                  onEntryChangeHandler(e, "realMileage")
                                }
                                tabIndex={0}
                              >
                                {!!entry.realMileage ? (
                                  <>
                                    <Icon icon="edit" size="sm" />
                                    {`${entry.realMileage} `}
                                  </>
                                ) : (
                                  <> + Tilføj aflæste </>
                                )}
                                {`km`}
                              </EditableCalibration>
                            )
                          )}
                        </>
                      )}
                      {entry.interval.unit &&
                        entry.interval.type !== "Reparation" && (
                          <LogDetail>
                            Service v. hver:{" "}
                            {formatFloat(entry.intervalStamp, formatLang)}{" "}
                            {entry.interval.unit}
                          </LogDetail>
                        )}
                    </LogHeader>

                    {RenderNoteField(allowEdit, entry)}
                    {allowEdit &&
                      currentInterval &&
                      currentInterval.notify &&
                      currentInterval.email &&
                      RenderEmailNotificationField(true)}
                    <DeviceDocumentUpload
                      tw="-mt-4"
                      deviceID={data.id}
                      path={DeviceFilePathEnum.VehicleServiceDocuments}
                      childPath={[entry.id]}
                      toggleUploadActionText="Tilføj bilag"
                      fileListHeadingText="Bilag"
                      offCanvasRootSelector="#editService"
                    />
                    <LogFooter>
                      <LogDetail>
                        Registreret d. {formatDateStylish(entry.date + "Z")} af{" "}
                        <span>{userFullname(entry.author)}</span>
                      </LogDetail>
                      {!!entry?.edit?.user?.lastName && (
                        <LogDetail tw="font-normal">
                          <i>
                            Sidst redigeret d.{" "}
                            {formatDateStylish(entry.edit.date + "Z")} af{" "}
                            <span>{userFullname(entry.edit.user)}</span>
                          </i>
                        </LogDetail>
                      )}
                    </LogFooter>
                    {allowEdit && (
                      <EditableButtonGroup
                        user={user}
                        deleteId={entry.id}
                        fetch={fetch}
                        setDisplayNoUI={setDisplayNoUI}
                        onCancel={(e) => editHandler(e, i, entry.id, true)}
                        onSubmit={() => onUpdateHandler(entry.id)}
                        setAllowStatedHeight={setAllowStatedHeight}
                      />
                    )}
                  </LogItem>
                </Event>
              )
            })}
      </Timeline>
    </div>
  )
}

export default EditTimeline
