import { useContext, useMemo } from "react"
import { MapContext } from "app/GoogleMap/context"
import { DKPolygon } from "data/polygons"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { DeviceContext } from "app/Device/context"

export const useTrackers = () => {
  const {
    state: { mapsInstance },
  } = useContext(MapContext)
  const {
    state: { syncAddress },
  } = useContext(DeviceContext)
  const {
    getAddressFromCoords,
    getDawaAddressFromCoords,
  } = useFirebaseFunctions()

  const isLocatedInDenmark = (coords) => {
    if (!mapsInstance) return null

    const [lat, lng] = coords
    const { maps }: { maps: typeof google.maps } = mapsInstance

    const denmark = new google.maps.Polygon({
      paths: DKPolygon.coordinates[0].map(
        ([lt, lg]) => new google.maps.LatLng(lt, lg)
      ),
    })

    const isInDenmark = maps.geometry.poly.containsLocation(
      new google.maps.LatLng(lat, lng),
      denmark
    )

    return isInDenmark
  }

  const updateAddress = async (
    position,
    deviceData,
    isInDenmark,
    updateOffline
  ) => {
    if (typeof window === "undefined") {
      return
    }
    const { speed } = deviceData
    // console.log("syncAddress", syncAddress)

    const [lng, lat] = position.geometry.coordinates
    if ((!isInDenmark || speed > 0) && !updateOffline) {
      const d = await getAddressFromCoords({ lat, lng })
      if (d.data.results) {
        const result =
          d.data.results.find((res) => res.types.includes("route")) ??
          d.data.results.find((res) => res.types.includes("street_address"))

        const {
          short_name,
          long_name,
        } = result.address_components.find((comp) =>
          comp.types.includes("route")
        )
        const city = result.address_components.find(
          (comp) =>
            comp.types.includes("postal_town") ||
            comp.types.includes("locality") ||
            comp.types.includes("sublocality") ||
            comp.types.includes("administrative_area_level_2")
        )
        const postal_code = result.address_components.find((comp) =>
          comp.types.includes("postal_code")
        )
        const country = result.address_components.find((comp) =>
          comp.types.includes("country")
        )

        const main_name =
          short_name === long_name ? long_name : `${short_name}, ${long_name}`

        return [
          main_name,
          `${postal_code.long_name} ${city.long_name}`,
          country && !isInDenmark ? country.long_name : null,
        ]
          .filter(Boolean)
          .join(", ")
      }
    } else if (speed === 0) {
      const d = await getDawaAddressFromCoords({ lat, lng })
      return d.data.betegnelse ?? "Fejl"
    }
  }

  return {
    // updateTrackerValues,
    isLocatedInDenmark,
    updateAddress,
  }
}
