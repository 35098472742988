import { useState, useRef, SetStateAction, Dispatch } from "react"

export const useFormData = <T extends object>(
  model?
): [
  T,
  (key: keyof T, value: any) => void,
  () => void,
  Dispatch<SetStateAction<T>>
] => {
  const modelRef = useRef(model)
  const [dataModel, setDataModel] = useState<T>(model)

  const updateModel = (key: keyof T, value: any) => {
    setDataModel((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const resetModel = () => {
    setDataModel(modelRef.current)
  }

  return [dataModel, updateModel, resetModel, setDataModel]
}
