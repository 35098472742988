import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledCrudList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    > div {
      display: grid;
      grid-template-columns: 1.5fr 1fr;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h4 {
          margin: 0;
        }

        &.actions {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-end;
        }
      }

      ${(props) =>
        props.selected &&
        `

      `}
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    ${
      props.enableScroll
        ? `height: 100%;
            padding-left: 2rem;
            margin-left: -2rem;
            padding-right: 2rem;
            padding-top: 1rem;
            padding-bottom: 13.2rem;
            overflow-y: scroll;
          `
        : ``
    }

  `}
`

function CrudList({ children, ...props }) {
  return <StyledCrudList {...props}>{children}</StyledCrudList>
}

export default CrudList

CrudList.defaultProps = {}
CrudList.propTypes = {
  children: PropTypes.node,
}
