/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"
import { ICompany, ICompanySettings } from "./types"

export enum CompanyTypes {
  SetCompany = "Company/SetCompany",
  SetCompanySettings = "Company/SetCompanySettings",
  ResetCompany = "Company/ResetCompany",
}

type Actions = {
  [CompanyTypes.SetCompany]: { company: ICompany }
  [CompanyTypes.SetCompanySettings]: { settings: ICompanySettings }
  [CompanyTypes.ResetCompany]: null
}

export type CompanyActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const CompanyActions = createActions<Actions>()
