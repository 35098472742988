import { IDisplayKey } from "app/TrackerKPI/kpi.types"
import { StringDictionary } from "lib/global.types"

export type DeviceStatus =
  | "STOPPED"
  | "RUNNING"
  | "IDLE"
  | "WORKING"
  | "IN_TRANSPORT"
  | "UNKNOWN"

export enum DeviceStatusEnum {
  STOPPED = 0,
  RUNNING = 1,
  IDLE = 2,
  WORKING = 3,
  IN_TRANSPORT = 4,
  UNKNOWN = 5,
}

export enum DeviceTypeEnum {
  Machine = "machine",
  Car = "car",
  Asset = "asset",
  MaterialScanner = "materialScanner",
  Beacon = "beacon",
}

export enum DeviceFeatureEnum {
  AutoTurnOff = "autoTurnOff",
  AutoTurnOffGen2 = "autoTurnOffGen2",
  AutoTurnOffTime = "autoTurnOffTime",
  Immobilizer = "immobilizer",
  LowerIdleRPM = "lowerIdleRPM",
  ShowAutoTurnOff = "showAutoTurnOff",
  ShowIdleLow = "showIdleLow",
  ShowImmobilizer = "showImmobilizer",
  LowerIdleAIN1 = "lowerIdleAIN1", // float
  HigherIdleAIN1 = "higherIdleAIN1", // float
  ZeroIdleAIN1 = "zeroIdleAIN1", // float
  engineLowTemperatur = "engineLowTemperatur", // int
  EngineLoadLow = "engineLoadLow", // int
  EngineLoadHigh = "engineLoadHigh", // int
  idleReadInput = "idleReadInput", // int, 1-4
  DOutOnIgnition = "dOutOnIgnition", // int
  FirstActivation = "firstActivation", // int
  FirstActivationTime = "firstActivationTime", // float, 0-120
  DisclaimerRead = "disclaimerRead", // int, 0-1
  DisclaimerReadTimestamp = "disclaimerReadTimestamp", // timestamp
}

export type DeviceFeature = {
  [DeviceFeatureEnum.AutoTurnOff]: number
  [DeviceFeatureEnum.AutoTurnOffGen2]: number
  [DeviceFeatureEnum.AutoTurnOffTime]: number
  [DeviceFeatureEnum.Immobilizer]: number
  [DeviceFeatureEnum.LowerIdleRPM]: number
  [DeviceFeatureEnum.ShowAutoTurnOff]: number
  [DeviceFeatureEnum.ShowIdleLow]: number
  [DeviceFeatureEnum.ShowImmobilizer]: number
  [DeviceFeatureEnum.LowerIdleAIN1]: number
  [DeviceFeatureEnum.HigherIdleAIN1]: number
  [DeviceFeatureEnum.ZeroIdleAIN1]: number
  [DeviceFeatureEnum.engineLowTemperatur]: number
  [DeviceFeatureEnum.EngineLoadLow]: number
  [DeviceFeatureEnum.EngineLoadHigh]: number
  [DeviceFeatureEnum.idleReadInput]: number
  [DeviceFeatureEnum.DOutOnIgnition]: number
  [DeviceFeatureEnum.FirstActivation]: number
  [DeviceFeatureEnum.FirstActivationTime]: number
  [DeviceFeatureEnum.DisclaimerRead]: number
  [DeviceFeatureEnum.DisclaimerReadTimestamp]: number
}

export type Coordinates = [number, number]

export interface IDeviceGeometry {
  coordinates: Coordinates
  type: string
}

export interface IDeviceAlarm {
  deviceID: number
  alarmID: string
}

export interface IDevicePosition {
  geometry: IDeviceGeometry
  properties: StringDictionary<string>
  type: string
}

export interface IRawDevice {
  id: number // Current values for these are stored in Firebase Database
  name: string
  note: string // Note that are visible below the name, if set
  description: string // Information about where the device is mounted
  position: IDevicePosition // Last known position
  heading: number // Heading in degrees
  speed: number // Current device travel speed
  status: DeviceStatusEnum
  feature: DeviceFeature
  group: number[]
  data?: IDisplayKey[]
  values: IDisplayKey[]
  GPSFailure: number
  lastsatcountvalid: string
  internalVoltageLevel: number
  externalVoltageLost: number
  batLevel: number
  batteryLongevity: number
  batteryLongevityVolt72: number
  externalVoltage: number
}

export interface IRawDeviceUpdate {
  companyID: string
  heading: number
  id: number
  nextService: string
  batLevel: number
  batteryLongevity: number
  batteryLongevityVolt72: number
  satcount: number
  lastsatcountvalid: string
  externalVoltage: number // miliVolt
  analog1Voltage: number
  speed: number
  status: DeviceStatusEnum
  position: IDevicePosition
  values: IDisplayKey[]
  timestamp?: number
  address?: string
}

// These are extensions on the client
export interface IDevice extends IRawDevice {
  toggled: boolean
  toggledInGroups: number[]
  deviceHasCAN: boolean
  deviceType: DeviceTypeEnum
  currentTrip: IRawDeviceUpdate[]
}

export interface IRawDeviceGroup {
  id: number
  name: string
  description: string
  vehicleCount: number
}

// These are extensions on the client
export interface IDeviceGroup extends IRawDeviceGroup {
  devices: number[]
  toggled: boolean
  visible: boolean
}

export type FirestoreDevice = {
  id: number // Device ID
  name: string
  deviceLastUpdated?: number // Timestamp for when this device was last updated
  alarmIDs?: number[] // Alarms enabled on this vehicle
  averageDailySpeed?: number
  dailySpeedLogCount?: number // The count of daily logs where speed was larger than 0
  dailySpeedLogSum?: number // The sum of all the speeds logged today
  currentRoute?: Coordinates[] // Current route in progress
}
