import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import KPIBase from "./Base"
import Date from "../Date"

const StyledTimestamp = styled(KPIBase)``

function Timestamp({ data, elapsed, format, unsetValue, ...props }) {
  const isUnset = ["1970-01-01T00:00:00Z", "0001-01-01T00:00:00Z"].find(
    (x) => x === data
  )

  return (
    <StyledTimestamp {...props}>
      {isUnset ? unsetValue : <Date date={data} format={format} />}
    </StyledTimestamp>
  )
}

export default Timestamp

Timestamp.defaultProps = {
  elapsed: false,
}
Timestamp.propTypes = {
  elapsed: PropTypes.bool,
  data: PropTypes.string.isRequired,
}
