import React, { useMemo, useContext } from "react"
import tw from "twin.macro"
import { ContactItem } from "./Item"
import { IContactOrContactGroup } from "../conversations.types"
import { ConversationContext } from "../context"
import Checkbox from "lib/Checkbox"

interface IResultsProps {
  query: string
  suggestions: any[]
  results: any[]
  onSearchResultSelect: (c: IContactOrContactGroup) => void
  onSearchResultDeselect?: (c: IContactOrContactGroup) => void
  allowSelectWhileDisabled?: boolean
}

export const Results: React.FC<IResultsProps> = ({
  query,
  suggestions,
  results,
  onSearchResultSelect,
  onSearchResultDeselect,
  allowSelectWhileDisabled,
  ...props
}) => {
  const {
    state: { selectedContacts },
  } = useContext(ConversationContext)
  const mappedResults = useMemo(() => results.map(({ item }) => item), [
    results,
  ])
  const mappedSuggestions = useMemo(() => suggestions.map(({ item }) => item), [
    suggestions,
  ])

  const renderResult = (contact: IContactOrContactGroup) => {
    const isSelected = selectedContacts.map((sc) => sc.id).includes(contact.id)
    return (
      <ContactItem
        key={contact.id}
        contact={contact}
        onSelect={() => onSearchResultSelect(contact)}
        onDeselect={
          onSearchResultDeselect
            ? () => onSearchResultDeselect(contact)
            : () => ({})
        }
        tw="relative"
        css={allowSelectWhileDisabled ? [tw`pr-16`] : []}
        allowSelectWhileDisabled={allowSelectWhileDisabled}
        {...props}
      >
        {allowSelectWhileDisabled && (
          <Checkbox
            tw="absolute right-0 top-6"
            checked={isSelected}
            onChange={() => ({})}
          />
        )}
      </ContactItem>
    )
  }

  return (
    <>
      {mappedResults &&
        mappedResults.length > 0 &&
        mappedResults.map(renderResult)}
      {mappedSuggestions &&
        mappedSuggestions.length > 0 &&
        mappedSuggestions.map(renderResult)}
    </>
  )
}
