import React from "react"
import tw from "twin.macro"
import styled from "styled-components"

interface IPanelHeaderProps {
  cancelComponent?: React.ReactNode
  confirmComponent: React.ReactNode
  title: React.ReactNode
}

const StyledPanelHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  ${tw`text-2xl`}
`

export const PanelHeader: React.FC<IPanelHeaderProps> = ({
  cancelComponent = null,
  confirmComponent,
  title,
  ...props
}) => {
  return (
    <StyledPanelHeader {...props}>
      <span>{cancelComponent}</span>
      <span tw="text-center font-bold">{title}</span>
      <span tw="text-right">{confirmComponent}</span>
    </StyledPanelHeader>
  )
}
