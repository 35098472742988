import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ValidationInput from "@clevertrack/shared/src/lib/ValidationInput"
import I18n from "app/I18n"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  ValidationInput as CustomValidationInput,
} from "@clevertrack/shared"

const StyledGroupForm = styled(Form)``

function GroupForm({ id, name, description, onSave, onCancel, ...props }) {
  const [newName, setName] = useState(name)
  const [newDescription, setDescription] = useState(description)

  function onSubmitHandler(e) {
    onSave(id, {
      name: newName,
      description: newDescription,
    })
  }

  return (
    <StyledGroupForm onSubmit={onSubmitHandler}>
      <FormField label="Titel">
        <CustomValidationInput
          value={newName}
          onChange={(e) => setName(e.target.value)}
          required
          type="text"
          placeholder="Indtast gruppens titel"
        />
      </FormField>
      <FormField label="Beskrivelse">
        <ValidationInput
          renderAsFormField
          as="textarea"
          value={newDescription}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Indtast gruppebeskrivelse"
        />
      </FormField>
      <ButtonGroup position="between">
        <Button invert variant="default" type="button" onClick={onCancel}>
          <I18n strKey="cancel" />
        </Button>
        <Button variant="primary" type="submit">
          <I18n strKey="save" /> <I18n strKey="group" />
        </Button>
      </ButtonGroup>
    </StyledGroupForm>
  )
}

export default GroupForm

GroupForm.defaultProps = {
  name: "",
  description: "",
}
GroupForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
}
