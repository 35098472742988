import React from "react"
import "twin.macro"

export const CriteriumTypeTitle: React.FC = ({ children, ...props }) => {
  return (
    <h4 tw="my-0" {...props}>
      {children}
    </h4>
  )
}
