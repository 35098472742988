import React from "react"
import "twin.macro"
import styled from "styled-components"
import { useOnScreen } from "hooks/useOnScreen"

const StyledInput = styled.input`
  ${(props) =>
    props.theme.utils.getBoxModel(
      props.theme.input[props.size].padding,
      null,
      props.theme.input[props.size].margin
    )};
  line-height: ${(props) => `${props.theme.input[props.size].lineHeight}rem`};
  width: 100%;
  color: inherit;
`

export enum InputSizeEnum {
  SM = "sm",
  MD = "md",
  LG = "lg",
}

const Input: React.FC<any> = ({
  size = "md",
  forwardRef,
  className,
  ...props
}) => {
  const isOnScreen = useOnScreen(forwardRef)

  return props.type === "password" ? (
    <StyledInput
      size={size}
      ref={forwardRef}
      tabIndex={!isOnScreen ? "-1" : ""}
      {...props}
    />
  ) : (
    <StyledInput
      size={size}
      ref={forwardRef}
      className={[className, "data-hj-allow"]}
      tabIndex={!isOnScreen ? "-1" : ""}
      {...props}
    />
  )
}

export default Input
