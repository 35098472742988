import React, { useRef, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { Link, navigate } from "gatsby"
import Icon from "lib/Icon"
import { Button, Responsive } from "@clevertrack/shared"
import I18n from "app/I18n"
import Navigation from "app/Dashboard/Navigation"
import NavItem from "app/Dashboard/NavItem"
import { logout } from "services/auth"
import User from "./"
import useOnClickOutside from "hooks/useOnClickOutside"
import AdminComponent from "app/AdminComponent"
import FeatureComponent from "app/FeatureComponent"
import { UserContext } from "./context"
import { UserTypes, UserActions } from "./actions"
import { useDevices } from "app/Device/hooks"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { ConversationContext } from "app/Conversations/context"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import { CompanyActions, CompanyTypes } from "app/Company/actions"
import { CompanyContext } from "app/Company/context"
import { DeviceContext } from "app/Device/context"
import FEATURE, { COMPANYFUNCTIONS, USERRIGHTS } from "data/featureFlags"
import Cookies from "js-cookie"

const StyledUserMenu = styled.div``

const StyledUserName = styled(User)`
  padding: 1rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    span {
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

const StyledMenu = styled.nav`
  padding: 0.5rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.grayLight};

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-top: 0;
    ul {
      margin: 0;
    }
  `}
`

const StyledNavigation = styled(Navigation)`
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    flex-direction: column;
    margin: 0;
  `}
`

const StyledNavItem = styled(NavItem)`
  width: 100%;
  a:first-child {
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: flex-start;
    border: none;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: 1rem 2rem;
    grid-column-gap: 1rem;

    &:hover,
    &.active {
      border: none;
      color: ${(props) => props.theme.colors.primary};
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    a:first-child {
      display: grid;
      grid-template-columns: auto auto auto;
      padding: 1rem 1.5rem;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    margin-right: 0;
    a:first-child {
      grid-template-columns: 2rem auto auto;
      grid-column-gap: 1rem;
      margin-left: 0rem;
      font-size: 1.4rem;

      svg {
        margin: auto;
        height: 1.4rem;
        width: 1.4rem;
      }
    }
  `}
`

function UserMenu({ children, user, onSelectNavItem, onSignOut, ...props }) {
  const ref = useRef(null)
  const {
    state: { settingsToggled },
    dispatch,
  } = useContext(AppContext)
  const {
    state: { syncAddress },
  } = useContext(DeviceContext)
  const { resetDevicesAndGroups } = useDevices(syncAddress)
  const { dispatch: userDispatch } = useContext(UserContext)
  const {
    dispatch: companyDispatch,
    state: {
      company: { functions, ...restCompany },
    },
  } = useContext(CompanyContext)
  const { dispatch: conversationDispatch } = useContext(ConversationContext)

  useOnClickOutside(ref, () => {
    if (settingsToggled)
      dispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSettings, {
          toggled: false,
        })
      )
  })

  async function onLogOut() {
    const result = await logout()
    if (result) {
      await resetDevicesAndGroups()
      companyDispatch(CompanyActions(CompanyTypes.ResetCompany, null))
      conversationDispatch(ConversationActions(ConversationTypes.Reset, null))
      dispatch(GlobalAppActions(GlobalAppTypes.Reset, null))
      userDispatch(UserActions(UserTypes.ResetUser, null))
      if (onSignOut) onSignOut()
      navigate("/")
    }
  }

  const deviceMountingUrl = useMemo(() => {
    if (
      user.feature.includes(FEATURE.DEVICEMOUNTING) &&
      user.is_token_whitelisted === 1
    ) {
      const strBuilder = [`https://`]
      const token = Cookies.get("clevertrack-authenticated")
      switch (process.env.GATSBY_ACTIVE_ENV) {
        case "production":
          strBuilder.push(
            `hub.clevertrack.dk?token=${token}&companyID=${restCompany.id}`
          )
          break
        case "staging":
          strBuilder.push(
            `hub.staging.clevertrack.dk?token=${token}&companyID=${restCompany.id}`
          )
          break
        case "development":
        default:
          strBuilder.push(
            `localhost:8002?token=${token}&companyID=${restCompany.id}`
          )
      }
      return strBuilder.join("")
    }
    return null
  }, [user])

  return (
    <StyledUserMenu {...props} ref={ref}>
      <Responsive tabletLandscape={<StyledUserName />} />
      <StyledMenu>
        <StyledNavigation tw="pb-2">
          <StyledNavItem>
            <Link
              to="/app/settings"
              activeClassName="active"
              onClick={onSelectNavItem}
            >
              <Icon icon="person" />
              <span>
                <I18n strKey="nav_my_settings" />
              </span>
            </Link>
          </StyledNavItem>
          <AdminComponent user={user} hideFromNoneAdmins>
            <StyledNavItem>
              <Link
                to="/app/settings/users"
                activeClassName="active"
                onClick={onSelectNavItem}
                partiallyActive
              >
                <Icon icon="user-add" />
                <span>
                  <I18n strKey="users_plural" />
                </span>
              </Link>
            </StyledNavItem>
          </AdminComponent>
        </StyledNavigation>
        {(!!user.is_admin ||
          user.feature.some((x) =>
            [
              FEATURE.GROUPS,
              FEATURE.ZONES,
              FEATURE.ALARMS,
              FEATURE.DRIVERS,
            ].includes(x)
          )) && (
          <StyledNavigation tw="border border-t border-solid border-brand-gray-light border-l-0 border-r-0 border-b-0 pt-2">
            <FeatureComponent user={user} feature={FEATURE.GROUPS}>
              <StyledNavItem>
                <Link
                  to="/app/settings/groups"
                  activeClassName="active"
                  onClick={onSelectNavItem}
                  partiallyActive
                >
                  <Icon icon="list-config" />
                  <span>
                    <I18n strKey="nav_group_settings" />
                  </span>
                </Link>
              </StyledNavItem>
            </FeatureComponent>
            <FeatureComponent user={user} feature={FEATURE.ZONES}>
              <StyledNavItem>
                <Link
                  to="/app/settings/zones"
                  activeClassName="active"
                  onClick={onSelectNavItem}
                  partiallyActive
                >
                  <Icon icon="layer-plus" />
                  <span>
                    <I18n strKey="nav_zones" />
                  </span>
                </Link>
              </StyledNavItem>
            </FeatureComponent>
            <FeatureComponent user={user} feature={FEATURE.ALARMS}>
              <StyledNavItem>
                <Link
                  to="/app/settings/alarms"
                  activeClassName="active"
                  onClick={onSelectNavItem}
                  partiallyActive
                >
                  <Icon icon="siren" />
                  <span>
                    <I18n strKey="nav_alarms" />
                  </span>
                </Link>
              </StyledNavItem>
            </FeatureComponent>
            {Object.values(functions).includes(COMPANYFUNCTIONS.DRIVERS) && (
              <FeatureComponent user={user} feature={FEATURE.DRIVERS}>
                <StyledNavItem>
                  <Link
                    to="/app/settings/drivers"
                    activeClassName="active"
                    onClick={onSelectNavItem}
                    partiallyActive
                  >
                    <Icon icon="id-card-alt" />
                    <span>Fører ID-opsætning</span>
                  </Link>
                </StyledNavItem>
              </FeatureComponent>
            )}
          </StyledNavigation>
        )}
      </StyledMenu>
      {deviceMountingUrl && (
        <FeatureComponent user={user} feature={FEATURE.DEVICEMOUNTING}>
          <StyledMenu>
            <StyledNavigation tw="pb-2">
              <StyledNavItem>
                <a href={deviceMountingUrl}>
                  <Icon icon="hard-hat" />
                  <span>Enhedsmontering</span>
                  <Icon icon="external-link"></Icon>
                </a>
              </StyledNavItem>
            </StyledNavigation>
          </StyledMenu>
        </FeatureComponent>
      )}
      <StyledMenu>
        <Button
          type="button"
          size="sm"
          variant="transparent"
          appearance="link"
          onClick={onLogOut}
          tw="text-brand-red-500 px-10"
        >
          <span>
            <I18n strKey="user_log_out" />
          </span>
          <Icon icon="sign-out" size="sm" />
        </Button>
      </StyledMenu>
    </StyledUserMenu>
  )
}

export default UserMenu

UserMenu.defaultProps = {}
UserMenu.propTypes = {
  children: PropTypes.node,
}
