import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { useLocation } from "@reach/router"
import PopOver from "app/PopOver"
import { ConversationContext } from "./context"
import {
  RouteTypeEnum,
  PopOverRoutesEnum,
  ConversationRoutes,
} from "./conversations.types"
import { ConversationActions, ConversationTypes } from "./actions"
import Icon, { IconSizeEnum } from "lib/Icon"
import { AppContext } from "context"
import { MenuTypeEnum } from "context/App/app.types"
import { Conversations } from "./Routes/Conversations"
import { Contacts } from "./Routes/Contacts"
import { SearchProvider } from "app/Search/context"
import { SizeEnum } from "theme"
import { ContactForm } from "./Contact/Form"
import { ViewConversation } from "./Conversation"
import { CompanyContext } from "app/Company/context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import NavLink from "components/Navigation/NavLink"
import { useConversation } from "./hooks"
import { UserContext } from "app/User/context"
import { GroupForm } from "./Contact/GroupForm"
import { UserVisibilitySettings } from "app/User/types"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { Responsive } from "@clevertrack/shared"
import useViewport, { ViewportEnum } from "hooks/useViewport"
import { useLockBodyScrollOnMount } from "hooks/useLockBodyScroll"
import FeatureComponent from "app/FeatureComponent"
import { COMPANYFUNCTIONS } from "data/featureFlags"

const StyledPopOver = styled(PopOver)`
  width: 100vw;
  overflow-y: hidden;
  // max-width: 36rem;
  box-shadow: -1px 0px -4px 1px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 2000;
  bottom: ${SizeEnum.TapBarHeight}rem;

  ${tw`w-full`}

  .wrapper {
    ${tw`bg-white h-full`}
    display: grid;
    grid-template-rows: ${SizeEnum.Tabs}rem auto;
    overflow-y: hidden;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    left: auto;
    top: ${SizeEnum.DesktopMenu}rem;
    right: 0;
    position: fixed;
    max-width: 40rem;
    bottom: 0;
    z-index: 100;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 60rem;
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    max-width: 70rem;

  `}

  ${(props) => props.theme.media.xxxl_desktop_up`
    max-width: 80rem;
  `}
`

const StyledPanelToggle = styled.header`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
`

const StyledTab = styled.span<{ toggled: boolean }>`
  flex: 1 0 50%;
  transition: all ease-out 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid transparent;
  background: ${(props) => props.theme.colors.grayLighter};
  opacity: 1;
  transform: translateY(1px);
  font-size: 1.2rem;
  cursor: pointer;

  svg {
    margin-right: 1rem;
  }

  &:last-child {
    border-left: 1px solid ${(props) => props.theme.colors.grayLight};
  }

  ${(props) =>
    props.toggled &&
    `
    opacity: 1;
    background: ${props.theme.colors.white};
  `}
`

export interface IConversationsSharedProps {
  onNavigate: (route: ConversationRoutes) => void
  onToggleScreen: (route: ConversationRoutes) => void
}

const ConversationRouter: React.FC<{}> = ({ ...props }) => {
  const {
    state: { toggledRoutes, currentConversationID, goToMessageID },
    dispatch,
  } = useContext(ConversationContext)
  const {
    state: { company },
  } = useContext(CompanyContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const { onNavigate, onToggleScreen } = useConversation()
  const { setMessagesRead } = useFirebaseFunctions()
  useLockBodyScrollOnMount()
  const conversationsRootRef = useRef(null)
  const [
    conversationRouteAnimationEnded,
    setConversationRouteAnimationEnded,
  ] = useState(false)

  useEffect(() => {
    if (
      !toggledRoutes.includes(RouteTypeEnum.Conversations) &&
      !toggledRoutes.includes(RouteTypeEnum.Contacts)
    ) {
      const defaultToggledRoute = RouteTypeEnum.Conversations
      onNavigate(defaultToggledRoute)
    }

    if (
      !toggledRoutes.includes(PopOverRoutesEnum.ViewConversation) &&
      !toggledRoutes.includes(PopOverRoutesEnum.NewMessage) &&
      !toggledRoutes.includes(PopOverRoutesEnum.EditGroup)
    ) {
      dispatch(
        ConversationActions(ConversationTypes.SetSelectedContacts, {
          contacts: [],
        })
      )
      dispatch(
        ConversationActions(ConversationTypes.SetConversation, {
          conversation: null,
        })
      )
      dispatch(
        ConversationActions(ConversationTypes.SetGoToMessageID, {
          messageID: null,
        })
      )
    }
  }, [toggledRoutes])

  useEffect(() => {
    if (conversationRouteAnimationEnded) {
      setUserConversationMessagesRead()
    }
  }, [conversationRouteAnimationEnded])

  const renderView = () => {
    if (toggledRoutes.includes(RouteTypeEnum.Conversations)) {
      return (
        <SearchProvider>
          <Conversations
            onNavigate={onNavigate}
            onToggleScreen={onToggleScreen}
          />
        </SearchProvider>
      )
    }

    if (toggledRoutes.includes(RouteTypeEnum.Contacts)) {
      return (
        <SearchProvider>
          <Contacts onNavigate={onNavigate} onToggleScreen={onToggleScreen} />
        </SearchProvider>
      )
    }
  }

  const setUserConversationMessagesRead = () => {
    if (company && user && currentConversationID)
      setMessagesRead({
        companyID: company.id as string,
        contactID: currentConversationID,
        userID: user.id,
      })
  }

  const onConversationRouteAnimationEnd = () => {
    setConversationRouteAnimationEnded(
      toggledRoutes.includes(PopOverRoutesEnum.ViewConversation)
    )
  }

  const renderPopOverRoutes = () => {
    return conversationsRootRef.current ? (
      <>
        <PopOver
          key="ConversationPopover"
          show={
            toggledRoutes.includes(PopOverRoutesEnum.ViewConversation) ||
            toggledRoutes.includes(PopOverRoutesEnum.NewMessage)
          }
          fromBottom
          selector={conversationsRootRef.current}
          zindex={90}
          onRest={onConversationRouteAnimationEnd}
        >
          {toggledRoutes.includes(PopOverRoutesEnum.ViewConversation) && (
            <ViewConversation
              onClose={() => onToggleScreen(PopOverRoutesEnum.ViewConversation)}
            />
          )}
          {toggledRoutes.includes(PopOverRoutesEnum.NewMessage) && (
            <SearchProvider>
              <ViewConversation
                onClose={() => onToggleScreen(PopOverRoutesEnum.NewMessage)}
                rootRef={conversationsRootRef}
              />
            </SearchProvider>
          )}
        </PopOver>
        <SearchProvider>
          <PopOver
            key="CreatePopover"
            show={toggledRoutes.includes(PopOverRoutesEnum.CreateContact)}
            fromBottom
            selector={conversationsRootRef.current}
            zindex={100}
          >
            <ContactForm
              onClose={() => onToggleScreen(PopOverRoutesEnum.CreateContact)}
            />
          </PopOver>
          <PopOver
            key="CreateGroupPopover"
            show={toggledRoutes.includes(PopOverRoutesEnum.CreateGroup)}
            fromBottom
            selector={conversationsRootRef.current}
            zindex={95}
          >
            <GroupForm
              onClose={() => onToggleScreen(PopOverRoutesEnum.CreateGroup)}
            />
          </PopOver>
          <PopOver
            key="EditGroupPopover"
            show={toggledRoutes.includes(PopOverRoutesEnum.EditGroup)}
            fromBottom
            selector={conversationsRootRef.current}
            zindex={100}
          >
            <GroupForm
              onClose={() => onToggleScreen(PopOverRoutesEnum.EditGroup)}
            />
          </PopOver>
        </SearchProvider>
        <PopOver
          key="EditPopover"
          show={toggledRoutes.includes(PopOverRoutesEnum.EditContact)}
          fromBottom
          selector={conversationsRootRef.current}
          zindex={100}
        >
          <ContactForm
            onClose={() => onToggleScreen(PopOverRoutesEnum.EditContact)}
          />
        </PopOver>
      </>
    ) : null
  }

  return useMemo(
    () => (
      <section className="wrapper" ref={conversationsRootRef}>
        <StyledPanelToggle>
          <StyledTab
            toggled={toggledRoutes.includes(RouteTypeEnum.Conversations)}
            onClick={() => onNavigate(RouteTypeEnum.Conversations)}
          >
            <Icon size={IconSizeEnum.SM} icon="conversation" />
            Samtaler
          </StyledTab>
          <StyledTab
            toggled={toggledRoutes.includes(RouteTypeEnum.Contacts)}
            onClick={() => onNavigate(RouteTypeEnum.Contacts)}
          >
            <Icon size={IconSizeEnum.SM} icon="people" />
            Kontakter
          </StyledTab>
        </StyledPanelToggle>
        <main>{renderView()}</main>
        {renderPopOverRoutes()}
      </section>
    ),
    [toggledRoutes]
  )
}

export const Conversation: React.FC = () => {
  const {
    state: { toggledMenus },
  } = useContext(AppContext)
  const {
    state: { company },
  } = useContext(CompanyContext)

  const { state, dispatch } = useContext(ConversationContext)

  useEffect(() => {
    if (!toggledMenus.includes(MenuTypeEnum.Conversations)) {
      dispatch(ConversationActions(ConversationTypes.ResetRoutes, null))
    }
  }, [toggledMenus])

  if (!company?.msisdn) return null

  return (
    <StyledPopOver
      fromRight
      show={toggledMenus.includes(MenuTypeEnum.Conversations)}
    >
      <ConversationRouter />
    </StyledPopOver>
  )
}

export const ConversationWidget: React.FC = React.memo(() => {
  const {
    state: { company },
  } = useContext(CompanyContext)

  const {
    state: { toggledMenus },
    dispatch,
  } = useContext(AppContext)

  const {
    state: { user, userSettings },
  } = useContext(UserContext)

  const {
    state: { subscriptionActive },
    dispatch: conversationsDispatch,
  } = useContext(ConversationContext)

  const {
    getData,
    subscribeToUserConversations,
    unsubscribeToContacts,
    unsubscribeToUserConversations,
    unsubscribeToGroups,
    unreadMessages,
  } = useConversation()

  const location = useLocation()
  const vp = useViewport()

  const conversationsToggled = toggledMenus.includes(MenuTypeEnum.Conversations)

  const handleNotificationsToggle = (menuKey: MenuTypeEnum) => {
    dispatch(
      GlobalAppActions(GlobalAppTypes.ToggleMenu, {
        key: menuKey,
      })
    )
  }

  useEffect(() => {
    if (vp && [ViewportEnum.Phone, ViewportEnum.TabletPortrait].includes(vp)) {
      dispatch(GlobalAppActions(GlobalAppTypes.Reset, null))
    }
  }, [location.pathname])

  const onMount = useCallback(async () => {
    await getData()
    await subscribeToUserConversations()
    if (!subscriptionActive) {
      conversationsDispatch(
        ConversationActions(ConversationTypes.SetSubscriptionsActive, {
          active: true,
        })
      )
    }
  }, [])

  useLayoutEffect(() => {
    if (company?.id) {
      onMount()
    }
    return () => {
      unsubscribeToContacts()
      unsubscribeToUserConversations()
      unsubscribeToGroups()
    }
  }, [company])

  return company?.msisdn ? (
    <FeatureComponent feature={COMPANYFUNCTIONS.MESSENGER} user={user}>
      <Responsive
        phone={
          <>
            <Icon
              // css={conversationsToggled ? [tw`text-brand-500`] : []}
              icon="conversation"
              onClick={() =>
                handleNotificationsToggle(MenuTypeEnum.Conversations)
              }
            />
            {unreadMessages > 0 &&
              userSettings?.visibilitySettings?.includes(
                UserVisibilitySettings.MessengerNotifications
              ) && (
                <span tw="bg-brand-red-500 rounded-full text-base flex items-center justify-center px-2 h-8 font-bold text-white absolute top-0 left-1/2">
                  {unreadMessages}
                </span>
              )}
          </>
        }
        tabletLandscape={
          <NavLink
            onClick={() =>
              handleNotificationsToggle(MenuTypeEnum.Conversations)
            }
            className={conversationsToggled ? "active icon-only" : "icon-only"}
            partiallyActive={true}
          >
            <span tw="relative">
              <Icon icon="conversation" />
              {unreadMessages > 0 &&
                userSettings?.visibilitySettings?.includes(
                  UserVisibilitySettings.MessengerNotifications
                ) && (
                  <span tw="bg-brand-red-500 rounded-full text-base flex items-center justify-center px-2 h-8 font-bold text-white absolute -top-5 left-1/2">
                    {unreadMessages}
                  </span>
                )}
            </span>
          </NavLink>
        }
      />
    </FeatureComponent>
  ) : null
})
