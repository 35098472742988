import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import KPIBase from "./Base"
import { formatSecondsToDuration } from "utils/datetime"

const StyledDuration = styled(KPIBase)``

function Duration({ data, unsetValue, ...props }) {
  const [h, m, s] = data.split(":")
  const seconds = +h * 60 * 60 + +m * 60

  if (seconds > 0) {
    const formattedSeconds = formatSecondsToDuration(seconds, {
      verboseDuration: true,
    })

    return (
      <StyledDuration {...props}>{`${
        formattedSeconds.hrs ? formattedSeconds.hrs : ""
      } ${formattedSeconds.min ? formattedSeconds.min : ""} ${
        formattedSeconds.sec ? formattedSeconds.sec : ""
      }`}</StyledDuration>
    )
  }

  return <StyledDuration {...props}>{unsetValue}</StyledDuration>
}

export default Duration

Duration.defaultProps = {}
Duration.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
