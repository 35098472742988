import React from "react"
import styled from "styled-components"
import Icon from "lib/Icon"
import tw from "twin.macro"
import { Card, Tag } from "@clevertrack/shared"

export const StyledBaseResult = styled(Card)`
  transition: all ease-out 0.2s;
  grid-area: all;

  & + & {
    margin-top: 0;
  }

  p {
    margin: 0;
  }

  &:hover {
    background: ${(props) => props.theme.colors.grayLighter};
    cursor: pointer;
  }
`

const StyledResult = styled(StyledBaseResult)`
  ${tw`text-xl p-4`}
  .labels {
    display: none;
    top: 0;
    right: 1rem;
    position: absolute;
  }

  .results & {
    opacity: 0.4;
    position: relative;

    .labels {
      display: flex;
    }

    &:hover {
      opacity: 1;
    }

    &.match {
      opacity: 1;
      order: -2;

      .label-match {
        background: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.white};
      }

      .label-suggest {
        display: none;
      }

      &:hover {
        background: ${(props) => props.theme.colors.grayLighter};
      }
    }

    &.suggestion {
      opacity: 1;
      order: -1;

      .label-suggest {
        background: ${(props) => props.theme.colors.gray};
        color: ${(props) => props.theme.colors.white};
      }

      .label-match {
        display: none;
      }

      &:hover {
        background: ${(props) => props.theme.colors.grayLighter};
      }
    }
  }
`

export interface IBaseResultProps {
  onClick: (...args) => void
  children: React.ReactNode
}

interface IResultProps extends IBaseResultProps {
  match: boolean
  suggestion: boolean
  vehicleCount: number
  name: string
  id: number
  groupTrackerCountMap: any
}

export const Result: React.FC<IResultProps> = ({
  onClick,
  match,
  suggestion,
  children,
  name,
  vehicleCount,
  id,
  groupTrackerCountMap,
  note,
  description,
  ...props
}) => {
  const count =
    vehicleCount !== undefined
      ? ` (${
          groupTrackerCountMap[id] && +groupTrackerCountMap[id] !== vehicleCount
            ? `${groupTrackerCountMap[id]}/`
            : ""
        }${vehicleCount})`
      : ``

  return (
    <StyledResult size="sm" onClick={onClick} {...props}>
      {children ? (
        children
      ) : (
        <p tw="my-4 leading-none">
          <span className="labels">
            <Tag className="label-suggest" title="Forslag" show={suggestion}>
              <Icon size="sm" icon="idea" />
            </Tag>
            <Tag className="label-match" title="Match" show={match}>
              <Icon size="sm" icon="star" />
            </Tag>
          </span>
          <strong tw="text-lg md:text-xl">
            {name}
            {`${count}`}
          </strong>

          {note !== "" && (
            <span tw="block my-2 text-lg md:text-lg opacity-60">{note}</span>
          )}
          {!!vehicleCount && !note && description !== "" && (
            <span tw="block leading-snug m-0 text-lg md:text-lg opacity-60">
              {description}
            </span>
          )}
        </p>
      )}
    </StyledResult>
  )
}
