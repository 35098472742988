import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import "twin.macro"
import { Button, ButtonGroup, Card, Logo } from "@clevertrack/shared"
import PasswordForm from "app/PasswordForm"
import { checkPasswordToken, setPasswordWithToken } from "services/public"
import { queryResolver } from "utils/queryResolver"
import cogoToast from "@clevertrackdk/cogo-toast"
import LoginBG from "components/LoginBG"
import { SEO } from "components/SEO"
import SignedOutWrapper from "app/SignedOutWrapper"

const StyledNewPassword = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCard = styled(Card)`
  margin-top: 2rem;
  min-width: 30rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    min-width: 50rem;
  `}
`

const StyledSysMsg = styled.small`
  color: ${(props) => props.theme.colors[props.color]};
  margin-top: 1.5rem;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 75%;
  ${(props) => props.theme.media.tablet_portrait_up`
    float: right;
  `}
`

function NewPassword({ ...props }) {
  const [sysMsg, setSysMsg] = useState(null)
  const [redirectCountdown, setRedirectCountdown] = useState(3)
  const [tokenValid, setTokenValidity] = useState(null)

  const { token } = queryResolver(window.location.href)

  useEffect(() => {
    const IIFE = (async () => {
      try {
        const result = await checkPasswordToken(token)
        setTokenValidity({ validity: result })
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  const submitHandler = async (pass, e) => {
    e.preventDefault()
    try {
      const response = await setPasswordWithToken(token, pass)
      if (response.result === "OK") {
        cogoToast.loading("Skifter password")
        setTimeout(() => {
          cogoToast.success("Password skift lykkedes")
          setTimeout(() => {
            setSysMsg(
              `Nyt password gemt! Du omdirigeres om: ${redirectCountdown}`
            )
            setTimeout(() => setRedirectCountdown(2), 1000)
            setTimeout(() => setRedirectCountdown(1), 2000)
            setTimeout(() => {
              navigate("/app")
            }, 3000)
          }, 1500)
        }, 3250)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (redirectCountdown !== 3) {
      setSysMsg(`Nyt password gemt! Du omdirigeres om: ${redirectCountdown}`)
    }
  }, [redirectCountdown])

  const renderForm = () => {
    if (!!tokenValid) {
      if (tokenValid.validity) {
        return (
          <>
            <h2 tw="text-center">Nyt password</h2>
            <PasswordForm onSubmit={submitHandler}>
              {sysMsg ? (
                <StyledSysMsg color="success"> {sysMsg} </StyledSysMsg>
              ) : (
                <ButtonGroup position="center" tw="mx-0">
                  <Button type="submit" variant="primary" tw="mx-0">
                    Bekræft
                  </Button>
                </ButtonGroup>
              )}
            </PasswordForm>
          </>
        )
      } else {
        return (
          <>
            <h2 tw="text-center">Link udløbet</h2>
            <p tw="text-center">Linket er allerede blevet anvendt.</p>
            <ButtonGroup position="between" tw="px-0">
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate("/app/reset-password")}
              >
                Få nyt link
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate("/")}
              >
                Gå tilbage
              </Button>
            </ButtonGroup>
          </>
        )
      }
    } else {
      return <p>Vent venligst</p>
    }
  }

  return (
    <SignedOutWrapper>
      <SEO title="Nyt password" slug="/app/new-password" />
      <StyledNewPassword>{renderForm()}</StyledNewPassword>
    </SignedOutWrapper>
  )
}

export default NewPassword

NewPassword.defaultProps = {}

NewPassword.propTypes = {}
