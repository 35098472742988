import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Duration, Number } from "app/TrackerKPI"
import { formatFloat } from "utils"
import { DeviceTypeEnum } from "app/Device/types"

const StyledKpiGrid = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};

  .item {
    padding: 0;
    margin-left: -1rem;

    .value {
      opacity: 0.6;
      text-align: left;
    }

    svg {
      ${(props) => props.theme.media.desktop_up`
        width: 1.8rem;
        height: 1.2rem;
      `}
    }
  }
`

function KpiGrid({ data, lang, show, displayHours, displayMileage, ...props }) {
  // console.log(data.hours)
  if (data?.deviceMeta?.deviceType === DeviceTypeEnum.Beacon) return null
  return (
    <StyledKpiGrid show={show} {...props}>
      {displayHours && data?.deviceMeta?.deviceType !== DeviceTypeEnum.Car && (
        <Number
          icon="timer"
          translationKey="engineHoursToday"
          data={formatFloat(data.hours, lang)}
          unit=" timer"
        />
      )}
      {displayMileage &&
        data?.deviceMeta?.deviceType !== DeviceTypeEnum.Machine && (
          <Number
            icon="sigma"
            translationKey="totalDistance"
            unit=" km"
            data={`${formatFloat(Math.floor(data.mileage), lang)}`}
          />
        )}
    </StyledKpiGrid>
  )
}

export default KpiGrid

KpiGrid.defaultProps = {
  data: { hours: undefined, mileage: undefined },
  lang: "da-DK",
  displayHours: true,
  displayMileage: true,
}
KpiGrid.propTypes = {
  data: PropTypes.object,
  lang: PropTypes.string,
  displayHours: PropTypes.bool,
  displayMileage: PropTypes.bool,
}
