import Fuse from "fuse.js"

/**
 * Filter dataset by query
 * @param {String} query
 * @param {Array} dataset
 */

const freetextSearch = (dataset, options) => {
  const fuzzy = new Fuse(dataset, options)
  return fuzzy
}

export { freetextSearch }
