import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"

const StyledLegendItem = styled.span`
  display: grid;
  grid-template-columns: 1.4rem 1fr;
  grid-column-gap: 1rem;
  align-items: center;

  ${tw`text-xl md:text-2xl py-1 px-4`}

  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: 2rem 1fr;
    grid-column-gap: 1rem;
    min-width: 12rem;
  `}
`

function LegendItem({ children, ...props }) {
  return <StyledLegendItem {...props}>{children}</StyledLegendItem>
}

export default LegendItem

LegendItem.defaultProps = {}
LegendItem.propTypes = {
  children: PropTypes.node.isRequired,
}
