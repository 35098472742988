import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { RadialChart } from "react-vis"
import "react-vis/dist/style.css"

const StyledChart = styled.span`
  // position: absolute;
  background: white;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  backface-visibility: hidden;
`

function DonutChart({ width = 36, height = 36, series, ...props }) {
  if (!series) return null
  return (
    <StyledChart {...props}>
      <RadialChart
        data={series}
        width={width}
        height={height}
        padAngle={0.15}
        radius={16}
        innerRadius={12}
        animation={true}
      />
    </StyledChart>
  )
}

export default DonutChart

DonutChart.defaultProps = {}
DonutChart.propTypes = {
  series: PropTypes.array.isRequired,
}
