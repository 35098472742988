import React, { useReducer, createContext } from "react"
import { toggleItemInEnumArray, updateCollectionItemByID } from "utils/array"
import {
  IContact,
  ConversationRoutes,
  PopOverRoutesEnum,
  IContactGroup,
  IContactOrContactGroup,
  IResolvedConversation,
} from "./conversations.types"
import { ConversationActionMap, ConversationTypes } from "./actions"
import uniqBy from "lodash-es/uniqBy"
import orderBy from "lodash-es/orderBy"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialConversationStateType = {
  toggledRoutes: ConversationRoutes[]
  contacts: IContact[]
  groups: IContactGroup[]
  conversations: IResolvedConversation[]
  editContact: IContact | null
  editGroup: IContactGroup | null
  currentConversationID: string
  selectedContacts: IContactOrContactGroup[]
  message: string
  goToMessageID: number | null
  loading: boolean
  subscriptionActive: boolean
}

const initialState: InitialConversationStateType = {
  toggledRoutes: [],
  contacts: [],
  groups: [],
  conversations: [],
  editContact: null,
  editGroup: null,
  currentConversationID: "",
  selectedContacts: [],
  message: "",
  goToMessageID: null,
  loading: false,
  subscriptionActive: false,
}

const ConversationContext = createContext<{
  state: InitialConversationStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const conversationReducer = (
  state: InitialConversationStateType,
  action: ConversationActionMap
) => {
  switch (action.type) {
    case ConversationTypes.ToggleRoute:
      return {
        ...state,
        toggledRoutes: toggleItemInEnumArray<ConversationRoutes>(
          state.toggledRoutes,
          action.payload.key
        ),
      }
    case ConversationTypes.SetRoute:
      return {
        ...state,
        toggledRoutes: [action.payload.key],
      }
    case ConversationTypes.SetRoutes:
      return {
        ...state,
        toggledRoutes: action.payload.routes,
      }
    case ConversationTypes.EditContact:
      return {
        ...state,
        editContact: action.payload.contact,
        toggledRoutes: toggleItemInEnumArray<ConversationRoutes>(
          state.toggledRoutes,
          PopOverRoutesEnum.EditContact
        ),
      }
    case ConversationTypes.EditGroup:
      return {
        ...state,
        editGroup: action.payload.group,
        toggledRoutes: toggleItemInEnumArray<ConversationRoutes>(
          state.toggledRoutes,
          PopOverRoutesEnum.EditGroup
        ),
      }
    case ConversationTypes.SetContacts:
      const newContacts = action.payload.contacts.map((contact) => {
        const contactID = contact.id
        const previousContact = state.contacts.find((c) => c.id === contactID)
        return {
          ...contact,
          last_read_by: {
            ...previousContact?.last_read_by,
            ...contact.last_read_by,
          },
        }
      })

      const newConversations = state.conversations.map((convo) => {
        const newConversationContact = action.payload.contacts.find(
          (contact) => contact.id === convo.id
        )

        return {
          ...convo,
          contact: newConversationContact,
        }
      })

      return {
        ...state,
        contacts: newContacts,
        conversations: newConversations,
      }
    case ConversationTypes.AddContact:
      return {
        ...state,
        contacts: [...state.contacts, action.payload.contact],
      }
    case ConversationTypes.AddConversation:
      return {
        ...state,
        conversations: uniqBy(
          [...state.conversations, action.payload.newConversation],
          "id"
        ),
      }
    case ConversationTypes.SetConversations:
      return {
        ...state,
        conversations: action.payload.conversations,
      }
    case ConversationTypes.UpdateConversationByID:
      const conversation = state.conversations.find(
        (convo) => convo.id === action.payload.conversation.id
      )
      if (conversation) {
        const contact = state.contacts.find((c) => c.id === conversation.id)
        const conversationMessages = uniqBy(
          [...action.payload.conversation.messages, ...conversation.messages],
          (x) => x.internal_id || x.id
        )

        if (contact) {
          const unreadCount = conversationMessages.filter((x) => {
            if (action.payload.conversation.contact.last_read_by) {
              return (
                x.received &&
                action.payload.conversation.contact.last_read_by[
                  action.payload.userID
                ] < x.time
              )
            } else {
              return true
            }
          }).length

          conversation.unread_count = unreadCount
        }

        if (conversationMessages.length >= conversation.messages.length) {
          const updatedConversation = {
            ...conversation,
            contact: action.payload.conversation.contact,
            messages: orderBy(conversationMessages, "time", "asc"),
            last_message_timestamp:
              action.payload.conversation.last_message_timestamp,
          }

          return {
            ...state,
            conversations: updateCollectionItemByID<IResolvedConversation>(
              state.conversations,
              updatedConversation.id,
              updatedConversation
            ),
          }
        }
      }

      // If no conversation exists, this is a new conversation.
      const newUnreadCount = action.payload.conversation.messages.filter(
        (x) => {
          if (action.payload.conversation.contact.last_read_by) {
            return (
              x.received &&
              action.payload.conversation.contact.last_read_by[
                action.payload.userID
              ] < x.time
            )
          } else {
            return true
          }
        }
      ).length

      const newConversation = {
        ...conversation,
        unread_count: newUnreadCount,
        contact: action.payload.conversation.contact,
        messages: orderBy(action.payload.conversation.messages, "time", "asc"),
        last_message_timestamp:
          action.payload.conversation.last_message_timestamp,
      }

      return {
        ...state,
        conversations: [newConversation, ...state.conversations],
      }
    case ConversationTypes.SetConversationUnreadCountByID:
      if (action.payload.unreadMap) {
        const updatedConversations = [...state.conversations].map((conv) => {
          const unreadCount = action.payload.unreadMap.find(
            (x) => x.id === conv.id
          )
          if (unreadCount) {
            return {
              ...conv,
              unread_count: unreadCount?.unread_count,
            }
          }
          return conv
        })

        return {
          ...state,
          conversations: updatedConversations,
        }
      }

      /* if (updateConversationUnreadMessages) {
        const updatedConversation = {
          ...updateConversationUnreadMessages,
          unread_count: action.payload.unread_count,
        }

        return {
          ...state,
          conversations: updateCollectionItemByID<IResolvedConversation>(
            state.conversations,
            updatedConversation.id,
            updatedConversation
          ),
        }
      } */

      return state
    case ConversationTypes.SetConversation:
      return {
        ...state,
        currentConversationID: action.payload.conversation,
      }
    case ConversationTypes.SetGoToMessageID:
      return {
        ...state,
        goToMessageID: action.payload.messageID,
      }
    case ConversationTypes.SetGroups:
      return {
        ...state,
        groups: action.payload.groups,
      }
    case ConversationTypes.SetSelectedContacts:
      return {
        ...state,
        selectedContacts: action.payload.contacts,
      }
    case ConversationTypes.ResetGroup:
      return {
        ...state,
        editGroup: null,
      }
    case ConversationTypes.ResetContact:
      return {
        ...state,
        editContact: null,
      }
    case ConversationTypes.ResetRoutes:
      return {
        ...state,
        toggledRoutes: [],
      }
    case ConversationTypes.SetMessage:
      return {
        ...state,
        message: action.payload.message,
      }
    case ConversationTypes.ResetConversation:
      return {
        ...state,
        currentConversation: null,
      }
    case ConversationTypes.SetLoading:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case ConversationTypes.SetSubscriptionsActive:
      return {
        ...state,
        subscriptionActive: action.payload.active,
      }
    case ConversationTypes.Reset:
      return {
        ...state,
        toggledRoutes: [],
        contacts: [],
        groups: [],
        conversations: [],
        editContact: null,
        editGroup: null,
        currentConversationID: "",
        selectedContacts: [],
        message: "",
        loading: false,
        subscriptionActive: false,
      }
    default:
      return state
  }
}

const ConversationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(conversationReducer, initialState)

  return (
    <ConversationContext.Provider value={{ state, dispatch }}>
      {children}
    </ConversationContext.Provider>
  )
}

export { ConversationContext, ConversationProvider }
