import React, {
  Suspense,
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Button, Form, Textarea } from "@clevertrack/shared"
import Icon, { IconSizeEnum } from "lib/Icon"
import { ConversationActions, ConversationTypes } from "../actions"
import { ConversationContext } from "../context"
import Expander from "lib/Expander"
import useOnClickOutside from "hooks/useOnClickOutside"

const StyledForm = styled(Form)`
  display: grid;
  height: 100%;
  grid-template-rows: auto;
  ${tw`p-6 bg-white relative z-50`}
`

const StyledMessageWrapper = styled.div`
  position: relative;
`

const StyledButton = styled(Button)`
  ${tw`py-2 px-4`}
  position: absolute;
  right: 1rem;
  top: 0;
`

interface IMessageFormProps {
  onSubmit: (message: string) => void
  isGroupMessage: boolean
}

const EmojiPicker = React.lazy(() => import("emoji-picker-react"))

export const MessageForm: React.FC<IMessageFormProps> = ({
  onSubmit,
  isGroupMessage,
  ...props
}) => {
  const {
    state: { message },
    dispatch,
  } = useContext(ConversationContext)
  const [emojiKeyboardToggled, setEmojiKeyboardToggled] = useState(false)
  const emojiRef = useRef(null)
  const formRef = useRef<HTMLFormElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>()
  const textareaMemoizedCursorPosition = useRef(0)
  useOnClickOutside(emojiRef, () => setEmojiKeyboardToggled(false))

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        ConversationActions(ConversationTypes.SetMessage, {
          message: e.target.value,
        })
      )
    },
    []
  )

  const onSubmitHandler = (e) => {
    e.preventDefault()
    onSubmit(message)
  }

  const onHandlePick = (event, emoji) => {
    insertEmojiAtCursor(emoji.emoji)
  }

  const insertEmojiAtCursor = (emoji) => {
    const newMessage = [
      message.substring(0, textareaMemoizedCursorPosition.current),
      `${emoji}`,
      message.substring(textareaMemoizedCursorPosition.current),
    ].join("")

    dispatch(
      ConversationActions(ConversationTypes.SetMessage, {
        message: newMessage,
      })
    )
  }

  useEffect(() => {
    return () => {
      dispatch(
        ConversationActions(ConversationTypes.SetMessage, {
          message: "",
        })
      )
    }
  }, [])

  return (
    <StyledForm onSubmit={onSubmitHandler} forwardRef={formRef}>
      <StyledMessageWrapper>
        <Textarea
          // maxLength={messageMaxLength}
          tw="border border-solid bg-white border-gray-200 p-4 pr-40 mt-0 min-h-full focus:(outline-none)"
          placeholder={isGroupMessage ? "Skriv gruppebesked" : "Skriv besked"}
          onChange={onChangeHandler}
          disableTabWhileInvisible
          forwardRef={textareaRef}
          value={message}
          onBlur={(e) =>
            (textareaMemoizedCursorPosition.current = e.target.selectionEnd)
          }
        />
        <Suspense fallback={<div>Loading...</div>}>
          <div tw="absolute w-8 h-8 top-6 right-44" ref={emojiRef}>
            <Icon
              tw="cursor-pointer"
              icon="smiley"
              onClick={() => setEmojiKeyboardToggled((prev) => !prev)}
            />

            <Expander
              toggled={emojiKeyboardToggled}
              tw="absolute -right-44 bottom-12 md:(right-0)"
            >
              <EmojiPicker
                onEmojiClick={onHandlePick}
                native
                disableAutoFocus
                groupNames={{
                  smileys_people: "Smileys og personer",
                  animals_nature: "Dyr og natur",
                  food_drink: "Mad og drikkevarer",
                  travel_places: "Rejser og steder",
                  activities: "Aktivitet",
                  objects: "Objekter",
                  symbols: "Symboler",
                  flags: "Flag",
                  recently_used: "Hyppigt brugte",
                }}
              />
            </Expander>
          </div>
        </Suspense>

        <StyledButton
          size="md"
          type="submit"
          variant="primary"
          icon="right"
          disabled={message.length === 0}
        >
          <span>Send</span>
          <Icon size={IconSizeEnum.SM} icon="long-arrow-up" />
        </StyledButton>
      </StyledMessageWrapper>
    </StyledForm>
  )
}
