import React, { useReducer, createContext } from "react"
import {
  TOGGLE_CLUSTER,
  SET_CENTER,
  SET_ACTIVE_CLUSTER,
  SET_ALLOW_SCROLL,
  SET_GOOGLE_MAPS_INSTANCE,
} from "./actions"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type MapsType = {
  map: google.maps.Map<Element>
  maps: typeof google.maps
}

interface InitialMapsState {
  center: [number, number]
  activeClusters: number[]
  allowScroll: boolean
  mapsInstance: MapsType | null
}

const initialState: InitialMapsState = {
  center: null,
  activeClusters: [],
  allowScroll: true,
  mapsInstance: null,
}

const MapContext = createContext<{
  state: InitialMapsState
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const toggleCluster = (clusters, id) => {
  const newClusters = clusters.slice()
  const exists = clusters.some((x) => x === id)
  return exists ? newClusters.filter((x) => x !== id) : [...newClusters, id]
}

const activateCluster = (clusters, id) => {
  const newClusters = clusters.slice()
  const exists = clusters.some((x) => x === id)
  return exists ? newClusters.filter((x) => x !== id) : [...newClusters, id]
}

const mapsReducer = (state: InitialMapsState, action) => {
  switch (action.type) {
    case TOGGLE_CLUSTER:
      return {
        ...state,
        activeClusters: toggleCluster(state.activeClusters, action.id),
      }
    case SET_ACTIVE_CLUSTER:
      return {
        ...state,
        activeClusters:
          action.id === null
            ? []
            : activateCluster(state.activeClusters, action.id),
      }
    case SET_CENTER:
      return {
        ...state,
        center: action.coordinates,
      }
    case SET_ALLOW_SCROLL:
      return {
        ...state,
        allowScroll: action.allow,
      }
    case SET_GOOGLE_MAPS_INSTANCE:
      return {
        ...state,
        mapsInstance: {
          map: action.map,
          maps: action.maps,
        },
      }
    default:
      return state
  }
}

const MapProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mapsReducer, initialState)

  return (
    <MapContext.Provider value={{ state, dispatch }}>
      {children}
    </MapContext.Provider>
  )
}

export { MapContext, MapProvider }
