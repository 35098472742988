import { ActionMap, createActions } from "lib/reducer.types"
import {
  Alarm,
  AlarmSchedule,
  AlarmSetupPanels,
  AlertCriterium,
  AlertNotification,
} from "./alarm.types"

/* Action types */
export enum AlarmTypes {
  SetCurrentAlarm = "Alarm/SetCurrentAlarm",
  SetAlarmList = "Alarm/SetAlarmList",
  AddAlarmToList = "Alarm/AddAlarmToList",
  UpdateAlarmByID = "Alarm/UpdateAlarmByID",
  RemoveAlarmFromList = "Alarm/RemoveAlarmFromList",
  SetCurrentCriterium = "Alarm/SetCurrentCriterium",
  SetCurrentSchedule = "Alarm/SetCurrentSchedule",
  SetAlertNotifications = "Alarm/SetAlertNotifications",
  AddAlertNotification = "Alarm/AddAlertNotifications",
  SetCurrentAlertNotification = "Alarm/SetCurrentAlertNotification",
  UpdateAlertNotificationByID = "Alarm/UpdateAlertNotificationByID",
  UpdateAlarmCriteriumByID = "Alarm/UpdateAlarmCriteriumByID",
  SetCurrentEditorPanel = "Alarm/SetCurrentEditorPanel",
  ResetAlarmForm = "Alarm/ResetAlarmForm",
  Reset = "Alarm/Reset",
}

/* Action creators */
type Actions = {
  [AlarmTypes.SetCurrentAlarm]: { alarm: Alarm }
  [AlarmTypes.SetAlarmList]: { list: Alarm[] }
  [AlarmTypes.AddAlarmToList]: { alarm: Alarm }
  [AlarmTypes.UpdateAlarmByID]: { alarm: Alarm }
  [AlarmTypes.RemoveAlarmFromList]: { alarmID: string }
  [AlarmTypes.SetCurrentCriterium]: { criterium: AlertCriterium | null }
  [AlarmTypes.SetCurrentSchedule]: { schedule: AlarmSchedule | null }
  [AlarmTypes.SetAlertNotifications]: {
    alertNotifications: AlertNotification[]
  }
  [AlarmTypes.AddAlertNotification]: { alertNotification: AlertNotification }
  [AlarmTypes.SetCurrentAlertNotification]: {
    alertNotification: AlertNotification | null
  }
  [AlarmTypes.UpdateAlertNotificationByID]: {
    alertNotification: AlertNotification
  }
  [AlarmTypes.UpdateAlarmCriteriumByID]: { criterium: AlertCriterium }
  [AlarmTypes.SetCurrentEditorPanel]: { panel: AlarmSetupPanels | null }
  [AlarmTypes.ResetAlarmForm]: null
  [AlarmTypes.Reset]: null
}

export type AlarmActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const AlarmActions = createActions<Actions>()
