import React from "react"
import "twin.macro"
import { Button, ButtonGroup, Responsive } from "@clevertrack/shared"

type EditorButtonGroupProps = {
  onSave: (...args) => void
  onCancel: (...args) => void
  saveDisabled: boolean
}

export const EditorButtonGroup: React.FC<EditorButtonGroupProps> = ({
  onCancel,
  onSave,
  saveDisabled,
  ...props
}) => {
  return (
    <Responsive
      phone={
        <ButtonGroup
          sticky
          tw="mt-16 bg-white"
          style={{ zIndex: 2000 }}
          {...props}
        >
          <Button variant="transparent" onClick={onCancel}>
            Annullér
          </Button>
          <Button variant="primary" disabled={saveDisabled} onClick={onSave}>
            Gem
          </Button>
        </ButtonGroup>
      }
      tabletLandscape={
        <ButtonGroup tw="mt-4 bg-white" {...props}>
          <Button variant="transparent" onClick={onCancel}>
            Annullér
          </Button>
          <Button variant="primary" disabled={saveDisabled} onClick={onSave}>
            Gem
          </Button>
        </ButtonGroup>
      }
    />
  )
}
