import React from "react"
import styled from "styled-components"
import "twin.macro"
import Icon from "lib/Icon"
import { Result as DefaultResult } from "app/ResultListGroup/Result"

const StyledEmptyItem = styled(DefaultResult)`
  padding: 1.5rem;
`

const FlexItem = styled.div`
  flex: 1;
  font-weight: bold;
`

const StyledIcon = styled(Icon)`
  opacity: 0.9;
  flex: 0.25;
  transition: transform 0.2s;
  transform: scale(1.25)
    ${(props) => (props.rotate ? `rotate(135deg)` : `rotate(0deg)`)};
`

const Status = styled.div`
  background: ${(props) =>
    props.active ? props.theme.colors.success : props.theme.colors.grayLight};
  color: ${(props) => (props.active ? "white" : props.theme.colors.grayDark)};
  font-size: 65%;
  padding: 0.3rem 0.6rem;
  display: inline;
`

const Main = styled.div`
  display: flex;
  align-items: center;
`

function EmptyItem({ tracker, toggle, ...props }) {
  return (
    <StyledEmptyItem {...props}>
      <Main>
        <FlexItem>
          <div>{tracker.name}</div>
          <div tw="font-normal text-lg opacity-80">{tracker.note}</div>
        </FlexItem>
        <StyledIcon size="sm" icon="plus" rotate={toggle} />
      </Main>
    </StyledEmptyItem>
  )
}

export default EmptyItem
