import { AxiosResponse } from "axios"
import { privateApi } from "utils/api"
import { IZone } from "routes/Zones/zones.types"
import { APIResponse } from "./service.types"

interface ZonesAPIResponse extends APIResponse {
  zones: IZone[]
}

export interface GetAllZonesResponse extends AxiosResponse {
  data: ZonesAPIResponse
}

export const zoneService = () => {
  const getAllZones = async () => {
    try {
      const response = await privateApi().get("/zones")
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const createZone = async (body: IZone) => {
    try {
      const response = await privateApi().post(`/zones`, body)
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const updateZone = async (body: IZone) => {
    try {
      const { id, ...data } = body
      const response = await privateApi().patch(`/zones/${id}`, data)
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const deleteZone = async (id: number) => {
    try {
      const response = await privateApi().delete(`/zones/${id}`)
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const service = { getAllZones, createZone, updateZone, deleteZone }
  return service
}
