import tw from "twin.macro"

export function getStatusKey(status) {
  switch (status) {
    case "0":
    case 0:
      return "stopped" // Stopped
    case "1":
    case 1:
      return "running" // Running
    case "2":
    case 2:
      return "idle" // Idle
    case 3:
    case "3":
    case 6:
    case "6":
      return "working" // Working
    case 4:
    case "4":
      return "movement" // In transport
    default:
      return "unknown" // Unknown
  }
}

export function getStatusCode(status) {
  switch (status) {
    case "0":
    case 0:
      return 0 // Stopped
    case 1:
    case "1":
      return 1 // Running
    case 2:
    case "2":
      return 2 // Idle
    case 3:
    case "3":
    case 6:
    case "6":
      return 3 // Working
    case 4:
    case "4":
      return 4 // In transport
    default:
      return 5 // Unknown
  }
}

export const statusMap = [
  { code: 0, strKey: "legend_stopped" },
  { code: 1, strKey: "legend_ignition" },
  { code: 2, strKey: "legend_idling" },
  { code: 3, strKey: "legend_working" },
  { code: 4, strKey: "legend_movement" },
  { code: 5, strKey: "legend_outdated" },
]

export const signatureMap = [
  { code: 0, key: "stopped", strKey: "legend_stopped", icon: null },
  {
    code: 1,
    key: "ignition",
    strKey: "legend_ignition",
    icon: null,
    subStates: [
      {
        code: 1,
        key: "driving",
        strKey: "legend_driving",
        icon: "location-arrow",
        iconStyle: tw`text-white transform rotate-45 scale-50`,
      },
    ],
  },
  { code: 2, key: "idling", strKey: "legend_idling", icon: null },
  { code: 3, key: "working", strKey: "legend_working", icon: null },
  {
    code: 4,
    key: "transport",
    strKey: "legend_movement",
    icon: null,
    subStates: [
      {
        code: 4,
        key: "driving",
        strKey: "legend_transport",
        icon: "location-arrow",
        iconStyle: tw`text-white transform rotate-45 scale-50`,
      },
    ],
  },
  { code: 5, key: "outdated", strKey: "legend_outdated", icon: null },
]
