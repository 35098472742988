export interface IDisplayKey {
  name: DisplayKeyEnum
  value: string | number
  type: DisplayKeyValueType
}

export enum DisplayKeyEnum {
  Address = "address",
  LastConfirmationTime = "lastConfirmationTime",
  LastSeenBy = "lastSeenBy",
  Speed = "speed",
  FirstStartToday = "firstStartToday",
  LatestStopToday = "latestStopToday",
  DrivingHoursToday = "drivingHoursToday",
  IdleHoursToday = "idleHoursToday",
  StopHoursToday = "stopHoursToday",
  DailyDistanceToday = "dailyDistanceToday",
  EngineHoursToday = "engineHoursToday",
  FuelConsumptionToday = "fuelConsumptionToday",
  TotalDistance = "totalDistance",
  TotalEngineHours = "totalEngineHours",
  TotalDrivingHours = "totalDrivingHours",
  TotalMovingHours = "totalMovingHours",
  LastService = "lastService",
  NextService = "nextService",
  Temperature = "temperature",
  Humidity = "humidity",
  TotalIdleHours = "totalIdleHours",
  TotalFuel = "totalFuel",
  MachineWithCAN = "machineWithCAN",
  LastIgnitionStop = "lastIgnitionStop",
  BatteryLongevity = "batteryLongevity",
  BatteryLongevityVolt72 = "batteryLongevityVolt72",
  FuelLevel = "fuelLevel",
}

export type DisplayKey =
  | "address"
  | "lastConfirmationTime"
  | "lastSeenBy"
  | "speed"
  | "firstStartToday"
  | "latestStopToday"
  | "drivingHoursToday"
  | "idleHoursToday"
  | "stopHoursToday"
  | "dailyDistanceToday"
  | "engineHoursToday"
  | "fuelConsumptionToday"
  | "totalDistance"
  | "totalEngineHours"
  | "totalDrivingHours"
  | "lastService"
  | "nextService"
  | "temperature"
  | "humidity"
  | "totalIdleHours"
  | "totalFuel"
  | "lastIgnitionStop"
  | "batteryLongevity"
  | "batteryLongevityVolt72"
  | "fuelLevel"

export type DisplayKeyValueType =
  | "TimeSpan"
  | "int"
  | "float"
  | "DateTime"
  | "string"
