import React from "react"
import styled from "styled-components"
import { useOnScreen } from "hooks/useOnScreen"
import { siteTheme as theme } from "theme"

const StyledTextarea = styled.div`
  ${(props) =>
    theme.utils.getBoxModel(
      theme.input[props.size].padding,
      null,
      theme.input[props.size].margin
    )};

  textarea {
    line-height: ${(props) => `${theme.input[props.size].lineHeight}rem`};
    width: 100%;
    border: none;
  }

  ${(props) =>
    props.renderAsFormField &&
    `
      padding-top: ${`${(theme.input[props.size].padding[0] * 2).toFixed(
        2
      )}rem`};
      padding-bottom: ${`${(theme.input[props.size].padding[0] * 0.75).toFixed(
        2
      )}rem`};

      textarea {
        border: none;
        padding: 0;
        margin: 0;
      }
    `}
`

const Textarea = React.forwardRef(
  (
    { size, renderAsFormField, value, disableTabWhileInvisible, ...props },
    ref
  ) => {
    const isOnScreen = useOnScreen(ref)
    return (
      <StyledTextarea renderAsFormField={renderAsFormField} size={size || "md"}>
        <textarea
          value={value}
          ref={ref}
          tabIndex={!isOnScreen && disableTabWhileInvisible ? "-1" : ""}
          {...props}
        />
      </StyledTextarea>
    )
  }
)

export default Textarea

/* Textarea.defaultProps = {
  size: "md",
  renderAsFormField: false,
  value: "",
}
Textarea.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  renderAsFormField: PropTypes.bool,
  value: PropTypes.node,
}
 */
