import React, { useContext, useState, useEffect } from "react"
import "twin.macro"
import { UserContext } from "app/User/context"
import {
  AlertNotificationMethodEnum,
  IAlarmNotificationRecipient,
} from "routes/Alarms/alarm.types"
import { IUser } from "app/User/types"
import sortedUniqBy from "lodash-es/sortedUniqBy"
import sortBy from "lodash-es/sortBy"
import { FormField } from "@clevertrack/shared"
import { Select } from "lib/Select"
import Accordion from "lib/Accordion"
import Icon from "app/Icon"
import { IconSizeEnum } from "lib/Icon"
import { ContactForm } from "app/Conversations/Contact/Form"
import { useConversation } from "app/Conversations/hooks"
import { useFirestoreContact } from "services/firestore/contact"
import cogoToast from "@clevertrackdk/cogo-toast"
import { getUsers } from "services/user"

type RecipientProps = {
  selectedRecipients?: IAlarmNotificationRecipient[]
  onSelectRecipient: (recipient: IAlarmNotificationRecipient) => void
  onRemoveRecipient: (recipientID: string) => void
}

const TemplateOption = ({ innerProps, innerRef, data }) => {
  const { deleteCompanyContactHard } = useFirestoreContact()
  if (data.isSelected) return null

  const onDeleteContactHandler = (e, id) => {
    e.stopPropagation()
    deleteCompanyContactHard(id).then(() => {
      cogoToast.success("Kontakt slettet")
    })
  }

  return (
    <div
      tw="px-4 py-4 hover:bg-brand-gray-lighter cursor-pointer relative"
      ref={innerRef}
      {...innerProps}
    >
      <span tw="block text-2xl">
        {[data.value.first_name, data.value.last_name].join(" ")}
      </span>
      <span tw="block text-lg opacity-60">{data.value.email}</span>
      {data.value.customContact && (
        <span
          tw="absolute right-4 top-4 text-brand-red-500 text-lg"
          onClick={(e) => onDeleteContactHandler(e, data.value.id)}
        >
          Slet
        </span>
      )}
    </div>
  )
}

export const Recipients: React.FC<RecipientProps> = ({
  onSelectRecipient,
  onRemoveRecipient,
  selectedRecipients,
  ...props
}) => {
  const {
    state: { user },
  } = useContext(UserContext)
  const [users, setUsers] = useState([])
  const {
    contactsSorted,
    subscribeToContacts,
    unsubscribeToContacts,
  } = useConversation()

  const availableUsers: IAlarmNotificationRecipient[] = sortedUniqBy(
    sortBy([user, ...users], "firstName"),
    "id"
  ).map((u: IUser) => {
    return {
      id: u.id,
      first_name: u.firstName,
      last_name: u.lastName,
      email: u.email,
      phone: u.phoneNumber,
      area_code: "45",
      notificationMethods: [AlertNotificationMethodEnum.Email],
    }
  })

  const selectUserOptions = availableUsers.map((u) => {
    return {
      label: [u.first_name, u.last_name].join(" "),
      value: u,
      isSelected:
        selectedRecipients.length > 0 &&
        selectedRecipients?.some((recipient) => recipient?.id === u?.id),
    }
  })

  const selectContactOptions = contactsSorted.map((u) => {
    return {
      label: [u.first_name, u.last_name].join(" "),
      value: {
        ...u,
        customContact: true,
      },
      isSelected:
        selectedRecipients.length > 0 &&
        selectedRecipients?.some((recipient) => recipient?.id === u?.id),
    }
  })

  const recipientGroups = [
    {
      label: "Brugere",
      options: selectUserOptions,
    },
    {
      label: "Kontakter",
      options: selectContactOptions,
    },
  ].filter((x) => x.options.filter((x) => !x.isSelected).length > 0)

  const [showContactForm, setShowContactForm] = useState<boolean>(false)
  const onAddContact = () => {
    setShowContactForm(true)
  }

  const loadUsers = async () => {
    try {
      const response = await getUsers()
      const { Users } = response.data
      setUsers(Users)
    } catch (e) {}
  }

  useEffect(() => {
    subscribeToContacts()
    loadUsers()
    return () => {
      unsubscribeToContacts()
      setUsers([])
    }
  }, [])

  const renderRecipient = (recipient: IAlarmNotificationRecipient) => {
    return (
      <li key={recipient?.id}>
        <span tw="flex flex-row justify-between items-center px-4 py-2 transition hover:bg-brand-gray-lighter">
          <span tw="text-2xl">
            <span tw="flex items-center">
              {[recipient.first_name, recipient.last_name].join(" ")}
              <span
                tw="flex items-center text-brand-red-500 ml-4 cursor-pointer transition hover:opacity-60"
                onClick={() => onRemoveRecipient(recipient.id)}
              >
                <span tw="mr-2 text-xl">Fjern</span>
                <Icon size={IconSizeEnum.SM} icon="close" />
              </span>
            </span>
            <span tw="block text-xl opacity-60">
              {recipient.email}
              {recipient.phone !== "" ? (
                <span tw="inline-block ml-2">
                  {["|", recipient.area_code, recipient.phone].join(" ")}
                </span>
              ) : (
                ""
              )}
            </span>
          </span>
          {/* <span>
            <Checkbox
              checked={recipient.notificationMethods.includes(
                AlertNotificationMethodEnum.Email
              )}
            />
          </span> */}
        </span>
      </li>
    )
  }

  return (
    <section tw="mb-8">
      <header tw="mt-0 pb-8 pt-0">
        <h4 tw="my-0 block">
          Modtagere
          <small tw="font-normal block">
            Hvem skal modtage underretninger?
          </small>
        </h4>
      </header>
      <FormField label="Find modtager">
        <Select
          components={{
            Option: TemplateOption,
          }}
          placeholder="Søg efter en modtager"
          options={recipientGroups}
          hideSelectedOptions
          closeMenuOnSelect
          onChange={(opt) => onSelectRecipient(opt.value)}
          blurInputOnSelect
          isClearable={false}
          controlShouldRenderValue={false}
          tw="text-3xl"
          style={{ zIndex: 700 }}
        />
      </FormField>
      {!showContactForm && (
        <span
          tw="flex items-center text-brand-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
          onClick={onAddContact}
        >
          <span tw="mr-2 text-xl border-dotted border-0 border-b">
            Opret kontaktperson
          </span>
          <Icon size={IconSizeEnum.SM} icon="plus" />
        </span>
      )}
      <Accordion toggled={showContactForm}>
        <ContactForm
          inline
          onClose={() => setShowContactForm(false)}
          onContactCreated={(contact) => onSelectRecipient(contact)}
        />
      </Accordion>
      <Accordion toggled={selectedRecipients.length > 0}>
        <ul tw="p-0 m-0 list-none space-y-2 mt-4">
          <li tw="flex justify-between items-center p-0 px-4 pt-2 text-2xl">
            <span tw="font-bold">Valgte modtagere</span>
            {/* <span>
              <span>Email</span>
            </span> */}
          </li>
          {selectedRecipients?.map((recipient) => renderRecipient(recipient))}
        </ul>
      </Accordion>
    </section>
  )
}
