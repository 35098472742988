import { privateApi } from "utils/api"

/**
 * Service for updating vehicle calibration
 * @param {number} id vehicle id
 * @param {object} body contains name, description + calibrated mileage / hours
 */

const updateVehicleCalibration = async (id, body) => {
  try {
    const response = privateApi().patch(`/VehiclesCalibration/${id}`, body)
    return response
  } catch (err) {
    console.log(err)
  }
}

/**
 * Service for getting name, description and calibrated mileage / hours.
 * @param {number} id vehicle id
 */

const readVehicleCalibration = async (id) => {
  try {
    const response = privateApi().get(`/VehiclesCalibration/${id}`)
    return response
  } catch (err) {
    console.log(err)
  }
}

export { updateVehicleCalibration, readVehicleCalibration }
