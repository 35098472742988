import React from "react"
import tw from "twin.macro"
import styled from "styled-components"

const StyledResultListGroup = styled.div<{ columns: number }>`
  ${tw`grid`}
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  grid-column-gap: 2rem;

  h4 {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`

export const ResultListGroup: React.FC<{
  children: React.ReactNode
  columns?: number
}> = ({ columns = 2, children, ...props }) => {
  return (
    <StyledResultListGroup columns={columns} {...props}>
      {children}
    </StyledResultListGroup>
  )
}

export default ResultListGroup
