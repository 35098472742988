import React, { useReducer, createContext } from "react"
import { NotificationActionMap, NotificationTypes } from "./actions"
import { PlatformStatusNotificationProps } from "./notificationcenter.types"

type InitialNotificationCenterStateType = {
  platformUpdates: PlatformStatusNotificationProps[]
}

const initialState: InitialNotificationCenterStateType = {
  platformUpdates: [],
}

const NotificationCenterContext = createContext<{
  state: InitialNotificationCenterStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const reducer = (
  state: InitialNotificationCenterStateType,
  action: NotificationActionMap
) => {
  switch (action.type) {
    case NotificationTypes.SetPlatformUpdates:
      return { ...state, platformUpdates: action.payload.updates }
    default:
      return state
  }
}

const NotificationCenterProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <NotificationCenterContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationCenterContext.Provider>
  )
}

export { NotificationCenterContext, NotificationCenterProvider }
