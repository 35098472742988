import React from "react"
import PropTypes from "prop-types"
import { Duration, Number, Text, Timestamp } from "app/TrackerKPI"

function KPIWidget({ name, value, type, icon, unit, ...props }) {
  function renderKpiWidget() {
    /**
     * One of
     * TimeSpan
     * float
     * DateTime
     * string
     * int
     */
    switch (type) {
      case "TimeSpan":
        return (
          <Duration
            key={name}
            icon={icon}
            kpiTitle={name}
            data={value}
            unit={unit}
            {...props}
          />
        )
      case "int":
        return (
          <Number
            key={name}
            icon={icon}
            kpiTitle={name}
            data={(+value).toFixed(1)}
            unit={unit}
            {...props}
          />
        )
      case "float":
        return (
          <Number
            key={name}
            icon={icon}
            kpiTitle={name}
            data={value}
            unit={unit}
            {...props}
          />
        )
      case "DateTime":
        const formatStr =
          name.toLowerCase().indexOf("service") > 0 ? "dd-MM-yyyy" : null
        return (
          <Timestamp
            key={name}
            format={formatStr}
            icon={icon}
            kpiTitle={name}
            data={value}
            unit={unit}
            {...props}
          />
        )
      case "string":
      case "":
        return (
          <Text
            key={name}
            icon={icon}
            kpiTitle={name}
            data={value}
            unit={unit}
            {...props}
          />
        )
      default:
        console.warn("Unhandled data type encountered", type)
        console.warn("This warning was thrown by widget:", props)
        return null
    }
  }

  return renderKpiWidget()
}

export default KPIWidget

KPIWidget.defaultProps = {}
KPIWidget.propTypes = {
  children: PropTypes.node,
}
