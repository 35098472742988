import Icon from "app/Icon"
import { IconSizeEnum } from "lib/Icon"
import React from "react"
import "twin.macro"

export const EditorPanelHeader: React.FC = ({ children, onBack, ...props }) => {
  return (
    <header tw="flex items-center justify-between pb-8 md:(py-8) lg:block">
      <h3 tw="my-0 md:(my-8)">{children}</h3>
      {onBack && (
        <a
          tw="md:hidden text-brand-500 text-xl cursor-pointer flex items-center"
          onClick={onBack}
        >
          <Icon tw="mr-2" size={IconSizeEnum.SM} icon="long-arrow-left" />
          <span>Tilbage</span>
        </a>
      )}
    </header>
  )
}
