import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { UserContext } from "./context"
import UserName from "./Name"
import { NotificationCenterWidget } from "app/NotificationCenter"
import { Responsive } from "@clevertrack/shared"

const StyledUser = styled.div<{ withAvatar: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.withAvatar ? `4rem 1fr 1fr` : `1fr`};
  grid-column-gap: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.grayLight};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: ${(props) => (props.withAvatar ? `0` : `2rem`)};

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-left: 2rem;
  `}
`

const StyledAvatar = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  background: ${(props) => props.theme.colors.grayLight};
  margin-right: 1rem;
  text-transform: uppercase;
`

function User({ children, ...props }) {
  const {
    state: { user },
  } = useContext(UserContext)

  if (!user) return null
  return (
    <StyledUser withAvatar={user.firstName} {...props}>
      {user.firstName && (
        <StyledAvatar>{user.firstName.split("")[0]}</StyledAvatar>
      )}
      <UserName {...user} />
      <Responsive
        phone={<NotificationCenterWidget tw="ml-auto pr-4 md:hidden" />}
        tabletLandscape={<></>}
      />
    </StyledUser>
  )
}

export default User

User.defaultProps = {
  children: null,
}
User.propTypes = {
  children: PropTypes.node,
}
