import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import KPIBase from "./Base"

const StyledText = styled(KPIBase)``

function Text({ data, ...props }) {
  const displayData = props.formatDataFnc ? props.formatDataFnc(data) : data
  return displayData ? <StyledText {...props}>{displayData}</StyledText> : null
}

export default Text

Text.defaultProps = {}
Text.propTypes = {
  data: PropTypes.string.isRequired,
}
