import React, { useContext } from "react"
import Checkbox from "lib/Checkbox"
import "twin.macro"
import {
  Form,
  FormField,
  Input,
  Textarea,
  Button,
  ButtonGroup,
  ValidationInput,
} from "@clevertrack/shared"
import { IZoneMeta, ZoneFormActionsEnum } from "../zones.types"
import { ZonesContext } from "../context"
import { ZonesActions, ZonesTypes } from "../actions"
import I18n from "app/I18n"
import { getDefaultValidationMessagesByLang } from "utils/validation"

interface CreateZoneFormProps {
  zoneMeta?: IZoneMeta
  canSubmit: boolean
  onSubmit: (...args) => void
  onCancel: (...args) => void
}

export const CreateZoneForm: React.FC<CreateZoneFormProps> = React.memo(
  ({ zoneMeta, onSubmit, onCancel, canSubmit, ...props }) => {
    const {
      dispatch,
      state: { editZone },
    } = useContext(ZonesContext)

    const onFieldChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      key: string
    ) => {
      const newData = { ...editZone, [key]: e.target.value }
      dispatch(
        ZonesActions(ZonesTypes.SetCurrentEditingZone, { zone: newData })
      )
    }

    const onToggleActive = (value: boolean) => {
      const newData = { ...editZone, active: value }
      dispatch(
        ZonesActions(ZonesTypes.SetCurrentEditingZone, { zone: newData })
      )
    }

    const onSubmitHandler = (e) => {
      e.preventDefault()
      if (editZone.id) onSubmit(ZoneFormActionsEnum.SAVE)
      else onSubmit(ZoneFormActionsEnum.CREATE)
    }

    const defaultValidationMessages = getDefaultValidationMessagesByLang("da")

    return (
      <Form tw="h-full md:h-auto" onSubmit={onSubmitHandler}>
        <section tw="px-8 pb-8 md:(px-0)">
          <FormField validationKey="title" label="Titel*">
            <ValidationInput
              id="title"
              type="text"
              placeholder="Titel"
              required
              onChange={(e) => onFieldChange(e, "name")}
              value={editZone.name}
              validationMessages={defaultValidationMessages}
            />
          </FormField>
          <FormField validationKey="description" label="Beskrivelse af zonen">
            <Textarea
              placeholder="Beskrivelse"
              onChange={(e) => onFieldChange(e, "description")}
              value={editZone.description}
            />
          </FormField>
          <FormField validationKey="active" tw="border-0">
            <Checkbox
              onChange={onToggleActive}
              appearance="toggle"
              checked={editZone?.active}
            >
              <span>Er zonen aktiv?</span>
            </Checkbox>
          </FormField>
          <ButtonGroup
            tw="hidden md:flex mt-12 border-0 border-t border-solid border-gray-300"
            position="right"
          >
            <Button invert variant="default" type="button" onClick={onCancel}>
              <I18n strKey="cancel" />
            </Button>
            <Button type="submit" variant="primary" disabled={!canSubmit}>
              {editZone.id ? "Gem zone" : "Opret zone"}
            </Button>
          </ButtonGroup>
        </section>
        <ButtonGroup tw="px-8 md:hidden" sticky position="between">
          <Button invert variant="default" type="button" onClick={onCancel}>
            <I18n strKey="cancel" />
          </Button>
          <Button
            onClick={onSubmitHandler}
            variant="primary"
            disabled={!canSubmit}
          >
            {editZone.id ? "Gem zone" : "Opret zone"}
          </Button>
        </ButtonGroup>
      </Form>
    )
  }
)
