import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"

interface IBaseContactProps {
  prefix?: React.ReactNode
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  isDisabled?: boolean
  allowSelectWhileDisabled?: boolean
}

interface IContactHeaderProps {
  name: React.ReactNode
  appendix?: React.ReactNode
}

export const ContactHeader: React.FC<IContactHeaderProps> = ({
  appendix,
  name,
  ...props
}) => {
  return (
    <span tw="flex flex-grow items-center justify-between" {...props}>
      <span tw="text-xl truncate w-1/2 md:(text-2xl max-w-xl) font-bold">
        {name}
      </span>
      {appendix && (
        <span tw="text-lg md:(text-xl) text-gray-400">{appendix}</span>
      )}
    </span>
  )
}

export const ContactLatestMessage: React.FC<{
  highlight?: string
  body: string
  read?: boolean
  latestMessageDeliveryError?: boolean
}> = ({ highlight, body, read, latestMessageDeliveryError, ...props }) => {
  const highlightIndex = body.toLowerCase().indexOf(highlight?.toLowerCase())
  let bodyBeforeHighlight
  let bodyAfterHighlight
  if (highlight && highlightIndex > -1) {
    bodyBeforeHighlight =
      highlightIndex > 50
        ? `...${body.substring(highlightIndex - 30, highlightIndex)}`
        : `${body.substring(0, highlightIndex)}`

    bodyAfterHighlight =
      highlightIndex > 50
        ? `${body.substring(
            highlightIndex + highlight.length,
            highlightIndex + highlight.length + 80
          )}`
        : `${body.substring(highlightIndex + highlight.length)}`
  }

  const renderBody = () => {
    if (highlight && highlightIndex > -1) {
      return (
        <>
          <span>{bodyBeforeHighlight}</span>
          <span tw="font-bold">
            {body.substring(highlightIndex, highlightIndex + highlight.length)}
          </span>
          <span>{bodyAfterHighlight}</span>
        </>
      )
    }
    return body
  }

  return (
    <span tw="text-lg md:(text-xl) flex items-center" {...props}>
      {read ? (
        <span tw="text-gray-400 max-w-sm truncate overflow-hidden md:(max-w-md) lg:(max-w-xl) xl:(max-w-2xl)">
          {renderBody()}
        </span>
      ) : (
        <strong tw="max-w-sm truncate overflow-hidden md:(max-w-md) lg:(max-w-xl) xl:(max-w-2xl)">
          {renderBody()}
        </strong>
      )}
      {latestMessageDeliveryError && (
        <span tw="flex items-center text-brand-red-500 ml-4">
          <Icon icon="exclamation-triangle" size={IconSizeEnum.SM} />
        </span>
      )}
    </span>
  )
}

export const ContactPrefix: React.FC = ({ children, ...props }) => {
  return (
    <span
      tw="flex items-center justify-center w-12 h-12 rounded-full bg-brand-gray-light text-brand-gray-base"
      {...props}
    >
      <span tw="font-bold text-lg">{children}</span>
    </span>
  )
}

const StyledBaseContact = styled.li<{
  isDisabled: boolean
  allowSelectWhileDisabled: boolean
}>`
  ${tw`grid items-center py-4 px-4 md:(px-8) transition-all bg-white hover:bg-brand-gray-lighter cursor-pointer`}
  grid-template-columns: 3rem auto;
  column-gap: 1rem;

  ${(props) =>
    props.isDisabled ? tw`opacity-50 hover:bg-white cursor-default` : ``}

  ${(props) =>
    props.allowSelectWhileDisabled
      ? tw`cursor-pointer hover:(bg-brand-gray-lighter opacity-100)`
      : ``}
`

export const BaseContact: React.FC<IBaseContactProps> = ({
  children,
  prefix,
  isDisabled,
  allowSelectWhileDisabled,
  ...props
}) => {
  return (
    <StyledBaseContact
      isDisabled={!!isDisabled}
      allowSelectWhileDisabled={!!allowSelectWhileDisabled}
      {...props}
    >
      {prefix && <span>{prefix}</span>}
      {children && <span tw="flex flex-col">{children}</span>}
    </StyledBaseContact>
  )
}
