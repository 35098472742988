import React, { useState, useEffect } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

const StyledOffcanvasInner = styled((props) => <animated.div {...props} />)`
  position: fixed;
  top: ${(props) => (props.direction === "bottom" ? 0 : 0)};
  bottom: ${(props) => (props.direction === "top" ? "auto" : 0)};
  left: ${(props) => (props.direction === "right" ? 0 : 0)};
  right: ${(props) => (props.direction === "left" ? "auto" : 0)};
  pointer-events: ${(props) => (props.show ? "all" : "none")};
`

export interface IOffCanvasInnerProps {
  fromTop?: boolean
  fromRight?: boolean
  fromBottom?: boolean
  fromLeft?: boolean
  customYOffset?: string
  show?: boolean
  onRest?: (...args) => void
}

const OffCanvasInner: React.FC<IOffCanvasInnerProps> = ({
  children,
  fromTop,
  fromRight,
  fromBottom,
  fromLeft,
  customYOffset,
  show,
  onRest,
  ...props
}) => {
  const [initialized, setInitialized] = useState(false)
  let direction
  let transform = ["", ""]

  useEffect(() => {
    // Simple assertion of wether or not the component mounted.
    if (!initialized) setInitialized(true)
    return function cleanup() {}
  }, [initialized])

  if (fromTop) {
    direction = "top"
    transform = customYOffset
      ? [`translateY(${customYOffset})`, "translateY(0%)"]
      : [`translateY(-100%)`, "translateY(0%)"]
  }
  if (fromBottom) {
    direction = "bottom"
    transform = customYOffset
      ? [`translateY(${customYOffset})`, "translateY(0%)"]
      : [`translateY(100%)`, "translateY(0%)"]
  }
  if (fromRight) {
    direction = "right"
    transform = ["translateX(100%)", "translateX(0%)"]
  }
  if (fromLeft) {
    direction = "left"
    transform = ["translateX(-100%)", "translateX(0%)"]
  }

  // Using array deconstruction, we can easily switch between transition states
  let [from, to] = transform
  if (!show) [to, from] = transform

  const styleProps = useSpring({
    from: { transform: from },
    to: { transform: to },
    immediate: !initialized,
    onRest: () => (onRest ? onRest() : {}),
  })

  return (
    <StyledOffcanvasInner
      direction={direction}
      show={show ? 1 : 0}
      style={styleProps}
      {...props}
    >
      {children}
    </StyledOffcanvasInner>
  )
}

export default OffCanvasInner
