import React, { useState, useEffect, useCallback, useRef, useMemo } from "react"
import styled from "styled-components"
import Checkbox from "lib/Checkbox"
import tw from "twin.macro"
import I18n from "app/I18n"
import Panel from "app/Panel"
import KpiGrid from "../Result/KpiGrid"
import {
  ValidationInput,
  Textarea,
  FormField,
  Form,
  Button,
  ButtonGroup,
  Responsive,
  TagInput,
} from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import Accordion from "lib/Accordion"
import emailValidation from "utils/emailValidation"
import useOnClickOutside from "hooks/useOnClickOutside"

import { parse, parseJSON, addMonths, differenceInMonths } from "date-fns"
import DateFilter from "app/History/DateFilter"
import uniq from "lodash-es/uniq"
import { TrackerLabel } from "./EditService"

const StyledSetupForm = styled(Panel)`
  padding-top: 1rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 1rem;
    padding-bottom: 0;
  `}
`

const StyledForm = styled(Form)`
  section {
    padding: 0 2rem;
  }

  ${(props) => props.theme.media.desktop_up`
    width: 84%;
 `}
`

const Styledh2 = styled.h2`
  // color: ${(props) => props.theme.colors.gray};
  margin-top: 0;
  margin-bottom: 0;
  ${tw`text-4xl`}

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-top: 4rem;
  `}
`

const ToggleGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;

  ${(props) => props.theme.media.big_desktop_up`
    grid-template-columns: repeat(4, 1fr);
 `}
`

const Toggle = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 0.5rem;
  background: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.grayLighter};
  font-size: 1.3rem;
  font-weight: bold;

  &:hover {
    background: ${(props) => props.theme.colors.grayDark};
  }
`

const Setup = styled.div`
  padding: 2rem;
  padding-bottom: 8rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-bottom: 1rem;
  `}
`

const SwitchWrapper = styled.div`
  padding: 1.5rem 0;
`

const DatepickerWrapper = styled.div`
  margin-top: 2rem;
  // margin-bottom: 2rem;
`

function SetupForm({
  data,
  setupParams,
  onExit,
  interval,
  onCreate,
  onUpdate,
  onDelete,
  setupTrackerData,
  ...props
}) {
  const typeUnits = {
    Kilometer: "km",
    Motortimer: "timer",
    Eftersyn: "mdr",
    Syn: "mdr",
  }

  const { name, note } = useMemo(() => {
    if (data.deviceMeta) {
      return { name: data.deviceMeta.name, note: data.deviceMeta.note }
    } else return { name: null, note: null }
  }, [data])

  const [currentInterval, setCurrentInterval] = useState(interval)
  const [intervalType, setIntervalType] = useState<number | null>(
    interval.intervalType
  )
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [showDP, setShowDP] = useState(false)
  const [intervalValue, setIntervalValue] = useState(null)
  const [date, setDate] = useState(new Date())
  const [limits, setLimits] = useState({ min: 1, max: Infinity })

  const parseDate = useCallback(
    (date_) => parse(`${date_} 00:00`, "yyyy-MM-dd HH:mm", new Date()),
    []
  )

  // THIS LINE IS BROKEN - FIX 🙌
  // const onSelectHandler = (d) => setDate(parseDate(d))
  const onSelectDateHandler = (d) => setDate(d)

  const setLimitsByUnit = (unit) => {
    switch (unit) {
      case "km":
        setLimits({ ...limits, min: 100 })
        break
      case "timer":
        setLimits({ ...limits, min: 24 })
        break
      case "mdr":
        setLimits({ ...limits, min: 1 })
        break
    }
  }

  const [emailList, setEmailList] = useState<string[]>([])

  useEffect(() => {
    if (interval.unit !== "mdr") {
      if (interval.endValue === ``) {
        setIntervalValue(``)
      } else {
        setIntervalValue(interval.intervalValue)
      }
    } else {
      // Normalize dates
      interval.startDate = parseJSON(interval.startDate)
      interval.endDate = parseJSON(interval.endDate)
      setIntervalValue(differenceInMonths(interval.endDate, interval.startDate))
    }

    setEmailList(interval.email?.split(";").filter((x) => x !== ""))
    setDate(interval.startDate)
    setIntervalType(interval.intervalType)

    interval.emailMsg = interval?.emailMsg || ``

    // interval.startValue = Number(Math.floor(interval.startValue))

    console.log(interval)

    setCurrentInterval(interval)
    setLimitsByUnit(interval.unit)
  }, [interval])

  useEffect(() => {
    const intervalVal = Number(intervalValue)
    const newEndDate = addMonths(date, intervalVal)
    onChangeInterval({
      startDate: date,
      endDate: newEndDate,
    })
  }, [date])

  function onDeleteInterval(id) {
    onDelete(id)
    setConfirmDelete(false)
  }

  function onDeleteHandler() {
    setConfirmDelete(!confirmDelete)
  }

  const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
  const validationMessages = {
    ...defaultValidationMessages,
    customError: "Ups! Indtast en korrekt email.",
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    switch (setupParams) {
      case "new":
        onCreate({ ...currentInterval, intervalValue, intervalType }, data.id)
        break
      default:
        onUpdate({ ...currentInterval, intervalValue, intervalType })
    }
  }

  const exitHandler = () => {
    onExit()
    setConfirmDelete(false)
    setShowDP(false)
  }

  const onToggleHandler = (type) => {
    const newUnit = typeUnits[type]
    const startValue =
      newUnit === "km"
        ? Number(Math.floor(data.mileage))
        : Number(Math.floor(data.hours))
    const newStart = startValue > 0 ? startValue : ``

    setLimitsByUnit(newUnit)

    // startValue can be set = newStart, to insert current value in field
    onChangeInterval({
      type,
      unit: newUnit,
      startValue: ``,
    })
  }

  const onChangeInterval = (append) => {
    const modifiedInterval = {
      ...currentInterval,
      ...append,
    }
    setCurrentInterval(modifiedInterval)
  }

  const onAddEmailHandler = (email) => {
    // Add to list
    const newList = uniq([email, ...emailList])
    setEmailList(newList)
    onChangeInterval({ email: newList.join(";") })
  }

  const onEmailKeyDownHandler = (e) => {
    if (e.which === 13 && emailValidation(e.target.value)) {
      onAddEmailHandler(e.target.value)
      e.target.value = ""
    }
  }

  const onEmailBlurHandler = (e) => {
    if (emailValidation(e.target.value)) {
      onAddEmailHandler(e.target.value)
      e.target.value = ""
    }
  }

  const onRemoveEmailHandler = (email) => {
    const newList = emailList.filter((e) => e !== email)
    setEmailList(newList)
    onChangeInterval({ email: newList.join(";") })
  }

  const onIntervalChangeHandler = (e) => {
    setIntervalValue(e.target.value)
    const intervalVal = Number(e.target.value)
    if (currentInterval.unit !== "mdr") {
      const startVal = Number(currentInterval.startValue)
      onChangeInterval({ endValue: intervalVal + startVal })
    } else {
      const newEndDate = addMonths(currentInterval.startDate, intervalVal)
      onChangeInterval({ endDate: newEndDate })
    }
  }

  const dpRef = useRef(null)

  useOnClickOutside(dpRef, () => setShowDP(false), { desktop: "mousedown" })

  return !!currentInterval ? (
    <StyledSetupForm {...props}>
      <StyledForm onSubmit={onSubmitHandler}>
        {setupParams === "new" && (
          <Responsive
            phone={
              <section tw="mb-4">
                <h3 tw="my-0 mt-2 mb-4">Opsæt service</h3>
                <div tw="flex">
                  <div tw="flex flex-col">
                    <TrackerLabel tw="font-bold mb-1">{name}</TrackerLabel>
                    <small tw="opacity-60 block">{note}</small>
                  </div>
                  <KpiGrid
                    tw="p-0 ml-16 space-x-12"
                    show={true}
                    data={setupTrackerData}
                  />
                </div>
              </section>
            }
            tabletLandscape={<></>}
          />
        )}
        {setupParams === "new" && (
          <section tw="mb-4">
            <h3>Vælg service type</h3>
            <Responsive
              phone={null}
              tabletLandscape={
                <div tw="flex mb-8">
                  <div tw="flex flex-col">
                    <TrackerLabel tw="font-bold mb-1">{name}</TrackerLabel>
                    <small tw="opacity-60 block">{note}</small>
                  </div>
                  <KpiGrid
                    tw="p-0 ml-16 w-96 space-x-12"
                    show={true}
                    data={setupTrackerData}
                  />
                </div>
              }
            />
            <ToggleGroup>
              {Object.keys(typeUnits).map((type, i) => {
                return (
                  <Toggle
                    key={`type_slector_${i}`}
                    onClick={() => onToggleHandler(type)}
                  >
                    {type}
                  </Toggle>
                )
              })}
            </ToggleGroup>
          </section>
        )}
        {setupParams === "edit" && (
          <section>
            <div tw="flex md:items-center justify-between">
              <h3 tw="mt-2 mb-4 md:my-8 block">Rediger serviceinterval</h3>
              {confirmDelete ? (
                <>
                  <ButtonGroup
                    tw="justify-end md:hidden"
                    direction="column-reverse"
                  >
                    <Button
                      variant="transparent"
                      size="sm"
                      type="button"
                      onClick={() => onDeleteHandler()}
                    >
                      <I18n strKey="cancel" />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      type="button"
                      onClick={() => onDeleteInterval(currentInterval.id)}
                    >
                      <I18n strKey="confirm" />
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup tw="hidden md:flex" direction="row-reverse">
                    <Button
                      variant="transparent"
                      size="sm"
                      type="button"
                      onClick={() => onDeleteHandler()}
                    >
                      <I18n strKey="cancel" />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      type="button"
                      onClick={() => onDeleteInterval(currentInterval.id)}
                    >
                      <I18n strKey="confirm" />
                    </Button>
                  </ButtonGroup>
                </>
              ) : (
                <ButtonGroup position="right">
                  <Button
                    variant="danger"
                    size="sm"
                    type="button"
                    onClick={() => onDeleteHandler()}
                  >
                    <I18n strKey="delete" />
                  </Button>
                </ButtonGroup>
              )}
            </div>
            <div>
              <KpiGrid
                tw="p-0 m-0 mb-8 ml-1 w-96 space-x-12"
                show={true}
                data={setupTrackerData}
              />
            </div>
          </section>
        )}

        <Setup>
          <FormField validationKey="name" label="Navn på interval">
            <ValidationInput
              id="name"
              required
              type="text"
              placeholder="Indtast navn"
              value={currentInterval.type}
              onChange={(e) => onChangeInterval({ type: e.target.value })}
            />
          </FormField>

          <SwitchWrapper tw="flex items-center text-xl">
            <Checkbox
              inline
              name="intervalType"
              checkboxAppearance="radio"
              checked={intervalType === 0}
              onChange={(e) => setIntervalType(0)}
              disabled={currentInterval.unit === "mdr"}
            >
              <span>Dynamisk interval</span>
            </Checkbox>
            <Checkbox
              inline
              name="intervalType"
              checkboxAppearance="radio"
              checked={intervalType === 1}
              onChange={(e) => setIntervalType(1)}
              disabled={currentInterval.unit === "mdr"}
            >
              <span>Fast interval</span>
            </Checkbox>
          </SwitchWrapper>

          <FormField
            validationKey="intervalValue"
            label={`Interval (${currentInterval.unit}) *`}
          >
            <ValidationInput
              id="intervalValue"
              type="number"
              required
              min={limits.min.toString()}
              placeholder={`Indtast ${currentInterval.type?.toLowerCase()}`}
              value={intervalValue}
              onChange={onIntervalChangeHandler}
            />
          </FormField>

          {currentInterval.unit !== "mdr" ? (
            <>
              <FormField
                validationKey="startValue"
                label={`Indtast ${currentInterval.unit} fra seneste service *`}
              >
                <ValidationInput
                  id="startValue"
                  type="number"
                  placeholder={`Til beregning af serviceindex (${currentInterval.unit})`}
                  value={currentInterval.startValue}
                  required
                  onChange={(e) => {
                    const intervalVal = Number(intervalValue)
                    const startVal = Number(e.target.value)
                    onChangeInterval({
                      endValue: intervalVal + startVal,
                      startValue: e.target.value,
                    })
                  }}
                />
              </FormField>
              {intervalType === 0 && (
                <SwitchWrapper tw="text-xl bg-blue-100 p-4 mt-4 border border-solid border-blue-200">
                  {`Næste service ved`}{" "}
                  <span tw="font-bold">
                    {`${+currentInterval.startValue + +intervalValue} ${
                      currentInterval.unit
                    }`}
                  </span>
                  {` og for hver følgende `}
                  <span tw="font-bold">{+intervalValue}</span>
                  {` ${currentInterval.unit} fra registrering`}
                </SwitchWrapper>
              )}
              {intervalType === 1 && (
                <SwitchWrapper tw="text-xl bg-blue-100 p-4 mt-4 border border-solid border-blue-200">
                  {`Næste service ved`}{" "}
                  <span tw="font-bold">{`${
                    +currentInterval.startValue + +intervalValue
                  } ${currentInterval.unit}`}</span>
                  {" og for hver "}
                  <span tw="font-bold">{`${+intervalValue} ${
                    currentInterval.unit
                  }`}</span>
                  {" uanset hvornår det registreres"}
                </SwitchWrapper>
              )}
            </>
          ) : (
            <DatepickerWrapper>
              <DateFilter
                key="datefilter"
                onSelectDate={(d) => onSelectDateHandler(d)}
                date={date}
                label="Start dato (evt. seneste service)"
              />
            </DatepickerWrapper>
          )}
          <SwitchWrapper>
            <Checkbox
              checked={currentInterval.notify}
              appearance="toggle"
              onChange={(e) => {
                onChangeInterval({
                  notify: e,
                })
              }}
            >
              <span>Email notifikationer</span>
            </Checkbox>
          </SwitchWrapper>
          {currentInterval.notify && (
            <>
              <FormField
                validationKey="sendNotificationAtTreshold"
                label="Send påmindelse ved index"
              >
                <ValidationInput
                  id="sendNotificationAtTreshold"
                  type="number"
                  placeholder="Indtast grænseværdi for påmindelse om service"
                  value={currentInterval?.sendNotificationAtTreshold}
                  validationMessages={validationMessages}
                  onChange={(e) =>
                    onChangeInterval({
                      sendNotificationAtTreshold: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    onChangeInterval({
                      sendNotificationAtTreshold:
                        e.target.value === "" ? `95` : e.target.value,
                    })
                  }}
                  min="0"
                />
              </FormField>
              <FormField
                validationKey="email"
                label={`Email - påmindelse v. index ${
                  currentInterval?.sendNotificationAtTreshold || 95
                }%`}
              >
                <ValidationInput
                  id="email"
                  type="email"
                  placeholder="Indtast email til påmindelse"
                  customValidation={emailValidation}
                  validationMessages={validationMessages}
                  onKeyDown={onEmailKeyDownHandler}
                  onBlur={onEmailBlurHandler}
                />
              </FormField>
              <SwitchWrapper tw="py-4">
                <Accordion toggled={emailList.length > 0}>
                  <TagInput
                    tw="mt-0 mb-0 pb-4 top-0"
                    onRemoveTag={onRemoveEmailHandler}
                    tagMap={emailList}
                  />
                </Accordion>
              </SwitchWrapper>

              <FormField label="Besked i påmindelse">
                <Textarea
                  value={currentInterval?.emailMsg || ``}
                  onChange={(e) => {
                    onChangeInterval({ emailMsg: e.target.value })
                  }}
                  placeholder="Indtast evt. note ved servicepåmindelse"
                />
              </FormField>
            </>
          )}
        </Setup>
        <Responsive
          phone={
            <ButtonGroup
              tw="px-8 bg-brand-gray-lighter"
              sticky
              position="center"
              style={{ bottom: "6.7rem" }}
            >
              <Button variant="default" type="button" onClick={exitHandler}>
                Annuller
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  typeof intervalType === "undefined" &&
                  currentInterval.unit !== "mdr"
                }
              >
                Gem
              </Button>
            </ButtonGroup>
          }
          tabletLandscape={
            <ButtonGroup
              tw="hidden bg-brand-gray-lighter md:(flex mx-8)"
              position="between"
            >
              <Button variant="default" type="button" onClick={exitHandler}>
                Annuller
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  typeof intervalType === "undefined" &&
                  currentInterval.unit !== "mdr"
                }
              >
                Gem
              </Button>
            </ButtonGroup>
          }
        />
      </StyledForm>
    </StyledSetupForm>
  ) : null
}

export default SetupForm

SetupForm.defaultProps = {}
SetupForm.propTypes = {}
