import { privateApi } from "utils/api"

/**
 * Gets a vehicles history logs by id
 * @param {string} vehicle_id Vehicle ID
 * @param {string} start_date Start date (DD-MM-YYYY)
 * @param {string} start_time Start time (HH:MM:SS)
 * @param {string} stop_date Stop date (DD-MM-YYYY)
 * @param {string} stop_time Stop time (HH:MM:SS)
 */

const getVehicleHistoryById = async (
  vehicle_id: string,
  start: string,
  stop: string,
  includeActivityBeyondSelectedPeriodIfNoMatch: boolean
) => {
  try {
    const response = await privateApi().get(
      `/history/${vehicle_id}?start=${start}T00:00:00.000Z&stop=${stop}T23:59:59.999Z${
        includeActivityBeyondSelectedPeriodIfNoMatch
          ? `&includeDeviceActivityBeyondSelectedPeriodIfNoMatch=true`
          : ``
      }`
    )
    return {
      vehicle_id,
      data: response.data,
    }
  } catch (e) {
    console.log(e)
  }
}

const getVehicleRouteById = async (
  vehicle_id: string,
  start: string,
  stop: string
) => {
  try {
    const response = await privateApi().get(
      `/route/${vehicle_id}?start=${start}&stop=${stop}`
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

const fetchIntervalRoute = async (id: string, start: string, end: string) => {
  try {
    const from = new Date(`${start}`)
    const to = new Date(`${end}`)
    const response = await getVehicleRouteById(
      id,
      from.toISOString(),
      to.toISOString()
    )
    if (response.data.result === "OK" && response.data.count > 0) {
      return response.data.route
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export { getVehicleHistoryById, getVehicleRouteById, fetchIntervalRoute }
