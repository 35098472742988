import { useState, useRef } from "react"

enum PromiseStatusEnum {
  ERROR = "Error",
  SUCCESS = "Success",
}

type PromiseStatus =
  | { status: PromiseStatusEnum }
  | { status: PromiseStatusEnum; error: any }

type SetPromiseProps = {
  promise: Promise<any>
  loadingText?: string
  onSuccess?: (...args) => void
  onError?: (...args) => void
}

export const usePromise = () => {
  const [promiseStatus, setPromiseStatus] = useState<PromiseStatus | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [currentLoadingText, setCurrentLoadingText] = useState<string>(
    "Indlæser"
  )
  const currentPromiseRef = useRef<Promise<any> | null>()

  const setPromise = async ({
    promise,
    onSuccess,
    onError,
    loadingText,
  }: SetPromiseProps) => {
    if (loadingText) setCurrentLoadingText(loadingText)
    currentPromiseRef.current = promise
    setLoading(true)
    try {
      const result = await currentPromiseRef.current
      setLoading(false)
      if (result) {
        setPromiseStatus({ status: PromiseStatusEnum.SUCCESS })
        if (onSuccess) onSuccess(result)
      }
    } catch (error) {
      setLoading(false)
      setPromiseStatus({ status: PromiseStatusEnum.ERROR, error })
      if (onError) onError(error)
    }
  }

  return { setPromise, promiseStatus, loading, loadingText: currentLoadingText }
}
