import { format, parseJSON, differenceInSeconds } from "date-fns"
import { da } from "date-fns/locale"
import { lastInArr } from "utils/fpBooster"
import { formatSecondsToDuration } from "utils/datetime"
import sum from "lodash-es/sum"
import last from "lodash-es/last"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { DeviceStatusEnum } from "app/Device/types"
import { ITrip } from "routes/HistoryV2/history.types"

const groupTripsData = (
  trips: ITrip[],
  zeroDurationTripIDs: string[],
  showZeroDistanceTrips
) => {
  // "filteredTrips" - get rid of the "parked" types etc.
  const filteredTrips = trips.filter((trip) => trip.type === "trip")

  // "transformedTrips" - Map to "duration", as that's what will
  // be used to split data on the "bars" and "total" summary
  const transformedTrips = filteredTrips.map((trip, j) => {
    return trip.trip_states.map((state, i) => {
      if (!trip.trip_states[i - 1]?.duration && !filteredTrips[j - 1]) {
        return {
          id: trip.id,
          status: state.status,
          duration: "00:00:00",
        }
      } else if (trip.trip_states[i - 1]?.duration) {
        return {
          id: trip.id,
          status: state.status,
          duration: trip.trip_states[i - 1]?.duration,
        }
      } else if (filteredTrips[j - 1]) {
        return {
          id: trip.id,
          status: state.status,
          duration: last(filteredTrips[j - 1].trip_states)?.duration,
        }
      }
      return {
        id: trip.id,
        status: state.status,
        duration: "00:00:00",
      }
    })
  })

  const statusFilter = (s) => {
    return transformedTrips
      .map((trip) => {
        return trip
          .filter(
            (state) =>
              state.status === s &&
              (secondsFromDuration(state.duration) >= 0 ||
                zeroDurationTripIDs.includes(state.id) ||
                showZeroDistanceTrips)
          )
          .map((state) => state.duration)
      })
      .filter(Boolean)
      .reduce((acc, current) => acc.concat(current), [])
  }

  // "status" - array from 0-5, at each index containing another array of the durations
  const availableStatus = [
    DeviceStatusEnum.STOPPED,
    DeviceStatusEnum.RUNNING,
    DeviceStatusEnum.IDLE,
    DeviceStatusEnum.WORKING,
    DeviceStatusEnum.IN_TRANSPORT,
    DeviceStatusEnum.UNKNOWN,
  ]
  const status = availableStatus.map((s) => statusFilter(s))

  // "statusTotalSeconds" - array 0-5 - containing the summed durations
  // in seconds of that status at the index
  // Example: StatusTotalSeconds[0] contains the total seconds of status 0
  const statusTotalSeconds = status.map((s) => {
    return s.reduce((acc, duration) => acc + secondsFromDuration(duration), 0)
  })

  return {
    status,
    statusTotalSeconds,
    transformedTrips,
  }
}

export const secondsFromDuration = (duration) => {
  const split = duration.split(":").map((item) => Number(item))
  const seconds = split[0] * 3600 + split[1] * 60 + split[2]

  /* const minutesRemainder = seconds - Math.floor(seconds / 60) * 60
  seconds = minutesRemainder >= 30 ? seconds + 60 : seconds */
  return seconds
}

const formatTimestamp = (date_) => {
  return format(date_, "HH:mm", {
    locale: da,
  })
}

// Status - 0: stopped - 1: driving - 2: idle - 3: working - 4: moving - 5: offline
const statusMap = [
  {
    displayKeyRef: null,
    status: "Stoppet",
    color: "red",
    icon: "stop",
    info: "mellem ture",
    info_sing: "mellem ture",
  },
  {
    displayKeyRef: DisplayKeyEnum.TotalDrivingHours,
    status: "Køretimer",
    color: "green",
    icon: "steering-wheel",
    info: "ture",
    info_sing: "tur",
  },
  {
    displayKeyRef: DisplayKeyEnum.TotalIdleHours,
    status: "Tomgang",
    color: "yellow",
    icon: "slow-mo-video",
    info: "steder",
    info_sing: "sted",
  },
  {
    displayKeyRef: DisplayKeyEnum.TotalEngineHours,
    status: "Arbejdet",
    color: "blue",
    icon: "hammer-solid",
    info: "gange",
    info_sing: "gang",
  },
  {
    displayKeyRef: DisplayKeyEnum.TotalMovingHours,
    status: "Flyttet",
    color: "purple",
    icon: "dolly-solid",
    info: "gange",
    info_sing: "gang",
  },
  {
    status: "Offline",
    color: "gray",
    icon: "steering-wheel",
    info: "gange",
    info_sing: "gang",
  },
]

// These are the display sorting / ordering for "bars" and "total" in the summary
const statusPriority = [
  DeviceStatusEnum.STOPPED,
  DeviceStatusEnum.IN_TRANSPORT,
  DeviceStatusEnum.IDLE,
  DeviceStatusEnum.RUNNING,
  DeviceStatusEnum.WORKING,
  DeviceStatusEnum.UNKNOWN,
]
const statusPriorityCANmachine = [
  DeviceStatusEnum.STOPPED,
  DeviceStatusEnum.IN_TRANSPORT,
  DeviceStatusEnum.RUNNING,
  DeviceStatusEnum.IDLE,
  DeviceStatusEnum.WORKING,
  DeviceStatusEnum.UNKNOWN,
]

export const summaryDataFromDay = (
  day,
  machineWithCan,
  showSeconds,
  zeroDurationTripIDs,
  showZeroDistanceTrips,
  summary
) => {
  const groupedTrips = groupTripsData(
    day.trips,
    zeroDurationTripIDs,
    showZeroDistanceTrips
  )

  const getStatusInfo = (status) => {
    const { info, info_sing } = statusMap[status]

    if (+status === DeviceStatusEnum.RUNNING) {
      const dataLength = groupedTrips.transformedTrips.length
      return `${dataLength} ${dataLength !== 1 ? info : info_sing}`
    } else if (
      [
        DeviceStatusEnum.IDLE,
        DeviceStatusEnum.WORKING,
        DeviceStatusEnum.IN_TRANSPORT,
        DeviceStatusEnum.UNKNOWN,
      ].some((index) => index === status)
    ) {
      const dataLength = groupedTrips.status[status].length
      return `${dataLength} ${dataLength !== 1 ? info : info_sing}`
    } else {
      return info
    }
  }

  const statusPriorityObject = machineWithCan
    ? statusPriorityCANmachine
    : statusPriority

  const drivingHoursToday = summary?.find(
    (x) => x.name === DisplayKeyEnum.DrivingHoursToday
  )?.value
  const idleHoursToday = summary?.find(
    (x) => x.name === DisplayKeyEnum.IdleHoursToday
  )?.value

  const total = statusPriorityObject.reduce((acc, status) => {
    const statusObject = statusMap[status]
    const seconds = groupedTrips.statusTotalSeconds[status]

    let actualValue

    if (idleHoursToday && status === DeviceStatusEnum.IDLE) {
      actualValue = secondsFromDuration(idleHoursToday)
    }

    if (drivingHoursToday && status === DeviceStatusEnum.RUNNING) {
      actualValue = secondsFromDuration(drivingHoursToday)
    }

    return seconds < 1
      ? acc
      : [
          ...acc,
          {
            name: statusObject.displayKeyRef,
            icon: statusObject.icon,
            kpi: statusObject.status,
            durationRaw: actualValue ?? seconds,
            value: formatSecondsToDuration(actualValue ?? seconds, {
              showSeconds,
            }),
            info: getStatusInfo(status),
          },
        ]
  }, [])

  const bars = statusPriorityObject.reduce((acc, status) => {
    const statusObject = statusMap[status]
    const seconds = groupedTrips.statusTotalSeconds[status]
    const sumMax = groupedTrips.statusTotalSeconds.reduce((a, b) => a + b, 0)
    const mapRange = (value, x1, y1, x2, y2) =>
      ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

    let actualValue
    if (idleHoursToday && status === DeviceStatusEnum.IDLE) {
      actualValue = secondsFromDuration(idleHoursToday)
    }

    if (drivingHoursToday && status === DeviceStatusEnum.RUNNING) {
      actualValue = secondsFromDuration(drivingHoursToday)
    }

    return seconds < 1
      ? acc
      : [
          ...acc,
          {
            index: mapRange(
              actualValue ?? seconds,
              -sumMax / 20,
              sumMax,
              0,
              100
            ),
            // lower limit at -sumMax/20 to artificially scale small bars larger
            // Makes for better visualization

            color: statusObject.color,
            hoverContent: `${statusObject.status}: ${formatSecondsToDuration(
              actualValue ?? seconds,
              {
                showSeconds,
              }
            )}`,
          },
        ]
  }, [])

  const getBoundaryTimeStamps = ({ lastState, firstState, seconds }) => {
    const firstTimestamp = parseJSON(firstState.timestamp)
    const lastTimestamp = parseJSON(lastState.timestamp)

    const formatted = {
      seconds: formatSecondsToDuration(seconds, {
        leadingZeros: false,
        showSeconds,
      }),
      lastTimestamp: formatTimestamp(lastTimestamp),
      firstTimestamp: formatTimestamp(firstTimestamp),
    }

    return {
      lastTimestamp,
      firstTimestamp,
      seconds,
      formatted,
    }
  }

  const dayTime = () => {
    const tripsWithStates = day.trips.filter((x) => x.type === "trip")
    const allStates = tripsWithStates.flatMap((x) => x.trip_states)
    const lastState = allStates[0]

    const firstState = lastInArr(allStates)
    const seconds = sum(
      allStates
        .slice(0, allStates.length - 1)
        .map((x) => secondsFromDuration(x.duration))
    )

    if (allStates.length === 0) return {}

    return getBoundaryTimeStamps({ lastState, firstState, seconds })
  }

  const getTripTime = (states) => {
    const lastState = states[0]
    const firstState = lastInArr(states)
    const seconds = sum(
      states
        .slice(0, states.length - 1)
        .map((x) => secondsFromDuration(x.duration))
    )

    if (states.length === 0) return {}

    return getBoundaryTimeStamps({ lastState, firstState, seconds })
  }

  return {
    groupedTrips,
    bars,
    total,
    dayTime: dayTime(),
    getTripTime,
  }
}
