import { IDisplayKey } from "app/TrackerKPI/kpi.types"
import { IPosition } from "app/GoogleMap/map.types"
import { DeviceStatusEnum, IRawDeviceUpdate } from "app/Device/types"

export interface ILog {
  id?: string | number
  address: string
  display_keys: IDisplayKey[]
  duration: string
  pos: IPosition
  status: DeviceStatusEnum
  timestamp: string
  placeholder?: boolean
}

export interface ITrackerHistoryData {
  count: number
  logs: ILog[]
  result: string
}

export interface ITrackerHistory {
  vehicle_id: number
  data: ITrackerHistoryData
}

export type Prefilter = { days: number; label: string }

export type HistoryLogViewSettings = {
  minDurationTreshold?: number // Hides all trip states below the treshold
  groupTripsPerDay?: boolean // Toggles a view where the period is shown chronologically
  splitTripsOnDateChange?: boolean // If a trip is in progress past midnight, we split the trip so it belongs to the next day
  showZeroDistanceTrips?: boolean // Show trips with zero distance, and only a start and stop status.
}

export interface IFilters {
  date: IDateFilter
}

export interface IDateFilter {
  start: string
  stop: string
  quickfilter: Prefilter | null
}

export interface ITrackerAnalysis {
  tracker: any
  history: ITrackerDailyLog[]
}

export interface ITrackerDailyLog {
  timestamp: string
  summary: IDisplayKey[]
  trips: TripTypes[]
}

export type TripTypes = IParked | ITrip

export type TripType = "parked" | "trip"

export interface ITrip {
  id: string
  type: TripType
  summary: IDisplayKey[]
  trip_states: ITripState[] | []
  in_progress: boolean
  current_trip?: IRawDeviceUpdate[]
}

export interface IParked {
  id: string
  type: TripType
  stop_timestamp: string
  start_timestamp: string
}

export interface ITripStateBase {
  pos: IPosition
  timestamp: string
  status: DeviceStatusEnum
  duration: string
}

export interface ITripState extends ITripStateBase {
  address: string
  display_keys: IDisplayKey[]
}

export interface IRouteGeopoint {
  address?: string
  pos: IPosition
  timestamp: string
  speed: string
  status: DeviceStatusEnum | ""
}

export interface IRoute {
  id: string
  tracker_id: string
  route: IRouteGeopoint[]
}

export interface IGeometry {
  type: "Point"
  coordinates: [number, number]
}

export interface IMapPointOfInterest {
  key?: string
  type: "Feature"
  geometry: IGeometry
  properties: IRouteGeopoint
}

export enum HistoryHintEnum {
  NoActivityDays = "History/NoActivityDays",
}

export type HistoryHintKeys = HistoryHintEnum
