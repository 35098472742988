import React, { useReducer, createContext } from "react"
import { HistoryActionMap, HistoryTypes } from "./actions"
import { format, addDays } from "date-fns"
import {
  IFilters,
  ITrackerDailyLog,
  ITrackerHistory,
  IRoute,
  HistoryLogViewSettings,
} from "./history.types"
import { IPosition } from "app/GoogleMap/map.types"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialHistoryStateType = {
  filters: IFilters
  history: ITrackerHistory[]
  logs: ITrackerDailyLog[]
  toggledLogs: string[]
  toggledTooltip: IPosition | null
  routes: IRoute[]
  pinnedRoutes: string[]
  toggledRoute: string | null
  toggledRouteTimestamp: string | null
  toggleMap: boolean
  loadingHistoryLogs: boolean
  loadingRenderCriticalData: boolean
  logViewSettings: HistoryLogViewSettings | {}
  promisesTotal: number | null
  promisesCompleted: number | null
  animating: boolean
}

const initialState: InitialHistoryStateType = {
  filters: {
    date: {
      start: format(addDays(new Date(), -7), "yyyy-MM-dd"),
      stop: format(new Date(), "yyyy-MM-dd"),
      quickfilter: { days: 7, label: "Sidste 7 dage" },
    },
  },
  history: [],
  logs: [],
  toggledLogs: [],
  toggledTooltip: null,
  routes: [],
  pinnedRoutes: [],
  toggledRoute: null,
  toggledRouteTimestamp: null,
  toggleMap: false,
  loadingHistoryLogs: false,
  loadingRenderCriticalData: false,
  logViewSettings: {
    minDurationTreshold: 0,
    groupTripsPerDay: true,
    splitTripsOnDateChange: false,
    showZeroDistanceTrips: false,
  },
  promisesTotal: null,
  promisesCompleted: null,
  animating: false,
}

const HistoryContext = createContext<{
  state: InitialHistoryStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const toggleDay = (intervals: string[], id: string) => {
  const newIntervals = intervals.slice()
  const exists = intervals.find((x) => x === id) ? true : false
  return exists ? newIntervals.filter((x) => x !== id) : [...newIntervals, id]
}

const toggleRoute = (routes: IRoute[], route: IRoute) => {
  const newRoutes = routes.slice()
  const exists = routes.find((rt) => rt.id === route.id) ? true : false
  return exists
    ? newRoutes.filter((rt) => rt.id !== route.id)
    : [...newRoutes, route]
}

const toggleInterval = (intervals: string[], id: string) => {
  const newIntervals = intervals.slice()
  const exists = intervals.find((x) => x === id) ? true : false
  return exists ? newIntervals.filter((x) => x !== id) : [...newIntervals, id]
}

const historyReducer = (
  state: InitialHistoryStateType,
  action: HistoryActionMap
) => {
  switch (action.type) {
    case HistoryTypes.AddRoute:
      return {
        ...state,
        routes: toggleRoute(state.routes, action.payload.route),
      }
    case HistoryTypes.ToggleRoute:
      return {
        ...state,
        toggledRoute:
          state.toggledRoute === action.payload.routeId
            ? null
            : action.payload.routeId,
        toggledRouteTimestamp: null,
      }
    case HistoryTypes.PinRoute:
      return {
        ...state,
        pinnedRoutes: toggleInterval(
          state.pinnedRoutes,
          action.payload.routeId
        ),
      }
    case HistoryTypes.SetFilters:
      return {
        ...state,
        filters: { ...action.payload.filters },
      }
    case HistoryTypes.SetTooltip:
      return {
        ...state,
        toggledTooltip: action.payload.toggledTooltip,
      }
    case HistoryTypes.SetTrackerHistory:
      return {
        ...state,
        history: action.payload.history,
      }
    case HistoryTypes.SetTrackerLogs:
      return {
        ...state,
        logs: action.payload.logs,
      }
    case HistoryTypes.ToggleDay:
      return {
        ...state,
        toggledLogs: toggleDay(state.toggledLogs, action.payload.id),
      }
    case HistoryTypes.ToggleMap:
      return {
        ...state,
        toggleMap: !state.toggleMap,
      }
    case HistoryTypes.ToggleAnimating:
      return {
        ...state,
        animating: action.payload.animating,
      }
    case HistoryTypes.ToggleRouteTimestamp:
      return {
        ...state,
        toggledRouteTimestamp: action.payload.timestamp,
      }
    case HistoryTypes.LoadingHistoryLogs:
      return {
        ...state,
        loadingHistoryLogs: action.payload,
      }
    case HistoryTypes.SetLogViewSettings:
      return {
        ...state,
        logViewSettings: {
          ...state.logViewSettings,
          ...action.payload.viewSettings,
        },
      }
    case HistoryTypes.LoadingRenderCriticalData:
      return {
        ...state,
        loadingRenderCriticalData: action.payload.loading,
        promisesTotal:
          action.payload.completed === null
            ? null
            : action.payload.total ?? state.promisesTotal,
        promisesCompleted:
          action.payload.completed !== null
            ? state.promisesCompleted + action.payload.completed
            : null,
      }
    case HistoryTypes.Clear:
      return {
        ...state,
        toggledTooltip: null,
        routes: [],
        pinnedRoutes: [],
        toggledLogs: [],
        toggledRoute: null,
        toggledRouteTimestamp: null,
      }
    case HistoryTypes.ResetRoutes:
      return {
        ...state,
        routes: [],
        pinnedRoutes: [],
        toggledRoute: null,
        toggledRouteTimestamp: null,
      }
    case HistoryTypes.Reset:
      return {
        ...state,
        promisesTotal: null,
        promisesCompleted: null,
        loadingHistoryLogs: false,
        loadingRenderCriticalData: false,
        history: [],
        logs: [],
        toggledTooltip: null,
        routes: [],
        pinnedRoutes: [],
        toggledLogs: [],
        toggledRoute: null,
        toggledRouteTimestamp: null,
      }
    default:
      return state
  }
}

const HistoryProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(historyReducer, initialState)

  return (
    <HistoryContext.Provider value={{ state, dispatch }}>
      {children}
    </HistoryContext.Provider>
  )
}

export { HistoryContext, HistoryProvider }
