import { DEFAULTFUNCTIONS, DEFAULTFEATURES } from "data/featureFlags"
import { StringDictionary } from "lib/global.types"

interface IBasicCompany {
  id: number | string | null
}

export interface ICompany extends IBasicCompany {
  name: string
  unread?: number
  msisdn: number | null
  features: StringDictionary<string>
  functions: StringDictionary<string>
}

export interface ICompanySettings {}

export const defaultCompanySettings: ICompanySettings = {}

export const initialCompanyState: ICompany = {
  id: null,
  name: "",
  unread: 0,
  msisdn: null,
  features: DEFAULTFEATURES,
  functions: DEFAULTFUNCTIONS,
}
