import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"

const StyledTrackerHeading = styled(Icon).attrs((props) => ({
  style: {
    transform: `rotateZ(${props.heading}deg)`,
  },
}))<{ heading: number }>`
  width: 1rem;
  height: 1rem;
  transition: transform ease-out 0.2s;
  fill: ${(props) => props.theme.colors.white};
  position: absolute;
`

function TrackerHeading({ heading, speed, ...props }) {
  if (speed === 0) return null
  return (
    <StyledTrackerHeading
      className="heading"
      heading={heading}
      icon="location-arrow"
      {...props}
    />
  )
}

export default TrackerHeading

TrackerHeading.defaultProps = {}
TrackerHeading.propTypes = {
  heading: PropTypes.number,
}
