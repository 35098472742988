import React, { useState, useCallback, useEffect, useRef } from "react"
import styled from "styled-components"
import Input from "../Input"
import Textarea from "../Textarea"
import { handleInputValidation } from "./helper"

const StyledInput = styled(Input)``

const StyledTextarea = styled(Textarea)``

const ValidationInput = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      onFocus,
      onValidate,
      customValidation,
      revalidate,
      validationMessages,
      size,
      as: renderAs,
      ...props
    },
    ref
  ) => {
    const [isDirty, setDirty] = useState(false)
    const [isValid, setValidity] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)
    const localRef = useRef(null)
    const inputRef = ref || localRef

    const validateInput = useCallback(() => {
      const validation = handleInputValidation(
        inputRef.current,
        validationMessages,
        customValidation
      )
      setValidity(validation.valid)
      setErrorMsg(validation.message)
    }, [customValidation, inputRef, validationMessages])

    function onFocusHandler(e) {
      onFocus(e)
    }

    function onBlurHandler(e) {
      validateInput()
      props.required && setDirty(true)
      onBlur(e)
    }

    function onChangeHandler(e) {
      setDirty(true)
      setValidity(true)
      setErrorMsg(null)
      onChange(e)
      props.required && onValidate(errorMsg, isValid)
    }

    useEffect(() => {
      if (onValidate) onValidate(errorMsg, isValid)
    }, [errorMsg, isValid, onValidate])

    useEffect(() => {
      if (revalidate) validateInput()
    }, [revalidate, validateInput])

    return renderAs === "textarea" ? (
      <StyledTextarea
        renderAsFormField={true}
        forwardRef={inputRef}
        size={size}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onChange={onChangeHandler}
        dirty={isDirty}
        valid={isValid}
        {...props}
      />
    ) : (
      <StyledInput
        forwardRef={inputRef}
        size={size}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onChange={onChangeHandler}
        dirty={isDirty}
        valid={isValid}
        {...props}
      />
    )
  }
)

export default ValidationInput

/* ValidationInput.defaultProps = {
  size: "md",
  revalidate: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onValidate: () => {},
  customValidation: null,
  notifyIfValid: false,
}

ValidationInput.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  revalidate: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onValidate: PropTypes.func,
  customValidation: PropTypes.func,
  notifyIfValid: PropTypes.bool,
}
 */
