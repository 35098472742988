/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"
import {
  ConversationRoutes,
  IContact,
  IResolvedConversation,
  IContactGroup,
  IContactOrContactGroup,
} from "./conversations.types"

export enum ConversationTypes {
  ToggleRoute = "Conversation/ToggleRoute",
  SetRoute = "Conversation/SetRoute",
  SetRoutes = "Conversation/SetRoutes",
  ResetRoutes = "Conversation/ResetRoutes",
  AddContact = "Conversation/AddContact",
  EditContact = "Conversation/EditContact",
  EditGroup = "Conversation/EditGroup",
  ResetGroup = "Conversation/ResetGroup",
  ResetContact = "Conversation/ResetContact",
  SetContacts = "Conversation/SetContacts",
  SetGroups = "Conversation/SetGroups",
  AddConversation = "Conversation/AddConversation",
  SetConversations = "Conversation/SetConversations",
  UpdateConversationByID = "Conversation/UpdateConversationByID",
  SetConversationUnreadCountByID = "Conversation/SetConversationUnreadCountByID",
  SetConversation = "Conversation/SetConversation",
  SetGoToMessageID = "Conversation/SetGoToMessageID",
  SetSelectedContacts = "Conversation/SetSelectedContacts",
  ResetConversation = "Conversation/ResetConversation",
  SetMessage = "Conversation/SetMessage",
  Reset = "Conversation/Reset",
  SetLoading = "Conversation/Loading",
  SetSubscriptionsActive = "Conversation/SubscriptionActive",
}

type Actions = {
  [ConversationTypes.ToggleRoute]: { key: ConversationRoutes }
  [ConversationTypes.SetRoute]: { key: ConversationRoutes }
  [ConversationTypes.SetRoutes]: { routes: ConversationRoutes[] }
  [ConversationTypes.ResetRoutes]: null
  [ConversationTypes.AddContact]: { contact: IContact }
  [ConversationTypes.EditContact]: { contact: IContact }
  [ConversationTypes.EditGroup]: { group: IContactGroup }
  [ConversationTypes.ResetGroup]: null
  [ConversationTypes.ResetContact]: null
  [ConversationTypes.SetContacts]: { contacts: IContact[] }
  [ConversationTypes.SetGroups]: { groups: IContactGroup[] }
  [ConversationTypes.AddConversation]: {
    newConversation: IResolvedConversation
  }
  [ConversationTypes.SetConversations]: {
    conversations: IResolvedConversation[] | null
  }
  [ConversationTypes.UpdateConversationByID]: {
    conversation: IResolvedConversation
    userID: string
  }
  [ConversationTypes.SetConversationUnreadCountByID]: {
    unreadMap: { id: string; unread_count: number }[]
  }
  [ConversationTypes.SetConversation]: {
    conversation: string | null
  }
  [ConversationTypes.SetGoToMessageID]: {
    messageID: number | null
  }
  [ConversationTypes.SetSelectedContacts]: {
    contacts: IContactOrContactGroup[] | null
  }
  [ConversationTypes.ResetConversation]: null

  [ConversationTypes.SetMessage]: { message: string }
  [ConversationTypes.Reset]: null
  [ConversationTypes.SetLoading]: { loading: boolean }
  [ConversationTypes.SetSubscriptionsActive]: { active: boolean }
}

export type ConversationActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const ConversationActions = createActions<Actions>()
