import React, { useState } from "react"
import "twin.macro"
import { DeviceSpeedCriterium } from "routes/Alarms/alarm.types"

import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import { FormField, Input } from "@clevertrack/shared"

interface IDeviceSpeedCriteriaProps {
  criterium: DeviceSpeedCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceSpeedCriteriaElement extends IDeviceSpeedCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

export const DeviceSpeedCriteriaElement: React.FC<IDeviceSpeedCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  return (
    <AlarmConfigurationComponent tw="border-b-0">
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">Hastighedsgrænse</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">Annullér</span>
            </>
          ) : (
            <>
              <span tw="text-lg">Redigér</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">
        Opfyldt når: Trackeren bevæger sig med over {criterium.treshold} km/t
      </span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceSpeedCriteria: React.FC<IDeviceSpeedCriteriaProps> = ({
  criterium = {},
  onSave,
  onCancel,
  ...props
}) => {
  const [treshold, setTreshold] = useState(criterium.treshold)
  const onSaveHandler = () => {
    if (onSave)
      onSave({
        ...criterium,
        treshold,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>Angiv hastighedsgrænse</CriteriumTypeTitle>
      <div tw="pt-4">
        <FormField label="Hastighedsgrænse (km/t)">
          <Input
            type="number"
            min="0"
            step="1"
            value={treshold}
            onChange={(e) => setTreshold(+e.target.value)}
          />
        </FormField>
      </div>
      {onSave && onCancel && (
        <EditorButtonGroup onSave={onSaveHandler} onCancel={onCancelHandler} />
      )}
    </>
  )
}
