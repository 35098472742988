import { ActionMap, createActions } from "lib/reducer.types"

/* Action types */
export enum SearchTypes {
  SetResults = "Search/SetResults",
  SetQuery = "Search/SetQuery",
  SetSuggestions = "Search/SetSuggestions",
  ResetSearch = "Search/Reset",
}

type Actions = {
  [SearchTypes.SetResults]: { results: any[] }
  [SearchTypes.SetQuery]: { query: string }
  [SearchTypes.SetSuggestions]: { suggestions: any[] }
  [SearchTypes.ResetSearch]: undefined
}

export type SearchActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const SearchActions = createActions<Actions>()

/** Anything below is to be deprecated eventually */

export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS"
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
export const SET_SEARCH_SUGGESTIONS = "SET_SEARCH_SUGGESTIONS"
export const RESET_SEARCH = "RESET_SEARCH"

/* Action creators */

export const setSearchResults = (results) => {
  return {
    type: SET_SEARCH_RESULTS,
    results,
  }
}

export const setSearchQuery = (query) => {
  return {
    type: SET_SEARCH_QUERY,
    query,
  }
}

export const setSearchSuggestions = (suggestions) => {
  return {
    type: SET_SEARCH_SUGGESTIONS,
    suggestions,
  }
}

export const resetSearch = () => {
  return {
    type: RESET_SEARCH,
  }
}
