import React, { useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useReport } from "powerbi-report-component"
import { ViewportContext } from "context/ViewportContext"
import { isViewport } from "helpers/viewport"

const StyledReport = styled.div`
  height: 100%;
  width: 100%;
  position: sticky;
  ${(props) => props.theme.media.tablet_landscape_up`
    position: static;
    //top: 7.5rem;
    // bottom: 0;
    height: 100%;
    width: 100%;
  `}
`

const Report = ({ token, embed_url, report_id, onReportLoaded }) => {
  const reportRef = useRef(null)
  const [report, setEmbed] = useReport()
  const vp = useContext(ViewportContext)

  const reportStyles = {
    display: "none",
  }
  const myReportConfig = {
    embedType: "report",
    tokenType: "Embed",
    accessToken: token,
    embedUrl: `${embed_url}`,
    embedId: report_id,
    style: reportStyles,
    extraSettings: {
      localeSettings: {
        language: "da",
        formatLocale: "da",
      },
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  }

  const myPhoneReportConfig = {
    embedType: "report",
    tokenType: "Embed",
    accessToken: token,
    embedUrl: `${embed_url}`,
    embedId: report_id,
    style: reportStyles,
    extraSettings: {
      localeSettings: {
        language: "da",
        formatLocale: "da",
      },
      layoutType: 2,
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  }

  const onPrintCallback = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.which === 80) {
      // CMD+P OR CTRL+P
      e.preventDefault()
      report.print()
    }
  }

  // !important
  useEffect(() => {
    // call inside useEffect so the we have the reportRef (reference available)
    isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? setEmbed(reportRef, myPhoneReportConfig)
      : setEmbed(reportRef, myReportConfig)
  }, [vp])

  useEffect(() => {
    if (report) onReportLoaded(report)
    return () => {
      onReportLoaded(null)
    }
  }, [report])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", onPrintCallback)
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", onPrintCallback)
      }
    }
  }, [])

  return <StyledReport className="report" ref={reportRef} />
}

export default Report

Report.defaultProps = {}
Report.propTypes = {
  children: PropTypes.node,
}
