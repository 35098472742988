import React, { useContext, useState, useMemo, useEffect } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import sumBy from "lodash-es/sumBy"
import first from "lodash-es/first"
import last from "lodash-es/last"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Responsive } from "@clevertrack/shared"
import { ITrip, ITripState } from "routes/HistoryV2/history.types"
import TrackerStatus from "app/TrackerStatus"
import { history_category } from "app/TrackerKPI/Widget/helper"
import KPIWidget from "app/TrackerKPI/Widget"
import { HistoryActions, HistoryTypes } from "routes/HistoryV2/actions"
import { HistoryContext } from "routes/HistoryV2/context"
import { secondsFromDuration, summaryDataFromDay } from "./dayHelper"
import { formatSecondsToDuration } from "utils/datetime"
import Accordion from "lib/Accordion"
import { EventFeed } from "app/EventFeed"
import { getDisplayKey } from "app/Device/helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { DeviceStatusEnum, DeviceTypeEnum } from "app/Device/types"
import I18n from "app/I18n"
import { getStatusCode, statusMap } from "app/TrackerStatus/helper"
import { Link } from "gatsby"

const StyledTrackerStatus = styled(TrackerStatus)`
  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
`

const StyledTrip = styled.article`
  font-size: 1.2rem;
  position: relative;

  /* &:not(:first-child) .trip {
    &::before {
      left: 5rem;
      bottom: 0;
      height: 50%;
      content: "";
      ${tw`border border-solid border-0 border-brand-gray-light border-l absolute transform translate-y-1/2`}

      ${(props) => props.theme.media.tablet_landscape_up`
        left: 5.5rem;
      `}
    }
  } */

  .trip {
    ${tw`relative px-2 md:(pr-4)`}

    &::after {
      left: 7.2rem;
      right: 2rem;
      content: "";
      ${tw`block absolute bottom-0 right-0 h-px bg-brand-gray-light`}

      ${(props) => props.theme.media.tablet_landscape_up`
        left: 7.5rem;
      `}
    }

    header {
      display: grid;
      grid-template-columns: 16rem auto auto;
      column-gap: 0.5rem;
      padding: 1rem 0;
    }

    .tripContent {
      padding: 1rem 0;
      display: grid;
      grid-template-columns: 1fr auto;

      ${(props) => props.theme.media.tablet_landscape_up`
        column-gap: 2rem;
      `}
      position: relative;
      z-index: 10;
      // border-left: 4px solid transparent;

      .tripStates {
        width: 100%;
        position: relative;
        z-index: 100;
        flex: 1;

        .statesHeading {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          letter-spacing: 0.05em;
        }
      }

      .state {
        display: flex;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        .time {
          flex-shrink: 0;
          opacity: 0.6;
        }

        .status_duration_address {
          display: grid;
          grid-template-columns: 2.4rem 8rem auto;
          align-items: center;
          column-gap: 1rem;

          .duration {
            flex-shrink: 0;
            font-weight: bold;
            white-space: nowrap;
          }

          .address {
            opacity: 0.6;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .status {
            margin-left: 0.4rem;
            flex-shrink: 0;
          }
        }
      }

      .toggle {
        opacity: 60%;
        width: 2rem;
        padding-left: 0.5rem;

        svg {
          transform: scale(0.7);
        }
      }
    }
  }

  &.toggled .trip {
    border-right: 4px solid;
    ${tw`border-brand-500`}
  }

  .rightContent {
    height: 100%;
    flex: 0 1 16rem;
    align-self: center;
    justify-self: flex-end;
    position: relative;
    display: flex;


    ${(props) => props.theme.media.tablet_landscape_up`
      width: 14rem;
    `}

    ${(props) => props.theme.media.desktop_up`
      width: 15rem;
    `}

    ${(props) => props.theme.media.big_desktop_up`
      width: 16rem;
    `}

    ${(props) => props.theme.media.xxl_desktop_up`
      width: 18rem;
    `}

    &.detailed {
      align-self: center;
    }

    &.in_progress {
      padding-bottom: 4rem;
    }
  }

  .minidash {
    display: none;
  }

  .minidash-mobile {
    padding: 0 0 2rem;
    margin-left: 7rem;
    position: relative;
    z-index: 1;

    header {
      padding: 1rem 0.5rem;
      ${tw`flex items-center w-full`}
    }

    ${(props) => props.theme.media.tablet_landscape_up`
      margin-left: 8rem;
    `}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .trip {
      .tripStates {
        width: auto;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: ${props.theme.colors.white};
        opacity: 0;
        z-index: 200;
        transition: all ease-out .2s;
        pointer-events: none;
      }
    }

    .state {
      display: flex;
      align-items: center;
    }

    .toggle {
      display: block;
      opacity: 60%;
      width: 4rem;
      padding-left: 2.5rem;

      svg {
        transform: scale(0.7);
      }
    }

    .time {
      align-self: center;
      margin-right: 0.3rem;
    }

    .minidash {
      display: flex;
      flex-direction: column;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 1;
      align-self: center;
    }

    &:not(.untoggled):not(.toggled) .trip {
      transition: all ease-out .15s;
      border-right: 4px solid transparent;

      &:hover {
        border-right: 4px solid;
        ${tw`border-brand-500`}
      }
    }

    &.toggled .trip,
    &.untoggled .trip {

    }

    &.toggled .trip {
      // box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.2);
      transform: translate3d(0, 0, 0);
    }

    &.untoggled .trip {
      position: relative;
      border-right: 4px solid transparent;

      &:before {
        opacity: 0.6;
      }

      &:hover {
        border-right: 4px solid;
        ${tw`border-brand-500`}
      }
    }
  `}
`

const StyledKPIWidget = styled(KPIWidget)`
  padding: 0;
  line-height: 2rem;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
    flex: 1;

    .title {
      display: flex;
      span {
        order: 1;
      }

      svg {
        margin-right: 0.7rem;
      }
    }

    .value {
      font-size: 1.2rem;
      white-space: nowrap;
      ${tw`text-right ml-auto`}
    }
  }

  &.data-small {
    ${tw`text-lg w-auto flex items-center leading-none`}
    .title,
    .item,
    .value {
      ${tw`text-lg`}
      svg {
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
      }
    }

    .value {
      ${tw`text-right ml-auto`}
    }

    &.data-detail {
      .item {
        ${tw`w-full flex py-1`}

        .title {
        }
        .value {
          ${tw`self-end ml-auto`}
        }
      }
    }
  }
`

const TripContinuation = styled.span`
  &:before {
    left: 5rem;
    display: block;
    top: 1rem;
    bottom: 2rem;
    content: "";
    z-index: 100;
    height: 2rem;
    position: relative;
    ${tw`border border-solid border-0 border-white border-l absolute`}
  }

  &:after {
    left: 5rem;
    display: block;
    top: 1rem;
    bottom: 2rem;
    content: "";
    height: 2rem;
    z-index: 110;
    position: relative;
    ${tw`border border-dashed border-0 border-brand-gray-dark opacity-40 border-l absolute`}
  }

  + svg {
    margin-left: 0.45rem;
    transform: rotateZ(180deg);
    position: absolute;
    left: 4rem;
    top: 1rem;
    ${tw`fill-current text-brand-gray-dark opacity-40`};
  }
`

const TripContinuationStart = styled(TripContinuation)`
  &:before {
    left: 5rem;
    display: block;
    bottom: 0;
    top: 1rem;
    content: "";
    z-index: 100;
    position: relative;
    ${tw`border border-solid border-0 border-white border-l absolute`}
  }

  &:after {
    left: 5rem;
    display: block;
    bottom: 0;
    top: 1rem;
    content: "";
    z-index: 110;
    position: relative;
    ${tw`border border-dashed border-0 border-brand-gray-dark opacity-40 border-l absolute`}
  }

  + svg {
    margin-top: -1rem;
  }
`
const TripContinuationEnd = styled(TripContinuation)`
  + svg {
    margin-top: 1.5rem;
  }
`

const StyledAccordion = styled(Accordion)``

const EventBulletComponent: React.FC = ({ event, ...bulletProps }) => {
  if (event.isPseudoStart) {
    return (
      <>
        <TripContinuationStart />
        <Icon icon="location-arrow" size={IconSizeEnum.SM} />
      </>
    )
  }
  if (event.isPseudoEnd) {
    return (
      <>
        <TripContinuationEnd />
        <Icon icon="location-arrow" size={IconSizeEnum.SM} />
      </>
    )
  }
  return <StyledTrackerStatus tw="text-white mx-auto" {...bulletProps} />
}

export const Trip: React.FC = ({
  trip,
  prevTrip,
  getTripTime,
  deviceType,
  displayKeys,
  deviceHasCAN,
  showSeconds,
  showDefaultExpanded,
  dayToggled,
  isFirstTripOfDay,
  completedDailyDistanceToday,
  fuelConsumptionToday,
  ...props
}) => {
  const { trip_states, in_progress } = trip
  const first_trip_state = first(trip_states)
  const last_trip_state = last(trip_states)
  const middle_trip_states = trip_states.slice(1, -1)
  const [showAllTripStates, setShowAllTripStates] = useState(
    !showDefaultExpanded || middle_trip_states.length === 0
  )
  const {
    state: {
      pinnedRoutes,
      toggledRoute,
      toggledRouteTimestamp,
      logViewSettings,
    },
    dispatch,
  } = useContext(HistoryContext)

  const toggled = useMemo(() => {
    if (pinnedRoutes.length > 0 || toggledRoute !== null) {
      return pinnedRoutes.includes(trip.id) || toggledRoute === trip.id
    }
    return null
  }, [pinnedRoutes, toggledRoute])

  useEffect(() => {
    if (!dayToggled) {
      setShowAllTripStates(false)
    } else if (dayToggled && middle_trip_states.length === 0) {
      setShowAllTripStates(true)
    }
  }, [dayToggled])

  useEffect(() => {
    setShowAllTripStates(!showDefaultExpanded)
  }, [showDefaultExpanded])

  if (trip_states.length === 0) return null

  const summaryData = summaryDataFromDay(
    { trips: [trip] },
    deviceHasCAN,
    showSeconds,
    [],
    logViewSettings.showZeroDistanceTrips
  )

  const onClickHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    dispatch(
      HistoryActions(HistoryTypes.ToggleRoute, {
        routeId: trip.id,
      })
    )
  }

  const onPinHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    dispatch(
      HistoryActions(HistoryTypes.PinRoute, {
        routeId: trip.id,
      })
    )
  }

  const viewOnMapHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    dispatch(HistoryActions(HistoryTypes.ToggleMap, {}))
  }

  // Trip activity data
  const drivingHoursToday = trip.summary.find(
    (x) => x.name === DisplayKeyEnum.DrivingHoursToday
  )
  const idleHoursToday = trip.summary.find(
    (x) => x.name === DisplayKeyEnum.IdleHoursToday
  )
  const engingeHoursToday = trip.summary.find(
    (x) => x.name === DisplayKeyEnum.EngineHoursToday
  )

  const dailyDistanceToday = getDisplayKey(
    displayKeys,
    DisplayKeyEnum.DailyDistanceToday
  )

  const totalEngineHours = getDisplayKey(
    summaryData.total,
    DisplayKeyEnum.TotalEngineHours,
    history_category
  )

  const totalDrivingHours = drivingHoursToday
    ? {
        ...getDisplayKey(trip.summary, DisplayKeyEnum.DrivingHoursToday),
        durationRaw: secondsFromDuration(drivingHoursToday.value),
      }
    : getDisplayKey(summaryData.total, DisplayKeyEnum.TotalDrivingHours)

  const totalMovingHours = getDisplayKey(
    summaryData.total,
    DisplayKeyEnum.TotalMovingHours
  )

  const totalIdleHours = idleHoursToday
    ? {
        ...getDisplayKey(trip.summary, DisplayKeyEnum.IdleHoursToday),
        durationRaw: secondsFromDuration(idleHoursToday.value),
      }
    : getDisplayKey(summaryData.total, DisplayKeyEnum.TotalIdleHours)

  // Trip meta data
  const totalDistance = getDisplayKey(
    trip.summary,
    DisplayKeyEnum.TotalDistance,
    history_category
  )

  const estimatedDistanceOfCurrentTrip =
    Number(dailyDistanceToday.value.replace(",", ".")) -
    Number(completedDailyDistanceToday)

  const fuelConsumptionTodayTotal = getDisplayKey(
    displayKeys,
    DisplayKeyEnum.FuelConsumptionToday
  )
  const totalFuel =
    in_progress && fuelConsumptionTodayTotal?.value
      ? {
          ...getDisplayKey(trip.summary, DisplayKeyEnum.TotalFuel),
          value: (
            +fuelConsumptionTodayTotal?.value.replace(",", ".") -
            +fuelConsumptionToday
          ).toFixed(1),
        }
      : getDisplayKey(trip.summary, DisplayKeyEnum.TotalFuel)

  const tripEngineHoursSummary = summaryData.total.filter((x) => {
    return ["Arbejdet", "Tomgang"].includes(x.kpi)
  })

  const tripEngineHours = sumBy(tripEngineHoursSummary, "durationRaw")

  const tripEngineHoursKpi = {
    key: "totalEngingeHours",
    icon: "timer",
    kpi: "totalEngineHours",
    translationKey: "totalEngineHours",
    value: engingeHoursToday
      ? formatSecondsToDuration(secondsFromDuration(engingeHoursToday.value), {
          showSeconds,
        })
      : formatSecondsToDuration(tripEngineHours, {
          showSeconds,
        }),
    type: "string",
    durationRaw: engingeHoursToday
      ? secondsFromDuration(engingeHoursToday.value)
      : sumBy(tripEngineHoursSummary, "durationRaw"),
  }

  const onBulletClick = (data) => {
    const timestamp = data.timestamp
    dispatch(
      HistoryActions(HistoryTypes.ToggleRouteTimestamp, {
        timestamp: timestamp === toggledRouteTimestamp ? null : timestamp,
      })
    )
  }

  const renderTripAddress = (tripState: ITripState) => {
    const tripStateIndex = trip_states.findIndex(
      (state) => tripState.timestamp === state.timestamp
    )

    if (
      (tripStateIndex === trip_states.length - 1 && !isFirstTripOfDay) ||
      tripState.isPseudoStart ||
      tripState.isPseudoEnd
    ) {
      return null // Trip duration, mileage etc.
    }
    return (
      <span
        tw="opacity-80 max-w-sm md:(max-w-lg) lg:(max-w-xl)"
        /* css={
          isFirstTripOfDay &&
          tripStateIndex === trip_states.length - 1 && [
            tw`text-xl font-bold opacity-80`,
          ]
        } */
      >
        {tripState.address === "?" ? (
          <span tw="italic">Ukendt adresse</span>
        ) : (
          tripState.address
        )}
      </span>
    )
  }

  const renderOngoingTripString = (status: DeviceStatusEnum) => {
    switch (status) {
      case DeviceStatusEnum.RUNNING:
      case DeviceStatusEnum.IN_TRANSPORT:
        return "Igangværende tur"
      case DeviceStatusEnum.IDLE:
        return "Tomgang"
      case DeviceStatusEnum.WORKING:
        return "Arbejder"
    }
  }

  const renderTripDuration = (tripState: ITripState) => {
    if (tripState.isPseudoStart) {
      return (
        <>
          <span tw="whitespace-nowrap">Tur fortsat fra forrige dag</span>
          {totalDistance.value > 0 &&
            ![DeviceTypeEnum.Machine, DeviceTypeEnum.Beacon].includes(
              deviceType
            ) && (
              <span tw="ml-1">
                (
                <span tw="font-bold">
                  {in_progress
                    ? estimatedDistanceOfCurrentTrip.toFixed(1)
                    : totalDistance.formatDataFnc(totalDistance.value)}
                </span>
                {totalDistance.unit})
              </span>
            )}
        </>
      )
    }

    if (tripState.isPseudoEnd) {
      return <span tw="whitespace-nowrap">Tur fortsætter næste dag</span>
    }

    const tripStateIndex = trip_states.findIndex(
      (state) => tripState.id === state.id
    )

    if (tripStateIndex === trip_states.length - 1) {
      const tripDuration = formatSecondsToDuration(tripTime.seconds, {
        showSeconds,
        verboseDuration: true,
      })

      return (
        <span tw="whitespace-nowrap">
          {tripDuration.hrs !== "" && (
            <>
              <span tw="font-bold">{tripDuration.hrs.split(" ")[0]}</span>
              <span tw="ml-1">{tripDuration.hrs.split(" ")[1]}</span>
            </>
          )}
          {tripDuration.min !== "" && (
            <>
              <span tw="font-bold">{tripDuration.min.split(" ")[0]}</span>
              <span tw="ml-1">
                {tripDuration.min.split(" ")[1]}{" "}
                {tripDuration.min.split(" ")[2]}
              </span>
            </>
          )}
          {tripDuration.sec !== null && (
            <>
              <span tw="font-bold">{tripDuration.sec.split(" ")[0]}</span>
              <span tw="ml-1">{tripDuration.sec.split(" ")[1]}</span>
            </>
          )}
          <span tw="ml-1">
            (
            <span tw="font-bold">
              {in_progress
                ? estimatedDistanceOfCurrentTrip.toFixed(1)
                : totalDistance.formatDataFnc(totalDistance.value)}
            </span>
            {totalDistance.unit})
          </span>
        </span>
      )
    }

    const startTimestamp = trip_states[tripStateIndex - 1]
      ? tripState.timestamp
      : prevTrip
      ? prevTrip.start_timestamp
      : null
    const endTimestamp = trip_states[tripStateIndex - 1]
      ? trip_states[tripStateIndex - 1].timestamp
      : prevTrip
      ? prevTrip.stop_timestamp
      : null

    /* const tripTimeInSeconds = trip_states[tripStateIndex - 1]
      ? getTripTime([trip_states[tripStateIndex - 1].timestamp, tripState.timestamp])
      : null

    console.log(tripTimeInSeconds) */

    const mappedStatus = getStatusCode(tripState.status)
    const { strKey } = statusMap.find((s) => s.code === mappedStatus)
    const mappedStatusText = I18n({ strKey })

    const duration =
      startTimestamp && endTimestamp
        ? formatSecondsToDuration(
            (+new Date(endTimestamp) - +new Date(startTimestamp)) / 1000,
            { showSeconds }
          )
        : in_progress
        ? renderOngoingTripString(mappedStatus)
        : "Dag afsluttet"

    return <span>{duration}</span>
  }

  const renderMiniDashboard = () => {
    return (
      <>
        <div>
          {deviceType === DeviceTypeEnum.Car && (
            <>
              {totalDrivingHours.durationRaw && (
                <StyledKPIWidget
                  className="data data-small"
                  {...totalDrivingHours}
                />
              )}

              <Accordion toggled={showAllTripStates}>
                <div tw="pb-4">
                  {totalIdleHours.durationRaw > 0 && (
                    <StyledKPIWidget
                      className="data data-small data-detail"
                      {...totalIdleHours}
                    />
                  )}
                </div>
              </Accordion>
            </>
          )}
          {deviceType === DeviceTypeEnum.Machine && (
            <>
              {tripEngineHoursKpi.durationRaw > 0 && (
                <StyledKPIWidget
                  className="data data-small"
                  {...tripEngineHoursKpi}
                />
              )}
              {totalMovingHours.durationRaw > 0 && (
                <StyledKPIWidget
                  className="data data-small"
                  {...totalMovingHours}
                  translationKey="totalMovingHours"
                />
              )}
              {(totalEngineHours.durationRaw > 0 ||
                totalIdleHours.durationRaw > 0) && (
                <Accordion toggled={showAllTripStates}>
                  <div tw="pt-2 pb-4 pl-6">
                    {totalEngineHours.durationRaw > 0 && (
                      <StyledKPIWidget
                        className="data data-small data-detail"
                        {...totalEngineHours}
                        translationKey="totalWorkHours"
                        icon="hammer-solid"
                      />
                    )}
                    {totalIdleHours.durationRaw > 0 && (
                      <StyledKPIWidget
                        className="data data-small data-detail"
                        {...totalIdleHours}
                      />
                    )}
                  </div>
                </Accordion>
              )}
              {totalDrivingHours.durationRaw > 0 && (
                <StyledKPIWidget
                  className="data data-small"
                  {...totalDrivingHours}
                  icon="play"
                  translationKey="legend_ignition"
                />
              )}
            </>
          )}
          {deviceHasCAN && !isNaN(totalFuel.value) && (
            <StyledKPIWidget
              tw="mt-2"
              className="data data-small"
              {...totalFuel}
            />
          )}
        </div>
      </>
    )
  }

  const tripTime = getTripTime(trip_states)

  return (
    <StyledTrip
      className={toggled !== null ? (toggled ? `toggled` : `untoggled`) : ""}
    >
      <div
        onClick={onClickHandler}
        tw="cursor-pointer relative p-0"
        className="trip"
      >
        <div className="tripContent">
          <EventFeed
            className="tripStates"
            lastEvent={{
              ...first_trip_state,
              icon: in_progress ? null : "stop",
            }}
            firstEvent={{
              ...last_trip_state,
              icon: "play",
            }}
            defaultExpanded={!showDefaultExpanded}
            remainingEvents={middle_trip_states.reverse()}
            resetStateWhenTrue={showDefaultExpanded && !showAllTripStates}
            expandRemainingEventsButtonText={`+ ${middle_trip_states.length}

          ${middle_trip_states.length === 1 ? ` statusskift` : ` statusskift`}`}
            renderComponents={{
              EventBulletComponent: <EventBulletComponent />,
              FirstEventTimestampComponent: <span tw="font-bold" />,
              EventTimestampComponent: <span tw="opacity-60" />,
              LastEventTimestampComponent: <span tw="font-bold" />,
            }}
            onFeedExpanded={() => setShowAllTripStates(true)}
            itemOptions={{
              eventBulletPropertyKeys: ["status", "emphasize"],
              eventBulletIconKey: "icon",
              eventBulletIconSize: IconSizeEnum.SM,
              eventBulletCss: tw`w-6 h-6`,
              eventIsInProgress: in_progress,
              eventTimestampKey: "timestamp",
              eventTimestampFormat: "HH:mm",
              eventEmphasizeCondition: [
                "timestamp",
                toggledRouteTimestamp,
                tw``,
              ],
              eventBulletClickEvent: onBulletClick,
              renderEventContent: renderTripAddress,
              renderEventDescription: renderTripDuration,
            }}
          />
          <Responsive
            phone={false}
            largeDesktop={
              <div
                className={[
                  "rightContent",
                  showAllTripStates ? `detailed` : ``,
                  in_progress ? `in_progress` : ``,
                ].join(" ")}
              >
                <div className="minidash">{renderMiniDashboard()}</div>
                {in_progress && (
                  <div tw="absolute bottom-0 right-0">
                    <Link
                      to="/app"
                      tw="ml-auto justify-end flex items-center text-brand-500"
                    >
                      <Icon tw="w-4 h-4 mr-2" icon="map" />
                      <span>Se på kortet</span>
                    </Link>
                  </div>
                )}{" "}
              </div>
            }
          />
        </div>
        <Responsive
          phone={
            <StyledAccordion
              toggled={toggled}
              maxHeight="500px"
              transitionDuration=".2s"
            >
              <div className="minidash-mobile">
                <header tw="flex p-0 mb-2 items-center w-full border-solid border-0 border-b border-brand-gray-light">
                  <h3 tw="text-xl my-0 opacity-80">Denne tur</h3>
                  <span
                    tw="text-lg flex items-center text-brand-500 ml-auto cursor-pointer cursor-pointer hover:opacity-60 transition lg:(hidden)"
                    onClick={(e) => viewOnMapHandler(e)}
                  >
                    <span tw="mr-2">Se turen på kortet</span>
                    <Icon
                      tw="mt-px"
                      size={IconSizeEnum.XS}
                      icon="chevron-right"
                    />
                  </span>
                </header>
                {renderMiniDashboard()}
              </div>
            </StyledAccordion>
          }
          largeDesktop={<></>}
        />
      </div>
    </StyledTrip>
  )
}
