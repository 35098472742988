import React, { useReducer, createContext } from "react"
import { SET_LANG, TOGGLE_DISMISS } from "./actions"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

const initialState = {
  lang: "da",
  dismissedKeys: [],
}
const SettingsContext = createContext(initialState)
const { Provider } = SettingsContext

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const toggleDismissKey = (keys, key) => {
  const newKeys = keys.slice()
  const exists = keys.find((x) => x === key) ? true : false
  return exists ? newKeys.filter((x) => x !== key) : [...newKeys, key]
}

const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_LANG:
        return {
          ...state,
          lang: action.lang,
        }
      case TOGGLE_DISMISS:
        return {
          ...state,
          dismissedKeys: toggleDismissKey(state.dismissedKeys, action.key),
        }
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { SettingsContext, SettingsProvider }
