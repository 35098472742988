import { DeviceStatusEnum } from "app/Device/types"
import { convertCoordinatesToGeoJson, getClusters } from "app/GoogleMap/helper"
import { IPosition, IMapRoute } from "app/GoogleMap/map.types"
import { differenceInSeconds, isSameSecond } from "date-fns"
import { sortBy, maxBy, flatMap, uniqBy } from "lodash-es"
import { formatSecondsToDuration } from "utils/datetime"
import { IRoute, IRouteGeopoint } from "../history.types"

export const filterPointsOfInterest = (
  points: IPosition[],
  toggledRouteTimestamp: string
): IPosition[] => {
  const sortedPoints = points.sort((a, b) => {
    return new Date(a.properties.timestamp) < new Date(b.properties.timestamp)
      ? -1
      : 1
  })

  const start = sortedPoints[0]
  const lastItem = sortedPoints[sortedPoints.length - 1]

  const ignition = sortedPoints.filter(
    (point) => +point.properties.status === DeviceStatusEnum.RUNNING
  )

  const idle = sortedPoints.filter(
    (point) => +point.properties.status === DeviceStatusEnum.IDLE
  )

  const stops = sortedPoints.filter(
    (point) => +point.properties.status === DeviceStatusEnum.STOPPED
  )

  const inTransport = sortedPoints.filter(
    (point) => +point.properties.status === DeviceStatusEnum.IN_TRANSPORT
  )

  const working = sortedPoints.filter(
    (point) => +point.properties.status === DeviceStatusEnum.WORKING
  )

  // Also set the start and end-point to easily access those to render Points and Clusters.

  const pts =
    toggledRouteTimestamp !== null
      ? [...ignition, ...idle, ...inTransport, ...working, ...stops]
      : [start, ...idle, ...inTransport, ...working, ...stops]

  if (points.some((pt) => pt.properties.in_progress)) {
    pts.push(lastItem)
  }

  const ptsOfInterest = pts
    .sort((a, b) => {
      return new Date(a.properties.timestamp) < new Date(b.properties.timestamp)
        ? -1
        : 1
    })
    .filter((pt) => {
      if (toggledRouteTimestamp) {
        return (
          isSameSecond(
            new Date(pt.properties.timestamp),
            new Date(toggledRouteTimestamp)
          ) ||
          `${pt.properties.timestamp}Z` === toggledRouteTimestamp ||
          `${pt.properties.timestamp}` === toggledRouteTimestamp
        )
      }
      return true
    })

  return ptsOfInterest.length === 0 &&
    points.some((pt) => pt.properties.in_progress)
    ? [lastItem]
    : ptsOfInterest
}

export const setPoints = (
  routes,
  pinnedRoutes,
  toggledRoute,
  toggledRouteTimestamp
) => {
  const toggledRoutes = routes.filter(
    (route) => pinnedRoutes.includes(route.id) || toggledRoute === route.id
  )

  const isolationMode = toggledRoutes.length > 0

  const reversedRoutes = sortBy(routes.slice(0).reverse(), (x) =>
    maxBy(x.route.map((rt) => rt.timestamp))
  )

  // On each run, map over toggled intervals, and find the route with the equivalent id
  const newMappedRoutes: IMapRoute[] = reversedRoutes
    .map((rt: IRoute, routeIndex) => {
      const r: IMapRoute = {}
      // If theres a match, convert the routes points to GeoJson coordinates.
      if (rt) {
        const isIsolated = toggledRoutes.some((route) => route.id === rt.id)

        const strokeStyle = {
          strokeColor: "#374649",
          strokeOpacity: 1,
          strokeWeight: 2,
          zIndex: 1000,
        }

        if (isolationMode && !isIsolated) {
          strokeStyle.strokeColor = "#374649"
          strokeStyle.strokeOpacity = 0.5
          strokeStyle.strokeWeight = 1
          strokeStyle.zIndex = 100
        }
        r.pointsRaw = rt.route
          .sort((a, b) => {
            return new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1
          })
          .map((point: IRouteGeopoint, i) => {
            if (point.status !== "") {
              const pointOfInterestKey = `${point.timestamp}_${point.status}_${i}`
              const remainingPoints = rt.route.slice(i + 1)

              let nextStatusChange = remainingPoints.find(
                (p) => p.status !== ""
              )

              // console.log("nextStatusChange", nextStatusChange)

              if (!nextStatusChange) {
                // No more status changes in this trip, probably.
                // Check if there's more trips today.
                if (routeIndex + 1 < reversedRoutes.length) {
                  // We have more trips! nextStatusChange is the first route item of the next trip.
                  nextStatusChange = reversedRoutes[routeIndex + 1].route[0]
                  /* console.log(
                      "next identified route",
                      reversedRoutes[routeIndex + 1]
                    )
                    console.log(
                      "nextStatusChange (from next route)",
                      nextStatusChange
                    ) */
                } else {
                  return {
                    ...point,
                    pointOfInterestKey,
                  }
                }
              }

              const diffInSeconds = differenceInSeconds(
                new Date(nextStatusChange.timestamp),
                new Date(point.timestamp)
              )

              return {
                ...point,
                duration: formatSecondsToDuration(diffInSeconds, {
                  shortUnits: true,
                }),
                pointOfInterestKey,
              }
            }
            return point
          })
        r.geoPoints = convertCoordinatesToGeoJson(r.pointsRaw, rt.id)
        r.polyline = {
          path: r.pointsRaw.map((x: IRouteGeopoint, i) => {
            return {
              ...x.pos,
              isPointOfInterest: x.status !== "",
              renderPointOfInterestIndex: x.status !== "" ? i : "",
            }
          }),
          geodesic: true,
          ...strokeStyle,
        }
        r.isIsolated = isIsolated
        return r
      }
      return r
    })
    .filter((r) => Object.keys(r).length > 0)

  const newGeoPoints =
    newMappedRoutes.length > 0
      ? flatMap(newMappedRoutes.map((rt) => rt?.geoPoints))
      : []

  const newPointsOfInterest =
    newGeoPoints.length > 0
      ? isolationMode
        ? filterPointsOfInterest(
            flatMap(
              newMappedRoutes
                .filter((rt) => rt?.isIsolated)
                .map((rt) => rt?.geoPoints)
            )
              .filter((pt) => pt.properties.status !== "")
              .reverse(),
            toggledRouteTimestamp
          )
        : filterPointsOfInterest(
            newGeoPoints.filter((pt) => pt.properties.status !== "").reverse(),
            toggledRouteTimestamp
          )
      : []

  const newPointsAndClusters =
    newMappedRoutes.length > 0
      ? isolationMode
        ? flatMap(
            newMappedRoutes
              .filter((rt) => rt?.isIsolated)
              .map((rt) => rt?.pointsOfInterest)
          )
        : flatMap(newMappedRoutes.map((rt) => rt.pointsOfInterest))
      : []

  const isolatedRouteGeoPoints = flatMap(
    newMappedRoutes.filter((rt) => rt?.isIsolated).map((rt) => rt?.geoPoints)
  )

  const newTooltipGeoPoints = isolationMode
    ? isolatedRouteGeoPoints.map((point) => ({
        latitude: point.properties.pos.lat,
        longitude: point.properties.pos.lng,
        ...point,
      }))
    : newGeoPoints.map((point) => ({
        latitude: point.properties.pos.lat,
        longitude: point.properties.pos.lng,
        ...point,
      }))

  return {
    mappedRoutes: newMappedRoutes,
    geoPoints: newTooltipGeoPoints.sort((a, b) => {
      return new Date(a.properties.timestamp) < new Date(b.properties.timestamp)
        ? -1
        : 1
    }),
    tooltipGeoPoints: newTooltipGeoPoints.filter(
      (pt) => pt.properties.status === ""
    ),
    pointsAndClusters: newPointsAndClusters,
    pointsOfInterest: uniqBy(
      newPointsOfInterest
        .map((pt) => {
          if (pt) {
            return {
              ...pt,
              latitude: pt.properties.pos.lat,
              longitude: pt.properties.pos.lng,
            }
          }
          return pt
        })
        .filter(Boolean),
      "key"
    ),
  }
}

export const arrowIntervalHelper = (mapOptions, k) => {
  const roundedZoomLevel = Math.floor(mapOptions.zoom)
  switch (roundedZoomLevel) {
    case 1:
      return Math.floor(k / roundedZoomLevel) / 8
    case 2:
      return Math.floor(k / roundedZoomLevel) / 8
    case 3:
      return Math.floor(k / roundedZoomLevel) / 8
    case 4:
      return Math.floor(k / roundedZoomLevel) / 8
    case 5:
      return Math.floor(k / roundedZoomLevel) / 8
    case 6:
      return Math.floor(k / roundedZoomLevel) / 8
    case 7:
      return Math.floor(k / roundedZoomLevel) / 5
    case 8:
      return Math.floor(k / roundedZoomLevel) / 3
    case 9:
      return Math.floor(k / roundedZoomLevel) / 5
    case 10:
      return Math.floor(k / roundedZoomLevel) / 6
    case 11:
      return Math.floor(k / roundedZoomLevel) / 5
    case 12:
      return Math.floor((k * 2) / roundedZoomLevel) / 3
    case 13:
      return Math.floor(k / roundedZoomLevel) / 3
    case 14:
      return Math.floor((k * 0.5) / roundedZoomLevel)
    case 15:
      return Math.floor(k / roundedZoomLevel) * 0.2
    default:
      return Math.floor((k / roundedZoomLevel) * 0.2)
  }
}

export const getPointsAndClusters = (src: IPosition[], mapOptions) => {
  if (mapOptions.bounds && mapOptions.zoom) {
    // Creates renderable map markers based on the Trackers collection
    const { nw, se, sw, ne } = mapOptions.bounds
    const superclusterBounds = [nw.lng, sw.lat, se.lng, ne.lat]
    const { _instance, clusters } = getClusters(
      src,
      superclusterBounds,
      mapOptions.zoom
    )
    return { clusters, cluster: _instance }
  }
}

export const pointsOfInterestRenderer = (
  pointsSource,
  pointRenderFnc,
  clusterRenderFnc,
  mapOptions
) => {
  const first = pointsSource[0]
  const last = pointsSource[pointsSource.length - 1]
  const renderPointsArray: React.ReactElement[] = [
    pointRenderFnc(first, true),
    pointsSource.length <= 1 ? null : pointRenderFnc(last, false, true),
  ].filter(Boolean)

  const modifiedPointsSource = pointsSource.slice(1, pointsSource.length - 1)

  if (modifiedPointsSource.length > 0) {
    const ptsAndClusters = getPointsAndClusters(
      modifiedPointsSource,
      mapOptions
    )
    if (ptsAndClusters?.clusters && ptsAndClusters?.cluster) {
      const pts = clusterRenderFnc(
        ptsAndClusters.clusters,
        ptsAndClusters.cluster
      )
      renderPointsArray.push(...pts)
    }
  }

  return renderPointsArray
}
