/* Action types */
export const TOGGLE_CLUSTER = "TOGGLE_CLUSTER"
export const SET_CENTER = "SET_CENTER"
export const SET_ACTIVE_CLUSTER = "SET_ACTIVE_CLUSTER"
export const SET_ALLOW_SCROLL = "SET_ALLOW_SCROLL"
export const SET_GOOGLE_MAPS_INSTANCE = "SET_GOOGLE_MAPS_INSTANCE"

/* Action creators */

export const toggleCluster = (id) => {
  return {
    type: TOGGLE_CLUSTER,
    id,
  }
}

export const setGoogleMapsInstance = (
  map: google.maps.Map<Element>,
  maps: typeof google.maps
) => {
  return {
    type: SET_GOOGLE_MAPS_INSTANCE,
    map,
    maps,
  }
}

export const setActiveCluster = (id) => {
  return {
    type: SET_ACTIVE_CLUSTER,
    id,
  }
}

export const setCenter = (coordinates) => {
  return {
    type: SET_CENTER,
    coordinates,
  }
}

export const setAllowScroll = (allow) => {
  return {
    type: SET_ALLOW_SCROLL,
    allow,
  }
}
