import React, { useContext } from "react"
import "twin.macro"
import {
  IContactOrContactGroup,
  PopOverRoutesEnum,
} from "app/Conversations/conversations.types"
import { SearchContext } from "app/Search/context"
import { Results } from "./Results"
import {
  BaseContact,
  ContactPrefix,
  ContactHeader,
} from "app/Conversations/Contact"
import Icon from "app/Icon"
import { ConversationContext } from "app/Conversations/context"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import { IconSizeEnum } from "lib/Icon"
import Panel from "app/Panel"

interface IContactListProps {
  children?: React.ReactNode
  selectedContacts?: IContactOrContactGroup[]
  onSearchResultSelect?: (c: IContactOrContactGroup) => void
  onSearchResultDeselect?: (c: IContactOrContactGroup) => void
  hideCreateButtons?: boolean
  onlySearchResults?: boolean
  allowSelectWhileDisabled?: boolean
}

export const ContactList: React.FC<IContactListProps> = ({
  children,
  onSearchResultSelect,
  onSearchResultDeselect,
  hideCreateButtons,
  onlySearchResults,
  allowSelectWhileDisabled = false,
  forwardRef,
  ...props
}) => {
  const {
    state: { query, suggestions, results },
  } = useContext(SearchContext)
  const { dispatch } = useContext(ConversationContext)

  const onCreateGroupHandler = () => {
    dispatch(
      ConversationActions(ConversationTypes.ToggleRoute, {
        key: PopOverRoutesEnum.CreateGroup,
      })
    )
  }

  const renderCreateButtons = () => {
    if (hideCreateButtons) return null
    return (
      <>
        <BaseContact
          prefix={
            <ContactPrefix>
              <Icon size={IconSizeEnum.SM} icon="person" />
            </ContactPrefix>
          }
          onClick={() =>
            dispatch(
              ConversationActions(ConversationTypes.ToggleRoute, {
                key: PopOverRoutesEnum.CreateContact,
              })
            )
          }
        >
          {query.length > 1 &&
          results.length === 0 &&
          suggestions.length === 0 ? (
            <ContactHeader
              name={
                <span tw="font-normal">
                  + Opret <span tw="font-bold">{query}</span>...
                </span>
              }
            />
          ) : (
            <ContactHeader
              name={<span tw="font-normal">+ Opret ny kontakt</span>}
            />
          )}
        </BaseContact>
        <BaseContact
          onClick={onCreateGroupHandler}
          prefix={
            <ContactPrefix>
              <Icon size={IconSizeEnum.MD} icon="groups" />
            </ContactPrefix>
          }
        >
          <ContactHeader
            name={<span tw="font-normal">+ Opret ny gruppe</span>}
          />
        </BaseContact>
      </>
    )
  }

  if (onlySearchResults) {
    return query.length > 0 ? (
      <ul tw="p-0 m-0 bg-white shadow-card" ref={forwardRef} {...props}>
        <Results
          query={query}
          suggestions={suggestions}
          results={results}
          onSearchResultSelect={
            onSearchResultSelect ? onSearchResultSelect : () => ({})
          }
          onSearchResultDeselect={
            onSearchResultDeselect ? onSearchResultDeselect : () => ({})
          }
          allowSelectWhileDisabled={allowSelectWhileDisabled}
        />
        {renderCreateButtons()}
      </ul>
    ) : (
      <Panel tw="flex flex-col items-center pt-24 bg-white">
        <span tw="w-24 h-24 bg-gray-200 flex text-gray-600 items-center justify-center rounded-full">
          <Icon icon="address-book" size={IconSizeEnum.LG} />
        </span>
        <h3 tw="mb-0">Søg blandt kontakter og grupper</h3>
        <p tw="text-2xl text-gray-400">Resultater dukker op her</p>
      </Panel>
    )
  }

  return (
    <ul tw="p-0 m-0" ref={forwardRef} {...props}>
      {query.length > 0 ? (
        <>
          <Results
            query={query}
            suggestions={suggestions}
            results={results}
            onSearchResultSelect={
              onSearchResultSelect ? onSearchResultSelect : () => ({})
            }
            onSearchResultDeselect={
              onSearchResultDeselect ? onSearchResultDeselect : () => ({})
            }
            allowSelectWhileDisabled={allowSelectWhileDisabled}
          />
          {renderCreateButtons()}
        </>
      ) : (
        <>
          {renderCreateButtons()}
          {children}
        </>
      )}
    </ul>
  )
}
